<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonEntity, PersonEntityFilter } from '@/modules/hub/data/person-entity/list'
	import { PersonEntityService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonEntity;
			},
			
			filterEntity()
			{
				return PersonEntityFilter;
			},

			service()
			{
				return new PersonEntityService();
			},

			filterData()
			{
				const personEntityId =
					this.$route.query['personEntityId'];

				if (personEntityId)
				{
					return {
						'personEntityId': personEntityId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>