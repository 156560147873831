<template>
	<span>{{value}}</span>
</template>
<script>
	export default {
		props:
		{
			data: Object
		},

		computed:
		{
			hasValue()
			{
				return this.data.value != null && this.data.value != undefined
			},

			value()
			{
				if (this.hasValue)
				{
					if (this.data.value == true)
					{
						return this.$loc.fromResource('global.yes')
					}

					return this.$loc.fromResource('global.no')
				}
			}
		}
	}
</script>
<style>
	.kaveh-table-color {
		width: 25px;
		height: 25px;
		border: #ddd solid 1px;
		display: inline-block;
		border-radius: 100%;
	}
</style>
