//GeneratorFlag:[NoChange]
export default {
	path: 'military-type',
	name: 'peopleNet.baseInfo.militaryType.layout',
	redirect: to => {
		return {name: 'peopleNet.militaryType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-info/military-type"],
		resourceKey: 'peopleNet.views.militaryType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[MilitaryTypeCreate]"
		{
			path: 'create',
			name: 'peopleNet.militaryType.create',
			component: () => import('@/modules/people-net/views/military-type/military-type-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.create',
				clientAccessKeys:["/people-net/base-info/military-type/create"],
				serverAccessKeys:["/people-net/military-type/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[MilitaryTypeCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.militaryType.createImport',
			component: () => import('@/modules/people-net/views/military-type/military-type-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.createImport',
				clientAccessKeys:["/people-net/base-info/military-type/create-import"],
				serverAccessKeys:["/people-net/military-type/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[MilitaryTypeDelete]"
		{
			path: 'delete',
			name: 'peopleNet.militaryType.delete',
			component: () => import('@/modules/people-net/views/military-type/military-type-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.delete',
				clientAccessKeys:["/people-net/base-info/military-type/delete"],
				serverAccessKeys:["/people-net/military-type/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[MilitaryTypeDetail]"
		{
			path: 'detail',
			name: 'peopleNet.militaryType.detail',
			component: () => import('@/modules/people-net/views/military-type/military-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.detail',
				clientAccessKeys:["/people-net/base-info/military-type/detail"],
				serverAccessKeys:["/people-net/military-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[MilitaryTypeList]"
		{
			path: 'list',
			name: 'peopleNet.militaryType.list',
			component: () => import('@/modules/people-net/views/military-type/military-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.list',
				clientAccessKeys:["/people-net/base-info/military-type/list"],
				serverAccessKeys:["/people-net/military-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[MilitaryTypeUpdate]"
		{
			path: 'update',
			name: 'peopleNet.militaryType.update',
			component: () => import('@/modules/people-net/views/military-type/military-type-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.militaryType.update',
				clientAccessKeys:["/people-net/base-info/military-type/update"],
				serverAccessKeys:["/people-net/military-type/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}