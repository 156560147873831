//GeneratorFlag:[NoChange]
export default {
	path: 'product-status',
	name: 'shop.baseSys.productStatus.layout',
	redirect: to => {
		return {name: 'shop.productStatus.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/product-status"],
		resourceKey: 'shop.views.productStatus.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductStatusDetail]"
		{
			path: 'detail',
			name: 'shop.productStatus.detail',
			component: () => import('@/modules/shop/views/product-status/product-status-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStatus.detail',
				clientAccessKeys:["/shop/base-sys/product-status/detail"],
				serverAccessKeys:["/shop/product-status/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductStatusList]"
		{
			path: 'list',
			name: 'shop.productStatus.list',
			component: () => import('@/modules/shop/views/product-status/product-status-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productStatus.list',
				clientAccessKeys:["/shop/base-sys/product-status/list"],
				serverAccessKeys:["/shop/product-status/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}