<template>
	<content-loading v-if="loading || isLoading" />
	<div v-else class="kaveh-content" :style="{'max-width': width, 'border': border, 'padding': padding}">
		<slot></slot>
	</div>
</template>
<script>
	import contentLoading from './content-loading'
	export default {

		components: {
			'content-loading': contentLoading,
		},

		props: {
			width: {
				type: String,
				default: '100%'
			},
			padding: {
				type: String,
				default: '20px'
			},
			border: {
				type: String,
				default: '#F8F8F8 solid 2px'
			},
			loading: {
				type: Boolean,
				default: false
			},
			isLoading: {
				type: Boolean,
				default: false
			}
		}
	}
</script>
<style>
	.kaveh-content {
		margin: 0 auto;
		background: #F8F8F8;
		border-radius:10px;
	}

	@media (max-width: 768px) {
		.kaveh-content {
			padding:10px !important;
		}
	}

</style>
