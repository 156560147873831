//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { PersonService, CityService } from '@/modules/people-net/services'; 
export default class PersonAddress extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'personAddress';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.invoiceDetail.personAddress.self';
	props = {
		personAddressId: {
			primarykey: true,
			name: 'personAddressId',
			resourceKey: 'shop.invoiceDetail.personAddress.personAddressId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'shop.invoiceDetail.personAddress.personId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'firstName',
				valueKey: 'personId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		addressTitle: {
			name: 'addressTitle',
			resourceKey: 'shop.invoiceDetail.personAddress.addressTitle',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		reciverFirstName: {
			name: 'reciverFirstName',
			resourceKey: 'shop.invoiceDetail.personAddress.reciverFirstName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		reciverLastName: {
			name: 'reciverLastName',
			resourceKey: 'shop.invoiceDetail.personAddress.reciverLastName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		reciverMobile: {
			name: 'reciverMobile',
			resourceKey: 'shop.invoiceDetail.personAddress.reciverMobile',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: ['mobile'],
			},
			errors: [],
			value: null
		},
		cityId: {
			foreignKey: true,
			name: 'cityId',
			resourceKey: 'shop.invoiceDetail.personAddress.cityId',
			type: ENUMS.PROP_TYPE.INT16,
			data: async (params) =>
			{
				const service =
					new CityService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: false,  
				canUseInExcel: false,  
				textKey: 'title',
				valueKey: 'cityId',
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		fullAddress: {
			name: 'fullAddress',
			resourceKey: 'shop.invoiceDetail.personAddress.fullAddress',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 250,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		plaque: {
			name: 'plaque',
			resourceKey: 'shop.invoiceDetail.personAddress.plaque',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 10,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		postalCode: {
			name: 'postalCode',
			resourceKey: 'shop.invoiceDetail.personAddress.postalCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: ['postalCode'],
			},
			errors: [],
			value: null
		},
	};
}