//GeneratorFlag:[NoChange]
export default {
	path: 'wallet-with-draw',
	name: 'financial.adminOperation.walletWithDraw.layout',
	redirect: to => {
		return {name: 'financial.walletWithDraw.totalRequest'};
	},
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/financial/admin-operation/wallet-with-draw"],
		resourceKey: 'financial.views.walletWithDraw.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[WalletWithDrawTotalRequest]"
		{
			path: 'total-request',
			name: 'financial.walletWithDraw.totalRequest',
			component: () => import('@/modules/financial/views/wallet-with-draw/wallet-with-draw-total-request.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.views.walletWithDraw.totalRequest',
				clientAccessKeys:["/financial/admin-operation/wallet-with-draw/total-request"],
				serverAccessKeys:["/financial/wallet-with-draw/total-request"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]

	]
}