//GeneratorFlag:[NoChange]
export default {
	path: 'culture',
	name: 'peopleNet.baseSys.culture.layout',
	redirect: to => {
		return {name: 'peopleNet.culture.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/culture"],
		resourceKey: 'peopleNet.views.culture.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[CultureDetail]"
		{
			path: 'detail',
			name: 'peopleNet.culture.detail',
			component: () => import('@/modules/people-net/views/culture/culture-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.culture.detail',
				clientAccessKeys:["/people-net/base-sys/culture/detail"],
				serverAccessKeys:["/people-net/culture/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CultureList]"
		{
			path: 'list',
			name: 'peopleNet.culture.list',
			component: () => import('@/modules/people-net/views/culture/culture-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.culture.list',
				clientAccessKeys:["/people-net/base-sys/culture/list"],
				serverAccessKeys:["/people-net/culture/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}