//GeneratorFlag:[NoChange]
export default {
	path: 'ticket',
	name: 'ticketing.administrator.ticket.layout',
	redirect: to => {
		return {name: 'ticketing.ticket.adminList'};
	},
	component: () => import('@/modules/ticketing/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ticketing/administrator/ticket"],
		resourceKey: 'ticketing.views.ticket.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TicketAdminList]"
		{
			path: 'admin-list',
			name: 'ticketing.ticket.adminList',
			component: () => import('@/modules/ticketing/views/ticket/ticket-admin-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.views.ticket.adminList',
				clientAccessKeys:["/ticketing/administrator/ticket/admin-list"],
				serverAccessKeys:["/ticketing/ticket/admin-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]

	]
}