//GeneratorFlag:[NoChange]
export default {
	path: 'plan-transaction',
	name: 'shop.adminOperation.planTransaction.layout',
	redirect: to => {
		return {name: 'shop.planTransaction.registerExtraCommision'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/admin-operation/plan-transaction"],
		resourceKey: 'shop.views.planTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanTransactionRegisterExtraCommision]"
		{
			path: 'register-extra-commision',
			name: 'shop.planTransaction.registerExtraCommision',
			component: () => import('@/modules/shop/views/plan-transaction/plan-transaction-register-extra-commision.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransaction.registerExtraCommision',
				clientAccessKeys:["/shop/admin-operation/plan-transaction/register-extra-commision"],
				serverAccessKeys:["/shop/plan-transaction/register-extra-commision"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]

	]
}