//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { AccessKeys } from '@/modules/hub/data/access-agent/save'; 
import { PersonService, PersonRoleService } from '@/modules/hub/services'; 
export default class AccessAgent extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'accessAgent';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'hub.accessAgentSave.self';
	props = {
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'hub.accessAgentSave.personId',
			type: ENUMS.PROP_TYPE.INT32,
			serverAddress: '/hub/person/list',
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'personName',
				valueKey: 'personId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personRoleId: {
			foreignKey: true,
			name: 'personRoleId',
			resourceKey: 'hub.accessAgentSave.personRoleId',
			type: ENUMS.PROP_TYPE.INT16,
			serverAddress: '/hub/person-role/list',
			data: async (params) =>
			{
				const service =
					new PersonRoleService();
				return await service.list(params);
			},
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personRoleId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		accessKeys: {
			name: 'accessKeys',
			resourceKey: 'hub.accessAgentSave.accessKeys.self',
			type: ENUMS.PROP_TYPE.ARRAY,
			entity: new AccessKeys(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{keyName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: true,  
				canUseInExcel: true,  
				collectionMinLength: 0,
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: []
		},
		isSuccess: {
			name: 'isSuccess',
			resourceKey: 'hub.accessAgentSave.isSuccess',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
	};
}