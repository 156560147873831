//GeneratorFlag:[NoChange]
export default {
	path: 'person',
	name: 'hub.person.layout',
	redirect: to => {
		return {name: 'hub.person.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/person"],
		resourceKey: 'hub.views.person.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-people-fill',
		}
	},
	children: [
//GeneratorFlag:[PersonCreate]"
		{
			path: 'create',
			name: 'hub.person.create',
			component: () => import('@/modules/hub/views/person/person-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.create',
				clientAccessKeys:["/hub/person/create"],
				serverAccessKeys:["/hub/person/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreateImport]"
		{
			path: 'create-import',
			name: 'hub.person.createImport',
			component: () => import('@/modules/hub/views/person/person-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.createImport',
				clientAccessKeys:["/hub/person/create-import"],
				serverAccessKeys:["/hub/person/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDelete]"
		{
			path: 'delete',
			name: 'hub.person.delete',
			component: () => import('@/modules/hub/views/person/person-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.delete',
				clientAccessKeys:["/hub/person/delete"],
				serverAccessKeys:["/hub/person/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDetail]"
		{
			path: 'detail',
			name: 'hub.person.detail',
			component: () => import('@/modules/hub/views/person/person-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.detail',
				clientAccessKeys:["/hub/person/detail"],
				serverAccessKeys:["/hub/person/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonList]"
		{
			path: 'list',
			name: 'hub.person.list',
			component: () => import('@/modules/hub/views/person/person-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.list',
				clientAccessKeys:["/hub/person/list"],
				serverAccessKeys:["/hub/person/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonUpdate]"
		{
			path: 'update',
			name: 'hub.person.update',
			component: () => import('@/modules/hub/views/person/person-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.update',
				clientAccessKeys:["/hub/person/update"],
				serverAccessKeys:["/hub/person/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAccessDetail]"
		{
			path: 'person-access-detail',
			name: 'hub.person.personAccessDetail',
			component: () => import('@/modules/hub/views/person/person-access-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.personAccessDetail',
				clientAccessKeys:["/hub/person/person-access-detail"],
				serverAccessKeys:["/hub/person-access/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAccessUpdate]"
		{
			path: 'person-access-update',
			name: 'hub.person.personAccessUpdate',
			component: () => import('@/modules/hub/views/person/person-access-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.personAccessUpdate',
				clientAccessKeys:["/hub/person/person-access-update"],
				serverAccessKeys:["/hub/person-access/update","/hub/person-access/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonLegalDetail]"
		{
			path: 'person-legal-detail',
			name: 'hub.person.personLegalDetail',
			component: () => import('@/modules/hub/views/person/person-legal-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.personLegalDetail',
				clientAccessKeys:["/hub/person/person-legal-detail"],
				serverAccessKeys:["/hub/person-legal/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonLegalUpdate]"
		{
			path: 'person-legal-update',
			name: 'hub.person.personLegalUpdate',
			component: () => import('@/modules/hub/views/person/person-legal-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.personLegalUpdate',
				clientAccessKeys:["/hub/person/person-legal-update"],
				serverAccessKeys:["/hub/person-legal/update","/hub/person-legal/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRealDetail]"
		{
			path: 'person-real-detail',
			name: 'hub.person.personRealDetail',
			component: () => import('@/modules/hub/views/person/person-real-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.personRealDetail',
				clientAccessKeys:["/hub/person/person-real-detail"],
				serverAccessKeys:["/hub/person-real/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRealUpdate]"
		{
			path: 'person-real-update',
			name: 'hub.person.personRealUpdate',
			component: () => import('@/modules/hub/views/person/person-real-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.person.personRealUpdate',
				clientAccessKeys:["/hub/person/person-real-update"],
				serverAccessKeys:["/hub/person-real/update","/hub/person-real/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]














	]
}
