//GeneratorFlag:[NoChange]
import Invoice from './invoice';
import InvoicePaymentType from './invoice-payment-type';
import InvoiceStatuse from './invoice-statuse';
import Person from './person';
import PersonAddress from './person-address';
import Store from './store';
import InvoiceDetail from './invoice-detail';
import ShopPerson from './shop-person';

export default {Invoice, InvoicePaymentType, InvoiceStatuse, Person, PersonAddress, Store, InvoiceDetail, ShopPerson}
export{Invoice, InvoicePaymentType, InvoiceStatuse, Person, PersonAddress, Store, InvoiceDetail, ShopPerson}