<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { WalletTransactionStatuse, WalletTransactionStatuseFilter } from '@/modules/financial/data/wallet-transaction-statuse/list'
	import { WalletTransactionStatuseService } from '@/modules/financial/services'
	export default {
		computed:
		{
			entity()
			{
				return WalletTransactionStatuse;
			},
			
			filterEntity()
			{
				return WalletTransactionStatuseFilter;
			},

			service()
			{
				return new WalletTransactionStatuseService();
			},

			filterData()
			{
				const walletTransactionStatuseId =
					this.$route.query['walletTransactionStatuseId'];

				if (walletTransactionStatuseId)
				{
					return {
						'walletTransactionStatuseId': walletTransactionStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>