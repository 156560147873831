<template>
	<kaveh-form :visiblePk="true" :task="submit" :value="data" :entity="entity" />
</template>
<script>

	export default {
		emits: ['complete'],
		props: {
			'entity': {
				type: Function
			},

			'data': {
				type: Object
			}
		},

		methods: {

			submit(payload)
			{
				this.$emit('complete', payload)
			},
		}
	}
</script>

