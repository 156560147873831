//GeneratorFlag:[NoChange]
export default {
	path: 'company',
	name: 'peopleNet.baseInfo.company.layout',
	redirect: to => {
		return {name: 'peopleNet.company.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-info/company"],
		resourceKey: 'peopleNet.views.company.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[CompanyCreate]"
		{
			path: 'create',
			name: 'peopleNet.company.create',
			component: () => import('@/modules/people-net/views/company/company-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.create',
				clientAccessKeys:["/people-net/base-info/company/create"],
				serverAccessKeys:["/people-net/company/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.company.createImport',
			component: () => import('@/modules/people-net/views/company/company-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.createImport',
				clientAccessKeys:["/people-net/base-info/company/create-import"],
				serverAccessKeys:["/people-net/company/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyDelete]"
		{
			path: 'delete',
			name: 'peopleNet.company.delete',
			component: () => import('@/modules/people-net/views/company/company-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.delete',
				clientAccessKeys:["/people-net/base-info/company/delete"],
				serverAccessKeys:["/people-net/company/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyDetail]"
		{
			path: 'detail',
			name: 'peopleNet.company.detail',
			component: () => import('@/modules/people-net/views/company/company-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.detail',
				clientAccessKeys:["/people-net/base-info/company/detail"],
				serverAccessKeys:["/people-net/company/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyList]"
		{
			path: 'list',
			name: 'peopleNet.company.list',
			component: () => import('@/modules/people-net/views/company/company-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.list',
				clientAccessKeys:["/people-net/base-info/company/list"],
				serverAccessKeys:["/people-net/company/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyUpdate]"
		{
			path: 'update',
			name: 'peopleNet.company.update',
			component: () => import('@/modules/people-net/views/company/company-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.company.update',
				clientAccessKeys:["/people-net/base-info/company/update"],
				serverAccessKeys:["/people-net/company/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}