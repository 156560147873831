//GeneratorFlag:[NoChange]
export default {
	path: 'product-doc-type',
	name: 'shop.baseSys.productDocType.layout',
	redirect: to => {
		return {name: 'shop.productDocType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/product-doc-type"],
		resourceKey: 'shop.views.productDocType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductDocTypeDetail]"
		{
			path: 'detail',
			name: 'shop.productDocType.detail',
			component: () => import('@/modules/shop/views/product-doc-type/product-doc-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDocType.detail',
				clientAccessKeys:["/shop/base-sys/product-doc-type/detail"],
				serverAccessKeys:["/shop/product-doc-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDocTypeList]"
		{
			path: 'list',
			name: 'shop.productDocType.list',
			component: () => import('@/modules/shop/views/product-doc-type/product-doc-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDocType.list',
				clientAccessKeys:["/shop/base-sys/product-doc-type/list"],
				serverAccessKeys:["/shop/product-doc-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}