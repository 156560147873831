//GeneratorFlag:[NoChange]
import ticketRoute from './ticket-route'; //GeneratorFlag:[Ticket]
import ticketItemRoute from './ticket-item-route'; //GeneratorFlag:[TicketItem]
import baseInfoTicketDepartmentRoute from './base-info/ticket-department-route'; //GeneratorFlag:[BaseInfoTicketDepartment]
import baseSysCultureRoute from './base-sys/culture-route'; //GeneratorFlag:[BaseSysCulture]
import baseSysTicketStatuseRoute from './base-sys/ticket-statuse-route'; //GeneratorFlag:[BaseSysTicketStatuse]
import administratorTicketRoute from './administrator/ticket-route'; //GeneratorFlag:[AdministratorTicket]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'ticketing',
	name: 'ticketing',
	component: () => import('@/modules/ticketing/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/ticketing'],
		serverAccessKeys:[],
		resourceKey: 'service.ticketing',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-layers-half',
		}
	},
	children: [
ticketRoute, ticketItemRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BaseInfoLayout]
		{
			path: 'base-info',
			name: 'ticketing.baseInfo',
			component: () => import('@/modules/ticketing/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.layout.baseInfo',
				clientAccessKeys:['/ticketing/base-info'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			baseInfoTicketDepartmentRoute, //GeneratorFlag:[NewBaseInfoChild]
			]
		},
		//GeneratorFlag:[BaseSysLayout]
		{
			path: 'base-sys',
			name: 'ticketing.baseSys',
			component: () => import('@/modules/ticketing/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.layout.baseSys',
				clientAccessKeys:['/ticketing/base-sys'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-lock',
				}
			},
			children: [
			baseSysCultureRoute, baseSysTicketStatuseRoute, //GeneratorFlag:[NewBaseSysChild]
			]
		},
		//GeneratorFlag:[AdministratorLayout]
		{
			path: 'administrator',
			name: 'ticketing.administrator',
			component: () => import('@/modules/ticketing/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ticketing.layout.administrator',
				clientAccessKeys:['/ticketing/administrator'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			administratorTicketRoute, //GeneratorFlag:[NewAdministratorChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
ticketRoute, ticketItemRoute, baseInfoTicketDepartmentRoute, baseSysCultureRoute, baseSysTicketStatuseRoute, administratorTicketRoute, //GeneratorFlag:[NewExport]
}