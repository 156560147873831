//GeneratorFlag:[NoChange]
export default {
	path: 'person-request-dipost-statuse',
	name: 'peopleNet.baseSys.personRequestDipostStatuse.layout',
	redirect: to => {
		return {name: 'peopleNet.personRequestDipostStatuse.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-request-dipost-statuse"],
		resourceKey: 'peopleNet.views.personRequestDipostStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonRequestDipostStatuseDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personRequestDipostStatuse.detail',
			component: () => import('@/modules/people-net/views/person-request-dipost-statuse/person-request-dipost-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipostStatuse.detail',
				clientAccessKeys:["/people-net/base-sys/person-request-dipost-statuse/detail"],
				serverAccessKeys:["/people-net/person-request-dipost-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRequestDipostStatuseList]"
		{
			path: 'list',
			name: 'peopleNet.personRequestDipostStatuse.list',
			component: () => import('@/modules/people-net/views/person-request-dipost-statuse/person-request-dipost-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipostStatuse.list',
				clientAccessKeys:["/people-net/base-sys/person-request-dipost-statuse/list"],
				serverAccessKeys:["/people-net/person-request-dipost-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}