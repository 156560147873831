//GeneratorFlag:[NoChange]
import Person from './person';
import Company from './company';
import CompanyRank from './company-rank';
import PersonGender from './person-gender';
import PersonStatuse from './person-statuse';
import Rank from './rank';
import Team from './team';
import PersonAccess from './person-access';
import PersonDetail from './person-detail';
import PersonFilter from './person-filter';

export default {Person, Company, CompanyRank, PersonGender, PersonStatuse, Rank, Team, PersonAccess, PersonDetail, PersonFilter}
export{Person, Company, CompanyRank, PersonGender, PersonStatuse, Rank, Team, PersonAccess, PersonDetail, PersonFilter}