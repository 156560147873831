<template>
	<div class="task-warning">
		<div class="container">
			<div v-if="warning" class="task-warning-body bg-light p-3">
				<div class="bg-white text-danger p-3">
					<label class="d-block">
						<kaveh-icon-bootstrap name="bi-exclamation-triangle" size="50px" />
					</label>
					<label>{{ $loc.fromResource('com.task.taskRowNumberError',{'index':taskIndex + 1}) }}</label><br />
					<strong>{{ $loc.fromResource('com.task.errorContent')}}: {{ warning }}</strong>
				</div>
				<div class="mt-3 bg-white">
					<kaveh-table :data="data" :entity="entity" :selectable="false" :showRowNumber="false" />
				</div>
				<div class="mt-3">
					<button v-if="ShowSkip" @click="skip" class="btn btn-light border me-2">{{ $loc.fromResource('com.task.skip') }}</button>
					<button v-if="showRepeat" @click="repeat" type="button" class="btn btn-light border me-2">{{ $loc.fromResource('com.task.repeat') }}</button>
					<button v-if="showReplace" @click="replace" type="button" class="btn btn-light border me-4">{{ $loc.fromResource('com.task.replace') }}</button>
					<button @click="cancel" type="button" class="btn text-danger btn-light border">{{ $loc.fromResource('com.task.cancel') }}</button>
				</div>
				<div class="mt-3">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" v-model="applyToAllCases" id="applyToAllCases">
						<label class="form-check-label" for="applyToAllCases">
							{{ $loc.fromResource('com.task.applyToAllCases') }}
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>
<script>
	import { ServerUtility } from "@/core/server"
	export default {
		emits: ['skip', 'repeat', 'replace', 'cancel'],
		props: {
			'status': {
				type: String
			},

			'message': {
				type: String
			},

			'taskIndex': {
				type: Number
			},

			'taskData': {
				type: Object
			},

			'entity': {
				type: Function
			},

		},

		data() {
			return {
				ShowSkip: true,
				showRepeat: false,
				showReplace: false,
				warning: '',
				applyToAllCases: false
			}
		},

		beforeMount() {
			this.warning = new ServerUtility().getErrorMessage(this.status, this.message)
		},

		computed: {
			data() {
				return [this.taskData];
			},
		},

		methods: {
			skip() {
				this.$emit('skip', this.applyToAllCases == true ?
					this.status : undefined);
			},

			repeat() {
				this.$emit('repeat');
			},

			replace() {
				this.$emit('replace', this.applyToAllCases == true ?
					this.status : undefined);
			},

			cancel() {
				this.$emit('cancel');
			}
		}
	}

</script>

<style>
	.task-warning {
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
		top: 0;
		left: 0;
		z-index: 100;
	}

		.task-warning .task-warning-body {
			margin: 100px auto 0 auto;
		}
</style>
