//GeneratorFlag:[NoChange]
export default {
	path: 'product-transport-cost-type',
	name: 'shop.baseSys.productTransportCostType.layout',
	redirect: to => {
		return {name: 'shop.productTransportCostType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/product-transport-cost-type"],
		resourceKey: 'shop.views.productTransportCostType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductTransportCostTypeDetail]"
		{
			path: 'detail',
			name: 'shop.productTransportCostType.detail',
			component: () => import('@/modules/shop/views/product-transport-cost-type/product-transport-cost-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productTransportCostType.detail',
				clientAccessKeys:["/shop/base-sys/product-transport-cost-type/detail"],
				serverAccessKeys:["/shop/product-transport-cost-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductTransportCostTypeList]"
		{
			path: 'list',
			name: 'shop.productTransportCostType.list',
			component: () => import('@/modules/shop/views/product-transport-cost-type/product-transport-cost-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productTransportCostType.list',
				clientAccessKeys:["/shop/base-sys/product-transport-cost-type/list"],
				serverAccessKeys:["/shop/product-transport-cost-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}