<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PlanTransactionType, PlanTransactionTypeFilter } from '@/modules/shop/data/plan-transaction-type/list'
	import { PlanTransactionTypeService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return PlanTransactionType;
			},
			
			filterEntity()
			{
				return PlanTransactionTypeFilter;
			},

			service()
			{
				return new PlanTransactionTypeService();
			},

			filterData()
			{
				const planTransactionTypeId =
					this.$route.query['planTransactionTypeId'];

				if (planTransactionTypeId)
				{
					return {
						'planTransactionTypeId': planTransactionTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>