//GeneratorFlag:[NoChange]
export default {
	path: 'province',
	name: 'hub.geographical.province.layout',
	redirect: to => {
		return {name: 'hub.province.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/geographical/province"],
		resourceKey: 'hub.views.province.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-map-fill',
		}
	},
	children: [
//GeneratorFlag:[ProvinceCreate]"
		{
			path: 'create',
			name: 'hub.province.create',
			component: () => import('@/modules/hub/views/province/province-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.province.create',
				clientAccessKeys:["/hub/geographical/province/create"],
				serverAccessKeys:["/hub/province/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceCreateImport]"
		{
			path: 'create-import',
			name: 'hub.province.createImport',
			component: () => import('@/modules/hub/views/province/province-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.province.createImport',
				clientAccessKeys:["/hub/geographical/province/create-import"],
				serverAccessKeys:["/hub/province/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceDelete]"
		{
			path: 'delete',
			name: 'hub.province.delete',
			component: () => import('@/modules/hub/views/province/province-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.province.delete',
				clientAccessKeys:["/hub/geographical/province/delete"],
				serverAccessKeys:["/hub/province/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceDetail]"
		{
			path: 'detail',
			name: 'hub.province.detail',
			component: () => import('@/modules/hub/views/province/province-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.province.detail',
				clientAccessKeys:["/hub/geographical/province/detail"],
				serverAccessKeys:["/hub/province/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceList]"
		{
			path: 'list',
			name: 'hub.province.list',
			component: () => import('@/modules/hub/views/province/province-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.province.list',
				clientAccessKeys:["/hub/geographical/province/list"],
				serverAccessKeys:["/hub/province/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProvinceUpdate]"
		{
			path: 'update',
			name: 'hub.province.update',
			component: () => import('@/modules/hub/views/province/province-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.province.update',
				clientAccessKeys:["/hub/geographical/province/update"],
				serverAccessKeys:["/hub/province/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}