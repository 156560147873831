//GeneratorFlag:[NoChange]
export default {
	path: 'person-military',
	name: 'hub.baseInfo.personMilitary.layout',
	redirect: to => {
		return {name: 'hub.personMilitary.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-info/person-military"],
		resourceKey: 'hub.views.personMilitary.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonMilitaryCreate]"
		{
			path: 'create',
			name: 'hub.personMilitary.create',
			component: () => import('@/modules/hub/views/person-military/person-military-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMilitary.create',
				clientAccessKeys:["/hub/base-info/person-military/create"],
				serverAccessKeys:["/hub/person-military/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonMilitaryCreateImport]"
		{
			path: 'create-import',
			name: 'hub.personMilitary.createImport',
			component: () => import('@/modules/hub/views/person-military/person-military-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMilitary.createImport',
				clientAccessKeys:["/hub/base-info/person-military/create-import"],
				serverAccessKeys:["/hub/person-military/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonMilitaryDelete]"
		{
			path: 'delete',
			name: 'hub.personMilitary.delete',
			component: () => import('@/modules/hub/views/person-military/person-military-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMilitary.delete',
				clientAccessKeys:["/hub/base-info/person-military/delete"],
				serverAccessKeys:["/hub/person-military/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonMilitaryDetail]"
		{
			path: 'detail',
			name: 'hub.personMilitary.detail',
			component: () => import('@/modules/hub/views/person-military/person-military-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMilitary.detail',
				clientAccessKeys:["/hub/base-info/person-military/detail"],
				serverAccessKeys:["/hub/person-military/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonMilitaryList]"
		{
			path: 'list',
			name: 'hub.personMilitary.list',
			component: () => import('@/modules/hub/views/person-military/person-military-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMilitary.list',
				clientAccessKeys:["/hub/base-info/person-military/list"],
				serverAccessKeys:["/hub/person-military/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonMilitaryUpdate]"
		{
			path: 'update',
			name: 'hub.personMilitary.update',
			component: () => import('@/modules/hub/views/person-military/person-military-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMilitary.update',
				clientAccessKeys:["/hub/base-info/person-military/update"],
				serverAccessKeys:["/hub/person-military/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}