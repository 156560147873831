//GeneratorFlag:[NoChange]
export default {
	path: 'region',
	name: 'hub.geographical.region.layout',
	redirect: to => {
		return {name: 'hub.region.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/geographical/region"],
		resourceKey: 'hub.views.region.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-pin-map-fill',
		}
	},
	children: [
//GeneratorFlag:[RegionCreate]"
		{
			path: 'create',
			name: 'hub.region.create',
			component: () => import('@/modules/hub/views/region/region-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.create',
				clientAccessKeys:["/hub/geographical/region/create"],
				serverAccessKeys:["/hub/region/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionCreateImport]"
		{
			path: 'create-import',
			name: 'hub.region.createImport',
			component: () => import('@/modules/hub/views/region/region-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.createImport',
				clientAccessKeys:["/hub/geographical/region/create-import"],
				serverAccessKeys:["/hub/region/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionDelete]"
		{
			path: 'delete',
			name: 'hub.region.delete',
			component: () => import('@/modules/hub/views/region/region-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.delete',
				clientAccessKeys:["/hub/geographical/region/delete"],
				serverAccessKeys:["/hub/region/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionDetail]"
		{
			path: 'detail',
			name: 'hub.region.detail',
			component: () => import('@/modules/hub/views/region/region-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.detail',
				clientAccessKeys:["/hub/geographical/region/detail"],
				serverAccessKeys:["/hub/region/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionList]"
		{
			path: 'list',
			name: 'hub.region.list',
			component: () => import('@/modules/hub/views/region/region-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.list',
				clientAccessKeys:["/hub/geographical/region/list"],
				serverAccessKeys:["/hub/region/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionUpdate]"
		{
			path: 'update',
			name: 'hub.region.update',
			component: () => import('@/modules/hub/views/region/region-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.update',
				clientAccessKeys:["/hub/geographical/region/update"],
				serverAccessKeys:["/hub/region/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}