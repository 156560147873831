import * as persianJs from '@/lib/persian'
import * as persianDate from '@/lib/persian-date'

persianDate.toLocale('en');

const persian ={
	date: function () {

		if (arguments.length == 1 && typeof (arguments[0]) == 'string')
		{
			const date =
				arguments[0].split('/');

			const year = Number(date[0])
			const month = Number(date[1])
			const day = Number(date[2])

			return new persianDate([year, month, day])
		}

		return new persianDate(...arguments)
	},

	wordDate(date)
	{
		return persian.date(date).toLocale('fa').format('dddd D MMMM YY')
	},

	time: function() {
		const date = new Date();
		return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
	},

	cleanTime: function ()
	{
		const date = new Date();
		return `${date.getHours()}${date.getMinutes()}${date.getSeconds()}`
	},

	time7: function () {
		const date = new Date();
		return `${date.getHours()}:${date.getMinutes()}`
	},

	cleanDateTime() {
		return `${persian.date().format('YYYYMMDD')}${persian.cleanTime()}`;
	},

	addTimes: (times) => {

		const z = (n) => (n < 10 ? '0' : '') + n;

		let hour = 0
		let minute = 0
		let second = 0
		for (const time of times) {
			const splited = time.split(':');
			hour += parseInt(splited[0]);
			minute += parseInt(splited[1])

			if (splited[2]) {
				second += parseInt(splited[2])
			}

		}
		const seconds = second % 60
		const minutes = parseInt(minute % 60) + parseInt(second / 60)
		const hours = hour + parseInt(minute / 60)

		if (seconds > 0) {
			return z(hours) + ':' + z(minutes) + ':' +  z(seconds)
		}

		return z(hours) + ':' + z(minutes)


	},

	tools: function (str){
		return new persianJs(str)
	}
}

export default persian;
