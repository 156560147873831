//GeneratorFlag:[NoChange]
import bankPaymentRoute from './bank-payment-route'; //GeneratorFlag:[BankPayment]
import invoiceRoute from './invoice-route'; //GeneratorFlag:[Invoice]
import personAddressRoute from './person-address-route'; //GeneratorFlag:[PersonAddress]
import personPreTransactionRoute from './person-pre-transaction-route'; //GeneratorFlag:[PersonPreTransaction]
import productRoute from './product-route'; //GeneratorFlag:[Product]
import productCategoryRoute from './product-category-route'; //GeneratorFlag:[ProductCategory]
import productDetailRoute from './product-detail-route'; //GeneratorFlag:[ProductDetail]
import productDocRoute from './product-doc-route'; //GeneratorFlag:[ProductDoc]
import productFeatureRoute from './product-feature-route'; //GeneratorFlag:[ProductFeature]
import productMonetaryFeatureRoute from './product-monetary-feature-route'; //GeneratorFlag:[ProductMonetaryFeature]
import productStoreRoute from './product-store-route'; //GeneratorFlag:[ProductStore]
import storeRoute from './store-route'; //GeneratorFlag:[Store]
import baseInfoBankRoute from './base-info/bank-route'; //GeneratorFlag:[BaseInfoBank]
import baseInfoCategoryRoute from './base-info/category-route'; //GeneratorFlag:[BaseInfoCategory]
import baseInfoFeatureRoute from './base-info/feature-route'; //GeneratorFlag:[BaseInfoFeature]
import baseInfoFeatureItemRoute from './base-info/feature-item-route'; //GeneratorFlag:[BaseInfoFeatureItem]
import baseInfoPlanPrecentRoute from './base-info/plan-precent-route'; //GeneratorFlag:[BaseInfoPlanPrecent]
import baseInfoPlanSettingRoute from './base-info/plan-setting-route'; //GeneratorFlag:[BaseInfoPlanSetting]
import baseInfoProductColorRoute from './base-info/product-color-route'; //GeneratorFlag:[BaseInfoProductColor]
import baseInfoStoreProfitRateRoute from './base-info/store-profit-rate-route'; //GeneratorFlag:[BaseInfoStoreProfitRate]
import baseSysBankPaymentStatuseRoute from './base-sys/bank-payment-statuse-route'; //GeneratorFlag:[BaseSysBankPaymentStatuse]
import baseSysCultureRoute from './base-sys/culture-route'; //GeneratorFlag:[BaseSysCulture]
import baseSysInvoicePaymentTypeRoute from './base-sys/invoice-payment-type-route'; //GeneratorFlag:[BaseSysInvoicePaymentType]
import baseSysInvoiceStatuseRoute from './base-sys/invoice-statuse-route'; //GeneratorFlag:[BaseSysInvoiceStatuse]
import baseSysInvoiceTransactionTypeRoute from './base-sys/invoice-transaction-type-route'; //GeneratorFlag:[BaseSysInvoiceTransactionType]
import baseSysPersonCreaditTransactionTypeRoute from './base-sys/person-creadit-transaction-type-route'; //GeneratorFlag:[BaseSysPersonCreaditTransactionType]
import baseSysPersonPreTransactionStatuseRoute from './base-sys/person-pre-transaction-statuse-route'; //GeneratorFlag:[BaseSysPersonPreTransactionStatuse]
import baseSysPlanTransactionStatuseRoute from './base-sys/plan-transaction-statuse-route'; //GeneratorFlag:[BaseSysPlanTransactionStatuse]
import baseSysPlanTransactionTypeRoute from './base-sys/plan-transaction-type-route'; //GeneratorFlag:[BaseSysPlanTransactionType]
import baseSysProductDocTypeRoute from './base-sys/product-doc-type-route'; //GeneratorFlag:[BaseSysProductDocType]
import baseSysProductStatusRoute from './base-sys/product-status-route'; //GeneratorFlag:[BaseSysProductStatus]
import baseSysProductTransportCostTypeRoute from './base-sys/product-transport-cost-type-route'; //GeneratorFlag:[BaseSysProductTransportCostType]
import reportPlanTransactionRoute from './report/plan-transaction-route'; //GeneratorFlag:[ReportPlanTransaction]
import invoiceCancelRoute from './invoice-cancel-route'; //GeneratorFlag:[InvoiceCancel]
import baseSysInvoiceCancelStatuseRoute from './base-sys/invoice-cancel-statuse-route'; //GeneratorFlag:[BaseSysInvoiceCancelStatuse]
import planTransactionRoute from './plan-transaction-route'; //GeneratorFlag:[PlanTransaction]
import adminOperationPlanTransactionRoute from './admin-operation/plan-transaction-route'; //GeneratorFlag:[AdminOperationPlanTransaction]
import baseSysPlanTransactionCommentTypeRoute from './base-sys/plan-transaction-comment-type-route'; //GeneratorFlag:[BaseSysPlanTransactionCommentType]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'shop',
	name: 'shop',
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/shop'],
		serverAccessKeys:[],
		resourceKey: 'service.shop',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-layers-half',
		}
	},
	children: [
bankPaymentRoute, invoiceRoute, personAddressRoute, personPreTransactionRoute, productRoute, productCategoryRoute, productDetailRoute, productDocRoute, productFeatureRoute, productMonetaryFeatureRoute, productStoreRoute, storeRoute, invoiceCancelRoute, planTransactionRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BaseInfoLayout]
		{
			path: 'base-info',
			name: 'shop.baseInfo',
			component: () => import('@/modules/shop/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.layout.baseInfo',
				clientAccessKeys:['/shop/base-info'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			baseInfoBankRoute, baseInfoCategoryRoute, baseInfoFeatureRoute, baseInfoFeatureItemRoute, baseInfoPlanPrecentRoute, baseInfoPlanSettingRoute, baseInfoProductColorRoute, baseInfoStoreProfitRateRoute, //GeneratorFlag:[NewBaseInfoChild]
			]
		},
		//GeneratorFlag:[BaseSysLayout]
		{
			path: 'base-sys',
			name: 'shop.baseSys',
			component: () => import('@/modules/shop/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.layout.baseSys',
				clientAccessKeys:['/shop/base-sys'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-lock',
				}
			},
			children: [
			baseSysBankPaymentStatuseRoute, baseSysCultureRoute, baseSysInvoicePaymentTypeRoute, baseSysInvoiceStatuseRoute, baseSysInvoiceTransactionTypeRoute, baseSysPersonCreaditTransactionTypeRoute, baseSysPersonPreTransactionStatuseRoute, baseSysPlanTransactionStatuseRoute, baseSysPlanTransactionTypeRoute, baseSysProductDocTypeRoute, baseSysProductStatusRoute, baseSysProductTransportCostTypeRoute, baseSysInvoiceCancelStatuseRoute, baseSysPlanTransactionCommentTypeRoute, //GeneratorFlag:[NewBaseSysChild]
			]
		},
		//GeneratorFlag:[ReportLayout]
		{
			path: 'report',
			name: 'shop.report',
			component: () => import('@/modules/shop/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.layout.report',
				clientAccessKeys:['/shop/report'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			reportPlanTransactionRoute, //GeneratorFlag:[NewReportChild]
			]
		},
		//GeneratorFlag:[AdminOperationLayout]
		{
			path: 'admin-operation',
			name: 'shop.adminOperation',
			component: () => import('@/modules/shop/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.layout.adminOperation',
				clientAccessKeys:['/shop/admin-operation'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			adminOperationPlanTransactionRoute, //GeneratorFlag:[NewAdminOperationChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
bankPaymentRoute, invoiceRoute, personAddressRoute, personPreTransactionRoute, productRoute, productCategoryRoute, productDetailRoute, productDocRoute, productFeatureRoute, productMonetaryFeatureRoute, productStoreRoute, storeRoute, baseInfoBankRoute, baseInfoCategoryRoute, baseInfoFeatureRoute, baseInfoFeatureItemRoute, baseInfoPlanPrecentRoute, baseInfoPlanSettingRoute, baseInfoProductColorRoute, baseInfoStoreProfitRateRoute, baseSysBankPaymentStatuseRoute, baseSysCultureRoute, baseSysInvoicePaymentTypeRoute, baseSysInvoiceStatuseRoute, baseSysInvoiceTransactionTypeRoute, baseSysPersonCreaditTransactionTypeRoute, baseSysPersonPreTransactionStatuseRoute, baseSysPlanTransactionStatuseRoute, baseSysPlanTransactionTypeRoute, baseSysProductDocTypeRoute, baseSysProductStatusRoute, baseSysProductTransportCostTypeRoute, reportPlanTransactionRoute, invoiceCancelRoute, baseSysInvoiceCancelStatuseRoute, planTransactionRoute, adminOperationPlanTransactionRoute, baseSysPlanTransactionCommentTypeRoute, //GeneratorFlag:[NewExport]
}