//GeneratorFlag:[NoChange]
export default {
	path: 'person-app-purchase-type',
	name: 'peopleNet.baseSys.personAppPurchaseType.layout',
	redirect: to => {
		return {name: 'peopleNet.personAppPurchaseType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-app-purchase-type"],
		resourceKey: 'peopleNet.views.personAppPurchaseType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonAppPurchaseTypeDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personAppPurchaseType.detail',
			component: () => import('@/modules/people-net/views/person-app-purchase-type/person-app-purchase-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchaseType.detail',
				clientAccessKeys:["/people-net/base-sys/person-app-purchase-type/detail"],
				serverAccessKeys:["/people-net/person-app-purchase-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseTypeList]"
		{
			path: 'list',
			name: 'peopleNet.personAppPurchaseType.list',
			component: () => import('@/modules/people-net/views/person-app-purchase-type/person-app-purchase-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchaseType.list',
				clientAccessKeys:["/people-net/base-sys/person-app-purchase-type/list"],
				serverAccessKeys:["/people-net/person-app-purchase-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}