//GeneratorFlag:[NoChange]
export default {
	path: 'person-creadit-transaction-type',
	name: 'shop.baseSys.personCreaditTransactionType.layout',
	redirect: to => {
		return {name: 'shop.personCreaditTransactionType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/person-creadit-transaction-type"],
		resourceKey: 'shop.views.personCreaditTransactionType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonCreaditTransactionTypeDetail]"
		{
			path: 'detail',
			name: 'shop.personCreaditTransactionType.detail',
			component: () => import('@/modules/shop/views/person-creadit-transaction-type/person-creadit-transaction-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personCreaditTransactionType.detail',
				clientAccessKeys:["/shop/base-sys/person-creadit-transaction-type/detail"],
				serverAccessKeys:["/shop/person-creadit-transaction-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreaditTransactionTypeList]"
		{
			path: 'list',
			name: 'shop.personCreaditTransactionType.list',
			component: () => import('@/modules/shop/views/person-creadit-transaction-type/person-creadit-transaction-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personCreaditTransactionType.list',
				clientAccessKeys:["/shop/base-sys/person-creadit-transaction-type/list"],
				serverAccessKeys:["/shop/person-creadit-transaction-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}