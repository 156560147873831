<template>
	<div class="d-inline-block me-1 mb-2">
		<div class="btn-group" role="group">
			<button class="btn bg-base-color-dark border-0 rounded-top-0"
					@click="displayChange('grid')"
					v-bind:class="{'text-light': displayType == 'grid' }">
				<kaveh-icon-bootstrap name="bi-grid-3x3" size="15px" />
			</button>
			<button class="btn bg-base-color-dark border-0"
					@click="displayChange('grid-list')"
					v-bind:class="{'text-light': displayType == 'grid-list' }">
				<kaveh-icon-bootstrap name="bi-list" size="15px" />
			</button>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['displayChange'],

		props:
		{
			'filterEntity': Function,
			'displayType': String
		},

		data()
		{
			return {
				length: 0,
				isOpen: false,
				filterValue: null,
			}
		},

		methods:
		{
			displayChange(displayType)
			{
				this.$emit('displayChange', displayType)
			}
		}
	}
</script>

