//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseSubInfo } from '@/core/data'
import { ProductDetail, Store } from '@/modules/shop/data/product-store/list'; 
import { ProductDetailService, StoreService } from '@/modules/shop/services'; 
export default class ProductStore extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'productStore';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'shop.productStoreList.self';
	props = {
		productStoreId: {
			primarykey: true,
			name: 'productStoreId',
			resourceKey: 'shop.productStoreList.productStoreId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productDetailId: {
			foreignKey: true,
			name: 'productDetailId',
			resourceKey: 'shop.productStoreList.productDetailId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new ProductDetailService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'adminDisplayName',
				valueKey: 'productDetailId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		storeId: {
			foreignKey: true,
			name: 'storeId',
			resourceKey: 'shop.productStoreList.storeId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new StoreService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'storeName',
				valueKey: 'storeId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		firstPrice: {
			name: 'firstPrice',
			resourceKey: 'shop.productStoreList.firstPrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		secondStorePrice: {
			name: 'secondStorePrice',
			resourceKey: 'shop.productStoreList.secondStorePrice',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productStoreInventory: {
			name: 'productStoreInventory',
			resourceKey: 'shop.productStoreList.productStoreInventory',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productScore: {
			name: 'productScore',
			resourceKey: 'shop.productStoreList.productScore',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		maxSellCount: {
			name: 'maxSellCount',
			resourceKey: 'shop.productStoreList.maxSellCount',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		minSellCount: {
			name: 'minSellCount',
			resourceKey: 'shop.productStoreList.minSellCount',
			type: ENUMS.PROP_TYPE.BYTE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productDetail: {
			name: 'productDetail',
			resourceKey: 'shop.productStoreList.productDetail.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new ProductDetail(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{adminDisplayName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		store: {
			name: 'store',
			resourceKey: 'shop.productStoreList.store.self',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new Store(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: '{storeName}',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		productStoreSubInfo: {
			name: 'productStoreSubInfo',
			resourceKey: 'shop.productStoreList.productStoreSubInfo',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseSubInfo(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.subInfo.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}