import ENUMS from '../enums'
import BaseEntity from './base-entity'

export default class BaseLogr extends BaseEntity
{
	constructor(arg)
	{
		super()
		this.prepair(arg)
	}

	setValue(value, entity)
	{
		if (value)
		{
			if (value.creatorId == value.modifierId &&
				value.creatorIp == value.modifierIp &&
				value.creatorName == value.modifierName &&
				value.createDate == value.modifyDate &&
				value.displayCreateDate == value.displayModifyDate &&
				value.createTime == value.modifyTime)
			{
				value.modifierId = null;
				value.modifierIp = null;
				value.modifierName = null;
				value.modifyDate = null;
				value.displayModifyDate = null;
				value.modifyTime = null;
			}

			super.setValue(value, entity)
		}
	}

	name = 'logr';
	schema = ENUMS.SCHEMA_TYPE.SUB_INFO;
	resourceKey = 'data.logr.self';
	props = {
		creatorId: {
			name: 'creatorId',
			resourceKey: 'data.logr.creatorId',

			type: ENUMS.PROP_TYPE.NUMBER,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				patterns: []
			}
		},
		creatorIp: {
			name: 'creatorIp',
			resourceKey: 'data.logr.creatorIp',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},
		creatorName: {
			name: 'creatorName',
			resourceKey: 'data.logr.creatorName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},

		createDate: {
			name: 'createDate',
			resourceKey: 'data.logr.createDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},
		displayCreateDate: {
			name: 'displayCreateDate',
			resourceKey: 'data.logr.displayCreateDate',
			type: ENUMS.PROP_TYPE.PDATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.PDATE,
				patterns: []
			}
		},

		createTime: {
			name: 'createTime',
			resourceKey: 'data.logr.createTime',
			type: ENUMS.PROP_TYPE.TIME,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		},

		modifierId: {
			name: 'modifierId',
			resourceKey: 'data.logr.modifierId',
			type: ENUMS.PROP_TYPE.NUMBER,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				patterns: []
			}
		},

		modifierIp: {
			name: 'modifierIp',
			resourceKey: 'data.logr.modifierIp',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},

		modifierName: {
			name: 'modifierName',
			resourceKey: 'data.logr.modifierName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				patterns: []
			}
		},
		modifyDate: {
			name: 'modifyDate',
			resourceKey: 'data.logr.modifyDate',
			type: ENUMS.PROP_TYPE.DATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.DATE,
				patterns: []
			}
		},
		displayModifyDate: {
			name: 'displayModifyDate',
			resourceKey: 'data.logr.displayModifyDate',
			type: ENUMS.PROP_TYPE.PDATE,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.PDATE,
				patterns: []
			}
		},
		modifyTime: {
			name: 'modifyTime',
			resourceKey: 'data.logr.modifyTime',
			type: ENUMS.PROP_TYPE.time,
			display: {
				isVisible:true,
				displayType: ENUMS.DISPLAY_TYPE.TEXT,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TIME,
				patterns: []
			}
		}
	}
}
