//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class PlanSettingFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'planSettingFilter';
	schema = ENUMS.SCHEMA_TYPE.BASE_INFO;
	resourceKey = 'global.filters';
	props = {
	};
}