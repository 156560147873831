<template>
	<tr :class="{'checked':data.checked}">
		<td v-if="selectable">
			<div class="form-check form-switch">
				<input type="checkbox"
					   class="form-check-input"
					   v-bind:role="'switch'"
					   v-on:change="check"
					   v-model="data.checked"
					   v-bind:checked="data.checked" />
			</div>
		</td>
		<td v-if="showRowNumber" @dblclick="handleCellClick">
			<span style="color: var(--kaveh-base-color-dark);">{{ ((currentPage - 1) * pageSize) + (index + 1) }}</span>
		</td>
		<td v-for="(prop) in getProps(data)" :key="prop.name" @dblclick="handleCellClick">
			<table-cell :data="prop" :showError="showError" />
		</td>
	</tr>
</template>
<script>

	import tableCell from '../cells/table-cell'
	export default {
		emits: ['checked'],
		components:
		{
			'table-cell': tableCell,
		},

		props:
		{
			'data': Object,
			'entity': Function,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'columns': Array,
			'index': Number,
			'showRowNumber': Boolean,
			'showError':Boolean,
			'showForeignKey':Boolean
		},

		methods:
		{

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || (this.columns || []).indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			check()
			{
				this.$emit('checked')
			},

			handleCellClick(e) {

				if (e.target.tagName == "TD") {
					this.data.checked = !this.data.checked
					this.check()
				}
			}

		},
	}
</script>
