<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { NoticeType, NoticeTypeFilter } from '@/modules/hub/data/notice-type/list'
	import { NoticeTypeService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return NoticeType;
			},
			
			filterEntity()
			{
				return NoticeTypeFilter;
			},

			service()
			{
				return new NoticeTypeService();
			},

			filterData()
			{
				const noticeTypeId =
					this.$route.query['noticeTypeId'];

				if (noticeTypeId)
				{
					return {
						'noticeTypeId': noticeTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>