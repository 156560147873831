//GeneratorFlag:[NoChange]
export default {
	path: 'feature',
	name: 'shop.baseInfo.feature.layout',
	redirect: to => {
		return {name: 'shop.feature.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-info/feature"],
		resourceKey: 'shop.views.feature.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[FeatureCreate]"
		{
			path: 'create',
			name: 'shop.feature.create',
			component: () => import('@/modules/shop/views/feature/feature-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.feature.create',
				clientAccessKeys:["/shop/base-info/feature/create"],
				serverAccessKeys:["/shop/feature/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureCreateImport]"
		{
			path: 'create-import',
			name: 'shop.feature.createImport',
			component: () => import('@/modules/shop/views/feature/feature-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.feature.createImport',
				clientAccessKeys:["/shop/base-info/feature/create-import"],
				serverAccessKeys:["/shop/feature/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureDelete]"
		{
			path: 'delete',
			name: 'shop.feature.delete',
			component: () => import('@/modules/shop/views/feature/feature-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.feature.delete',
				clientAccessKeys:["/shop/base-info/feature/delete"],
				serverAccessKeys:["/shop/feature/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureDetail]"
		{
			path: 'detail',
			name: 'shop.feature.detail',
			component: () => import('@/modules/shop/views/feature/feature-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.feature.detail',
				clientAccessKeys:["/shop/base-info/feature/detail"],
				serverAccessKeys:["/shop/feature/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureList]"
		{
			path: 'list',
			name: 'shop.feature.list',
			component: () => import('@/modules/shop/views/feature/feature-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.feature.list',
				clientAccessKeys:["/shop/base-info/feature/list"],
				serverAccessKeys:["/shop/feature/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureUpdate]"
		{
			path: 'update',
			name: 'shop.feature.update',
			component: () => import('@/modules/shop/views/feature/feature-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.feature.update',
				clientAccessKeys:["/shop/base-info/feature/update"],
				serverAccessKeys:["/shop/feature/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}