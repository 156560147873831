//GeneratorFlag:[NoChange]
export default {
	path: 'plan-transaction-type',
	name: 'shop.baseSys.planTransactionType.layout',
	redirect: to => {
		return {name: 'shop.planTransactionType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/plan-transaction-type"],
		resourceKey: 'shop.views.planTransactionType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanTransactionTypeDetail]"
		{
			path: 'detail',
			name: 'shop.planTransactionType.detail',
			component: () => import('@/modules/shop/views/plan-transaction-type/plan-transaction-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransactionType.detail',
				clientAccessKeys:["/shop/base-sys/plan-transaction-type/detail"],
				serverAccessKeys:["/shop/plan-transaction-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanTransactionTypeList]"
		{
			path: 'list',
			name: 'shop.planTransactionType.list',
			component: () => import('@/modules/shop/views/plan-transaction-type/plan-transaction-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransactionType.list',
				clientAccessKeys:["/shop/base-sys/plan-transaction-type/list"],
				serverAccessKeys:["/shop/plan-transaction-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}