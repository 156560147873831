//GeneratorFlag:[NoChange]
export default {
	path: 'notice-provider',
	name: 'hub.baseSys.noticeProvider.layout',
	redirect: to => {
		return {name: 'hub.noticeProvider.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-sys/notice-provider"],
		resourceKey: 'hub.views.noticeProvider.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NoticeProviderDetail]"
		{
			path: 'detail',
			name: 'hub.noticeProvider.detail',
			component: () => import('@/modules/hub/views/notice-provider/notice-provider-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeProvider.detail',
				clientAccessKeys:["/hub/base-sys/notice-provider/detail"],
				serverAccessKeys:["/hub/notice-provider/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeProviderList]"
		{
			path: 'list',
			name: 'hub.noticeProvider.list',
			component: () => import('@/modules/hub/views/notice-provider/notice-provider-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeProvider.list',
				clientAccessKeys:["/hub/base-sys/notice-provider/list"],
				serverAccessKeys:["/hub/notice-provider/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}