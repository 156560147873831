//GeneratorFlag:[NoChange]
export default {
	path: 'city',
	name: 'hub.city.layout',
	redirect: to => {
		return {name: 'hub.city.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/city"],
		resourceKey: 'hub.views.city.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[CityCreate]"
		{
			path: 'create',
			name: 'hub.city.create',
			component: () => import('@/modules/hub/views/city/city-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.create',
				clientAccessKeys:["/hub/city/create"],
				serverAccessKeys:["/hub/city/create","/hub/province/list","/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CityImport]"
		{
			path: 'import',
			name: 'hub.city.import',
			component: () => import('@/modules/hub/views/city/city-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.import',
				clientAccessKeys:["/hub/city/import"],
				serverAccessKeys:["/hub/city/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CityDelete]"
		{
			path: 'delete',
			name: 'hub.city.delete',
			component: () => import('@/modules/hub/views/city/city-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.delete',
				clientAccessKeys:["/hub/city/delete"],
				serverAccessKeys:["/hub/city/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CityDetail]"
		{
			path: 'detail',
			name: 'hub.city.detail',
			component: () => import('@/modules/hub/views/city/city-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.detail',
				clientAccessKeys:["/hub/city/detail"],
				serverAccessKeys:["/hub/city/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CityList]"
		{
			path: 'list',
			name: 'hub.city.list',
			component: () => import('@/modules/hub/views/city/city-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.list',
				clientAccessKeys:["/hub/city/list"],
				serverAccessKeys:["/hub/city/list","/hub/province/list","/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CityUpdate]"
		{
			path: 'update',
			name: 'hub.city.update',
			component: () => import('@/modules/hub/views/city/city-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.update',
				clientAccessKeys:["/hub/city/update"],
				serverAccessKeys:["/hub/city/update","/hub/province/list","/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CityFullCity]"
		{
			path: 'full-city',
			name: 'hub.city.fullCity',
			component: () => import('@/modules/hub/views/city/city-full-city.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.city.fullCity',
				clientAccessKeys:["/hub/city/full-city"],
				serverAccessKeys:["/hub/city/full-city"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]







	]
}