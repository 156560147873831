<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { InvoiceCancelStatuse, InvoiceCancelStatuseFilter } from '@/modules/shop/data/invoice-cancel-statuse/list'
	import { InvoiceCancelStatuseService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return InvoiceCancelStatuse;
			},
			
			filterEntity()
			{
				return InvoiceCancelStatuseFilter;
			},

			service()
			{
				return new InvoiceCancelStatuseService();
			},

			filterData()
			{
				const invoiceCancelStatuseId =
					this.$route.query['invoiceCancelStatuseId'];

				if (invoiceCancelStatuseId)
				{
					return {
						'invoiceCancelStatuseId': invoiceCancelStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>