<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonGender, PersonGenderFilter } from '@/modules/hub/data/person-gender/list'
	import { PersonGenderService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonGender;
			},
			
			filterEntity()
			{
				return PersonGenderFilter;
			},

			service()
			{
				return new PersonGenderService();
			},

			filterData()
			{
				const personGenderId =
					this.$route.query['personGenderId'];

				if (personGenderId)
				{
					return {
						'personGenderId': personGenderId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>