<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PlanTransactionStatuse, PlanTransactionStatuseFilter } from '@/modules/shop/data/plan-transaction-statuse/list'
	import { PlanTransactionStatuseService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return PlanTransactionStatuse;
			},
			
			filterEntity()
			{
				return PlanTransactionStatuseFilter;
			},

			service()
			{
				return new PlanTransactionStatuseService();
			},

			filterData()
			{
				const planTransactionStatuseId =
					this.$route.query['planTransactionStatuseId'];

				if (planTransactionStatuseId)
				{
					return {
						'planTransactionStatuseId': planTransactionStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>