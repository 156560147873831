<template>
	<component v-bind:mode="mode"
			   v-bind:prop="prop"
			   v-bind:is="`form-${displayTypeComputed}`"
			   v-bind:use-validation="useValidation"
			   v-on:generator-up="(x) => {$emit('generator-up', x)}"
			   v-on:generator-down="(x) => {$emit('generator-down', x)}"
			   v-on:generator-remove="(x) => {$emit('generator-remove', x)}"
			   v-on:generator-select="(x) => {$emit('generator-select', x)}">
		<component v-bind:is="`form-${prop.setConfig.inputType}`"
				   v-bind:prop="prop"
				   v-on:change="onHandelChange"
				   v-on:keyup="onHandelKeyup"
				   v-on:submit="$emit('submit')" />
	</component>
</template>

<script>
	import validator from '@/core/validator'
	import formBlock from './common/form-block'
	import formInline from './common/form-inline'
	import formCheckbox from './checkbox/form-checkbox'
	import formCheckdrop from './checkdrop/form-checkdrop'
	import formNumber from './number/form-number'
	import formPassword from './password/form-password'
	import formSelect from './select/form-select'
	import formText from './text/form-text'
	import formHidden from './hidden/form-hidden'
	import formDate from './date/form-date'
	import formTime from './time/form-time'
	import formPersianDate from './persian-date/form-persian-date'
	import formEmail from './email/form-email'
	import formTextarea from './textarea/form-textarea'
	import formTree from './tree/form-tree'
	import formColor from './color/form-color'
	import formTextEditor from './text-editor/form-text-editor'
	import formUploader from './uploader/form-uploader'
	import formDatePicker from './date-picker/form-date-picker'
	import formTimePicker from './time-picker/form-time-picker'
	import formSignaturePad from './signature-pad/form-signature-pad'
	import { deviceType } from '@/plugins'

	export default {
		emits: ['submit', 'generator-remove', 'generator-up', 'generator-down', 'generator-select'],

		components: {
			'form-block': formBlock,
			'form-inline': formInline,
			'form-checkbox': formCheckbox,
			'form-checkdrop': formCheckdrop,
			'form-number': formNumber,
			'form-password': formPassword,
			'form-select': formSelect,
			'form-text': formText,
			'form-hidden': formHidden,
			'form-date': formDate,
			'form-time': formTime,
			'form-persian-date': formPersianDate,
			'form-email': formEmail,
			'form-textarea': formTextarea,
			'form-tree': formTree,
			'form-color': formColor,
			'form-text-editor': formTextEditor,
			'form-uploader': formUploader,
			'form-date-picker': formDatePicker,
			'form-time-picker': formTimePicker,
			'form-signature-pad': formSignaturePad,
		},

		props: {

			'prop': {
				type: Object
			},

			'mode': {
				type: String
			},

			'displayType': {
				type: String
			},

			'useValidation': {
				type: Boolean
			},
		},

		data() {
			return {
				_prop: this.prop
			}
		},

		computed: {

			displayTypeComputed() {
				if (this.displayType) {
					return this.displayType
				}

				return deviceType() == 'laptop' ? 'inline' : 'block'
			}
		},

		methods:
		{
			onHandelChange(value, reset) {
				if (!this._prop.changed) { this._prop.changed = true }

				if (reset) {
					this.bideReset()
				} else this.bindChange(value)
				this.$emitter.emit('formChange', this._prop)
			},

			onHandelKeyup(value) {
				if (typeof (value) != 'object' && this._prop.changed) {
					this.bindChange(value)
				}
			},

			bideReset() {
				this._prop.value = null
				this._prop.errors = []
			},

			bindChange(value) {
				this._prop.value =
					value !== "" ? value : undefined

				if (this.useValidation) {
					this._prop.errors =
						validator.getPropErrors(this._prop)
				}
			},
		}
	}
</script>
