//GeneratorFlag:[NoChange]
import BankPaymentService from './bank-payment-service'; //GeneratorFlag:[BankPayment]
import InvoiceService from './invoice-service'; //GeneratorFlag:[Invoice]
import PersonAddressService from './person-address-service'; //GeneratorFlag:[PersonAddress]
import PersonPreTransactionService from './person-pre-transaction-service'; //GeneratorFlag:[PersonPreTransaction]
import ProductService from './product-service'; //GeneratorFlag:[Product]
import ProductCategoryService from './product-category-service'; //GeneratorFlag:[ProductCategory]
import ProductDetailService from './product-detail-service'; //GeneratorFlag:[ProductDetail]
import ProductDocService from './product-doc-service'; //GeneratorFlag:[ProductDoc]
import ProductFeatureService from './product-feature-service'; //GeneratorFlag:[ProductFeature]
import ProductMonetaryFeatureService from './product-monetary-feature-service'; //GeneratorFlag:[ProductMonetaryFeature]
import ProductStoreService from './product-store-service'; //GeneratorFlag:[ProductStore]
import StoreService from './store-service'; //GeneratorFlag:[Store]
import BankService from './bank-service'; //GeneratorFlag:[Bank]
import CategoryService from './category-service'; //GeneratorFlag:[Category]
import FeatureService from './feature-service'; //GeneratorFlag:[Feature]
import FeatureItemService from './feature-item-service'; //GeneratorFlag:[FeatureItem]
import PlanPrecentService from './plan-precent-service'; //GeneratorFlag:[PlanPrecent]
import PlanSettingService from './plan-setting-service'; //GeneratorFlag:[PlanSetting]
import ProductColorService from './product-color-service'; //GeneratorFlag:[ProductColor]
import StoreProfitRateService from './store-profit-rate-service'; //GeneratorFlag:[StoreProfitRate]
import BankPaymentStatuseService from './bank-payment-statuse-service'; //GeneratorFlag:[BankPaymentStatuse]
import CultureService from './culture-service'; //GeneratorFlag:[Culture]
import InvoicePaymentTypeService from './invoice-payment-type-service'; //GeneratorFlag:[InvoicePaymentType]
import InvoiceStatuseService from './invoice-statuse-service'; //GeneratorFlag:[InvoiceStatuse]
import InvoiceTransactionTypeService from './invoice-transaction-type-service'; //GeneratorFlag:[InvoiceTransactionType]
import PersonCreaditTransactionTypeService from './person-creadit-transaction-type-service'; //GeneratorFlag:[PersonCreaditTransactionType]
import PersonPreTransactionStatuseService from './person-pre-transaction-statuse-service'; //GeneratorFlag:[PersonPreTransactionStatuse]
import PlanTransactionStatuseService from './plan-transaction-statuse-service'; //GeneratorFlag:[PlanTransactionStatuse]
import PlanTransactionTypeService from './plan-transaction-type-service'; //GeneratorFlag:[PlanTransactionType]
import ProductDocTypeService from './product-doc-type-service'; //GeneratorFlag:[ProductDocType]
import ProductStatusService from './product-status-service'; //GeneratorFlag:[ProductStatus]
import ProductTransportCostTypeService from './product-transport-cost-type-service'; //GeneratorFlag:[ProductTransportCostType]
import PlanLineService from './plan-line-service'; //GeneratorFlag:[PlanLine]
import PlanTransactionService from './plan-transaction-service'; //GeneratorFlag:[PlanTransaction]
import InvoiceCancelService from './invoice-cancel-service'; //GeneratorFlag:[InvoiceCancel]
import InvoiceCancelStatuseService from './invoice-cancel-statuse-service'; //GeneratorFlag:[InvoiceCancelStatuse]
import PlanTransactionCommentTypeService from './plan-transaction-comment-type-service'; //GeneratorFlag:[PlanTransactionCommentType]
//GeneratorFlag:[NewImport]
export default {
BankPaymentService, //GeneratorFlag:[BankPayment]
InvoiceService, //GeneratorFlag:[Invoice]
PersonAddressService, //GeneratorFlag:[PersonAddress]
PersonPreTransactionService, //GeneratorFlag:[PersonPreTransaction]
ProductService, //GeneratorFlag:[Product]
ProductCategoryService, //GeneratorFlag:[ProductCategory]
ProductDetailService, //GeneratorFlag:[ProductDetail]
ProductDocService, //GeneratorFlag:[ProductDoc]
ProductFeatureService, //GeneratorFlag:[ProductFeature]
ProductMonetaryFeatureService, //GeneratorFlag:[ProductMonetaryFeature]
ProductStoreService, //GeneratorFlag:[ProductStore]
StoreService, //GeneratorFlag:[Store]
BankService, //GeneratorFlag:[Bank]
CategoryService, //GeneratorFlag:[Category]
FeatureService, //GeneratorFlag:[Feature]
FeatureItemService, //GeneratorFlag:[FeatureItem]
PlanPrecentService, //GeneratorFlag:[PlanPrecent]
PlanSettingService, //GeneratorFlag:[PlanSetting]
ProductColorService, //GeneratorFlag:[ProductColor]
StoreProfitRateService, //GeneratorFlag:[StoreProfitRate]
BankPaymentStatuseService, //GeneratorFlag:[BankPaymentStatuse]
CultureService, //GeneratorFlag:[Culture]
InvoicePaymentTypeService, //GeneratorFlag:[InvoicePaymentType]
InvoiceStatuseService, //GeneratorFlag:[InvoiceStatuse]
InvoiceTransactionTypeService, //GeneratorFlag:[InvoiceTransactionType]
PersonCreaditTransactionTypeService, //GeneratorFlag:[PersonCreaditTransactionType]
PersonPreTransactionStatuseService, //GeneratorFlag:[PersonPreTransactionStatuse]
PlanTransactionStatuseService, //GeneratorFlag:[PlanTransactionStatuse]
PlanTransactionTypeService, //GeneratorFlag:[PlanTransactionType]
ProductDocTypeService, //GeneratorFlag:[ProductDocType]
ProductStatusService, //GeneratorFlag:[ProductStatus]
ProductTransportCostTypeService, //GeneratorFlag:[ProductTransportCostType]
PlanLineService, //GeneratorFlag:[PlanLine]
PlanTransactionService, //GeneratorFlag:[PlanTransaction]
InvoiceCancelService, //GeneratorFlag:[InvoiceCancel]
InvoiceCancelStatuseService, //GeneratorFlag:[InvoiceCancelStatuse]
PlanTransactionCommentTypeService, //GeneratorFlag:[PlanTransactionCommentType]
//GeneratorFlag:[NewExport]
}
export{
BankPaymentService, //GeneratorFlag:[BankPayment]
InvoiceService, //GeneratorFlag:[Invoice]
PersonAddressService, //GeneratorFlag:[PersonAddress]
PersonPreTransactionService, //GeneratorFlag:[PersonPreTransaction]
ProductService, //GeneratorFlag:[Product]
ProductCategoryService, //GeneratorFlag:[ProductCategory]
ProductDetailService, //GeneratorFlag:[ProductDetail]
ProductDocService, //GeneratorFlag:[ProductDoc]
ProductFeatureService, //GeneratorFlag:[ProductFeature]
ProductMonetaryFeatureService, //GeneratorFlag:[ProductMonetaryFeature]
ProductStoreService, //GeneratorFlag:[ProductStore]
StoreService, //GeneratorFlag:[Store]
BankService, //GeneratorFlag:[Bank]
CategoryService, //GeneratorFlag:[Category]
FeatureService, //GeneratorFlag:[Feature]
FeatureItemService, //GeneratorFlag:[FeatureItem]
PlanPrecentService, //GeneratorFlag:[PlanPrecent]
PlanSettingService, //GeneratorFlag:[PlanSetting]
ProductColorService, //GeneratorFlag:[ProductColor]
StoreProfitRateService, //GeneratorFlag:[StoreProfitRate]
BankPaymentStatuseService, //GeneratorFlag:[BankPaymentStatuse]
CultureService, //GeneratorFlag:[Culture]
InvoicePaymentTypeService, //GeneratorFlag:[InvoicePaymentType]
InvoiceStatuseService, //GeneratorFlag:[InvoiceStatuse]
InvoiceTransactionTypeService, //GeneratorFlag:[InvoiceTransactionType]
PersonCreaditTransactionTypeService, //GeneratorFlag:[PersonCreaditTransactionType]
PersonPreTransactionStatuseService, //GeneratorFlag:[PersonPreTransactionStatuse]
PlanTransactionStatuseService, //GeneratorFlag:[PlanTransactionStatuse]
PlanTransactionTypeService, //GeneratorFlag:[PlanTransactionType]
ProductDocTypeService, //GeneratorFlag:[ProductDocType]
ProductStatusService, //GeneratorFlag:[ProductStatus]
ProductTransportCostTypeService, //GeneratorFlag:[ProductTransportCostType]
PlanLineService, //GeneratorFlag:[PlanLine]
PlanTransactionService, //GeneratorFlag:[PlanTransaction]
InvoiceCancelService, //GeneratorFlag:[InvoiceCancel]
InvoiceCancelStatuseService, //GeneratorFlag:[InvoiceCancelStatuse]
PlanTransactionCommentTypeService, //GeneratorFlag:[PlanTransactionCommentType]
//GeneratorFlag:[NewExport]
}