//GeneratorFlag:[NoChange]
export default {
	path: 'notice-type',
	name: 'hub.notice.noticeType.layout',
	redirect: to => {
		return {name: 'hub.noticeType.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/notice/notice-type"],
		resourceKey: 'hub.views.noticeType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NoticeTypeDetail]"
		{
			path: 'detail',
			name: 'hub.noticeType.detail',
			component: () => import('@/modules/hub/views/notice-type/notice-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeType.detail',
				clientAccessKeys:["/hub/notice/notice-type/detail"],
				serverAccessKeys:["/hub/notice-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeTypeList]"
		{
			path: 'list',
			name: 'hub.noticeType.list',
			component: () => import('@/modules/hub/views/notice-type/notice-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeType.list',
				clientAccessKeys:["/hub/notice/notice-type/list"],
				serverAccessKeys:["/hub/notice-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}