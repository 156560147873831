//GeneratorFlag:[NoChange]
export default {
	path: 'person-marital',
	name: 'hub.baseSys.personMarital.layout',
	redirect: to => {
		return {name: 'hub.personMarital.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-sys/person-marital"],
		resourceKey: 'hub.views.personMarital.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonMaritalDetail]"
		{
			path: 'detail',
			name: 'hub.personMarital.detail',
			component: () => import('@/modules/hub/views/person-marital/person-marital-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMarital.detail',
				clientAccessKeys:["/hub/base-sys/person-marital/detail"],
				serverAccessKeys:["/hub/person-marital/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonMaritalList]"
		{
			path: 'list',
			name: 'hub.personMarital.list',
			component: () => import('@/modules/hub/views/person-marital/person-marital-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personMarital.list',
				clientAccessKeys:["/hub/base-sys/person-marital/list"],
				serverAccessKeys:["/hub/person-marital/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}