//GeneratorFlag:[NoChange]
export default {
	path: 'nationality-type',
	name: 'peopleNet.baseInfo.nationalityType.layout',
	redirect: to => {
		return {name: 'peopleNet.nationalityType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-info/nationality-type"],
		resourceKey: 'peopleNet.views.nationalityType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NationalityTypeCreate]"
		{
			path: 'create',
			name: 'peopleNet.nationalityType.create',
			component: () => import('@/modules/people-net/views/nationality-type/nationality-type-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.nationalityType.create',
				clientAccessKeys:["/people-net/base-info/nationality-type/create"],
				serverAccessKeys:["/people-net/nationality-type/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NationalityTypeCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.nationalityType.createImport',
			component: () => import('@/modules/people-net/views/nationality-type/nationality-type-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.nationalityType.createImport',
				clientAccessKeys:["/people-net/base-info/nationality-type/create-import"],
				serverAccessKeys:["/people-net/nationality-type/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NationalityTypeDelete]"
		{
			path: 'delete',
			name: 'peopleNet.nationalityType.delete',
			component: () => import('@/modules/people-net/views/nationality-type/nationality-type-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.nationalityType.delete',
				clientAccessKeys:["/people-net/base-info/nationality-type/delete"],
				serverAccessKeys:["/people-net/nationality-type/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NationalityTypeDetail]"
		{
			path: 'detail',
			name: 'peopleNet.nationalityType.detail',
			component: () => import('@/modules/people-net/views/nationality-type/nationality-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.nationalityType.detail',
				clientAccessKeys:["/people-net/base-info/nationality-type/detail"],
				serverAccessKeys:["/people-net/nationality-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NationalityTypeList]"
		{
			path: 'list',
			name: 'peopleNet.nationalityType.list',
			component: () => import('@/modules/people-net/views/nationality-type/nationality-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.nationalityType.list',
				clientAccessKeys:["/people-net/base-info/nationality-type/list"],
				serverAccessKeys:["/people-net/nationality-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NationalityTypeUpdate]"
		{
			path: 'update',
			name: 'peopleNet.nationalityType.update',
			component: () => import('@/modules/people-net/views/nationality-type/nationality-type-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.nationalityType.update',
				clientAccessKeys:["/people-net/base-info/nationality-type/update"],
				serverAccessKeys:["/people-net/nationality-type/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}