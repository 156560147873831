//GeneratorFlag:[NoChange]
import NoticeConfigService from './notice-config-service'; //GeneratorFlag:[NoticeConfig]
import PersonService from './person-service'; //GeneratorFlag:[Person]
import CityService from './city-service'; //GeneratorFlag:[City]
import CountryService from './country-service'; //GeneratorFlag:[Country]
import PersonMilitaryService from './person-military-service'; //GeneratorFlag:[PersonMilitary]
import PersonNationalityService from './person-nationality-service'; //GeneratorFlag:[PersonNationality]
import PersonRoleService from './person-role-service'; //GeneratorFlag:[PersonRole]
import ProvinceService from './province-service'; //GeneratorFlag:[Province]
import RegionService from './region-service'; //GeneratorFlag:[Region]
import NoticeConfigIppanelService from './notice-config-ippanel-service'; //GeneratorFlag:[NoticeConfigIppanel]
import NoticeConfigKavenegarService from './notice-config-kavenegar-service'; //GeneratorFlag:[NoticeConfigKavenegar]
import PersonAccessService from './person-access-service'; //GeneratorFlag:[PersonAccess]
import PersonLegalService from './person-legal-service'; //GeneratorFlag:[PersonLegal]
import PersonRealService from './person-real-service'; //GeneratorFlag:[PersonReal]
import FileAccessTypeService from './file-access-type-service'; //GeneratorFlag:[FileAccessType]
import FileExtensionService from './file-extension-service'; //GeneratorFlag:[FileExtension]
import NoticeTypeService from './notice-type-service'; //GeneratorFlag:[NoticeType]
import PersonEntityService from './person-entity-service'; //GeneratorFlag:[PersonEntity]
import PersonGenderService from './person-gender-service'; //GeneratorFlag:[PersonGender]
import PersonGradeService from './person-grade-service'; //GeneratorFlag:[PersonGrade]
import PersonMaritalService from './person-marital-service'; //GeneratorFlag:[PersonMarital]
import AccessAgentService from './access-agent-service'; //GeneratorFlag:[AccessAgent]
import AccessTokenService from './access-token-service'; //GeneratorFlag:[AccessToken]
import StaticFileService from './static-file-service'; //GeneratorFlag:[StaticFile]
import NoticeProviderService from './notice-provider-service'; //GeneratorFlag:[NoticeProvider]
import NoticeStatusService from './notice-status-service'; //GeneratorFlag:[NoticeStatus]
import OtpService from './otp-service'; //GeneratorFlag:[Otp]
//GeneratorFlag:[NewImport]
export default {
NoticeConfigService, //GeneratorFlag:[NoticeConfig]
PersonService, //GeneratorFlag:[Person]
CityService, //GeneratorFlag:[City]
CountryService, //GeneratorFlag:[Country]
PersonMilitaryService, //GeneratorFlag:[PersonMilitary]
PersonNationalityService, //GeneratorFlag:[PersonNationality]
PersonRoleService, //GeneratorFlag:[PersonRole]
ProvinceService, //GeneratorFlag:[Province]
RegionService, //GeneratorFlag:[Region]
NoticeConfigIppanelService, //GeneratorFlag:[NoticeConfigIppanel]
NoticeConfigKavenegarService, //GeneratorFlag:[NoticeConfigKavenegar]
PersonAccessService, //GeneratorFlag:[PersonAccess]
PersonLegalService, //GeneratorFlag:[PersonLegal]
PersonRealService, //GeneratorFlag:[PersonReal]
FileAccessTypeService, //GeneratorFlag:[FileAccessType]
FileExtensionService, //GeneratorFlag:[FileExtension]
NoticeTypeService, //GeneratorFlag:[NoticeType]
PersonEntityService, //GeneratorFlag:[PersonEntity]
PersonGenderService, //GeneratorFlag:[PersonGender]
PersonGradeService, //GeneratorFlag:[PersonGrade]
PersonMaritalService, //GeneratorFlag:[PersonMarital]
AccessAgentService, //GeneratorFlag:[AccessAgent]
AccessTokenService, //GeneratorFlag:[AccessToken]
StaticFileService, //GeneratorFlag:[StaticFile]
NoticeProviderService, //GeneratorFlag:[NoticeProvider]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
OtpService, //GeneratorFlag:[Otp]
//GeneratorFlag:[NewExport]
}
export{
NoticeConfigService, //GeneratorFlag:[NoticeConfig]
PersonService, //GeneratorFlag:[Person]
CityService, //GeneratorFlag:[City]
CountryService, //GeneratorFlag:[Country]
PersonMilitaryService, //GeneratorFlag:[PersonMilitary]
PersonNationalityService, //GeneratorFlag:[PersonNationality]
PersonRoleService, //GeneratorFlag:[PersonRole]
ProvinceService, //GeneratorFlag:[Province]
RegionService, //GeneratorFlag:[Region]
NoticeConfigIppanelService, //GeneratorFlag:[NoticeConfigIppanel]
NoticeConfigKavenegarService, //GeneratorFlag:[NoticeConfigKavenegar]
PersonAccessService, //GeneratorFlag:[PersonAccess]
PersonLegalService, //GeneratorFlag:[PersonLegal]
PersonRealService, //GeneratorFlag:[PersonReal]
FileAccessTypeService, //GeneratorFlag:[FileAccessType]
FileExtensionService, //GeneratorFlag:[FileExtension]
NoticeTypeService, //GeneratorFlag:[NoticeType]
PersonEntityService, //GeneratorFlag:[PersonEntity]
PersonGenderService, //GeneratorFlag:[PersonGender]
PersonGradeService, //GeneratorFlag:[PersonGrade]
PersonMaritalService, //GeneratorFlag:[PersonMarital]
AccessAgentService, //GeneratorFlag:[AccessAgent]
AccessTokenService, //GeneratorFlag:[AccessToken]
StaticFileService, //GeneratorFlag:[StaticFile]
NoticeProviderService, //GeneratorFlag:[NoticeProvider]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
OtpService, //GeneratorFlag:[Otp]
//GeneratorFlag:[NewExport]
}