//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"shop",
	"ext": ext,
	"layout":{
		"baseInfo": "Basic Information", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "System information", //GeneratorFlag:[BaseSysLayout]
		"report": "Report", //GeneratorFlag:[ReportLayout]
		"adminOperation": "Add Extra Commision", //GeneratorFlag:[AdminOperationLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"bankPayment": {
			"layout": "BankPayment",
			"create": "Create New BankPayment", //GeneratorFlag:[Views.BankPayment.Create]
			'createImport': 'Imports BankPayment From Excel', //GeneratorFlag:[Views.BankPayment.CreateImport] 
 			'delete': 'Delete BankPayment', //GeneratorFlag:[Views.BankPayment.Delete] 
 			'detail': 'Detail of BankPayment', //GeneratorFlag:[Views.BankPayment.Detail] 
 			'list': 'List of BankPayment', //GeneratorFlag:[Views.BankPayment.List] 
 			'update': 'Edit BankPayment', //GeneratorFlag:[Views.BankPayment.Update] 
 			'getPayment': 'Payment Gateway', //GeneratorFlag:[Views.BankPayment.GetPayment] 
 //GeneratorFlag:[Views.BankPayment]
		},
		"invoice": {
			"layout": "Invoice",
			"create": "Create New Invoice", //GeneratorFlag:[Views.Invoice.Create]
			'createImport': 'Imports Invoice From Excel', //GeneratorFlag:[Views.Invoice.CreateImport] 
 			'delete': 'Delete Invoice', //GeneratorFlag:[Views.Invoice.Delete] 
 			'detail': 'Detail of Invoice', //GeneratorFlag:[Views.Invoice.Detail] 
 			'list': 'List of Invoice', //GeneratorFlag:[Views.Invoice.List] 
 			'update': 'Edit Invoice', //GeneratorFlag:[Views.Invoice.Update] 
 			'amountSum': 'Total Amount of My invoice ', //GeneratorFlag:[Views.Invoice.AmountSum] 
 			'connectToOther': 'Connect invoce To Other', //GeneratorFlag:[Views.Invoice.ConnectToOther] 
 			'contactSummray': 'Report Of Contracts', //GeneratorFlag:[Views.Invoice.ContactSummray] 
 			'getContracts': 'List of Invoice', //GeneratorFlag:[Views.Invoice.GetContracts] 
 			'getInvoiceDetail': 'Detail Of Invoice', //GeneratorFlag:[Views.Invoice.GetInvoiceDetail] 
 			'getMyInvoiceCurrentWeek': 'List of Current Week Invoice', //GeneratorFlag:[Views.Invoice.GetMyInvoiceCurrentWeek] 
 			'getMyList': 'List of Invoice', //GeneratorFlag:[Views.Invoice.GetMyList] 
 			'getMyTeamSellByWeek': 'Sell of Team', //GeneratorFlag:[Views.Invoice.GetMyTeamSellByWeek] 
 			'register': 'Register Invoice', //GeneratorFlag:[Views.Invoice.Register] 
 			'uplineFactorInfo': 'Upline Invoice Info', //GeneratorFlag:[Views.Invoice.UplineFactorInfo] 
 //GeneratorFlag:[Views.Invoice]
		},
		"invoiceCancel": {
			"layout": "InvoiceCancel",
			"create": "Create New InvoiceCancel", //GeneratorFlag:[Views.InvoiceCancel.Create]
			'createImport': 'Imports InvoiceCancel From Excel', //GeneratorFlag:[Views.InvoiceCancel.CreateImport] 
 			'delete': 'Delete InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.Delete] 
 			'detail': 'Detail of InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.Detail] 
 			'list': 'List of InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.List] 
 			'update': 'Edit InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.Update] 
 			'contractInfo': 'Cancel Contract', //GeneratorFlag:[Views.InvoiceCancel.ContractInfo] 
 			'removeContract': 'Cancel Contract', //GeneratorFlag:[Views.InvoiceCancel.RemoveContract] 
 //GeneratorFlag:[Views.InvoiceCancel]
		},
		"personAddress": {
			"layout": "PersonAddress",
			"create": "Create New PersonAddress", //GeneratorFlag:[Views.PersonAddress.Create]
			'createImport': 'Imports PersonAddress From Excel', //GeneratorFlag:[Views.PersonAddress.CreateImport] 
 			'delete': 'Delete PersonAddress', //GeneratorFlag:[Views.PersonAddress.Delete] 
 			'detail': 'Detail of PersonAddress', //GeneratorFlag:[Views.PersonAddress.Detail] 
 			'list': 'List of PersonAddress', //GeneratorFlag:[Views.PersonAddress.List] 
 			'update': 'Edit PersonAddress', //GeneratorFlag:[Views.PersonAddress.Update] 
 //GeneratorFlag:[Views.PersonAddress]
		},
		"personPreTransaction": {
			"layout": "PersonPreTransaction",
			"create": "Create New PersonPreTransaction", //GeneratorFlag:[Views.PersonPreTransaction.Create]
			'createImport': 'Imports PersonPreTransaction From Excel', //GeneratorFlag:[Views.PersonPreTransaction.CreateImport] 
 			'delete': 'Delete PersonPreTransaction', //GeneratorFlag:[Views.PersonPreTransaction.Delete] 
 			'detail': 'Detail of PersonPreTransaction', //GeneratorFlag:[Views.PersonPreTransaction.Detail] 
 			'list': 'List of PersonPreTransaction', //GeneratorFlag:[Views.PersonPreTransaction.List] 
 			'update': 'Edit PersonPreTransaction', //GeneratorFlag:[Views.PersonPreTransaction.Update] 
 //GeneratorFlag:[Views.PersonPreTransaction]
		},
		"product": {
			"layout": "Product",
			"create": "Create New Product", //GeneratorFlag:[Views.Product.Create]
			'createImport': 'Imports Product From Excel', //GeneratorFlag:[Views.Product.CreateImport] 
 			'delete': 'Delete Product', //GeneratorFlag:[Views.Product.Delete] 
 			'detail': 'Detail of Product', //GeneratorFlag:[Views.Product.Detail] 
 			'list': 'List of Product', //GeneratorFlag:[Views.Product.List] 
 			'update': 'Edit Product', //GeneratorFlag:[Views.Product.Update] 
 			'register': 'Register Product', //GeneratorFlag:[Views.Product.Register] 
 //GeneratorFlag:[Views.Product]
		},
		"productCategory": {
			"layout": "ProductCategory",
			"create": "Create New ProductCategory", //GeneratorFlag:[Views.ProductCategory.Create]
			'createImport': 'Imports ProductCategory From Excel', //GeneratorFlag:[Views.ProductCategory.CreateImport] 
 			'delete': 'Delete ProductCategory', //GeneratorFlag:[Views.ProductCategory.Delete] 
 			'detail': 'Detail of ProductCategory', //GeneratorFlag:[Views.ProductCategory.Detail] 
 			'list': 'List of ProductCategory', //GeneratorFlag:[Views.ProductCategory.List] 
 			'update': 'Edit ProductCategory', //GeneratorFlag:[Views.ProductCategory.Update] 
 			'categoryProductRelatedAction': 'Category Product Related', //GeneratorFlag:[Views.ProductCategory.CategoryProductRelatedAction] 
 			'getProductCategory': 'Product Category', //GeneratorFlag:[Views.ProductCategory.GetProductCategory] 
 			'getProductCategoryListAction': 'product category', //GeneratorFlag:[Views.ProductCategory.GetProductCategoryListAction] 
 //GeneratorFlag:[Views.ProductCategory]
		},
		"productDetail": {
			"layout": "ProductDetail",
			"create": "Create New ProductDetail", //GeneratorFlag:[Views.ProductDetail.Create]
			'createImport': 'Imports ProductDetail From Excel', //GeneratorFlag:[Views.ProductDetail.CreateImport] 
 			'delete': 'Delete ProductDetail', //GeneratorFlag:[Views.ProductDetail.Delete] 
 			'detail': 'Detail of ProductDetail', //GeneratorFlag:[Views.ProductDetail.Detail] 
 			'list': 'List of ProductDetail', //GeneratorFlag:[Views.ProductDetail.List] 
 			'update': 'Edit ProductDetail', //GeneratorFlag:[Views.ProductDetail.Update] 
 //GeneratorFlag:[Views.ProductDetail]
		},
		"productDoc": {
			"layout": "ProductDoc",
			"create": "Create New ProductDoc", //GeneratorFlag:[Views.ProductDoc.Create]
			'createImport': 'Imports ProductDoc From Excel', //GeneratorFlag:[Views.ProductDoc.CreateImport] 
 			'delete': 'Delete ProductDoc', //GeneratorFlag:[Views.ProductDoc.Delete] 
 			'detail': 'Detail of ProductDoc', //GeneratorFlag:[Views.ProductDoc.Detail] 
 			'list': 'List of ProductDoc', //GeneratorFlag:[Views.ProductDoc.List] 
 			'update': 'Edit ProductDoc', //GeneratorFlag:[Views.ProductDoc.Update] 
 //GeneratorFlag:[Views.ProductDoc]
		},
		"productFeature": {
			"layout": "ProductFeature",
			"create": "Create New ProductFeature", //GeneratorFlag:[Views.ProductFeature.Create]
			'createImport': 'Imports ProductFeature From Excel', //GeneratorFlag:[Views.ProductFeature.CreateImport] 
 			'delete': 'Delete ProductFeature', //GeneratorFlag:[Views.ProductFeature.Delete] 
 			'detail': 'Detail of ProductFeature', //GeneratorFlag:[Views.ProductFeature.Detail] 
 			'list': 'List of ProductFeature', //GeneratorFlag:[Views.ProductFeature.List] 
 			'update': 'Edit ProductFeature', //GeneratorFlag:[Views.ProductFeature.Update] 
 //GeneratorFlag:[Views.ProductFeature]
		},
		"productMonetaryFeature": {
			"layout": "ProductMonetaryFeature",
			"create": "Create New ProductMonetaryFeature", //GeneratorFlag:[Views.ProductMonetaryFeature.Create]
			'createImport': 'Imports ProductMonetaryFeature From Excel', //GeneratorFlag:[Views.ProductMonetaryFeature.CreateImport] 
 			'delete': 'Delete ProductMonetaryFeature', //GeneratorFlag:[Views.ProductMonetaryFeature.Delete] 
 			'detail': 'Detail of ProductMonetaryFeature', //GeneratorFlag:[Views.ProductMonetaryFeature.Detail] 
 			'list': 'List of ProductMonetaryFeature', //GeneratorFlag:[Views.ProductMonetaryFeature.List] 
 			'update': 'Edit ProductMonetaryFeature', //GeneratorFlag:[Views.ProductMonetaryFeature.Update] 
 //GeneratorFlag:[Views.ProductMonetaryFeature]
		},
		"productStore": {
			"layout": "ProductStore",
			"create": "Create New ProductStore", //GeneratorFlag:[Views.ProductStore.Create]
			'createImport': 'Imports ProductStore From Excel', //GeneratorFlag:[Views.ProductStore.CreateImport] 
 			'delete': 'Delete ProductStore', //GeneratorFlag:[Views.ProductStore.Delete] 
 			'detail': 'Detail of ProductStore', //GeneratorFlag:[Views.ProductStore.Detail] 
 			'list': 'List of ProductStore', //GeneratorFlag:[Views.ProductStore.List] 
 			'update': 'Edit ProductStore', //GeneratorFlag:[Views.ProductStore.Update] 
 			'getProductInfo': 'Product Info', //GeneratorFlag:[Views.ProductStore.GetProductInfo] 
 //GeneratorFlag:[Views.ProductStore]
		},
		"store": {
			"layout": "Store",
			"create": "Create New Store", //GeneratorFlag:[Views.Store.Create]
			'createImport': 'Imports Store From Excel', //GeneratorFlag:[Views.Store.CreateImport] 
 			'delete': 'Delete Store', //GeneratorFlag:[Views.Store.Delete] 
 			'detail': 'Detail of Store', //GeneratorFlag:[Views.Store.Detail] 
 			'list': 'List of Store', //GeneratorFlag:[Views.Store.List] 
 			'update': 'Edit Store', //GeneratorFlag:[Views.Store.Update] 
 //GeneratorFlag:[Views.Store]
		},
		"bank": {
			"layout": "Create Bank",
			"create": "Create New Bank", //GeneratorFlag:[Views.Bank.Create]
			'createImport': 'Imports Bank From Excel', //GeneratorFlag:[Views.Bank.CreateImport] 
 			'delete': 'Delete Bank', //GeneratorFlag:[Views.Bank.Delete] 
 			'detail': 'Detail of Bank', //GeneratorFlag:[Views.Bank.Detail] 
 			'list': 'List of Bank', //GeneratorFlag:[Views.Bank.List] 
 			'update': 'Edit Bank', //GeneratorFlag:[Views.Bank.Update] 
 //GeneratorFlag:[Views.Bank]
		},
		"category": {
			"layout": "Create Category",
			"create": "Create New Category", //GeneratorFlag:[Views.Category.Create]
			'createImport': 'Imports Category From Excel', //GeneratorFlag:[Views.Category.CreateImport] 
 			'delete': 'Delete Category', //GeneratorFlag:[Views.Category.Delete] 
 			'detail': 'Detail of Category', //GeneratorFlag:[Views.Category.Detail] 
 			'list': 'List of Category', //GeneratorFlag:[Views.Category.List] 
 			'update': 'Edit Category', //GeneratorFlag:[Views.Category.Update] 
 			'listAction': 'Category List', //GeneratorFlag:[Views.Category.ListAction] 
 //GeneratorFlag:[Views.Category]
		},
		"feature": {
			"layout": "Create Feature",
			"create": "Create New Feature", //GeneratorFlag:[Views.Feature.Create]
			'createImport': 'Imports Feature From Excel', //GeneratorFlag:[Views.Feature.CreateImport] 
 			'delete': 'Delete Feature', //GeneratorFlag:[Views.Feature.Delete] 
 			'detail': 'Detail of Feature', //GeneratorFlag:[Views.Feature.Detail] 
 			'list': 'List of Feature', //GeneratorFlag:[Views.Feature.List] 
 			'update': 'Edit Feature', //GeneratorFlag:[Views.Feature.Update] 
 //GeneratorFlag:[Views.Feature]
		},
		"featureItem": {
			"layout": "Create FeatureItem",
			"create": "Create New FeatureItem", //GeneratorFlag:[Views.FeatureItem.Create]
			'createImport': 'Imports FeatureItem From Excel', //GeneratorFlag:[Views.FeatureItem.CreateImport] 
 			'delete': 'Delete FeatureItem', //GeneratorFlag:[Views.FeatureItem.Delete] 
 			'detail': 'Detail of FeatureItem', //GeneratorFlag:[Views.FeatureItem.Detail] 
 			'list': 'List of FeatureItem', //GeneratorFlag:[Views.FeatureItem.List] 
 			'update': 'Edit FeatureItem', //GeneratorFlag:[Views.FeatureItem.Update] 
 //GeneratorFlag:[Views.FeatureItem]
		},
		"planPrecent": {
			"layout": "Create PlanPrecent",
			"create": "Create New PlanPrecent", //GeneratorFlag:[Views.PlanPrecent.Create]
			'createImport': 'Imports PlanPrecent From Excel', //GeneratorFlag:[Views.PlanPrecent.CreateImport] 
 			'delete': 'Delete PlanPrecent', //GeneratorFlag:[Views.PlanPrecent.Delete] 
 			'detail': 'Detail of PlanPrecent', //GeneratorFlag:[Views.PlanPrecent.Detail] 
 			'list': 'List of PlanPrecent', //GeneratorFlag:[Views.PlanPrecent.List] 
 			'update': 'Edit PlanPrecent', //GeneratorFlag:[Views.PlanPrecent.Update] 
 //GeneratorFlag:[Views.PlanPrecent]
		},
		"planSetting": {
			"layout": "Create PlanSetting",
			"create": "Create New PlanSetting", //GeneratorFlag:[Views.PlanSetting.Create]
			'createImport': 'Imports PlanSetting From Excel', //GeneratorFlag:[Views.PlanSetting.CreateImport] 
 			'delete': 'Delete PlanSetting', //GeneratorFlag:[Views.PlanSetting.Delete] 
 			'detail': 'Detail of PlanSetting', //GeneratorFlag:[Views.PlanSetting.Detail] 
 			'list': 'List of PlanSetting', //GeneratorFlag:[Views.PlanSetting.List] 
 			'update': 'Edit PlanSetting', //GeneratorFlag:[Views.PlanSetting.Update] 
 //GeneratorFlag:[Views.PlanSetting]
		},
		"productColor": {
			"layout": "Create ProductColor",
			"create": "Create New ProductColor", //GeneratorFlag:[Views.ProductColor.Create]
			'createImport': 'Imports ProductColor From Excel', //GeneratorFlag:[Views.ProductColor.CreateImport] 
 			'delete': 'Delete ProductColor', //GeneratorFlag:[Views.ProductColor.Delete] 
 			'detail': 'Detail of ProductColor', //GeneratorFlag:[Views.ProductColor.Detail] 
 			'list': 'List of ProductColor', //GeneratorFlag:[Views.ProductColor.List] 
 			'update': 'Edit ProductColor', //GeneratorFlag:[Views.ProductColor.Update] 
 //GeneratorFlag:[Views.ProductColor]
		},
		"storeProfitRate": {
			"layout": "Create StoreProfitRate",
			"create": "Create New StoreProfitRate", //GeneratorFlag:[Views.StoreProfitRate.Create]
			'createImport': 'Imports StoreProfitRate From Excel', //GeneratorFlag:[Views.StoreProfitRate.CreateImport] 
 			'delete': 'Delete StoreProfitRate', //GeneratorFlag:[Views.StoreProfitRate.Delete] 
 			'detail': 'Detail of StoreProfitRate', //GeneratorFlag:[Views.StoreProfitRate.Detail] 
 			'list': 'List of StoreProfitRate', //GeneratorFlag:[Views.StoreProfitRate.List] 
 			'update': 'Edit StoreProfitRate', //GeneratorFlag:[Views.StoreProfitRate.Update] 
 //GeneratorFlag:[Views.StoreProfitRate]
		},
		"bankPaymentStatuse": {
			"layout": "BankPaymentStatuse",
			"detail": "Detail of BankPaymentStatuse", //GeneratorFlag:[Views.BankPaymentStatuse.Detail]
			'list': 'List of BankPaymentStatuse', //GeneratorFlag:[Views.BankPaymentStatuse.List] 
 //GeneratorFlag:[Views.BankPaymentStatuse]
		},
		"culture": {
			"layout": "Culture",
			"detail": "Detail of Culture", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'List of Culture', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"invoiceCancelStatuse": {
			"layout": "InvoiceCancelStatuse",
			"detail": "Detail of InvoiceCancelStatuse", //GeneratorFlag:[Views.InvoiceCancelStatuse.Detail]
			'list': 'List of InvoiceCancelStatuse', //GeneratorFlag:[Views.InvoiceCancelStatuse.List] 
 //GeneratorFlag:[Views.InvoiceCancelStatuse]
		},
		"invoicePaymentType": {
			"layout": "InvoicePaymentType",
			"detail": "Detail of InvoicePaymentType", //GeneratorFlag:[Views.InvoicePaymentType.Detail]
			'list': 'List of InvoicePaymentType', //GeneratorFlag:[Views.InvoicePaymentType.List] 
 //GeneratorFlag:[Views.InvoicePaymentType]
		},
		"invoiceStatuse": {
			"layout": "InvoiceStatuse",
			"detail": "Detail of InvoiceStatuse", //GeneratorFlag:[Views.InvoiceStatuse.Detail]
			'list': 'List of InvoiceStatuse', //GeneratorFlag:[Views.InvoiceStatuse.List] 
 //GeneratorFlag:[Views.InvoiceStatuse]
		},
		"invoiceTransactionType": {
			"layout": "InvoiceTransactionType",
			"detail": "Detail of InvoiceTransactionType", //GeneratorFlag:[Views.InvoiceTransactionType.Detail]
			'list': 'List of InvoiceTransactionType', //GeneratorFlag:[Views.InvoiceTransactionType.List] 
 //GeneratorFlag:[Views.InvoiceTransactionType]
		},
		"personCreaditTransactionType": {
			"layout": "PersonCreaditTransactionType",
			"detail": "Detail of PersonCreaditTransactionType", //GeneratorFlag:[Views.PersonCreaditTransactionType.Detail]
			'list': 'List of PersonCreaditTransactionType', //GeneratorFlag:[Views.PersonCreaditTransactionType.List] 
 //GeneratorFlag:[Views.PersonCreaditTransactionType]
		},
		"personPreTransactionStatuse": {
			"layout": "PersonPreTransactionStatuse",
			"detail": "Detail of PersonPreTransactionStatuse", //GeneratorFlag:[Views.PersonPreTransactionStatuse.Detail]
			'list': 'List of PersonPreTransactionStatuse', //GeneratorFlag:[Views.PersonPreTransactionStatuse.List] 
 //GeneratorFlag:[Views.PersonPreTransactionStatuse]
		},
		"planTransactionCommentType": {
			"layout": "PlanTransactionCommentType",
			"detail": "Detail of PlanTransactionCommentType", //GeneratorFlag:[Views.PlanTransactionCommentType.Detail]
			'list': 'List of PlanTransactionCommentType', //GeneratorFlag:[Views.PlanTransactionCommentType.List] 
 //GeneratorFlag:[Views.PlanTransactionCommentType]
		},
		"planTransactionStatuse": {
			"layout": "PlanTransactionStatuse",
			"detail": "Detail of PlanTransactionStatuse", //GeneratorFlag:[Views.PlanTransactionStatuse.Detail]
			'list': 'List of PlanTransactionStatuse', //GeneratorFlag:[Views.PlanTransactionStatuse.List] 
 //GeneratorFlag:[Views.PlanTransactionStatuse]
		},
		"planTransactionType": {
			"layout": "PlanTransactionType",
			"detail": "Detail of PlanTransactionType", //GeneratorFlag:[Views.PlanTransactionType.Detail]
			'list': 'List of PlanTransactionType', //GeneratorFlag:[Views.PlanTransactionType.List] 
 //GeneratorFlag:[Views.PlanTransactionType]
		},
		"productDocType": {
			"layout": "ProductDocType",
			"detail": "Detail of ProductDocType", //GeneratorFlag:[Views.ProductDocType.Detail]
			'list': 'List of ProductDocType', //GeneratorFlag:[Views.ProductDocType.List] 
 //GeneratorFlag:[Views.ProductDocType]
		},
		"productStatus": {
			"layout": "ProductStatus",
			"detail": "Detail of ProductStatus", //GeneratorFlag:[Views.ProductStatus.Detail]
			'list': 'List of ProductStatus', //GeneratorFlag:[Views.ProductStatus.List] 
 //GeneratorFlag:[Views.ProductStatus]
		},
		"productTransportCostType": {
			"layout": "ProductTransportCostType",
			"detail": "Detail of ProductTransportCostType", //GeneratorFlag:[Views.ProductTransportCostType.Detail]
			'list': 'List of ProductTransportCostType', //GeneratorFlag:[Views.ProductTransportCostType.List] 
 //GeneratorFlag:[Views.ProductTransportCostType]
		},
		"planLine": {
			"layout": "Current Week Commision",
			"getCommisionCurrentWeek": "Current Week Commision", //GeneratorFlag:[Views.PlanLine.GetCommisionCurrentWeek]
			'getMyDirect': 'My Direct', //GeneratorFlag:[Views.PlanLine.GetMyDirect] 
 //GeneratorFlag:[Views.PlanLine]
		},
		"planTransaction": {
			"layout": "PlanCalculation",
			"calculatePlan": "PlanCalculation", //GeneratorFlag:[Views.PlanTransaction.CalculatePlan]
			'getListCommision': 'Award List', //GeneratorFlag:[Views.PlanTransaction.GetListCommision] 
 			'getReward': 'Reward List', //GeneratorFlag:[Views.PlanTransaction.GetReward] 
 			'getVoacherAwards': 'Voacher Awards', //GeneratorFlag:[Views.PlanTransaction.GetVoacherAwards] 
 			'list': 'List of Award List', //GeneratorFlag:[Views.PlanTransaction.List] 
 			'registerExtraCommision': 'Add Extra Comission', //GeneratorFlag:[Views.PlanTransaction.RegisterExtraCommision] 
 			'totalReport': 'Total Commision', //GeneratorFlag:[Views.PlanTransaction.TotalReport] 
 			'totalReward': 'Total Reward', //GeneratorFlag:[Views.PlanTransaction.TotalReward] 
 //GeneratorFlag:[Views.PlanTransaction]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[BankPayment.Create] {
	"bankPaymentCreate": {
		"self": "bankPayment",
		"bankPaymentId": "bank payment id",
		"accountNumber": "account number",
		"shabaNumber": "shaba number",
		"userName": "user name",
		"password": "password",
		"sendUrl": "send url",
		"postBackUrl": "post back url",
		"additionalData": "additional data",
		"storeId": "store",
		"bankId": "bank",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "from range",
		"toRange": "to range",
	},
	//GeneratorFlag:[BankPayment.Delete] {
	"bankPaymentDelete": {
		"self": "bankPayment",
		"bankPaymentId": "bank payment id",
	},
	//GeneratorFlag:[BankPayment.Detail] {
	"bankPaymentDetail": {
		"self": "bankPayment",
		"bankPaymentId": "bank payment id",
		"accountNumber": "account number",
		"shabaNumber": "shaba number",
		"userName": "user name",
		"password": "password",
		"sendUrl": "send url",
		"postBackUrl": "post back url",
		"additionalData": "additional data",
		"storeId": "store",
		"bankId": "bank",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "from range",
		"toRange": "to range",
		"bank": {
			"self": "bank",
			"bankId": "شناسه",
			"title": "نام",
			"iconUrl": "آیکن",
		},
		"bankPaymentStatuse": {
			"self": "bank payment statuse",
			"bankPaymentStatuseId": "شناسه",
			"bankPaymentStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"bankPaymentSubInfo": "bank payment sub info",
	},
	//GeneratorFlag:[BankPayment.List] {
	"bankPaymentList": {
		"self": "bankPayment",
		"bankPaymentId": "bank payment id",
		"accountNumber": "account number",
		"shabaNumber": "shaba number",
		"userName": "user name",
		"password": "password",
		"sendUrl": "send url",
		"postBackUrl": "post back url",
		"additionalData": "additional data",
		"storeId": "store",
		"bankId": "bank",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "from range",
		"toRange": "to range",
		"bank": {
			"self": "bank",
			"bankId": "شناسه",
			"title": "نام",
			"iconUrl": "آیکن",
		},
		"bankPaymentStatuse": {
			"self": "bank payment statuse",
			"bankPaymentStatuseId": "شناسه",
			"bankPaymentStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"bankPaymentSubInfo": "bank payment sub info",
	},
	//GeneratorFlag:[BankPayment.Filter] {
	"bankPaymentFilter": {
		"self": "bankPayment",
		"bankPaymentId": "bank payment id",
		"accountNumber": "account number",
		"shabaNumber": "shaba number",
		"userName": "user name",
		"password": "password",
		"sendUrl": "send url",
		"postBackUrl": "post back url",
		"additionalData": "additional data",
		"storeId": "store",
		"bankId": "bank",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "from range",
		"toRange": "to range",
		"bank": {
			"self": "bank",
			"bankId": "شناسه",
			"title": "نام",
			"iconUrl": "آیکن",
		},
		"bankPaymentStatuse": {
			"self": "bank payment statuse",
			"bankPaymentStatuseId": "شناسه",
			"bankPaymentStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"bankPaymentSubInfo": "bank payment sub info",
	},
	//GeneratorFlag:[BankPayment.Update] {
	"bankPaymentUpdate": {
		"self": "bankPayment",
		"bankPaymentId": "bank payment id",
		"accountNumber": "account number",
		"shabaNumber": "shaba number",
		"userName": "user name",
		"password": "password",
		"sendUrl": "send url",
		"postBackUrl": "post back url",
		"additionalData": "additional data",
		"storeId": "store",
		"bankId": "bank",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "from range",
		"toRange": "to range",
	},
	//GeneratorFlag:[Invoice.Create] {
	"invoiceCreate": {
		"self": "invoice",
		"invoiceId": "invoice id",
		"personId": "person",
		"totalAmount": "total amount",
		"discountAmount": "discount amount",
		"paybleAmount": "payble amount",
		"paidAmount": "paid amount",
		"totalRemainAmount": "total remain amount",
		"invoiceStatuseId": "invoice statuse",
		"createdDate": "created date",
		"transportCostAmount": "transport cost amount",
		"packagingCostAmount": "packaging cost amount",
		"taxAmount": "tax amount",
		"checkoutDate": "checkout date",
		"displayCheckoutDate": "display checkout date",
		"invoicePaymentTypeId": "invoice payment type",
		"storeId": "store",
		"personAddressId": "person address",
		"invoiceSerial": "invoice serial",
		"isConnected": "is connected",
		"invoiceDetail": {
			"self": "invoice detail",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
	},
	//GeneratorFlag:[Invoice.Delete] {
	"invoiceDelete": {
		"self": "invoice",
		"invoiceId": "invoice id",
	},
	//GeneratorFlag:[Invoice.Detail] {
	"invoiceDetail": {
		"self": "invoice",
		"invoiceId": "invoice id",
		"personId": "person",
		"totalAmount": "total amount",
		"discountAmount": "discount amount",
		"paybleAmount": "payble amount",
		"paidAmount": "paid amount",
		"totalRemainAmount": "total remain amount",
		"invoiceStatuseId": "invoice statuse",
		"createdDate": "created date",
		"transportCostAmount": "transport cost amount",
		"packagingCostAmount": "packaging cost amount",
		"taxAmount": "tax amount",
		"checkoutDate": "checkout date",
		"displayCheckoutDate": "display checkout date",
		"invoicePaymentTypeId": "invoice payment type",
		"storeId": "store",
		"personAddressId": "person address",
		"invoiceSerial": "invoice serial",
		"isConnected": "is connected",
		"invoicePaymentType": {
			"self": "invoice payment type",
			"invoicePaymentTypeId": "شناسه",
			"invoicePaymentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"invoiceStatuse": {
			"self": "invoice statuse",
			"invoiceStatuseId": "شناسه",
			"invoiceStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddress": {
			"self": "person address",
			"personAddressId": "شناسه",
			"personId": "شخص",
			"addressTitle": "عنوان آدرس",
			"reciverFirstName": "نام",
			"reciverLastName": "نام خانوادگی",
			"reciverMobile": "همراه",
			"cityId": "شهر",
			"fullAddress": "آدرس",
			"plaque": "پلاک",
			"postalCode": "کد پستی",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"invoiceDetail": {
			"self": "invoice detail",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
		"invoiceSubInfo": "invoice sub info",
		"shopPerson": {
			"self": "shop person",
			"personId": "person",
			"fullName": "نام و نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankName": "رنک",
		},
	},
	//GeneratorFlag:[Invoice.List] {
	"invoiceList": {
		"self": "invoice",
		"invoiceId": "invoice id",
		"personId": "person",
		"totalAmount": "total amount",
		"discountAmount": "discount amount",
		"paybleAmount": "payble amount",
		"paidAmount": "paid amount",
		"totalRemainAmount": "total remain amount",
		"invoiceStatuseId": "invoice statuse",
		"createdDate": "created date",
		"transportCostAmount": "transport cost amount",
		"packagingCostAmount": "packaging cost amount",
		"taxAmount": "tax amount",
		"checkoutDate": "checkout date",
		"displayCheckoutDate": "display checkout date",
		"invoicePaymentTypeId": "invoice payment type",
		"storeId": "store",
		"personAddressId": "person address",
		"invoiceSerial": "invoice serial",
		"isConnected": "is connected",
		"invoicePaymentType": {
			"self": "invoice payment type",
			"invoicePaymentTypeId": "شناسه",
			"invoicePaymentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"invoiceStatuse": {
			"self": "invoice statuse",
			"invoiceStatuseId": "شناسه",
			"invoiceStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddress": {
			"self": "person address",
			"personAddressId": "شناسه",
			"personId": "شخص",
			"addressTitle": "عنوان آدرس",
			"reciverFirstName": "نام",
			"reciverLastName": "نام خانوادگی",
			"reciverMobile": "همراه",
			"cityId": "شهر",
			"fullAddress": "آدرس",
			"plaque": "پلاک",
			"postalCode": "کد پستی",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"invoiceDetail": {
			"self": "invoice detail",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
		"invoiceSubInfo": "invoice sub info",
		"shopPerson": {
			"self": "shop person",
			"personId": "person",
			"fullName": "نام و نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankName": "رنک",
		},
	},
	//GeneratorFlag:[Invoice.Filter] {
	"invoiceFilter": {
		"self": "invoice",
		"invoiceId": "invoice id",
		"personId": "person",
		"totalAmount": "total amount",
		"discountAmount": "discount amount",
		"paybleAmount": "payble amount",
		"paidAmount": "paid amount",
		"totalRemainAmount": "total remain amount",
		"invoiceStatuseId": "invoice statuse",
		"createdDate": "created date",
		"transportCostAmount": "transport cost amount",
		"packagingCostAmount": "packaging cost amount",
		"taxAmount": "tax amount",
		"checkoutDate": "checkout date",
		"displayCheckoutDate": "display checkout date",
		"invoicePaymentTypeId": "invoice payment type",
		"storeId": "store",
		"personAddressId": "person address",
		"invoiceSerial": "invoice serial",
		"isConnected": "is connected",
		"invoicePaymentType": {
			"self": "invoice payment type",
			"invoicePaymentTypeId": "شناسه",
			"invoicePaymentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"invoiceStatuse": {
			"self": "invoice statuse",
			"invoiceStatuseId": "شناسه",
			"invoiceStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddress": {
			"self": "person address",
			"personAddressId": "شناسه",
			"personId": "شخص",
			"addressTitle": "عنوان آدرس",
			"reciverFirstName": "نام",
			"reciverLastName": "نام خانوادگی",
			"reciverMobile": "همراه",
			"cityId": "شهر",
			"fullAddress": "آدرس",
			"plaque": "پلاک",
			"postalCode": "کد پستی",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"invoiceDetail": {
			"self": "invoice detail",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
		"invoiceSubInfo": "invoice sub info",
		"shopPerson": {
			"self": "shop person",
			"personId": "person",
			"fullName": "نام و نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankName": "رنک",
		},
	},
	//GeneratorFlag:[Invoice.Update] {
	"invoiceUpdate": {
		"self": "invoice",
		"invoiceId": "invoice id",
		"personId": "person",
		"totalAmount": "total amount",
		"discountAmount": "discount amount",
		"paybleAmount": "payble amount",
		"paidAmount": "paid amount",
		"totalRemainAmount": "total remain amount",
		"invoiceStatuseId": "invoice statuse",
		"createdDate": "created date",
		"transportCostAmount": "transport cost amount",
		"packagingCostAmount": "packaging cost amount",
		"taxAmount": "tax amount",
		"checkoutDate": "checkout date",
		"displayCheckoutDate": "display checkout date",
		"invoicePaymentTypeId": "invoice payment type",
		"storeId": "store",
		"personAddressId": "person address",
		"invoiceSerial": "invoice serial",
		"isConnected": "is connected",
		"invoiceDetail": {
			"self": "invoice detail",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
	},
	//GeneratorFlag:[InvoiceCancel.Create] {
	"invoiceCancelCreate": {
		"self": "invoiceCancel",
		"invoiceCancelId": "invoice cancel id",
		"invoiceId": "invoice",
		"cancelDatetime": "cancel datetime",
		"displayCancelDatetime": "display cancel datetime",
		"ipAddress": "ip address",
		"personId": "person",
		"totalCommisionFromInvoice": "total commision from invoice",
		"paybleAmount": "payble amount",
	},
	//GeneratorFlag:[InvoiceCancel.Delete] {
	"invoiceCancelDelete": {
		"self": "invoiceCancel",
		"invoiceCancelId": "invoice cancel id",
	},
	//GeneratorFlag:[InvoiceCancel.Detail] {
	"invoiceCancelDetail": {
		"self": "invoiceCancel",
		"invoiceCancelId": "invoice cancel id",
		"invoiceId": "invoice",
		"cancelDatetime": "cancel datetime",
		"displayCancelDatetime": "display cancel datetime",
		"ipAddress": "ip address",
		"personId": "person",
		"totalCommisionFromInvoice": "total commision from invoice",
		"paybleAmount": "payble amount",
		"invoice": {
			"self": "invoice",
			"invoiceId": "شناسه",
			"personId": "شخص",
			"totalAmount": "جمع کل",
			"discountAmount": "تخفیف",
			"paybleAmount": "قابل پرداخت",
			"paidAmount": "پرداخت شده",
			"totalRemainAmount": "باقی مانده",
			"invoiceStatuseId": "وضعیت",
			"createdDate": "تاریخ صدور",
			"transportCostAmount": "هزینه حمل و نقل",
			"packagingCostAmount": "هزینه بسته بندی",
			"taxAmount": "مالیات",
			"checkoutDate": "تاریخ تسویه",
			"displayCheckoutDate": "تاریخ تسویه",
			"invoicePaymentTypeId": "نوع پرداخت",
			"storeId": "فروشگاه",
			"personAddressId": "آدرس",
			"invoiceSerial": "سریال",
			"isConnected": "منتقل شده",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"invoiceCancelSubInfo": "invoice cancel sub info",
	},
	//GeneratorFlag:[InvoiceCancel.List] {
	"invoiceCancelList": {
		"self": "invoiceCancel",
		"invoiceCancelId": "invoice cancel id",
		"invoiceId": "invoice",
		"cancelDatetime": "cancel datetime",
		"displayCancelDatetime": "display cancel datetime",
		"ipAddress": "ip address",
		"personId": "person",
		"totalCommisionFromInvoice": "total commision from invoice",
		"paybleAmount": "payble amount",
		"invoice": {
			"self": "invoice",
			"invoiceId": "شناسه",
			"personId": "شخص",
			"totalAmount": "جمع کل",
			"discountAmount": "تخفیف",
			"paybleAmount": "قابل پرداخت",
			"paidAmount": "پرداخت شده",
			"totalRemainAmount": "باقی مانده",
			"invoiceStatuseId": "وضعیت",
			"createdDate": "تاریخ صدور",
			"transportCostAmount": "هزینه حمل و نقل",
			"packagingCostAmount": "هزینه بسته بندی",
			"taxAmount": "مالیات",
			"checkoutDate": "تاریخ تسویه",
			"displayCheckoutDate": "تاریخ تسویه",
			"invoicePaymentTypeId": "نوع پرداخت",
			"storeId": "فروشگاه",
			"personAddressId": "آدرس",
			"invoiceSerial": "سریال",
			"isConnected": "منتقل شده",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"invoiceCancelSubInfo": "invoice cancel sub info",
	},
	//GeneratorFlag:[InvoiceCancel.Filter] {
	"invoiceCancelFilter": {
		"self": "invoiceCancel",
		"invoiceCancelId": "invoice cancel id",
		"invoiceId": "invoice",
		"cancelDatetime": "cancel datetime",
		"displayCancelDatetime": "display cancel datetime",
		"ipAddress": "ip address",
		"personId": "person",
		"totalCommisionFromInvoice": "total commision from invoice",
		"paybleAmount": "payble amount",
		"invoice": {
			"self": "invoice",
			"invoiceId": "شناسه",
			"personId": "شخص",
			"totalAmount": "جمع کل",
			"discountAmount": "تخفیف",
			"paybleAmount": "قابل پرداخت",
			"paidAmount": "پرداخت شده",
			"totalRemainAmount": "باقی مانده",
			"invoiceStatuseId": "وضعیت",
			"createdDate": "تاریخ صدور",
			"transportCostAmount": "هزینه حمل و نقل",
			"packagingCostAmount": "هزینه بسته بندی",
			"taxAmount": "مالیات",
			"checkoutDate": "تاریخ تسویه",
			"displayCheckoutDate": "تاریخ تسویه",
			"invoicePaymentTypeId": "نوع پرداخت",
			"storeId": "فروشگاه",
			"personAddressId": "آدرس",
			"invoiceSerial": "سریال",
			"isConnected": "منتقل شده",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"invoiceCancelSubInfo": "invoice cancel sub info",
	},
	//GeneratorFlag:[InvoiceCancel.Update] {
	"invoiceCancelUpdate": {
		"self": "invoiceCancel",
		"invoiceCancelId": "invoice cancel id",
		"invoiceId": "invoice",
		"cancelDatetime": "cancel datetime",
		"displayCancelDatetime": "display cancel datetime",
		"ipAddress": "ip address",
		"personId": "person",
		"totalCommisionFromInvoice": "total commision from invoice",
		"paybleAmount": "payble amount",
	},
	//GeneratorFlag:[PersonAddress.Create] {
	"personAddressCreate": {
		"self": "personAddress",
		"personAddressId": "person address id",
		"personId": "person",
		"addressTitle": "address title",
		"reciverFirstName": "reciver first name",
		"reciverLastName": "reciver last name",
		"reciverMobile": "reciver mobile",
		"cityId": "city",
		"fullAddress": "full address",
		"plaque": "plaque",
		"postalCode": "postal code",
	},
	//GeneratorFlag:[PersonAddress.Delete] {
	"personAddressDelete": {
		"self": "personAddress",
		"personAddressId": "person address id",
	},
	//GeneratorFlag:[PersonAddress.Detail] {
	"personAddressDetail": {
		"self": "personAddress",
		"personAddressId": "person address id",
		"personId": "person",
		"addressTitle": "address title",
		"reciverFirstName": "reciver first name",
		"reciverLastName": "reciver last name",
		"reciverMobile": "reciver mobile",
		"cityId": "city",
		"fullAddress": "full address",
		"plaque": "plaque",
		"postalCode": "postal code",
		"city": {
			"self": "city",
			"cityId": "شناسه",
			"title": "عنوان",
			"provinceId": "استان",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddressSubInfo": "person address sub info",
	},
	//GeneratorFlag:[PersonAddress.List] {
	"personAddressList": {
		"self": "personAddress",
		"personAddressId": "person address id",
		"personId": "person",
		"addressTitle": "address title",
		"reciverFirstName": "reciver first name",
		"reciverLastName": "reciver last name",
		"reciverMobile": "reciver mobile",
		"cityId": "city",
		"fullAddress": "full address",
		"plaque": "plaque",
		"postalCode": "postal code",
		"city": {
			"self": "city",
			"cityId": "شناسه",
			"title": "عنوان",
			"provinceId": "استان",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddressSubInfo": "person address sub info",
	},
	//GeneratorFlag:[PersonAddress.Filter] {
	"personAddressFilter": {
		"self": "personAddress",
		"personAddressId": "person address id",
		"personId": "person",
		"addressTitle": "address title",
		"reciverFirstName": "reciver first name",
		"reciverLastName": "reciver last name",
		"reciverMobile": "reciver mobile",
		"cityId": "city",
		"fullAddress": "full address",
		"plaque": "plaque",
		"postalCode": "postal code",
		"city": {
			"self": "city",
			"cityId": "شناسه",
			"title": "عنوان",
			"provinceId": "استان",
		},
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddressSubInfo": "person address sub info",
	},
	//GeneratorFlag:[PersonAddress.Update] {
	"personAddressUpdate": {
		"self": "personAddress",
		"personAddressId": "person address id",
		"personId": "person",
		"addressTitle": "address title",
		"reciverFirstName": "reciver first name",
		"reciverLastName": "reciver last name",
		"reciverMobile": "reciver mobile",
		"cityId": "city",
		"fullAddress": "full address",
		"plaque": "plaque",
		"postalCode": "postal code",
	},
	//GeneratorFlag:[PersonPreTransaction.Create] {
	"personPreTransactionCreate": {
		"self": "personPreTransaction",
		"personPreTransactionId": "person pre transaction id",
		"transactionFrom": "transaction from",
		"amount": "amount",
		"refCode": "ref code",
		"personPreTransactionStatuseId": "person pre transaction statuse",
		"personId": "person",
	},
	//GeneratorFlag:[PersonPreTransaction.Delete] {
	"personPreTransactionDelete": {
		"self": "personPreTransaction",
		"personPreTransactionId": "person pre transaction id",
	},
	//GeneratorFlag:[PersonPreTransaction.Detail] {
	"personPreTransactionDetail": {
		"self": "personPreTransaction",
		"personPreTransactionId": "person pre transaction id",
		"transactionFrom": "transaction from",
		"amount": "amount",
		"refCode": "ref code",
		"personPreTransactionStatuseId": "person pre transaction statuse",
		"personId": "person",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personPreTransactionStatuse": {
			"self": "person pre transaction statuse",
			"personPreTransactionStatuseId": "person pre transaction statuse id",
			"personPreTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personPreTransactionSubInfo": "person pre transaction sub info",
	},
	//GeneratorFlag:[PersonPreTransaction.List] {
	"personPreTransactionList": {
		"self": "personPreTransaction",
		"personPreTransactionId": "person pre transaction id",
		"transactionFrom": "transaction from",
		"amount": "amount",
		"refCode": "ref code",
		"personPreTransactionStatuseId": "person pre transaction statuse",
		"personId": "person",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personPreTransactionStatuse": {
			"self": "person pre transaction statuse",
			"personPreTransactionStatuseId": "person pre transaction statuse id",
			"personPreTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personPreTransactionSubInfo": "person pre transaction sub info",
	},
	//GeneratorFlag:[PersonPreTransaction.Filter] {
	"personPreTransactionFilter": {
		"self": "personPreTransaction",
		"personPreTransactionId": "person pre transaction id",
		"transactionFrom": "transaction from",
		"amount": "amount",
		"refCode": "ref code",
		"personPreTransactionStatuseId": "person pre transaction statuse",
		"personId": "person",
		"person": {
			"self": "person",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personPreTransactionStatuse": {
			"self": "person pre transaction statuse",
			"personPreTransactionStatuseId": "person pre transaction statuse id",
			"personPreTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personPreTransactionSubInfo": "person pre transaction sub info",
	},
	//GeneratorFlag:[PersonPreTransaction.Update] {
	"personPreTransactionUpdate": {
		"self": "personPreTransaction",
		"personPreTransactionId": "person pre transaction id",
		"transactionFrom": "transaction from",
		"amount": "amount",
		"refCode": "ref code",
		"personPreTransactionStatuseId": "person pre transaction statuse",
		"personId": "person",
	},
	//GeneratorFlag:[Product.Create] {
	"productCreate": {
		"self": "product",
		"productId": "product id",
		"productKey": "product key",
		"cultureId": "culture",
		"categoryId": "category",
		"identifire": "identifire",
		"title": "title",
		"sellCount": "sell count",
		"caption": "caption",
		"firstPrice": "first price",
		"totalProductInventory": "total product inventory",
		"productCode": "product code",
		"imagePath": "image path",
		"productStatusId": "product status",
		"productMonetaryFeatureTitle": "product monetary feature title",
		"isOrigin": "is origin",
		"productSpecification": {
			"self": "product specification",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
	},
	//GeneratorFlag:[Product.Delete] {
	"productDelete": {
		"self": "product",
		"productId": "product id",
	},
	//GeneratorFlag:[Product.Detail] {
	"productDetail": {
		"self": "product",
		"productId": "product id",
		"productKey": "product key",
		"cultureId": "culture",
		"categoryId": "category",
		"identifire": "identifire",
		"title": "title",
		"sellCount": "sell count",
		"caption": "caption",
		"firstPrice": "first price",
		"totalProductInventory": "total product inventory",
		"productCode": "product code",
		"imagePath": "image path",
		"productStatusId": "product status",
		"productMonetaryFeatureTitle": "product monetary feature title",
		"isOrigin": "is origin",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productStatus": {
			"self": "product status",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productSpecification": {
			"self": "product specification",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"productSubInfo": "product sub info",
	},
	//GeneratorFlag:[Product.List] {
	"productList": {
		"self": "product",
		"productId": "product id",
		"productKey": "product key",
		"cultureId": "culture",
		"categoryId": "category",
		"identifire": "identifire",
		"title": "title",
		"sellCount": "sell count",
		"caption": "caption",
		"firstPrice": "first price",
		"totalProductInventory": "total product inventory",
		"productCode": "product code",
		"imagePath": "image path",
		"productStatusId": "product status",
		"productMonetaryFeatureTitle": "product monetary feature title",
		"isOrigin": "is origin",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productStatus": {
			"self": "product status",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productSpecification": {
			"self": "product specification",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"productSubInfo": "product sub info",
	},
	//GeneratorFlag:[Product.Filter] {
	"productFilter": {
		"self": "product",
		"productId": "product id",
		"productKey": "product key",
		"cultureId": "culture",
		"categoryId": "category",
		"identifire": "identifire",
		"title": "title",
		"sellCount": "sell count",
		"caption": "caption",
		"firstPrice": "first price",
		"totalProductInventory": "total product inventory",
		"productCode": "product code",
		"imagePath": "image path",
		"productStatusId": "product status",
		"productMonetaryFeatureTitle": "product monetary feature title",
		"isOrigin": "is origin",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productStatus": {
			"self": "product status",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productSpecification": {
			"self": "product specification",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"productSubInfo": "product sub info",
	},
	//GeneratorFlag:[Product.Update] {
	"productUpdate": {
		"self": "product",
		"productId": "product id",
		"productKey": "product key",
		"cultureId": "culture",
		"categoryId": "category",
		"identifire": "identifire",
		"title": "title",
		"sellCount": "sell count",
		"caption": "caption",
		"firstPrice": "first price",
		"totalProductInventory": "total product inventory",
		"productCode": "product code",
		"imagePath": "image path",
		"productStatusId": "product status",
		"productMonetaryFeatureTitle": "product monetary feature title",
		"isOrigin": "is origin",
		"productSpecification": {
			"self": "product specification",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
	},
	//GeneratorFlag:[ProductCategory.Create] {
	"productCategoryCreate": {
		"self": "productCategory",
		"productCategoryId": "product category id",
		"categoryId": "category",
		"productId": "product",
		"cultureId": "culture",
		"caption": "caption",
	},
	//GeneratorFlag:[ProductCategory.Delete] {
	"productCategoryDelete": {
		"self": "productCategory",
		"productCategoryId": "product category id",
	},
	//GeneratorFlag:[ProductCategory.Detail] {
	"productCategoryDetail": {
		"self": "productCategory",
		"productCategoryId": "product category id",
		"categoryId": "category",
		"productId": "product",
		"cultureId": "culture",
		"caption": "caption",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productCategorySubInfo": "product category sub info",
	},
	//GeneratorFlag:[ProductCategory.List] {
	"productCategoryList": {
		"self": "productCategory",
		"productCategoryId": "product category id",
		"categoryId": "category",
		"productId": "product",
		"cultureId": "culture",
		"caption": "caption",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productCategorySubInfo": "product category sub info",
	},
	//GeneratorFlag:[ProductCategory.Filter] {
	"productCategoryFilter": {
		"self": "productCategory",
		"productCategoryId": "product category id",
		"categoryId": "category",
		"productId": "product",
		"cultureId": "culture",
		"caption": "caption",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productCategorySubInfo": "product category sub info",
	},
	//GeneratorFlag:[ProductCategory.Update] {
	"productCategoryUpdate": {
		"self": "productCategory",
		"productCategoryId": "product category id",
		"categoryId": "category",
		"productId": "product",
		"cultureId": "culture",
		"caption": "caption",
	},
	//GeneratorFlag:[ProductDetail.Create] {
	"productDetailCreate": {
		"self": "productDetail",
		"productDetailId": "product detail id",
		"productId": "product",
		"productColorId": "product color",
		"productMonetaryFeatureId": "product monetary feature",
		"adminDisplayName": "admin display name",
		"cultureId": "culture",
		"isDefaultProduct": "is default product",
		"productStatusId": "product status",
	},
	//GeneratorFlag:[ProductDetail.Delete] {
	"productDetailDelete": {
		"self": "productDetail",
		"productDetailId": "product detail id",
	},
	//GeneratorFlag:[ProductDetail.Detail] {
	"productDetailDetail": {
		"self": "productDetail",
		"productDetailId": "product detail id",
		"productId": "product",
		"productColorId": "product color",
		"productMonetaryFeatureId": "product monetary feature",
		"adminDisplayName": "admin display name",
		"cultureId": "culture",
		"isDefaultProduct": "is default product",
		"productStatusId": "product status",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productColor": {
			"self": "product color",
			"productColorId": "شناسه",
			"productColorKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"colorHexaCode": "کد Hex",
			"isOrigin": "ریشه",
		},
		"productMonetaryFeature": {
			"self": "product monetary feature",
			"productMonetaryFeatureId": "شناسه",
			"productMonetaryFeatureKey": "product monetary feature key",
			"cultureId": "زبان",
			"parentId": "والد",
			"categoryId": "دسته بندی",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productStatus": {
			"self": "product status",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDetailSubInfo": "product detail sub info",
	},
	//GeneratorFlag:[ProductDetail.List] {
	"productDetailList": {
		"self": "productDetail",
		"productDetailId": "product detail id",
		"productId": "product",
		"productColorId": "product color",
		"productMonetaryFeatureId": "product monetary feature",
		"adminDisplayName": "admin display name",
		"cultureId": "culture",
		"isDefaultProduct": "is default product",
		"productStatusId": "product status",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productColor": {
			"self": "product color",
			"productColorId": "شناسه",
			"productColorKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"colorHexaCode": "کد Hex",
			"isOrigin": "ریشه",
		},
		"productMonetaryFeature": {
			"self": "product monetary feature",
			"productMonetaryFeatureId": "شناسه",
			"productMonetaryFeatureKey": "product monetary feature key",
			"cultureId": "زبان",
			"parentId": "والد",
			"categoryId": "دسته بندی",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productStatus": {
			"self": "product status",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDetailSubInfo": "product detail sub info",
	},
	//GeneratorFlag:[ProductDetail.Filter] {
	"productDetailFilter": {
		"self": "productDetail",
		"productDetailId": "product detail id",
		"productId": "product",
		"productColorId": "product color",
		"productMonetaryFeatureId": "product monetary feature",
		"adminDisplayName": "admin display name",
		"cultureId": "culture",
		"isDefaultProduct": "is default product",
		"productStatusId": "product status",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productColor": {
			"self": "product color",
			"productColorId": "شناسه",
			"productColorKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"colorHexaCode": "کد Hex",
			"isOrigin": "ریشه",
		},
		"productMonetaryFeature": {
			"self": "product monetary feature",
			"productMonetaryFeatureId": "شناسه",
			"productMonetaryFeatureKey": "product monetary feature key",
			"cultureId": "زبان",
			"parentId": "والد",
			"categoryId": "دسته بندی",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productStatus": {
			"self": "product status",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDetailSubInfo": "product detail sub info",
	},
	//GeneratorFlag:[ProductDetail.Update] {
	"productDetailUpdate": {
		"self": "productDetail",
		"productDetailId": "product detail id",
		"productId": "product",
		"productColorId": "product color",
		"productMonetaryFeatureId": "product monetary feature",
		"adminDisplayName": "admin display name",
		"cultureId": "culture",
		"isDefaultProduct": "is default product",
		"productStatusId": "product status",
	},
	//GeneratorFlag:[ProductDoc.Create] {
	"productDocCreate": {
		"self": "productDoc",
		"productDocId": "product doc id",
		"productId": "product",
		"docAddress": "doc address",
		"title": "title",
		"caption": "caption",
		"productDocTypeId": "product doc type",
	},
	//GeneratorFlag:[ProductDoc.Delete] {
	"productDocDelete": {
		"self": "productDoc",
		"productDocId": "product doc id",
	},
	//GeneratorFlag:[ProductDoc.Detail] {
	"productDocDetail": {
		"self": "productDoc",
		"productDocId": "product doc id",
		"productId": "product",
		"docAddress": "doc address",
		"title": "title",
		"caption": "caption",
		"productDocTypeId": "product doc type",
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productDocType": {
			"self": "product doc type",
			"productDocTypeId": "شناسه",
			"productDocTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDocSubInfo": "product doc sub info",
	},
	//GeneratorFlag:[ProductDoc.List] {
	"productDocList": {
		"self": "productDoc",
		"productDocId": "product doc id",
		"productId": "product",
		"docAddress": "doc address",
		"title": "title",
		"caption": "caption",
		"productDocTypeId": "product doc type",
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productDocType": {
			"self": "product doc type",
			"productDocTypeId": "شناسه",
			"productDocTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDocSubInfo": "product doc sub info",
	},
	//GeneratorFlag:[ProductDoc.Filter] {
	"productDocFilter": {
		"self": "productDoc",
		"productDocId": "product doc id",
		"productId": "product",
		"docAddress": "doc address",
		"title": "title",
		"caption": "caption",
		"productDocTypeId": "product doc type",
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productDocType": {
			"self": "product doc type",
			"productDocTypeId": "شناسه",
			"productDocTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDocSubInfo": "product doc sub info",
	},
	//GeneratorFlag:[ProductDoc.Update] {
	"productDocUpdate": {
		"self": "productDoc",
		"productDocId": "product doc id",
		"productId": "product",
		"docAddress": "doc address",
		"title": "title",
		"caption": "caption",
		"productDocTypeId": "product doc type",
	},
	//GeneratorFlag:[ProductFeature.Create] {
	"productFeatureCreate": {
		"self": "productFeature",
		"productFeatureId": "product feature id",
		"featureId": "feature",
		"stringValue": "string value",
		"floatValue": "float value",
		"intValue": "int value",
		"productId": "product",
	},
	//GeneratorFlag:[ProductFeature.Delete] {
	"productFeatureDelete": {
		"self": "productFeature",
		"productFeatureId": "product feature id",
	},
	//GeneratorFlag:[ProductFeature.Detail] {
	"productFeatureDetail": {
		"self": "productFeature",
		"productFeatureId": "product feature id",
		"featureId": "feature",
		"stringValue": "string value",
		"floatValue": "float value",
		"intValue": "int value",
		"productId": "product",
		"feature": {
			"self": "feature",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productFeatureSubInfo": "product feature sub info",
	},
	//GeneratorFlag:[ProductFeature.List] {
	"productFeatureList": {
		"self": "productFeature",
		"productFeatureId": "product feature id",
		"featureId": "feature",
		"stringValue": "string value",
		"floatValue": "float value",
		"intValue": "int value",
		"productId": "product",
		"feature": {
			"self": "feature",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productFeatureSubInfo": "product feature sub info",
	},
	//GeneratorFlag:[ProductFeature.Filter] {
	"productFeatureFilter": {
		"self": "productFeature",
		"productFeatureId": "product feature id",
		"featureId": "feature",
		"stringValue": "string value",
		"floatValue": "float value",
		"intValue": "int value",
		"productId": "product",
		"feature": {
			"self": "feature",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"product": {
			"self": "product",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productFeatureSubInfo": "product feature sub info",
	},
	//GeneratorFlag:[ProductFeature.Update] {
	"productFeatureUpdate": {
		"self": "productFeature",
		"productFeatureId": "product feature id",
		"featureId": "feature",
		"stringValue": "string value",
		"floatValue": "float value",
		"intValue": "int value",
		"productId": "product",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Create] {
	"productMonetaryFeatureCreate": {
		"self": "productMonetaryFeature",
		"productMonetaryFeatureId": "product monetary feature id",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "culture",
		"parentId": "parent id",
		"categoryId": "category",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Delete] {
	"productMonetaryFeatureDelete": {
		"self": "productMonetaryFeature",
		"productMonetaryFeatureId": "product monetary feature id",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Detail] {
	"productMonetaryFeatureDetail": {
		"self": "productMonetaryFeature",
		"productMonetaryFeatureId": "product monetary feature id",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "culture",
		"parentId": "parent id",
		"categoryId": "category",
		"title": "title",
		"isOrigin": "is origin",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productMonetaryFeatureSubInfo": "product monetary feature sub info",
	},
	//GeneratorFlag:[ProductMonetaryFeature.List] {
	"productMonetaryFeatureList": {
		"self": "productMonetaryFeature",
		"productMonetaryFeatureId": "product monetary feature id",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "culture",
		"parentId": "parent id",
		"categoryId": "category",
		"title": "title",
		"isOrigin": "is origin",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productMonetaryFeatureSubInfo": "product monetary feature sub info",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Filter] {
	"productMonetaryFeatureFilter": {
		"self": "productMonetaryFeature",
		"productMonetaryFeatureId": "product monetary feature id",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "culture",
		"parentId": "parent id",
		"categoryId": "category",
		"title": "title",
		"isOrigin": "is origin",
		"category": {
			"self": "category",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productMonetaryFeatureSubInfo": "product monetary feature sub info",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Update] {
	"productMonetaryFeatureUpdate": {
		"self": "productMonetaryFeature",
		"productMonetaryFeatureId": "product monetary feature id",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "culture",
		"parentId": "parent id",
		"categoryId": "category",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductStore.Create] {
	"productStoreCreate": {
		"self": "productStore",
		"productStoreId": "product store id",
		"productDetailId": "product detail",
		"storeId": "store",
		"firstPrice": "first price",
		"secondStorePrice": "second store price",
		"productStoreInventory": "product store inventory",
		"productScore": "product score",
		"maxSellCount": "max sell count",
		"minSellCount": "min sell count",
	},
	//GeneratorFlag:[ProductStore.Delete] {
	"productStoreDelete": {
		"self": "productStore",
		"productStoreId": "product store id",
	},
	//GeneratorFlag:[ProductStore.Detail] {
	"productStoreDetail": {
		"self": "productStore",
		"productStoreId": "product store id",
		"productDetailId": "product detail",
		"storeId": "store",
		"firstPrice": "first price",
		"secondStorePrice": "second store price",
		"productStoreInventory": "product store inventory",
		"productScore": "product score",
		"maxSellCount": "max sell count",
		"minSellCount": "min sell count",
		"productDetail": {
			"self": "product detail",
			"productDetailId": "شناسه ریزکالا",
			"productId": "محصول",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "خصوصیت",
			"adminDisplayName": "نام نمایشی مدیر",
			"cultureId": "زبان",
			"isDefaultProduct": "ویژگی پیش فرض",
			"productStatusId": "وضعیت",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"productStoreSubInfo": "product store sub info",
	},
	//GeneratorFlag:[ProductStore.List] {
	"productStoreList": {
		"self": "productStore",
		"productStoreId": "product store id",
		"productDetailId": "product detail",
		"storeId": "store",
		"firstPrice": "first price",
		"secondStorePrice": "second store price",
		"productStoreInventory": "product store inventory",
		"productScore": "product score",
		"maxSellCount": "max sell count",
		"minSellCount": "min sell count",
		"productDetail": {
			"self": "product detail",
			"productDetailId": "شناسه ریزکالا",
			"productId": "محصول",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "خصوصیت",
			"adminDisplayName": "نام نمایشی مدیر",
			"cultureId": "زبان",
			"isDefaultProduct": "ویژگی پیش فرض",
			"productStatusId": "وضعیت",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"productStoreSubInfo": "product store sub info",
	},
	//GeneratorFlag:[ProductStore.Filter] {
	"productStoreFilter": {
		"self": "productStore",
		"productStoreId": "product store id",
		"productDetailId": "product detail",
		"storeId": "store",
		"firstPrice": "first price",
		"secondStorePrice": "second store price",
		"productStoreInventory": "product store inventory",
		"productScore": "product score",
		"maxSellCount": "max sell count",
		"minSellCount": "min sell count",
		"productDetail": {
			"self": "product detail",
			"productDetailId": "شناسه ریزکالا",
			"productId": "محصول",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "خصوصیت",
			"adminDisplayName": "نام نمایشی مدیر",
			"cultureId": "زبان",
			"isDefaultProduct": "ویژگی پیش فرض",
			"productStatusId": "وضعیت",
		},
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"productStoreSubInfo": "product store sub info",
	},
	//GeneratorFlag:[ProductStore.Update] {
	"productStoreUpdate": {
		"self": "productStore",
		"productStoreId": "product store id",
		"productDetailId": "product detail",
		"storeId": "store",
		"firstPrice": "first price",
		"secondStorePrice": "second store price",
		"productStoreInventory": "product store inventory",
		"productScore": "product score",
		"maxSellCount": "max sell count",
		"minSellCount": "min sell count",
	},
	//GeneratorFlag:[Store.Create] {
	"storeCreate": {
		"self": "store",
		"storeId": "store id",
		"storeName": "store name",
		"valueAddedTaxPrecent": "value added tax precent",
		"complicationsTaxPrecent": "complications tax precent",
		"transportCost": "transport cost",
		"transportCostFreeFrom": "transport cost free from",
	},
	//GeneratorFlag:[Store.Delete] {
	"storeDelete": {
		"self": "store",
		"storeId": "store id",
	},
	//GeneratorFlag:[Store.Detail] {
	"storeDetail": {
		"self": "store",
		"storeId": "store id",
		"storeName": "store name",
		"valueAddedTaxPrecent": "value added tax precent",
		"complicationsTaxPrecent": "complications tax precent",
		"transportCost": "transport cost",
		"transportCostFreeFrom": "transport cost free from",
		"storeSubInfo": "store sub info",
	},
	//GeneratorFlag:[Store.List] {
	"storeList": {
		"self": "store",
		"storeId": "store id",
		"storeName": "store name",
		"valueAddedTaxPrecent": "value added tax precent",
		"complicationsTaxPrecent": "complications tax precent",
		"transportCost": "transport cost",
		"transportCostFreeFrom": "transport cost free from",
		"storeSubInfo": "store sub info",
	},
	//GeneratorFlag:[Store.Filter] {
	"storeFilter": {
		"self": "store",
		"storeId": "store id",
		"storeName": "store name",
		"valueAddedTaxPrecent": "value added tax precent",
		"complicationsTaxPrecent": "complications tax precent",
		"transportCost": "transport cost",
		"transportCostFreeFrom": "transport cost free from",
		"storeSubInfo": "store sub info",
	},
	//GeneratorFlag:[Store.Update] {
	"storeUpdate": {
		"self": "store",
		"storeId": "store id",
		"storeName": "store name",
		"valueAddedTaxPrecent": "value added tax precent",
		"complicationsTaxPrecent": "complications tax precent",
		"transportCost": "transport cost",
		"transportCostFreeFrom": "transport cost free from",
	},
	//GeneratorFlag:[Bank.Create] {
	"bankCreate": {
		"self": "bank",
		"bankId": "bank id",
		"title": "title",
		"iconUrl": "icon url",
	},
	//GeneratorFlag:[Bank.Delete] {
	"bankDelete": {
		"self": "bank",
		"bankId": "bank id",
	},
	//GeneratorFlag:[Bank.Detail] {
	"bankDetail": {
		"self": "bank",
		"bankId": "bank id",
		"title": "title",
		"iconUrl": "icon url",
		"bankSubInfo": "bank sub info",
	},
	//GeneratorFlag:[Bank.List] {
	"bankList": {
		"self": "bank",
		"bankId": "bank id",
		"title": "title",
		"iconUrl": "icon url",
		"bankSubInfo": "bank sub info",
	},
	//GeneratorFlag:[Bank.Filter] {
	"bankFilter": {
		"self": "bank",
		"bankId": "bank id",
		"title": "title",
		"iconUrl": "icon url",
		"bankSubInfo": "bank sub info",
	},
	//GeneratorFlag:[Bank.Update] {
	"bankUpdate": {
		"self": "bank",
		"bankId": "bank id",
		"title": "title",
		"iconUrl": "icon url",
	},
	//GeneratorFlag:[Category.Create] {
	"categoryCreate": {
		"self": "category",
		"categoryId": "category id",
		"categoryKey": "category key",
		"cultureId": "culture",
		"parentId": "parent",
		"title": "title",
		"categoryGenoString": "category geno string",
		"imagePath": "image path",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[Category.Delete] {
	"categoryDelete": {
		"self": "category",
		"categoryId": "category id",
	},
	//GeneratorFlag:[Category.Detail] {
	"categoryDetail": {
		"self": "category",
		"categoryId": "category id",
		"categoryKey": "category key",
		"cultureId": "culture",
		"parentId": "parent",
		"title": "title",
		"categoryGenoString": "category geno string",
		"imagePath": "image path",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"categorySubInfo": "category sub info",
	},
	//GeneratorFlag:[Category.List] {
	"categoryList": {
		"self": "category",
		"categoryId": "category id",
		"categoryKey": "category key",
		"cultureId": "culture",
		"parentId": "parent",
		"title": "title",
		"categoryGenoString": "category geno string",
		"imagePath": "image path",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"categorySubInfo": "category sub info",
	},
	//GeneratorFlag:[Category.Filter] {
	"categoryFilter": {
		"self": "category",
		"categoryId": "category id",
		"categoryKey": "category key",
		"cultureId": "culture",
		"parentId": "parent",
		"title": "title",
		"categoryGenoString": "category geno string",
		"imagePath": "image path",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"categorySubInfo": "category sub info",
	},
	//GeneratorFlag:[Category.Update] {
	"categoryUpdate": {
		"self": "category",
		"categoryId": "category id",
		"categoryKey": "category key",
		"cultureId": "culture",
		"parentId": "parent",
		"title": "title",
		"categoryGenoString": "category geno string",
		"imagePath": "image path",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[Feature.Create] {
	"featureCreate": {
		"self": "feature",
		"featureId": "feature id",
		"featureKey": "feature key",
		"cultureId": "culture",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[Feature.Delete] {
	"featureDelete": {
		"self": "feature",
		"featureId": "feature id",
	},
	//GeneratorFlag:[Feature.Detail] {
	"featureDetail": {
		"self": "feature",
		"featureId": "feature id",
		"featureKey": "feature key",
		"cultureId": "culture",
		"title": "title",
		"caption": "caption",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"featureSubInfo": "feature sub info",
	},
	//GeneratorFlag:[Feature.List] {
	"featureList": {
		"self": "feature",
		"featureId": "feature id",
		"featureKey": "feature key",
		"cultureId": "culture",
		"title": "title",
		"caption": "caption",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"featureSubInfo": "feature sub info",
	},
	//GeneratorFlag:[Feature.Filter] {
	"featureFilter": {
		"self": "feature",
		"featureId": "feature id",
		"featureKey": "feature key",
		"cultureId": "culture",
		"title": "title",
		"caption": "caption",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"featureSubInfo": "feature sub info",
	},
	//GeneratorFlag:[Feature.Update] {
	"featureUpdate": {
		"self": "feature",
		"featureId": "feature id",
		"featureKey": "feature key",
		"cultureId": "culture",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[FeatureItem.Create] {
	"featureItemCreate": {
		"self": "featureItem",
		"featureItemId": "feature item id",
		"featureItemKey": "feature item key",
		"cultureId": "culture",
		"featureId": "feature",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[FeatureItem.Delete] {
	"featureItemDelete": {
		"self": "featureItem",
		"featureItemId": "feature item id",
	},
	//GeneratorFlag:[FeatureItem.Detail] {
	"featureItemDetail": {
		"self": "featureItem",
		"featureItemId": "feature item id",
		"featureItemKey": "feature item key",
		"cultureId": "culture",
		"featureId": "feature",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"feature": {
			"self": "feature",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"featureItemSubInfo": "feature item sub info",
	},
	//GeneratorFlag:[FeatureItem.List] {
	"featureItemList": {
		"self": "featureItem",
		"featureItemId": "feature item id",
		"featureItemKey": "feature item key",
		"cultureId": "culture",
		"featureId": "feature",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"feature": {
			"self": "feature",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"featureItemSubInfo": "feature item sub info",
	},
	//GeneratorFlag:[FeatureItem.Filter] {
	"featureItemFilter": {
		"self": "featureItem",
		"featureItemId": "feature item id",
		"featureItemKey": "feature item key",
		"cultureId": "culture",
		"featureId": "feature",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"feature": {
			"self": "feature",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"featureItemSubInfo": "feature item sub info",
	},
	//GeneratorFlag:[FeatureItem.Update] {
	"featureItemUpdate": {
		"self": "featureItem",
		"featureItemId": "feature item id",
		"featureItemKey": "feature item key",
		"cultureId": "culture",
		"featureId": "feature",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanPrecent.Create] {
	"planPrecentCreate": {
		"self": "planPrecent",
		"planPrecentId": "plan precent id",
		"lineOrder": "line order",
		"linePrecent": "line precent",
	},
	//GeneratorFlag:[PlanPrecent.Delete] {
	"planPrecentDelete": {
		"self": "planPrecent",
		"planPrecentId": "plan precent id",
	},
	//GeneratorFlag:[PlanPrecent.Detail] {
	"planPrecentDetail": {
		"self": "planPrecent",
		"planPrecentId": "plan precent id",
		"lineOrder": "line order",
		"linePrecent": "line precent",
		"planPrecentSubInfo": "plan precent sub info",
	},
	//GeneratorFlag:[PlanPrecent.List] {
	"planPrecentList": {
		"self": "planPrecent",
		"planPrecentId": "plan precent id",
		"lineOrder": "line order",
		"linePrecent": "line precent",
		"planPrecentSubInfo": "plan precent sub info",
	},
	//GeneratorFlag:[PlanPrecent.Filter] {
	"planPrecentFilter": {
		"self": "planPrecent",
		"planPrecentId": "plan precent id",
		"lineOrder": "line order",
		"linePrecent": "line precent",
		"planPrecentSubInfo": "plan precent sub info",
	},
	//GeneratorFlag:[PlanPrecent.Update] {
	"planPrecentUpdate": {
		"self": "planPrecent",
		"planPrecentId": "plan precent id",
		"lineOrder": "line order",
		"linePrecent": "line precent",
	},
	//GeneratorFlag:[PlanSetting.Create] {
	"planSettingCreate": {
		"self": "planSetting",
		"planSettingId": "plan setting id",
		"planMaximunPayOutPerLineValue": "plan maximun pay out per line value",
		"planPayOutCommisionPrecent": "plan pay out commision precent",
		"planPayOutVoacherPrecent": "plan pay out voacher precent",
		"planSaveExpiretionDayCount": "plan save expiretion day count",
		"planMaximunPayoutTotal": "plan maximun payout total",
	},
	//GeneratorFlag:[PlanSetting.Delete] {
	"planSettingDelete": {
		"self": "planSetting",
		"planSettingId": "plan setting id",
	},
	//GeneratorFlag:[PlanSetting.Detail] {
	"planSettingDetail": {
		"self": "planSetting",
		"planSettingId": "plan setting id",
		"planMaximunPayOutPerLineValue": "plan maximun pay out per line value",
		"planPayOutCommisionPrecent": "plan pay out commision precent",
		"planPayOutVoacherPrecent": "plan pay out voacher precent",
		"planSaveExpiretionDayCount": "plan save expiretion day count",
		"planMaximunPayoutTotal": "plan maximun payout total",
		"planSettingSubInfo": "plan setting sub info",
	},
	//GeneratorFlag:[PlanSetting.List] {
	"planSettingList": {
		"self": "planSetting",
		"planSettingId": "plan setting id",
		"planMaximunPayOutPerLineValue": "plan maximun pay out per line value",
		"planPayOutCommisionPrecent": "plan pay out commision precent",
		"planPayOutVoacherPrecent": "plan pay out voacher precent",
		"planSaveExpiretionDayCount": "plan save expiretion day count",
		"planMaximunPayoutTotal": "plan maximun payout total",
		"planSettingSubInfo": "plan setting sub info",
	},
	//GeneratorFlag:[PlanSetting.Filter] {
	"planSettingFilter": {
		"self": "planSetting",
		"planSettingId": "plan setting id",
		"planMaximunPayOutPerLineValue": "plan maximun pay out per line value",
		"planPayOutCommisionPrecent": "plan pay out commision precent",
		"planPayOutVoacherPrecent": "plan pay out voacher precent",
		"planSaveExpiretionDayCount": "plan save expiretion day count",
		"planMaximunPayoutTotal": "plan maximun payout total",
		"planSettingSubInfo": "plan setting sub info",
	},
	//GeneratorFlag:[PlanSetting.Update] {
	"planSettingUpdate": {
		"self": "planSetting",
		"planSettingId": "plan setting id",
		"planMaximunPayOutPerLineValue": "plan maximun pay out per line value",
		"planPayOutCommisionPrecent": "plan pay out commision precent",
		"planPayOutVoacherPrecent": "plan pay out voacher precent",
		"planSaveExpiretionDayCount": "plan save expiretion day count",
		"planMaximunPayoutTotal": "plan maximun payout total",
	},
	//GeneratorFlag:[ProductColor.Create] {
	"productColorCreate": {
		"self": "productColor",
		"productColorId": "product color id",
		"productColorKey": "product color key",
		"cultureId": "culture",
		"title": "title",
		"colorHexaCode": "color hexa code",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductColor.Delete] {
	"productColorDelete": {
		"self": "productColor",
		"productColorId": "product color id",
	},
	//GeneratorFlag:[ProductColor.Detail] {
	"productColorDetail": {
		"self": "productColor",
		"productColorId": "product color id",
		"productColorKey": "product color key",
		"cultureId": "culture",
		"title": "title",
		"colorHexaCode": "color hexa code",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productColorSubInfo": "product color sub info",
	},
	//GeneratorFlag:[ProductColor.List] {
	"productColorList": {
		"self": "productColor",
		"productColorId": "product color id",
		"productColorKey": "product color key",
		"cultureId": "culture",
		"title": "title",
		"colorHexaCode": "color hexa code",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productColorSubInfo": "product color sub info",
	},
	//GeneratorFlag:[ProductColor.Filter] {
	"productColorFilter": {
		"self": "productColor",
		"productColorId": "product color id",
		"productColorKey": "product color key",
		"cultureId": "culture",
		"title": "title",
		"colorHexaCode": "color hexa code",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productColorSubInfo": "product color sub info",
	},
	//GeneratorFlag:[ProductColor.Update] {
	"productColorUpdate": {
		"self": "productColor",
		"productColorId": "product color id",
		"productColorKey": "product color key",
		"cultureId": "culture",
		"title": "title",
		"colorHexaCode": "color hexa code",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[StoreProfitRate.Create] {
	"storeProfitRateCreate": {
		"self": "storeProfitRate",
		"storeProfitRateId": "store profit rate id",
		"precentProfit": "precent profit",
		"title": "title",
		"caption": "caption",
		"storeId": "store",
	},
	//GeneratorFlag:[StoreProfitRate.Delete] {
	"storeProfitRateDelete": {
		"self": "storeProfitRate",
		"storeProfitRateId": "store profit rate id",
	},
	//GeneratorFlag:[StoreProfitRate.Detail] {
	"storeProfitRateDetail": {
		"self": "storeProfitRate",
		"storeProfitRateId": "store profit rate id",
		"precentProfit": "precent profit",
		"title": "title",
		"caption": "caption",
		"storeId": "store",
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"storeProfitRateSubInfo": "store profit rate sub info",
	},
	//GeneratorFlag:[StoreProfitRate.List] {
	"storeProfitRateList": {
		"self": "storeProfitRate",
		"storeProfitRateId": "store profit rate id",
		"precentProfit": "precent profit",
		"title": "title",
		"caption": "caption",
		"storeId": "store",
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"storeProfitRateSubInfo": "store profit rate sub info",
	},
	//GeneratorFlag:[StoreProfitRate.Filter] {
	"storeProfitRateFilter": {
		"self": "storeProfitRate",
		"storeProfitRateId": "store profit rate id",
		"precentProfit": "precent profit",
		"title": "title",
		"caption": "caption",
		"storeId": "store",
		"store": {
			"self": "store",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"storeProfitRateSubInfo": "store profit rate sub info",
	},
	//GeneratorFlag:[StoreProfitRate.Update] {
	"storeProfitRateUpdate": {
		"self": "storeProfitRate",
		"storeProfitRateId": "store profit rate id",
		"precentProfit": "precent profit",
		"title": "title",
		"caption": "caption",
		"storeId": "store",
	},
	//GeneratorFlag:[BankPaymentStatuse.Detail] {
	"bankPaymentStatuseDetail": {
		"self": "bankPaymentStatuse",
		"bankPaymentStatuseId": "bank payment statuse id",
		"bankPaymentStatuseKey": "bank payment statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[BankPaymentStatuse.List] {
	"bankPaymentStatuseList": {
		"self": "bankPaymentStatuse",
		"bankPaymentStatuseId": "bank payment statuse id",
		"bankPaymentStatuseKey": "bank payment statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[BankPaymentStatuse.Filter] {
	"bankPaymentStatuseFilter": {
		"self": "bankPaymentStatuse",
		"bankPaymentStatuseId": "bank payment statuse id",
		"bankPaymentStatuseKey": "bank payment statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "culture",
		"cultureId": "culture id",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "culture",
		"cultureId": "culture id",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "culture",
		"cultureId": "culture id",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[InvoiceCancelStatuse.Detail] {
	"invoiceCancelStatuseDetail": {
		"self": "invoiceCancelStatuse",
		"invoiceCancelStatuseId": "invoice cancel statuse id",
		"invoiceCancelStatuseKey": "invoice cancel statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[InvoiceCancelStatuse.List] {
	"invoiceCancelStatuseList": {
		"self": "invoiceCancelStatuse",
		"invoiceCancelStatuseId": "invoice cancel statuse id",
		"invoiceCancelStatuseKey": "invoice cancel statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[InvoiceCancelStatuse.Filter] {
	"invoiceCancelStatuseFilter": {
		"self": "invoiceCancelStatuse",
		"invoiceCancelStatuseId": "invoice cancel statuse id",
		"invoiceCancelStatuseKey": "invoice cancel statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[InvoicePaymentType.Detail] {
	"invoicePaymentTypeDetail": {
		"self": "invoicePaymentType",
		"invoicePaymentTypeId": "invoice payment type id",
		"invoicePaymentTypeKey": "invoice payment type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[InvoicePaymentType.List] {
	"invoicePaymentTypeList": {
		"self": "invoicePaymentType",
		"invoicePaymentTypeId": "invoice payment type id",
		"invoicePaymentTypeKey": "invoice payment type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[InvoicePaymentType.Filter] {
	"invoicePaymentTypeFilter": {
		"self": "invoicePaymentType",
		"invoicePaymentTypeId": "invoice payment type id",
		"invoicePaymentTypeKey": "invoice payment type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[InvoiceStatuse.Detail] {
	"invoiceStatuseDetail": {
		"self": "invoiceStatuse",
		"invoiceStatuseId": "invoice statuse id",
		"invoiceStatuseKey": "invoice statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceStatuse.List] {
	"invoiceStatuseList": {
		"self": "invoiceStatuse",
		"invoiceStatuseId": "invoice statuse id",
		"invoiceStatuseKey": "invoice statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceStatuse.Filter] {
	"invoiceStatuseFilter": {
		"self": "invoiceStatuse",
		"invoiceStatuseId": "invoice statuse id",
		"invoiceStatuseKey": "invoice statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceTransactionType.Detail] {
	"invoiceTransactionTypeDetail": {
		"self": "invoiceTransactionType",
		"invoiceTransactionTypeId": "invoice transaction type id",
		"invoiceTransactionTypeKey": "invoice transaction type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceTransactionType.List] {
	"invoiceTransactionTypeList": {
		"self": "invoiceTransactionType",
		"invoiceTransactionTypeId": "invoice transaction type id",
		"invoiceTransactionTypeKey": "invoice transaction type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceTransactionType.Filter] {
	"invoiceTransactionTypeFilter": {
		"self": "invoiceTransactionType",
		"invoiceTransactionTypeId": "invoice transaction type id",
		"invoiceTransactionTypeKey": "invoice transaction type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreaditTransactionType.Detail] {
	"personCreaditTransactionTypeDetail": {
		"self": "personCreaditTransactionType",
		"personCreaditTransactionTypeId": "person creadit transaction type id",
		"personCreaditTransactionTypeKey": "person creadit transaction type key",
		"cultureId": "culture",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreaditTransactionType.List] {
	"personCreaditTransactionTypeList": {
		"self": "personCreaditTransactionType",
		"personCreaditTransactionTypeId": "person creadit transaction type id",
		"personCreaditTransactionTypeKey": "person creadit transaction type key",
		"cultureId": "culture",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreaditTransactionType.Filter] {
	"personCreaditTransactionTypeFilter": {
		"self": "personCreaditTransactionType",
		"personCreaditTransactionTypeId": "person creadit transaction type id",
		"personCreaditTransactionTypeKey": "person creadit transaction type key",
		"cultureId": "culture",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonPreTransactionStatuse.Detail] {
	"personPreTransactionStatuseDetail": {
		"self": "personPreTransactionStatuse",
		"personPreTransactionStatuseId": "person pre transaction statuse id",
		"personPreTransactionStatuseKey": "person pre transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonPreTransactionStatuse.List] {
	"personPreTransactionStatuseList": {
		"self": "personPreTransactionStatuse",
		"personPreTransactionStatuseId": "person pre transaction statuse id",
		"personPreTransactionStatuseKey": "person pre transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PersonPreTransactionStatuse.Filter] {
	"personPreTransactionStatuseFilter": {
		"self": "personPreTransactionStatuse",
		"personPreTransactionStatuseId": "person pre transaction statuse id",
		"personPreTransactionStatuseKey": "person pre transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionCommentType.Detail] {
	"planTransactionCommentTypeDetail": {
		"self": "planTransactionCommentType",
		"planTransactionCommentTypeId": "plan transaction comment type id",
		"planTransactionCommentTypeKey": "plan transaction comment type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionCommentType.List] {
	"planTransactionCommentTypeList": {
		"self": "planTransactionCommentType",
		"planTransactionCommentTypeId": "plan transaction comment type id",
		"planTransactionCommentTypeKey": "plan transaction comment type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionCommentType.Filter] {
	"planTransactionCommentTypeFilter": {
		"self": "planTransactionCommentType",
		"planTransactionCommentTypeId": "plan transaction comment type id",
		"planTransactionCommentTypeKey": "plan transaction comment type key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionStatuse.Detail] {
	"planTransactionStatuseDetail": {
		"self": "planTransactionStatuse",
		"planTransactionStatuseId": "plan transaction statuse id",
		"planTransactionStatuseKey": "plan transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionStatuse.List] {
	"planTransactionStatuseList": {
		"self": "planTransactionStatuse",
		"planTransactionStatuseId": "plan transaction statuse id",
		"planTransactionStatuseKey": "plan transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionStatuse.Filter] {
	"planTransactionStatuseFilter": {
		"self": "planTransactionStatuse",
		"planTransactionStatuseId": "plan transaction statuse id",
		"planTransactionStatuseKey": "plan transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionType.Detail] {
	"planTransactionTypeDetail": {
		"self": "planTransactionType",
		"planTransactionTypeId": "plan transaction type id",
		"personPreTransactionStatuseKey": "person pre transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionType.List] {
	"planTransactionTypeList": {
		"self": "planTransactionType",
		"planTransactionTypeId": "plan transaction type id",
		"personPreTransactionStatuseKey": "person pre transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[PlanTransactionType.Filter] {
	"planTransactionTypeFilter": {
		"self": "planTransactionType",
		"planTransactionTypeId": "plan transaction type id",
		"personPreTransactionStatuseKey": "person pre transaction statuse key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductDocType.Detail] {
	"productDocTypeDetail": {
		"self": "productDocType",
		"productDocTypeId": "product doc type id",
		"productDocTypeKey": "product doc type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductDocType.List] {
	"productDocTypeList": {
		"self": "productDocType",
		"productDocTypeId": "product doc type id",
		"productDocTypeKey": "product doc type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductDocType.Filter] {
	"productDocTypeFilter": {
		"self": "productDocType",
		"productDocTypeId": "product doc type id",
		"productDocTypeKey": "product doc type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductStatus.Detail] {
	"productStatusDetail": {
		"self": "productStatus",
		"productStatusId": "product status id",
		"productStatusKey": "product status key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductStatus.List] {
	"productStatusList": {
		"self": "productStatus",
		"productStatusId": "product status id",
		"productStatusKey": "product status key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductStatus.Filter] {
	"productStatusFilter": {
		"self": "productStatus",
		"productStatusId": "product status id",
		"productStatusKey": "product status key",
		"cultureId": "culture id",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[ProductTransportCostType.Detail] {
	"productTransportCostTypeDetail": {
		"self": "productTransportCostType",
		"productTransportCostTypeId": "product transport cost type id",
		"productTransportCostTypeKey": "product transport cost type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductTransportCostType.List] {
	"productTransportCostTypeList": {
		"self": "productTransportCostType",
		"productTransportCostTypeId": "product transport cost type id",
		"productTransportCostTypeKey": "product transport cost type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductTransportCostType.Filter] {
	"productTransportCostTypeFilter": {
		"self": "productTransportCostType",
		"productTransportCostTypeId": "product transport cost type id",
		"productTransportCostTypeKey": "product transport cost type key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[BankPayment.GetPayment] {
	"bankPaymentGetPayment": {
		"self": "bankPayment",
		"price": "Price",
		"storeId": "StoreID",
		"gateWayAddress": "GateWayAddres",
	},
	//GeneratorFlag:[Category.ListAction] {
	"categoryListAction": {
		"self": "category",
		"cultureId": "Culture",
		"categoryId": "ID",
		"items": {
			"self": "category list item",
			"title": "عنوان",
			"categoryId": "شناسه",
			"parentId": "والد",
			"imagePath": "آدرس تصویر",
			"categoryGenoString": "شجره",
		},
	},
	//GeneratorFlag:[Invoice.AmountSum] {
	"invoiceAmountSum": {
		"self": "invoice",
		"totalFactorAmount": "Total Factor Amount",
		"savedValue": "Saved Amount",
	},
	//GeneratorFlag:[Invoice.ConnectToOther] {
	"invoiceConnectToOther": {
		"self": "invoice",
		"invoiceId": "Invoice",
		"personId": "Person",
		"result": "Result",
		"message": "Message",
	},
	//GeneratorFlag:[Invoice.ContactSummray] {
	"invoiceContactSummray": {
		"self": "invoice",
		"contractCount": "InvoiceCount",
		"totalContractAmount": "Total Contract Amount",
		"endedContractCount": "Ended Contract Count",
		"totalEndedContractAmount": "Ended Contract Amount",
		"totalCancelledContract": "Cacncelled Contract Amount",
		"totalCancelledContractCount": "Cacncelled Contract Count",
		"totalCommision": "TotalCommision",
	},
	//GeneratorFlag:[Invoice.GetContracts] {
	"invoiceGetContracts": {
		"self": "invoice",
		"invoiceId": "InvoiceID",
		"totalAmount": "Total Amount",
		"serial": "Contract Serial",
		"beginDate": "Begin Date",
		"endDate": "End Date",
		"remainTenTimes": "Remain Ten Times",
	},
	//GeneratorFlag:[Invoice.GetInvoiceDetail] {
	"invoiceGetInvoiceDetail": {
		"self": "invoice",
		"invoiceSerial": "Serial",
		"personId": "Person",
		"personCode": "Person Code",
		"invoiceId": "Invoice ID",
		"paybleAmount": "Payble Amount",
		"totalAmont": "Total Amount",
		"discountAmount": "Discount",
		"paidAmount": "Paid Amount",
		"totalRemainAmount": "Remain Amount",
		"transportCostAmount": "Transport Cost",
		"packagingCostAmount": "Packaging Cost",
		"taxAmount": "Tax",
		"displayCheckoutDate": "CheckOut Date",
		"invoiceStatuseName": "Status",
		"invoicePaymentTypeName": "Payment Type",
		"storeName": "Store",
		"isConnected": "Is Conneted",
		"personAddressCountryName": "Country",
		"personAddressProvinceName": "Province",
		"personAddressCity": "City",
		"reciverFirstName": "First Name",
		"reciverLastName": "Last Name",
		"reciverMobile": "Mobile",
		"fullAddress": "Address",
		"plaque": "Plaque",
		"postalCode": "Postal Code",
		"items": {
			"self": "product invoice item",
			"productName": "کالا",
			"productColorHex": "کدرنگ",
			"productColorName": "رنگ",
			"productCount": "تعداد",
			"basePrice": "قیمت پایه",
			"totalPrice": "جمع کل",
		},
	},
	//GeneratorFlag:[Invoice.GetMyInvoiceCurrentWeek] {
	"invoiceGetMyInvoiceCurrentWeek": {
		"self": "invoice",
		"invoiceSerial": "Serial",
		"invoiceId": "ID",
		"paybleAmount": "Payble Amount",
	},
	//GeneratorFlag:[Invoice.GetMyList] {
	"invoiceGetMyList": {
		"self": "invoice",
		"fromDisplayDate": "",
		"toDisplayDate": "",
		"invoiceProductItems": {
			"self": "get my list invoice product item message",
			"productName": "کالا",
			"porductCount": "تعداد",
			"productMonetaryFeatureTitle": "ویژگی",
			"productColorTitle": "رنگ",
			"description": "توضیحات",
		},
		"invoicePaymentTypeId": "InvoicePaymentTypeId",
		"invoicePaymentTypeTitle": "Payment Type",
		"storeTitle": "Store",
		"description": "Description",
		"invoiceId": "InvoiceID",
		"invoiceStatusTitle": "Status",
		"totalPayble": "Total Payble",
		"totalRemain": "Remain",
		"totalAmount": "Total Amount",
		"unitCurrency": "Currency",
		"transportCost": "Transport Cost",
		"invoiceSerial": "Serial",
	},
	//GeneratorFlag:[Invoice.GetMyTeamSellByWeek] {
	"invoiceGetMyTeamSellByWeek": {
		"self": "invoice",
		"fromDisplayDate": "From Date",
		"toDisplayDate": "To Date",
		"personId": "Person",
		"rowID": "Row",
		"personName": "Person",
		"personCode": "Person Code",
		"totalSell": "Total Sell",
		"totalScore": "Total Score",
		"totalInvoice": "Total Invoice",
		"savedValue": "Saved Value",
		"personalSell": "Personal Sell",
	},
	//GeneratorFlag:[Invoice.Register] {
	"invoiceRegister": {
		"self": "invoice",
		"personId": "",
		"personInvoiceId": "",
		"disountCode": "",
		"invoiceProductItems": {
			"self": "invoice product item",
			"productId": "",
			"porductCount": "",
			"productMonetaryFeatureId": "",
			"productColorId": "",
			"description": "",
		},
		"invoicePaymentTypeId": "",
		"storeId": "",
		"description": "",
		"invoiceId": "",
		"additionalData": "",
		"totalPayble": "",
		"postBackUrl": "",
		"transportCost": "",
		"personAddressId": "",
		"invoiceSerial": "",
	},
	//GeneratorFlag:[Invoice.UplineFactorInfo] {
	"invoiceUplineFactorInfo": {
		"self": "invoice",
		"personId": "Person",
		"count": "Count",
		"firstFactorAmount": "First Factor Amount",
		"totalFactorAmount": "Total Factor Amount",
	},
	//GeneratorFlag:[InvoiceCancel.ContractInfo] {
	"invoiceCancelContractInfo": {
		"self": "invoiceCancel",
		"invoiceId": "InvoiceId",
		"info": "Total Commision",
		"totalCommision": "Total Commision",
		"contractAmount": "Total Commision",
		"finalAmount": "Payble Amount",
	},
	//GeneratorFlag:[InvoiceCancel.RemoveContract] {
	"invoiceCancelRemoveContract": {
		"self": "invoiceCancel",
		"invoiceId": "InvoiceId",
	},
	//GeneratorFlag:[PlanLine.GetCommisionCurrentWeek] {
	"planLineGetCommisionCurrentWeek": {
		"self": "planLine",
		"fromDisplayDate": "From Date",
		"toDisplayDate": "To Date",
		"personId": "Person",
		"rowID": "Row",
		"personName": "Person",
		"personCode": "Person Code",
		"totalSale": "Total Sell",
		"finalTotalSale": "Final Total Sell",
		"linePrecent": "Precent",
		"commission": "Commission",
		"savedValue": "Saved Value",
	},
	//GeneratorFlag:[PlanLine.Filter] {
	"planLineFilter": {
		"self": "planLine",
		"fromDisplayDate": "From Date",
		"toDisplayDate": "To Date",
		"personId": "Person",
		"rowID": "Row",
		"personName": "Person",
		"personCode": "Person Code",
		"totalSale": "Total Sell",
		"finalTotalSale": "Final Total Sell",
		"linePrecent": "Precent",
		"commission": "Commission",
		"savedValue": "Saved Value",
	},
	//GeneratorFlag:[PlanLine.GetMyDirect] {
	"planLineGetMyDirect": {
		"self": "planLine",
		"personId": "ID",
		"personFullName": "Person",
		"personCode": "Person Code",
	},
	//GeneratorFlag:[PlanTransaction.CalculatePlan] {
	"planTransactionCalculatePlan": {
		"self": "planTransaction",
		"apiSecretKey": "Key",
		"beginDate": "Date Of Begin",
		"personCode": "Person Code",
	},
	//GeneratorFlag:[PlanTransaction.Filter] {
	"planTransactionFilter": {
		"self": "planTransaction",
		"apiSecretKey": "Key",
		"beginDate": "Date Of Begin",
		"personCode": "Person Code",
	},
	//GeneratorFlag:[PlanTransaction.GetListCommision] {
	"planTransactionGetListCommision": {
		"self": "planTransaction",
		"fromDisplayDate": "From",
		"toDisplayDate": "To",
		"personId": "PersonId",
		"totalCommision": "Total Commision",
		"finalCommision": "Payble Commision",
		"savedPerson": "Saved Person",
		"secondPersonLine": "Second Person Line",
		"firstLineTotalSell": "First Greater Line",
		"secondLineTotalSell ": "Second Greater Line",
		"displayDate": "Date",
	},
	//GeneratorFlag:[PlanTransaction.GetReward] {
	"planTransactionGetReward": {
		"self": "planTransaction",
		"displayDate": "Date",
		"totalCommision": "Total",
		"finalCommision": "Payble",
		"typeName": "Type",
		"statusName": "Status",
	},
	//GeneratorFlag:[PlanTransaction.GetVoacherAwards] {
	"planTransactionGetVoacherAwards": {
		"self": "planTransaction",
		"finalVoacher": "Voacher Value",
		"date": "Date",
	},
	//GeneratorFlag:[PlanTransaction.List] {
	"planTransactionList": {
		"self": "planTransaction",
		"fromDisplayDate": "From Date",
		"toDisplayDate": "To Date",
		"displayDate": "Date",
		"personInfo": "PersonInfo",
		"totalCommision": "Total Commision",
		"finalCommision": "Payble Commision",
		"planType": "Type",
		"statuse": "Status",
		"savedValue": "Saved Value",
		"level": "Level",
		"caption": "Caption",
	},
	//GeneratorFlag:[PlanTransaction.RegisterExtraCommision] {
	"planTransactionRegisterExtraCommision": {
		"self": "planTransaction",
		"personId": "Member",
		"totalCommision": "Total Commision",
		"finalCommision": "Final Commision",
		"date": "Date",
		"planTransactionTypeId": "Type",
		"caption": "Caption",
		"planTransactionId": "",
	},
	//GeneratorFlag:[PlanTransaction.TotalReport] {
	"planTransactionTotalReport": {
		"self": "planTransaction",
		"fromDisplayDate": "From Date",
		"toDisplayDate": "To Date",
		"planTransactionTypeId": "Type",
		"planTransactionStatuseId": "Status",
		"totalCommision": "Total Commision",
		"finalCommision": "Payble Commision",
		"totalSavedValue": "Total Saved Value",
	},
	//GeneratorFlag:[PlanTransaction.TotalReward] {
	"planTransactionTotalReward": {
		"self": "planTransaction",
		"totalCommision": "Total",
		"finalCommision": "Payble",
	},
	//GeneratorFlag:[Product.Register] {
	"productRegister": {
		"self": "product",
		"productId": "product id",
		"productKey": "product key",
		"cultureId": "culture",
		"categoryId": "category ",
		"title": "title",
		"caption": "caption",
		"firstPrice": "first price",
		"productCode": "product code",
		"imagePath": "image path",
		"productStatusId": "product status ",
		"productMonetaryFeatureTitle": "product monetary feature title",
		"productSpecification": {
			"self": "product specification",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"details": {
			"self": "Details",
			"productDetailId": "شناسه جزئیات",
			"productStoreId": "شناسه جزئیات فروشگاه",
			"cultureId": "زبان",
			"storeId": "فروشگاه",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "ویژگی انتخابی",
			"productStatusId": "وضعیت",
			"adminDisplayName": "نام نمایش مدیر",
			"firstPrice": "قیمت اول",
			"secondPrice": "قیمت دوم",
			"productStoreInventory": "موجودی انبار",
			"productScore": "امتیاز کالا",
			"maxSellCount": "حداکثردر فاکتور",
			"minSellCount": "حداقل در فاکتور",
		},
		"images": {
			"self": "Images",
			"productDocId": "شناسه تصویر",
			"docAddress": "تصویر",
			"base64String": "تصویر",
			"title": "عنوان",
			"caption": "توضیحات",
			"productDocTypeId": "نوع مدیا",
		},
	},
	//GeneratorFlag:[ProductCategory.CategoryProductRelatedAction] {
	"productCategoryCategoryProductRelatedAction": {
		"self": "productCategory",
		"categoryId": "Category Id",
		"title": "Title",
		"productId": "Product Id",
		"productDetailId": "Product Detail",
		"imagePath": "Image Path",
		"firstPrice": "First Price",
		"secondPrice": "Second Price",
		"categoryName": "Category",
		"inverstory": "Inventory",
		"firstUnitPrice": "First Unit Price",
		"secondUnitPrice": "Second Unit Price",
		"linePrice": "Removed Price",
		"description": "Description",
	},
	//GeneratorFlag:[ProductCategory.GetProductCategory] {
	"productCategoryGetProductCategory": {
		"self": "productCategory",
		"categoryId": "Category Id",
		"title": "Title",
		"productId": "Product Id",
		"productDetailId": "Product Detail",
		"imagePath": "Image Path",
		"firstPrice": "First Price",
		"secondPrice": "Second Price",
		"categoryName": "Category",
		"inverstory": "Inventory",
		"firstUnitPrice": "First Unit Price",
		"secondUnitPrice": "Second Unit Price",
		"linePrice": "Removed Price",
		"description": "Description",
	},
	//GeneratorFlag:[ProductCategory.GetProductCategoryListAction] {
	"productCategoryGetProductCategoryListAction": {
		"self": "productCategory",
		"categoryId": "",
		"cultureId": "",
		"title": "",
		"productId": "",
		"productDetailId": "",
		"imagePath": "",
		"basePrice": "",
		"secondPrice": "",
		"categoryName": "",
	},
	//GeneratorFlag:[ProductStore.GetProductInfo] {
	"productStoreGetProductInfo": {
		"self": "productStore",
		"productId": "Product ID",
		"storeId": "Store ID",
		"productStoreId": "Product Store ID",
		"cultureId": "CultureId",
		"title": "Title",
		"productMonetaryFeatureTitle": "Selective Feature Title",
		"firstPrice": "First Price",
		"scondPrice": "Second Price",
		"productStoreInventory": "Inventory",
		"caption": "Caption",
		"currency": "currency",
		"maxSellCount": "MaxSellCount",
		"minSellCount": "MinSellCount",
		"productColorId": "ProductColorId",
		"productMonetaryFeatureId": "Monetary Feature ID",
		"categoryId": "CategoryId",
		"categoryName": "Category",
		"storeName": "Store Name",
		"width": "Width",
		"weight": "Weight",
		"height": "Height",
		"lenght": "Lenght",
		"productStatusId": "ProductStatusId",
		"productStatusTitle": "Status",
		"images": {
			"self": "Product Images",
			"id": "شناسه عکس",
			"title": "",
			"caption": "",
			"imagePath": "",
			"productDocTypeId": "",
		},
		"colors": {
			"self": "Colors",
			"id": "",
			"productColorId": "",
			"title": "",
			"colorHexaCode": "",
			"firstPrice": "",
			"secondPrice": "",
			"productMonetaryFeatureId": "",
			"productStoreInventory": "",
		},
		"monetaryFeatures": {
			"self": "Selective Features",
			"productMonetaryFeatureId": "",
			"title": "",
			"firstPrice": "",
			"secondPrice": "",
			"productColorId": "",
			"productStoreInventory": "",
		},
	},
//GeneratorFlag:[NewEntity]
}