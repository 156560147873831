<template>
	<div class="kaveh-table-component">

		<table-actions v-if="actions"
					   :filter-entity="filterEntity"
					   :actions="actions"
					   :checkedLen="checkedLen"
					   :displayType="displayType"
					   v-on:selectAction="selectAction"
					   v-on:exportExcel="exportExcel"
					   v-on:filterChange="setFilter"
					   v-on:displayChange="setDisplay" />

		<table-loading v-if="getComponent() == 'loading'" />
		<table-empty v-else-if="getComponent() == 'empty'" />
		<table-error v-else-if="getComponent() == 'error'" />

		<table-grid v-else-if="getComponent() == 'grid'"
					v-on:changePage="changePage"
					v-on:changeOrder="changeOrder"
					v-on:checked="(x) => checkedLen = x.length"
					v-bind:orderPropertyName="orderPropertyName"
					v-bind:orderDescending="orderDescending"
					v-bind:items="items"
					v-bind:entity="entity"
					v-bind:totalRows="totalRows"
					v-bind:totalfilters="totalfilters"
					v-bind:currentPage="currentPage"
					v-bind:selectable="selectable"
					v-bind:pageSize="pageSize"
					v-bind:showError="showError"
					v-bind:showForeignKey="showForeignKey"
					v-bind:showRowNumber="showRowNumber" />

		<table-grid-list v-else-if="getComponent() == 'grid-list'"
						 v-on:changePage="changePage"
						 v-on:changeOrder="changeOrder"
						 v-on:checked="(x) => checkedLen = x.length"
						 v-bind:orderPropertyName="orderPropertyName"
						 v-bind:orderDescending="orderDescending"
						 v-bind:items="items"
						 v-bind:entity="entity"
						 v-bind:totalRows="totalRows"
						 v-bind:totalfilters="totalfilters"
						 v-bind:currentPage="currentPage"
						 v-bind:selectable="selectable"
						 v-bind:pageSize="pageSize"
						 v-bind:showError="showError"
						 v-bind:showForeignKey="showForeignKey"
						 v-bind:showRowNumber="showRowNumber" />

		<table-tree v-else-if="getComponent() == 'tree'"
					v-on:changePage="changePage"
					v-on:checked="(x) => checkedLen = x.length"
					v-bind:items="items"
					v-bind:entity="entity"
					v-bind:totalRows="totalRows"
					v-bind:totalfilters="totalfilters"
					v-bind:currentPage="currentPage"
					v-bind:selectable="selectable"
					v-bind:pageSize="pageSize"
					v-bind:task="task"
					v-bind:showError="showError"
					v-bind:showForeignKey="showForeignKey"
					v-bind:showRowNumber="showRowNumber" />
	</div>

</template>
<script>

	import { deviceType } from '@/plugins'
	import { ENUMS } from '../../core/data'

	import TableEmpty from './common/table-empty'
	import TableError from './common/table-error'
	import TableLoading from './common/table-loading'
	import TableActions from './actions/table-actions'
	import TableGrid from './table-grid'
	import TableGridList from './table-grid-list'
	import TableTree from './table-tree'

	import XLSX from 'xlsx'

	export default {

		components: {
			'table-actions': TableActions,
			'table-empty': TableEmpty,
			'table-error': TableError,
			'table-loading': TableLoading,
			'table-grid': TableGrid,
			'table-grid-list': TableGridList,
			'table-tree': TableTree,
		},

		props:
		{
			'entity': {
				type: Function
			},

			'filterEntity': {
				type: Function
			},

			'task': {
				type: Function
			},

			'actions': {
				type: Array
			},

			'data': {
				type: Object
			},

			'filterData': {
				type: Object
			},

			'selectable': {
				type: Boolean,
				default: true,
			},

			'showError': {
				type: Boolean,
				default: false,
			},

			'showRowNumber': {
				type: Boolean,
				default: true
			},

			'showForeignKey': {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				items: [],
				orderPropertyName: '',
				orderDescending: false,
				pageSize: 10,
				totalRows: 0,
				totalfilters: 0,
				checkedLen: 0,
				currentPage: 1,
				component: 'loading',
				displayType: 'grid' ,
				loading: false,
				localData: null,
				hasError:false
			}
		},

		beforeMount()
		{
			this.displayType =
				deviceType() == 'laptop' ? 'grid' : 'grid-list';

			this.init();
		},


		methods:
		{
			getPayload()
			{
				var query = this.$route.query
				var pageSize = Number(query.pageSize) || 10;
				var currentPage = Number(query.currentPage) || 1;

				return {
					length: pageSize,
					pageSize: pageSize,
					currentPage: currentPage,
					start: ((currentPage - 1) * pageSize)
				}
			},

			setFilter(data)
			{
				const payload =
					this.getPayload();

				this.init({
					...payload,
					start: 0, currentPage: 1, filter: data
				})
			},

			exportExcel()
			{
				var excel = XLSX;
				var excelUtils = XLSX.utils;
				var wb = XLSX.utils.book_new();
				var entity = new this.entity(null);

				var sheets = [{
					name: entity.name,
					displayName: this.$loc.fromResource(entity.resourceKey),
					data: []
				}];

				for (var item of this.items)
				{
					for (var key in item.props)
					{
						const prop = item.props[key];

						if (prop.type == ENUMS.PROP_TYPE.OBJECT)
						{
							if (prop.entity.schema == "mainInfo")
							{
								let index =
									sheets.findIndex((x) =>
									{
										return x.name == prop.name
									});

								if (index == -1)
								{
									sheets.push({
										name: prop.name,
										displayName: this.$loc.fromResource(prop.resourceKey),
										data: prop.displayValue ? [prop.displayValue] : []
									});

									continue;
								}

								if (prop.displayValue)
								{
									sheets[index].data.push(prop.displayValue);
								}
							}
						}
					}

					sheets[0].data.push(item.displayValue);
				}


				for (const item of sheets)
				{
					excelUtils.book_append_sheet(
						wb, excelUtils.json_to_sheet(item.data), item.displayName
					)
				}

				wb.Workbook = {
					Views: [{ RTL: true }]
				}

				excel.writeFile(wb, `${entity.name}-export-${Date.now().toString()}.xlsx`)
			},

			async init(payload)
			{
				try {
					this.loading = true;
					payload = payload || this.getPayload();

					if (this.orderPropertyName != '') {
						payload.order = {
							propertyName: this.orderPropertyName,
							desc: this.orderDescending
						}
					}

					var items = [];
					var data = await this.getData(payload);

					if (data) {
						for (var item of data.items || []) {
							var entity =
								new this.entity({ value: item })

							if (this.showError == true) {
								entity.parseAllError();
							}

							items.push(entity);
						}

						this.items = items;
						this.totalRows = data.total;
						this.totalfilters = data.filtered;
						this.pageSize = payload.pageSize;
						this.currentPage = payload.currentPage;
					}
				} catch (e) {
					this.hasError = true;
				}

				this.loading = false;
			},


			async getData(payload)
			{
				var data;
				payload = payload || {
					start: 0, length: 10, filter: {}
				};

				if (this.filterData)
				{
					payload.filter = Object.assign({}, payload.filter, this.filterData);
				}

				if (this.data != null)
				{
					data = {
						total: (this.data || []).length,
						items: (this.data || []).slice(payload.start, payload.start + payload.length)
					}
				}
				else
				{
					try
					{
						const res = await this.task(payload);
						data = res.data
					} catch (e)
					{
						console.log('table error:' + e)
					}
				}

				data = data || {};
				data.total = data.total || 0;
				data.items = data.items || [];
				data.filtered = data.filtered || 0;

				if (data.items.length == 0 && payload.start > 1)
				{
					const query = {
						...this.$route.query, currentPage: 1
					};

					await this.$router.replace({ query });
					await this.init();
					return;
				}

				this.localData = data;

				return data;
			},


			async selectAction(action)
			{
				var checkeds = this.getCheckeds();
				var activeMode = action.activeMode;

				if (action.task && typeof (action.task) == 'function')
				{
					action.task(checkeds);;
				}

				else if (action.routeName)
				{
					var query = {};
					var dataTransferMode = action.dataTransferMode;
					var dataTransferKeys = action.dataTransferKeys;

					if (dataTransferMode == 'store')
					{
						query['temp'] = await this.addToTemp(
							activeMode == 'single'
						);
					}

					if (dataTransferMode == 'query' && dataTransferKeys)
					{
						for (var key of dataTransferKeys)
						{
							query[key] = checkeds[0][key]
						}
					}

					this.$router.push({
						name: action.routeName, query: query
					})
				}
			},


			async addToTemp(single)
			{
				const checkeds = this.getCheckeds()

				if (single)
				{
					return await this.$store.dispatch('temp/add', checkeds[0]);
				}

				return await this.$store.dispatch('temp/add', checkeds);
			},

			getCheckeds()
			{
				var checkeds = [],
					index = this.getStartRowIndex();

				for (var item of this.items)
				{
					if (item.checked)
					{
						var value = item.value;
						value['rowIndex'] = index

						checkeds.push(value);
					}

					index += 1;
				}

				return checkeds
			},


			async changePage()
			{
				await this.init();
			},

			async changeOrder(order)
			{
				this.orderPropertyName = order.orderPropertyName;
				this.orderDescending = order.orderDescending;

				await this.init();
			},

			getStartRowIndex()
			{
				const pageSize = this.pageSize;
				const currentPage = this.currentPage;

				return ((currentPage - 1) * pageSize);
			},


			setDisplay(displayType)
			{
				this.displayType = displayType
			},


			getComponent()
			{
				if (this.loading)
				{
					return "loading"
				}

				if (this.hasError) {
					return "error"
				}

				if (this.items.length == 0)
				{
					return "empty"
				}

				return this.displayType
			},
		},
	}
</script>
<style>
	.table th, .table td {
		vertical-align: bottom;
	}

	.table .truncate {
		width: auto;
		min-width: 0;
		max-width: 200px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.table tr.checked * {
		text-shadow: 1px 1px 3px var(--kaveh-base-color-dark);
	}
</style>
