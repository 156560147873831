export default {

	filter: (items, filter, keys) =>
	{
		if (filter == "") return items


		var fitered = [];
		items = items || [];

		for (var i = 0; i < items.length; i++)
		{
			var item = items[i]
			var itemKeys = keys || Object.keys(item);

			for (var ii = 0; ii < itemKeys.length; ii++)
			{
				var key = itemKeys[ii];
				if (typeof item[key] == "string")
				{
					if (item[key].includes(filter))
					{
						fitered.push(item);
						break;
					}
				}
			}
		}

		return fitered;
	},


	flat: (items, keyName) =>
	{
		const flatten = (arr) =>
		{
			return arr.reduce((result, item) =>
			{
				if (item.children)
				{
					const children = flatten(item.children);
					delete item.children;
					result.push(item);
					result.push(...children);

				} else { result.push(item); }

				return result;

			}, [])
		}


		return flatten(items);
	},

	toNested: (items, keyName, parnetName) =>
	{
		keyName = keyName || 'id';
		parnetName = parnetName || 'parentId';

		var buildTree = (treeItem) =>
		{
			var tree =
				JSON.parse(
					JSON.stringify(treeItem));

			tree.children =
				tree.children || [];

			for (var i = 0; i < items.length; i++)
			{
				var item = items[i];
				if (tree[keyName] == item[parnetName])
				{
					item = buildTree(item)
					tree.children.push(item);
				}
			}

			return tree;
		};

		var nodes = [];
		for (var i = 0; i < items.length; i++)
		{
			const item = items[i];

			// find parent index
			const index = items.findIndex((x) =>
			{
				return x[keyName] == item[parnetName]
			});

			/// If the parent is not found, it is considered as the parent
			if (index == -1)
			{
				nodes.push(buildTree(items[i], items))
			}
		}

		return nodes;
	},

	move(items, oldIndex, newIndex) {
		if (newIndex >= items.length) {
			var k = newIndex - items.length + 1;
			while (k--) {
				items.push(undefined);
			}
		}
		items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);
		return items;
	},

	distinct(items) {

		const onlyUnique = (value, index, self) => {
			return self.indexOf(value) === index;
		}

		return items.filter(onlyUnique);
	}
}
