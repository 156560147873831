//GeneratorFlag:[NoChange]
export default {
	path: 'person-bank-account-type',
	name: 'peopleNet.baseSys.personBankAccountType.layout',
	redirect: to => {
		return {name: 'peopleNet.personBankAccountType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-bank-account-type"],
		resourceKey: 'peopleNet.views.personBankAccountType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonBankAccountTypeDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personBankAccountType.detail',
			component: () => import('@/modules/people-net/views/person-bank-account-type/person-bank-account-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personBankAccountType.detail',
				clientAccessKeys:["/people-net/base-sys/person-bank-account-type/detail"],
				serverAccessKeys:["/people-net/person-bank-account-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonBankAccountTypeList]"
		{
			path: 'list',
			name: 'peopleNet.personBankAccountType.list',
			component: () => import('@/modules/people-net/views/person-bank-account-type/person-bank-account-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personBankAccountType.list',
				clientAccessKeys:["/people-net/base-sys/person-bank-account-type/list"],
				serverAccessKeys:["/people-net/person-bank-account-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}