//GeneratorFlag:[NoChange]
export default {
	path: 'person-access',
	name: 'hub.personAccess.layout',
	redirect: to => {
		return {name: 'hub.personAccess.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/person-access"],
		resourceKey: 'hub.views.personAccess.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NewRoute]
	]
}