//GeneratorFlag:[NoChange]
export default {
	path: 'region',
	name: 'hub.region.layout',
	redirect: to => {
		return {name: 'hub.region.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/region"],
		resourceKey: 'hub.views.region.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[RegionCreate]"
		{
			path: 'create',
			name: 'hub.region.create',
			component: () => import('@/modules/hub/views/region/region-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.create',
				clientAccessKeys:["/hub/region/create"],
				serverAccessKeys:["/hub/region/create","/hub/city/list","/hub/province/list","/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionImport]"
		{
			path: 'import',
			name: 'hub.region.import',
			component: () => import('@/modules/hub/views/region/region-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.import',
				clientAccessKeys:["/hub/region/import"],
				serverAccessKeys:["/hub/region/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionDelete]"
		{
			path: 'delete',
			name: 'hub.region.delete',
			component: () => import('@/modules/hub/views/region/region-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.delete',
				clientAccessKeys:["/hub/region/delete"],
				serverAccessKeys:["/hub/region/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionDetail]"
		{
			path: 'detail',
			name: 'hub.region.detail',
			component: () => import('@/modules/hub/views/region/region-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.detail',
				clientAccessKeys:["/hub/region/detail"],
				serverAccessKeys:["/hub/region/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionList]"
		{
			path: 'list',
			name: 'hub.region.list',
			component: () => import('@/modules/hub/views/region/region-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.list',
				clientAccessKeys:["/hub/region/list"],
				serverAccessKeys:["/hub/region/list","/hub/city/list","/hub/province/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RegionUpdate]"
		{
			path: 'update',
			name: 'hub.region.update',
			component: () => import('@/modules/hub/views/region/region-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.region.update',
				clientAccessKeys:["/hub/region/update"],
				serverAccessKeys:["/hub/region/update","/hub/city/list","/hub/province/list","/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}