//GeneratorFlag:[NoChange]
export default {
	path: 'plan-transaction-comment-type',
	name: 'shop.baseSys.planTransactionCommentType.layout',
	redirect: to => {
		return {name: 'shop.planTransactionCommentType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/plan-transaction-comment-type"],
		resourceKey: 'shop.views.planTransactionCommentType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanTransactionCommentTypeDetail]"
		{
			path: 'detail',
			name: 'shop.planTransactionCommentType.detail',
			component: () => import('@/modules/shop/views/plan-transaction-comment-type/plan-transaction-comment-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransactionCommentType.detail',
				clientAccessKeys:["/shop/base-sys/plan-transaction-comment-type/detail"],
				serverAccessKeys:["/shop/plan-transaction-comment-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanTransactionCommentTypeList]"
		{
			path: 'list',
			name: 'shop.planTransactionCommentType.list',
			component: () => import('@/modules/shop/views/plan-transaction-comment-type/plan-transaction-comment-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransactionCommentType.list',
				clientAccessKeys:["/shop/base-sys/plan-transaction-comment-type/list"],
				serverAccessKeys:["/shop/plan-transaction-comment-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}