//GeneratorFlag:[NoChange]
export default {
	path: 'plan-setting',
	name: 'shop.baseInfo.planSetting.layout',
	redirect: to => {
		return {name: 'shop.planSetting.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-info/plan-setting"],
		resourceKey: 'shop.views.planSetting.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanSettingCreate]"
		{
			path: 'create',
			name: 'shop.planSetting.create',
			component: () => import('@/modules/shop/views/plan-setting/plan-setting-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planSetting.create',
				clientAccessKeys:["/shop/base-info/plan-setting/create"],
				serverAccessKeys:["/shop/plan-setting/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanSettingCreateImport]"
		{
			path: 'create-import',
			name: 'shop.planSetting.createImport',
			component: () => import('@/modules/shop/views/plan-setting/plan-setting-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planSetting.createImport',
				clientAccessKeys:["/shop/base-info/plan-setting/create-import"],
				serverAccessKeys:["/shop/plan-setting/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanSettingDelete]"
		{
			path: 'delete',
			name: 'shop.planSetting.delete',
			component: () => import('@/modules/shop/views/plan-setting/plan-setting-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planSetting.delete',
				clientAccessKeys:["/shop/base-info/plan-setting/delete"],
				serverAccessKeys:["/shop/plan-setting/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanSettingDetail]"
		{
			path: 'detail',
			name: 'shop.planSetting.detail',
			component: () => import('@/modules/shop/views/plan-setting/plan-setting-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planSetting.detail',
				clientAccessKeys:["/shop/base-info/plan-setting/detail"],
				serverAccessKeys:["/shop/plan-setting/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanSettingList]"
		{
			path: 'list',
			name: 'shop.planSetting.list',
			component: () => import('@/modules/shop/views/plan-setting/plan-setting-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planSetting.list',
				clientAccessKeys:["/shop/base-info/plan-setting/list"],
				serverAccessKeys:["/shop/plan-setting/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanSettingUpdate]"
		{
			path: 'update',
			name: 'shop.planSetting.update',
			component: () => import('@/modules/shop/views/plan-setting/plan-setting-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planSetting.update',
				clientAccessKeys:["/shop/base-info/plan-setting/update"],
				serverAccessKeys:["/shop/plan-setting/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}