//GeneratorFlag:[NoChange]
export default {
	path: 'person',
	name: 'peopleNet.person.layout',
	redirect: to => {
		return {name: 'peopleNet.person.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/person"],
		resourceKey: 'peopleNet.views.person.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-people-fill',
		}
	},
	children: [
//GeneratorFlag:[PersonCreate]"
		{
			path: 'create',
			name: 'peopleNet.person.create',
			component: () => import('@/modules/people-net/views/person/person-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.create',
				clientAccessKeys:["/people-net/person/create"],
				serverAccessKeys:["/people-net/person/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.person.createImport',
			component: () => import('@/modules/people-net/views/person/person-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.createImport',
				clientAccessKeys:["/people-net/person/create-import"],
				serverAccessKeys:["/people-net/person/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDelete]"
		{
			path: 'delete',
			name: 'peopleNet.person.delete',
			component: () => import('@/modules/people-net/views/person/person-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.delete',
				clientAccessKeys:["/people-net/person/delete"],
				serverAccessKeys:["/people-net/person/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDetail]"
		{
			path: 'detail',
			name: 'peopleNet.person.detail',
			component: () => import('@/modules/people-net/views/person/person-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.detail',
				clientAccessKeys:["/people-net/person/detail"],
				serverAccessKeys:["/people-net/person/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonList]"
		{
			path: 'list',
			name: 'peopleNet.person.list',
			component: () => import('@/modules/people-net/views/person/person-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.list',
				clientAccessKeys:["/people-net/person/list"],
				serverAccessKeys:["/people-net/person/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonUpdate]"
		{
			path: 'update',
			name: 'peopleNet.person.update',
			component: () => import('@/modules/people-net/views/person/person-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.person.update',
				clientAccessKeys:["/people-net/person/update"],
				serverAccessKeys:["/people-net/person/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}