<template>
	<div class="table-responsive mb-2" :style="{'max-height': layoutHeight}">
		<table class="table table-striped table-bordered bg-light mb-2">
			<thead>
				<tr>
					<th style="width:20px" v-if="selectable">
						<div class="line"></div>
						<div class="form-check form-switch">
							<input type="checkbox" class="form-check-input" v-bind:role="'switch'"
								   v-on:change="checkAll" v-bind:checked="checkedLen() == items.length" />
						</div>
					</th>
					<th v-if="showRowNumber">
						<div class="line"></div>
						<div>#</div>
					</th>
					<th v-for="prop in getProps()" v-bind:key="prop.name" class="order-column">
						<div class="line"></div>
						<div class="truncate">
							<span>{{ $loc.fromResource(prop.resourceKey) }}</span>
							<span v-if="prop.type != 'object'" class="order-key" @click="changeOrder(prop)">
								<span v-bind:class="{'text-primary': (orderPropertyName == prop.name && orderDescending == true) }">&#8593;</span>
								<span v-bind:class="{'text-primary': (orderPropertyName == prop.name && orderDescending == false) }">&#8595;</span>
							</span>
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<table-row v-for="(item,index) in items"
						   v-on:checked="check"
						   :key="index"
						   :selectable="selectable"
						   :showError="showError"
						   :showRowNumber="showRowNumber"
						   :showForeignKey="showForeignKey"
						   :currentPage="currentPage"
						   :pageSize="pageSize"
						   :columns="columns"
						   :index="index"
						   :data="item" />
			</tbody>
		</table>
	</div>

	<table-pagination :total="totalRows"
					  :pageSize="pageSize"
					  :current="currentPage"
					  :totalfilters="totalfilters"
					  v-on:change="(obj)=> $emit('changePage', obj )" />
</template>
<script>

	import tableRowGrid from './rows/row-grid'
	import tablePagination from './common/table-pagination'

	export default {
		emits: ['changePage', 'changeOrder', 'checked'],
		components:
		{
			'table-row': tableRowGrid,
			'table-pagination': tablePagination
		},

		props:
		{
			'items': Array,
			'actions': Array,
			'entity': Function,
			'totalRows': Number,
			'totalfilters': Number,
			'currentPage': Number,
			'selectable': Boolean,
			'pageSize': Number,
			'orderPropertyName': String,
			'orderDescending': Boolean,
			'showRowNumber': Boolean,
			'showError': Boolean,
			'showForeignKey': Boolean
		},

		data()
		{
			return {
				columns: [],
				order: '',
				orderDesc: false
			}
		},

		beforeMount()
		{
			this.initColumns()
		},

		computed:
		{
			layoutHeight() {
				return `${window.outerHeight - 300}px`;
			},
		},

		methods:
		{
			initColumns()
			{
				var columns = {}
				for (var item of this.items)
				{
					for (const key of Object.keys(item.value || {}).concat(this.showError ? Object.keys(item.error || {}) : []))
					{
						columns[key] = true
					}
				}

				this.columns = Object.keys(columns)
			},

			getProps(entity)
			{
				var props = []
				entity = entity || new this.entity({})
				for (var key in entity.props)
				{
					const prop = entity.props[key];

					if (prop.foreignKey && this.showForeignKey == false)
						continue;

					if (!prop.display.isVisible || this.columns.indexOf(key) == -1)
						continue;

					props.push(entity.props[key])
				}

				return props
			},

			check()
			{
				this.$emit('checked', this.getChecked())
			},

			checkAll(e)
			{
				for (const item of this.items)
				{
					item.checked = e.target.checked
				}

				this.$emit('checked', this.getChecked())
			},

			getChecked()
			{
				var items = []
				for (const item of this.items)
				{
					if (item.checked == true) { items.push(item) }
				}

				return items
			},

			checkedLen()
			{
				var len = 0
				for (const item of this.items) { len += item.checked ? 1 : 0 }

				return len
			},

			changeOrder(prop)
			{
				var orderPropertyName = prop.name;
				var orderDescending = false;

				if (this.orderPropertyName == prop.name)
				{
					orderDescending = !this.orderDescending;
				}

				this.$emit('changeOrder', {
					orderPropertyName: orderPropertyName,
					orderDescending: orderDescending
				});
			}
		},
	}
</script>
<style scoped>
	.table th.order-column {
		padding-left: 30px;
	}
		.table th.order-column .order-key {
			position: absolute;
			left: 0;
			width: 25px;
			top: 8px;
			text-align:center;
			cursor:pointer;
			font-size:12px;
		}

	.table thead th {
		position: sticky;
		top: 1px;
		background: #fff;
	}
	.table thead .line {
		position: absolute;
		left: 0;
		top: -2px;
		height: 2px;
		width: 100%;
		background: #fff;
	}
</style>
