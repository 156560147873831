//GeneratorFlag:[NoChange]
import newsRoute from './news-route'; //GeneratorFlag:[News]
import personRoute from './person-route'; //GeneratorFlag:[Person]
import personAppPurchaseRoute from './person-app-purchase-route'; //GeneratorFlag:[PersonAppPurchase]
import personCreditTransactionRoute from './person-credit-transaction-route'; //GeneratorFlag:[PersonCreditTransaction]
import personDocumentRoute from './person-document-route'; //GeneratorFlag:[PersonDocument]
import personRequestDipostRoute from './person-request-dipost-route'; //GeneratorFlag:[PersonRequestDipost]
import baseInfoCityRoute from './base-info/city-route'; //GeneratorFlag:[BaseInfoCity]
import baseInfoCompanyRoute from './base-info/company-route'; //GeneratorFlag:[BaseInfoCompany]
import baseInfoCompanyRankRoute from './base-info/company-rank-route'; //GeneratorFlag:[BaseInfoCompanyRank]
import baseInfoCountryRoute from './base-info/country-route'; //GeneratorFlag:[BaseInfoCountry]
import baseInfoMilitaryTypeRoute from './base-info/military-type-route'; //GeneratorFlag:[BaseInfoMilitaryType]
import baseInfoNationalityTypeRoute from './base-info/nationality-type-route'; //GeneratorFlag:[BaseInfoNationalityType]
import baseInfoPersonBankAccountRoute from './base-info/person-bank-account-route'; //GeneratorFlag:[BaseInfoPersonBankAccount]
import baseInfoProvinceRoute from './base-info/province-route'; //GeneratorFlag:[BaseInfoProvince]
import baseInfoRankRoute from './base-info/rank-route'; //GeneratorFlag:[BaseInfoRank]
import baseInfoTeamRoute from './base-info/team-route'; //GeneratorFlag:[BaseInfoTeam]
import baseSysCultureRoute from './base-sys/culture-route'; //GeneratorFlag:[BaseSysCulture]
import baseSysPersonAppPurchaseStatuseRoute from './base-sys/person-app-purchase-statuse-route'; //GeneratorFlag:[BaseSysPersonAppPurchaseStatuse]
import baseSysPersonAppPurchaseTypeRoute from './base-sys/person-app-purchase-type-route'; //GeneratorFlag:[BaseSysPersonAppPurchaseType]
import baseSysPersonBankAccountTypeRoute from './base-sys/person-bank-account-type-route'; //GeneratorFlag:[BaseSysPersonBankAccountType]
import baseSysPersonCreditTransactionStatuseRoute from './base-sys/person-credit-transaction-statuse-route'; //GeneratorFlag:[BaseSysPersonCreditTransactionStatuse]
import baseSysPersonCreditTransactionTypeRoute from './base-sys/person-credit-transaction-type-route'; //GeneratorFlag:[BaseSysPersonCreditTransactionType]
import baseSysPersonDocumentTypeRoute from './base-sys/person-document-type-route'; //GeneratorFlag:[BaseSysPersonDocumentType]
import baseSysPersonGenderRoute from './base-sys/person-gender-route'; //GeneratorFlag:[BaseSysPersonGender]
import baseSysPersonRequestDipostStatuseRoute from './base-sys/person-request-dipost-statuse-route'; //GeneratorFlag:[BaseSysPersonRequestDipostStatuse]
import baseSysPersonStatuseRoute from './base-sys/person-statuse-route'; //GeneratorFlag:[BaseSysPersonStatuse]
import accessAgentRoute from './access-agent-route'; //GeneratorFlag:[AccessAgent]
import personAccessRoute from './person-access-route'; //GeneratorFlag:[PersonAccess]
import baseSysPersonRequestDipostTypeRoute from './base-sys/person-request-dipost-type-route'; //GeneratorFlag:[BaseSysPersonRequestDipostType]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'people-net',
	name: 'peopleNet',
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/people-net'],
		serverAccessKeys:[],
		resourceKey: 'service.peopleNet',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-layers-half',
		}
	},
	children: [
newsRoute, personRoute, personAppPurchaseRoute, personCreditTransactionRoute, personDocumentRoute, personRequestDipostRoute, accessAgentRoute, personAccessRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BaseInfoLayout]
		{
			path: 'base-info',
			name: 'peopleNet.baseInfo',
			component: () => import('@/modules/people-net/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.layout.baseInfo',
				clientAccessKeys:['/people-net/base-info'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			baseInfoCityRoute, baseInfoCompanyRoute, baseInfoCompanyRankRoute, baseInfoCountryRoute, baseInfoMilitaryTypeRoute, baseInfoNationalityTypeRoute, baseInfoPersonBankAccountRoute, baseInfoProvinceRoute, baseInfoRankRoute, baseInfoTeamRoute, //GeneratorFlag:[NewBaseInfoChild]
			]
		},
		//GeneratorFlag:[BaseSysLayout]
		{
			path: 'base-sys',
			name: 'peopleNet.baseSys',
			component: () => import('@/modules/people-net/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.layout.baseSys',
				clientAccessKeys:['/people-net/base-sys'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-lock',
				}
			},
			children: [
			baseSysCultureRoute, baseSysPersonAppPurchaseStatuseRoute, baseSysPersonAppPurchaseTypeRoute, baseSysPersonBankAccountTypeRoute, baseSysPersonCreditTransactionStatuseRoute, baseSysPersonCreditTransactionTypeRoute, baseSysPersonDocumentTypeRoute, baseSysPersonGenderRoute, baseSysPersonRequestDipostStatuseRoute, baseSysPersonStatuseRoute, baseSysPersonRequestDipostTypeRoute, //GeneratorFlag:[NewBaseSysChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
newsRoute, personRoute, personAppPurchaseRoute, personCreditTransactionRoute, personDocumentRoute, personRequestDipostRoute, baseInfoCityRoute, baseInfoCompanyRoute, baseInfoCompanyRankRoute, baseInfoCountryRoute, baseInfoMilitaryTypeRoute, baseInfoNationalityTypeRoute, baseInfoPersonBankAccountRoute, baseInfoProvinceRoute, baseInfoRankRoute, baseInfoTeamRoute, baseSysCultureRoute, baseSysPersonAppPurchaseStatuseRoute, baseSysPersonAppPurchaseTypeRoute, baseSysPersonBankAccountTypeRoute, baseSysPersonCreditTransactionStatuseRoute, baseSysPersonCreditTransactionTypeRoute, baseSysPersonDocumentTypeRoute, baseSysPersonGenderRoute, baseSysPersonRequestDipostStatuseRoute, baseSysPersonStatuseRoute, accessAgentRoute, personAccessRoute, baseSysPersonRequestDipostTypeRoute, //GeneratorFlag:[NewExport]
}