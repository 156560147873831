<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonPreTransactionStatuse, PersonPreTransactionStatuseFilter } from '@/modules/shop/data/person-pre-transaction-statuse/list'
	import { PersonPreTransactionStatuseService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonPreTransactionStatuse;
			},
			
			filterEntity()
			{
				return PersonPreTransactionStatuseFilter;
			},

			service()
			{
				return new PersonPreTransactionStatuseService();
			},

			filterData()
			{
				const personPreTransactionStatuseId =
					this.$route.query['personPreTransactionStatuseId'];

				if (personPreTransactionStatuseId)
				{
					return {
						'personPreTransactionStatuseId': personPreTransactionStatuseId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>