<template>
	<div class="kaveh-form-control-password">
		<input v-bind:id="prop.name"
			   v-bind:dir="prop.display.direction"
			   v-on:change="onHandelChange"
			   v-on:keyup="onHandelKeyUp"
			   v-model="value"
			   v-bind:type="type"
			   class="form-control"
			   autocomplete="off" />
		<div class="eye">
			<i :class="{'bi bi-eye': type == 'text' ,'bi bi-eye-slash': type =='password'}" @click="type = type == 'text' ? 'password':'text'"></i>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null,
				type: 'password'
			}
		},

		beforeMount()
		{
			if (this.prop.value) { this.value = this.prop.value }
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('submit')
				}
				else
				{
					this.$emit('keyup', this.value)
				}
			},
		}
	}
</script>
<style>
	.kaveh-form-control-password {
		position: relative;
	}

		.kaveh-form-control-password .eye {
			position: absolute;
			right: 10px;
			top: 5px;
			font-size: 20px;
			cursor: pointer;
		}
</style>
