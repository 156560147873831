//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class NoticeConfigIppanelService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[NoticeConfigIppanel.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/notice-config-ippanel/create', params || { });
	};

	//GeneratorFlag:[NoticeConfigIppanel.Delete]
	async delete(noticeConfigId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:noticeConfigId
		}
	
		return await _server.delete('/hub/notice-config-ippanel/delete', {'params':params})
	};

	//GeneratorFlag:[NoticeConfigIppanel.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/notice-config-ippanel/detail', {'params':params});
	};

	//GeneratorFlag:[NoticeConfigIppanel.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/notice-config-ippanel/update', params || { });
	};

//GeneratorFlag:[NewAction]
}