//GeneratorFlag:[NoChange]
export default {
	path: 'person-document',
	name: 'peopleNet.personDocument.layout',
	redirect: to => {
		return {name: 'peopleNet.personDocument.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/person-document"],
		resourceKey: 'peopleNet.views.personDocument.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonDocumentCreate]"
		{
			path: 'create',
			name: 'peopleNet.personDocument.create',
			component: () => import('@/modules/people-net/views/person-document/person-document-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.create',
				clientAccessKeys:["/people-net/person-document/create"],
				serverAccessKeys:["/people-net/person-document/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDocumentCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.personDocument.createImport',
			component: () => import('@/modules/people-net/views/person-document/person-document-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.createImport',
				clientAccessKeys:["/people-net/person-document/create-import"],
				serverAccessKeys:["/people-net/person-document/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDocumentDelete]"
		{
			path: 'delete',
			name: 'peopleNet.personDocument.delete',
			component: () => import('@/modules/people-net/views/person-document/person-document-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.delete',
				clientAccessKeys:["/people-net/person-document/delete"],
				serverAccessKeys:["/people-net/person-document/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDocumentDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personDocument.detail',
			component: () => import('@/modules/people-net/views/person-document/person-document-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.detail',
				clientAccessKeys:["/people-net/person-document/detail"],
				serverAccessKeys:["/people-net/person-document/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDocumentList]"
		{
			path: 'list',
			name: 'peopleNet.personDocument.list',
			component: () => import('@/modules/people-net/views/person-document/person-document-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.list',
				clientAccessKeys:["/people-net/person-document/list"],
				serverAccessKeys:["/people-net/person-document/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDocumentUpdate]"
		{
			path: 'update',
			name: 'peopleNet.personDocument.update',
			component: () => import('@/modules/people-net/views/person-document/person-document-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocument.update',
				clientAccessKeys:["/people-net/person-document/update"],
				serverAccessKeys:["/people-net/person-document/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}