<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonCreditTransactionType, PersonCreditTransactionTypeFilter } from '@/modules/people-net/data/person-credit-transaction-type/list'
	import { PersonCreditTransactionTypeService } from '@/modules/people-net/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonCreditTransactionType;
			},
			
			filterEntity()
			{
				return PersonCreditTransactionTypeFilter;
			},

			service()
			{
				return new PersonCreditTransactionTypeService();
			},

			filterData()
			{
				const personCreditTransactionTypeId =
					this.$route.query['personCreditTransactionTypeId'];

				if (personCreditTransactionTypeId)
				{
					return {
						'personCreditTransactionTypeId': personCreditTransactionTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>