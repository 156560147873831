<template>
	<kaveh-text-editor ref="EditorRef" v-model="value" @keyup="onHandelKeyUp" @change="onHandelChange" id="prop.name" />
</template>
<script>
	export default {
		emits: ['change', 'keyup'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		mounted()
		{
			if (this.prop.value)
			{
				this.$refs.EditorRef.setValue(this.prop.value)
			}
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp()
			{
				this.$emit('keyup', this.value)
			}
		}
	}
</script>
