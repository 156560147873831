<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { PlanTransaction, PlanTransactionFilter } from '@/modules/shop/data/plan-transaction/list'
	import { PlanTransactionService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return PlanTransaction;
			},
			
			filterEntity()
			{
				return PlanTransactionFilter;
			},

			service()
			{
				return new PlanTransactionService();
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				try
				{
					return await this.service.list(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>