//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PlanTransactionService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PlanTransaction.CalculatePlan]
	async calculatePlan(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/plan-transaction/calculate-plan', params || { });
	};

	//GeneratorFlag:[PlanTransaction.GetListCommision]
	async getListCommision(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/plan-transaction/get-list-commision', {'params':params});
	};

	//GeneratorFlag:[PlanTransaction.GetReward]
	async getReward(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/plan-transaction/get-reward', {'params':params});
	};

	//GeneratorFlag:[PlanTransaction.GetVoacherAwards]
	async getVoacherAwards(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/plan-transaction/get-voacher-awards', {'params':params});
	};

	//GeneratorFlag:[PlanTransaction.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/plan-transaction/list', {'params':params});
	};

	//GeneratorFlag:[PlanTransaction.TotalReport]
	async totalReport(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/plan-transaction/total-report', {'params':params});
	};

	//GeneratorFlag:[PlanTransaction.TotalReward]
	async totalReward(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/shop/plan-transaction/total-reward', {'params':params});
	};

	//GeneratorFlag:[PlanTransaction.RegisterExtraCommision]
	async registerExtraCommision(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/shop/plan-transaction/register-extra-commision', params || { });
	};

//GeneratorFlag:[NewAction]
}