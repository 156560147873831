<template>
	<div class="row" v-if="localEntity">
		<div class="col-12">
			<list-collapse :entity="localEntity" />
		</div>
	</div>
</template>

<script>
	import listCollapse from './list-collapse'
	import { BaseEntity } from '@/core/data/entities'


	export default {
		components:
		{
			'list-collapse': listCollapse
		},

		props:
		{
			'entity': {
				type: Function
			},

			'props': {
				type: Object
			},

			'data': {
				type: Object
			},
		},

		data()
		{
			return {
				localEntity: null
			}
		},

		beforeMount()
		{
			this.init();
		},

		methods: {
			init()
			{
				if (this.props == undefined) {
					this.localEntity = new this.entity({ value: this.data });
				}

				else {

					var baseEntity = new BaseEntity();
					baseEntity.props = this.props;

					if (this.data) {
						baseEntity.setValue(this.data)
					}

					this.localEntity = baseEntity
				}
			}
		}
	}
</script>
