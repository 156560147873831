<template>
	<table-cell-errors v-if="hasError" :data="data" />
	<component v-else :is="getComponent"  :data="data" />
</template>
<script>
	import TableCellText from './table-cell-text'
	import TableCellBoolean from './table-cell-boolean'
	import TableCellColor from './table-cell-color'
	import TableCellPopup from './table-cell-popup'
	import TableCellErrors from './table-cell-errors'

	export default {
		components:
		{
			'table-cell-text': TableCellText,
			'table-cell-boolean': TableCellBoolean,
			'table-cell-color': TableCellColor,
			'table-cell-popup': TableCellPopup,
			'table-cell-errors': TableCellErrors,
		},

		props: {

			data: Object,
			showError: {
				type: Boolean,
				default: false
			}
		},

		computed: {

			hasError()
			{
				return this.showError && (this.data.errors || []).length > 0
			},

			getComponent()
			{
				switch (this.data.type)
				{
					case "object":
						return "table-cell-popup";

					case "boolean":
						return "table-cell-boolean";
				}

				switch ((this.data.setConfig || {}).inputType) {
					case "color":
						return "table-cell-color";
				}

				return "table-cell-text";

			}
		},
	}
</script>
