//GeneratorFlag:[NoChange]
export default {
	path: 'bank',
	name: 'shop.baseInfo.bank.layout',
	redirect: to => {
		return {name: 'shop.bank.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-info/bank"],
		resourceKey: 'shop.views.bank.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[BankCreate]"
		{
			path: 'create',
			name: 'shop.bank.create',
			component: () => import('@/modules/shop/views/bank/bank-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bank.create',
				clientAccessKeys:["/shop/base-info/bank/create"],
				serverAccessKeys:["/shop/bank/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankCreateImport]"
		{
			path: 'create-import',
			name: 'shop.bank.createImport',
			component: () => import('@/modules/shop/views/bank/bank-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bank.createImport',
				clientAccessKeys:["/shop/base-info/bank/create-import"],
				serverAccessKeys:["/shop/bank/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankDelete]"
		{
			path: 'delete',
			name: 'shop.bank.delete',
			component: () => import('@/modules/shop/views/bank/bank-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bank.delete',
				clientAccessKeys:["/shop/base-info/bank/delete"],
				serverAccessKeys:["/shop/bank/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankDetail]"
		{
			path: 'detail',
			name: 'shop.bank.detail',
			component: () => import('@/modules/shop/views/bank/bank-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bank.detail',
				clientAccessKeys:["/shop/base-info/bank/detail"],
				serverAccessKeys:["/shop/bank/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankList]"
		{
			path: 'list',
			name: 'shop.bank.list',
			component: () => import('@/modules/shop/views/bank/bank-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bank.list',
				clientAccessKeys:["/shop/base-info/bank/list"],
				serverAccessKeys:["/shop/bank/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BankUpdate]"
		{
			path: 'update',
			name: 'shop.bank.update',
			component: () => import('@/modules/shop/views/bank/bank-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.bank.update',
				clientAccessKeys:["/shop/base-info/bank/update"],
				serverAccessKeys:["/shop/bank/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}