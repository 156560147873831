<template>
	<div class="row" v-if="localEntity">
		<div class="col-12">
			<form-collapse mode="default"
						   :use-validation="useValidation"
						   :entity="localEntity"
						   v-on:generator-up="(x) => {$emit('generator-up', x)}"
						   v-on:generator-down="(x) => {$emit('generator-remove', x)}"
						   v-on:generator-remove="(x) => {$emit('generator-remove', x)}" />
		</div>

		<div class="col-12" v-if="entityHasError">
			<div class="alert alert-danger">
				{{ $loc.fromResource('error.chackForm') }}
			</div>
		</div>

		<div class="col-12" v-if="task">
			<kaveh-button @click="submit" @mousedown="getPropsForGenerator" class="btn btn-dark btn-sm" :is-loading="loading">
				{{ $loc.fromResource(submitResourceKey) }}
			</kaveh-button>
		</div>
	</div>
</template>

<script>
	import formCollapse from './form-collapse'
	import { BaseEntity } from '@/core/data/entities'

	export default {
		emits: ['generator-remove', 'generator-up', 'generator-down'],

		components:
		{
			'form-collapse': formCollapse
		},

		props:
		{
			'entity': {
				type: Function
			},

			'props': {
				type: Object
			},

			'mode': {
				type: String
			},

			'task': {
				type: Function
			},

			'value': {
				type: Object
			},

			'useValidation': {
				type: Boolean
			},

			'displayType': {
				type: String
			},

			'submitResourceKey': {
				type: String
			},
		},

		data()
		{
			return {
				ver: 0,
				loading: false,
				entityHasError: false,
				localEntity: null,
			}
		},

		beforeMount() {
			if (this.props == undefined) {
				this.localEntity = new this.entity({ value: this.value });
			}

			else {

				var baseEntity = new BaseEntity();
				baseEntity.props = this.props;

				if (this.value) {
					baseEntity.setValue(this.value)
				}

				this.localEntity = baseEntity
			}
		},

		methods: {

			getValue() {

				if (this.useValidation) {
					this.localEntity.cleanError();
					this.localEntity.parseFormError();
					if (this.localEntity.getFormErrorLength() > 0) {
						this.entityHasError = true
						return undefined
					}
				}

				return this.localEntity.getFormValue()
			},

			async getPropsForGenerator(e)
			{
				if (e.which == 2)
				{
					const flatProps = this.localEntity.getFlatProps();

					var input = document.createElement("input");

					input.type = "text"
					input.value = JSON.stringify(flatProps)

					input.select();
					navigator.clipboard.writeText(input.value);

					alert("The form template was successfully saved in clipboard");
				}
			},

			async submit()
			{
				if (this.task)
				{
					if (this.useValidation)
					{
						this.localEntity.cleanError();
						this.localEntity.parseFormError();
						if (this.localEntity.getFormErrorLength() > 0)
						{
							this.entityHasError = true
							return
						}
					}


					try
					{
						const value =
							this.localEntity.getFormValue()

						this.loading = true;
						await this.task(value);

					} catch (e)
					{
						console.log('form Error:' + e)
					}

					this.loading = false;
				}
			}
		}
	}
</script>
