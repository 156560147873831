<template>
	<div class="custom-form">

		<ul class="nav nav-tabs">
			<li class="nav-item pointer" @click="setState('personRole')">
				<a class="nav-link text-dark" v-bind:class="{'active': state == 'personRole'}">
					{{$loc.fromResource('hub.ext.accessAgent.savePrsonRole')}}
				</a>
			</li>
			<li class="nav-item pointer" @click="setState('person')">
				<a class="nav-link text-dark" v-bind:class="{'active': state == 'person'}">
					{{$loc.fromResource('hub.ext.accessAgent.savePrson')}}
				</a>
			</li>
		</ul>
		<div class="bg-white pt-5 ps-3 pe-3 border border-top-0">

			<div class="mb-3 row" v-if="state == 'person'">
				<label v-once for="personId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(form.personId.resourceKey)}}</span>
					<span v-if="isRequired('personId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': personListIsLoading}" v-model="form.personId.value" @change="onHandelChange('personId')" id="personId">
						<option v-for="(item,index) in personList" :value="item.personId" :key="index">
							{{item.personName}}
						</option>
					</select>
					<small class="text-danger" v-if="form.personId.errors.length > 0">
						{{getFirstError('personId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row" v-if="state == 'personRole'">
				<label v-once for="personRoleId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(form.personRoleId.resourceKey)}}</span>
					<span v-if="isRequired('personRoleId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': personRoleListIsLoading}" v-model="form.personRoleId.value" @change="onHandelChange('personRoleId')" id="personRoleId">
						<option v-for="(item,index) in personRoleList" :value="item.personRoleId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="form.personRoleId.errors.length > 0">
						{{getFirstError('personRoleId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="accessKeys" class="col-4 col-md-3">
					<span>{{$loc.fromResource(form.accessKeys.resourceKey)}}</span>
					<span v-if="isRequired('accessKeys')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<div class="text-center" v-if="treeLoading">
						<div class="spinner-border spinner-border-sm" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
					<route-tree ref="RouteTree" @change="routeTreeChange" />
					<small class="text-danger" v-if="form.accessKeys.errors.length > 0">
						{{getFirstError('accessKeys')}}
					</small>
				</div>
			</div>
		</div>

	</div>
</template>
<script>

	import RouteTree from '../tree/route-tree'
	import { ENUMS } from '@/core/data'
	import validator from '@/core/validator'
	import { PersonService, PersonRoleService, AccessAgentService } from '@/modules/hub/services';

	export default {

		components: {
			'route-tree': RouteTree,
		},

		props: {
			useValidator: {
				type: Boolean,
				default: true,
			}
		},

		data() {
			return {
				state: '',
				personList: [],
				personRoleList: [],

				personListIsLoading: true,
				personRoleListIsLoading: true,
				treeLoading: false,

				form: {
					personRoleId: {
						type: ENUMS.PROP_TYPE.INT16,
						resourceKey: 'hub.accessAgentSave.personRoleId',
						patterns: [],
						errors: [],
						value: null,
					},
					personId: {
						type: ENUMS.PROP_TYPE.INT32,
						resourceKey: 'hub.accessAgentSave.personId',
						patterns: [],
						errors: [],
						value: null,
					},
					accessKeys: {
						type: ENUMS.PROP_TYPE.OBJECT,
						resourceKey: 'hub.accessAgentSave.accessKeys.self',
						patterns: ['required'],
						errors: [],
						value: null,
					},

				}
			}
		},

		async beforeMount() {
			this.personList = await this.getPersonList();
			this.personRoleList = await this.getPersonRoleList();

			this.setState('personRole')
		},

		methods:
		{
			async getPersonList() {
				var items = [];
				var service = new PersonService();

				try {
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });

					if (res.status == 200) {
						items = res.data.items || [];
					}

				} catch (e) { }

				this.personListIsLoading = false;
				return items;
			},

			async getPersonRoleList() {
				var items = [];
				var service = new PersonRoleService();

				try {
					const res = await service.list({
						start: 0,
						length: 1000
					}, { HER: true });

					if (res.status == 200) {
						items = res.data.items || [];
					}

				} catch (e) { }

				this.personRoleListIsLoading = false;
				return items;
			},



			isRequired(name) {
				return this.form[name].patterns.includes('required');
			},

			onHandelChange(name) {
				if (this.useValidator) {
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}

				this.form[name].isChanged = true;

				if (name == 'personId' || name == 'personRoleId') {
					this.setTreeChecked();
				}
			},

			onHandelKeyUp(name) {
				if (this.useValidator && this.form[name].isChanged) {
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}
			},

			getFirstError(name) {
				const prop = this.form[name];

				if (prop.errors.length > 0) {
					return this.$loc.fromResource(prop.errors[0].resourceKey, {
						label: this.$loc.fromResource(prop.resourceKey),
					}
					)
				}

				return ''
			},

			routeTreeChange(routes) {
				var items = [];
				for (var i = 0; i < routes.length; i++) {
					items.push(routes[i])
				}

				this.form.accessKeys.value = items.length > 0 ? items : null;
				this.onHandelChange('accessKeys');
			},


			getValue() {
				if (this.useValidator) {
					if (validator.getObjectErrorLength(this.form) > 0) {
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.form);
				for (var i = 0; i < keys.length; i++) {
					value[keys[i]] = this.form[keys[i]].value
				}

				return value
			},

			async setTreeChecked() {

				const payload = {
					personId: this.form.personId.value,
					personRoleId: this.form.personRoleId.value
				}

				try {
					this.treeLoading = true;
					const service = new AccessAgentService();
					const res = await service.list(payload, { HSR: false });

					if (res.status == 200) {
						this.$refs.RouteTree.cleanTreeChecked()
						this.$refs.RouteTree.setTreeChecked(res.data.accessKeys);

					}

				} catch (e) { console.log() }

				this.treeLoading = false;
			},


			setState(value) {
				this.state = value;
				this.form.personId.value = null
				this.form.personRoleId.value = null

				if (value == 'person') {
					this.form.personId.patterns = ['required']
					this.form.personRoleId.patterns = []
				}

				if (value == 'personRole') {
					this.form.personRoleId.patterns = ['required']
					this.form.personId.patterns = []
				}

				this.$refs.RouteTree.cleanTreeChecked()
			}
		}
	}
</script>
