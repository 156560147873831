<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { FileExtension, FileExtensionFilter } from '@/modules/hub/data/file-extension/list'
	import { FileExtensionService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return FileExtension;
			},
			
			filterEntity()
			{
				return FileExtensionFilter;
			},

			service()
			{
				return new FileExtensionService();
			},

			filterData()
			{
				const fileExtensionId =
					this.$route.query['fileExtensionId'];

				if (fileExtensionId)
				{
					return {
						'fileExtensionId': fileExtensionId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>