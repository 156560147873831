<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { ProductDocType, ProductDocTypeFilter } from '@/modules/shop/data/product-doc-type/list'
	import { ProductDocTypeService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return ProductDocType;
			},
			
			filterEntity()
			{
				return ProductDocTypeFilter;
			},

			service()
			{
				return new ProductDocTypeService();
			},

			filterData()
			{
				const productDocTypeId =
					this.$route.query['productDocTypeId'];

				if (productDocTypeId)
				{
					return {
						'productDocTypeId': productDocTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>