//GeneratorFlag:[NoChange]
export default {
	path: 'access-token',
	name: 'hub.accessToken.layout',
	redirect: to => {
		return {name: 'hub.accessToken.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/access-token"],
		resourceKey: 'hub.views.accessToken.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[AccessTokenGenerate]"
		{
			path: 'generate',
			name: 'hub.accessToken.generate',
			component: () => import('@/modules/hub/views/access-token/access-token-generate.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.accessToken.generate',
				clientAccessKeys:["/hub/access-token/generate"],
				serverAccessKeys:["/hub/access-token/generate"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]

	]
}