<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonCreaditTransactionType, PersonCreaditTransactionTypeFilter } from '@/modules/shop/data/person-creadit-transaction-type/list'
	import { PersonCreaditTransactionTypeService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonCreaditTransactionType;
			},
			
			filterEntity()
			{
				return PersonCreaditTransactionTypeFilter;
			},

			service()
			{
				return new PersonCreaditTransactionTypeService();
			},

			filterData()
			{
				const personCreaditTransactionTypeId =
					this.$route.query['personCreaditTransactionTypeId'];

				if (personCreaditTransactionTypeId)
				{
					return {
						'personCreaditTransactionTypeId': personCreaditTransactionTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>