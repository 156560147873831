<template>
	<kaveh-uploader v-model="value" @change="onHandelChange" id="prop.name" />
</template>
<script>
	export default {
		emits: ['change', 'keyup'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			if (this.prop.value) { this.value = this.prop.value }
		},

		methods: {

			onHandelChange()
			{
				this.$emit('change', this.value)
			},

			onHandelKeyUp()
			{
				this.$emit('keyup', this.value)
			}
		}
	}
</script>
