

// initial state
const state = () => ({
	baseUrl: ''
})

// getters
const getters = {
}

// actions
const actions = {
	setBaseUrl({ commit }, value)
	{
		commit('setBaseUrl', value);
	}
}

// mutations
const mutations =
{
	setBaseUrl(state,baseUrl)
	{
		state.baseUrl = baseUrl;
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
