//GeneratorFlag:[NoChange]
export default {
	path: 'invoice',
	name: 'shop.invoice.layout',
	redirect: to => {
		return {name: 'shop.invoice.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/invoice"],
		resourceKey: 'shop.views.invoice.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[InvoiceCreate]"
		{
			path: 'create',
			name: 'shop.invoice.create',
			component: () => import('@/modules/shop/views/invoice/invoice-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.create',
				clientAccessKeys:["/shop/invoice/create"],
				serverAccessKeys:["/shop/invoice/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCreateImport]"
		{
			path: 'create-import',
			name: 'shop.invoice.createImport',
			component: () => import('@/modules/shop/views/invoice/invoice-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.createImport',
				clientAccessKeys:["/shop/invoice/create-import"],
				serverAccessKeys:["/shop/invoice/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceDelete]"
		{
			path: 'delete',
			name: 'shop.invoice.delete',
			component: () => import('@/modules/shop/views/invoice/invoice-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.delete',
				clientAccessKeys:["/shop/invoice/delete"],
				serverAccessKeys:["/shop/invoice/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceDetail]"
		{
			path: 'detail',
			name: 'shop.invoice.detail',
			component: () => import('@/modules/shop/views/invoice/invoice-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.detail',
				clientAccessKeys:["/shop/invoice/detail"],
				serverAccessKeys:["/shop/invoice/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceList]"
		{
			path: 'list',
			name: 'shop.invoice.list',
			component: () => import('@/modules/shop/views/invoice/invoice-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.list',
				clientAccessKeys:["/shop/invoice/list"],
				serverAccessKeys:["/shop/invoice/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceUpdate]"
		{
			path: 'update',
			name: 'shop.invoice.update',
			component: () => import('@/modules/shop/views/invoice/invoice-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoice.update',
				clientAccessKeys:["/shop/invoice/update"],
				serverAccessKeys:["/shop/invoice/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}