//GeneratorFlag:[NoChange]
export default {
	path: 'file-extension',
	name: 'hub.fileExtension.layout',
	redirect: to => {
		return {name: 'hub.fileExtension.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-sys/file-extension"],
		resourceKey: 'hub.views.fileExtension.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[FileExtensionDetail]"
		{
			path: 'detail',
			name: 'hub.fileExtension.detail',
			component: () => import('@/modules/hub/views/file-extension/file-extension-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.fileExtension.detail',
				clientAccessKeys:["/hub/base-sys/file-extension/detail"],
				serverAccessKeys:["/hub/file-extension/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FileExtensionList]"
		{
			path: 'list',
			name: 'hub.fileExtension.list',
			component: () => import('@/modules/hub/views/file-extension/file-extension-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.fileExtension.list',
				clientAccessKeys:["/hub/base-sys/file-extension/list"],
				serverAccessKeys:["/hub/file-extension/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}