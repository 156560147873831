//GeneratorFlag:[NoChange]
import documentRoute from './document-route'; //GeneratorFlag:[Document]
import walletRoute from './wallet-route'; //GeneratorFlag:[Wallet]
import walletTransactionRoute from './wallet-transaction-route'; //GeneratorFlag:[WalletTransaction]
import walletWithDrawRoute from './wallet-with-draw-route'; //GeneratorFlag:[WalletWithDraw]
import baseInfoDocumentCategoryRoute from './base-info/document-category-route'; //GeneratorFlag:[BaseInfoDocumentCategory]
import baseSysCultureRoute from './base-sys/culture-route'; //GeneratorFlag:[BaseSysCulture]
import baseSysDocumentSubmitTypeRoute from './base-sys/document-submit-type-route'; //GeneratorFlag:[BaseSysDocumentSubmitType]
import baseSysDocumentTypeRoute from './base-sys/document-type-route'; //GeneratorFlag:[BaseSysDocumentType]
import baseSysWalletCurrencyRoute from './base-sys/wallet-currency-route'; //GeneratorFlag:[BaseSysWalletCurrency]
import baseSysWalletTransactionStatuseRoute from './base-sys/wallet-transaction-statuse-route'; //GeneratorFlag:[BaseSysWalletTransactionStatuse]
import baseSysWalletTransactionTypeRoute from './base-sys/wallet-transaction-type-route'; //GeneratorFlag:[BaseSysWalletTransactionType]
import baseSysWalletTypeRoute from './base-sys/wallet-type-route'; //GeneratorFlag:[BaseSysWalletType]
import baseSysWalletWithDrawStatuseRoute from './base-sys/wallet-with-draw-statuse-route'; //GeneratorFlag:[BaseSysWalletWithDrawStatuse]
import adminOperationWalletWithDrawRoute from './admin-operation/wallet-with-draw-route'; //GeneratorFlag:[AdminOperationWalletWithDraw]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'financial',
	name: 'financial',
	component: () => import('@/modules/financial/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/financial'],
		serverAccessKeys:[],
		resourceKey: 'service.financial',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-calculator-fill',
		}
	},
	children: [
documentRoute, walletRoute, walletTransactionRoute, walletWithDrawRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BaseInfoLayout]
		{
			path: 'base-info',
			name: 'financial.baseInfo',
			component: () => import('@/modules/financial/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.layout.baseInfo',
				clientAccessKeys:['/financial/base-info'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			baseInfoDocumentCategoryRoute, //GeneratorFlag:[NewBaseInfoChild]
			]
		},
		//GeneratorFlag:[BaseSysLayout]
		{
			path: 'base-sys',
			name: 'financial.baseSys',
			component: () => import('@/modules/financial/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.layout.baseSys',
				clientAccessKeys:['/financial/base-sys'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-lock',
				}
			},
			children: [
			baseSysCultureRoute, baseSysDocumentSubmitTypeRoute, baseSysDocumentTypeRoute, baseSysWalletCurrencyRoute, baseSysWalletTransactionStatuseRoute, baseSysWalletTransactionTypeRoute, baseSysWalletTypeRoute, baseSysWalletWithDrawStatuseRoute, //GeneratorFlag:[NewBaseSysChild]
			]
		},
		//GeneratorFlag:[AdminOperationLayout]
		{
			path: 'admin-operation',
			name: 'financial.adminOperation',
			component: () => import('@/modules/financial/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'financial.layout.adminOperation',
				clientAccessKeys:['/financial/admin-operation'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			adminOperationWalletWithDrawRoute, //GeneratorFlag:[NewAdminOperationChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
documentRoute, walletRoute, walletTransactionRoute, walletWithDrawRoute, baseInfoDocumentCategoryRoute, baseSysCultureRoute, baseSysDocumentSubmitTypeRoute, baseSysDocumentTypeRoute, baseSysWalletCurrencyRoute, baseSysWalletTransactionStatuseRoute, baseSysWalletTransactionTypeRoute, baseSysWalletTypeRoute, baseSysWalletWithDrawStatuseRoute, adminOperationWalletWithDrawRoute, //GeneratorFlag:[NewExport]
}