<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { ProductStatus, ProductStatusFilter } from '@/modules/shop/data/product-status/list'
	import { ProductStatusService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return ProductStatus;
			},
			
			filterEntity()
			{
				return ProductStatusFilter;
			},

			service()
			{
				return new ProductStatusService();
			},

			filterData()
			{
				const productStatusId =
					this.$route.query['productStatusId'];

				if (productStatusId)
				{
					return {
						'productStatusId': productStatusId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>