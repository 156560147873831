<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content width="800px">
		<div class="custom-form" v-if="props">
			<div class="mb-3 row">
				<label v-once for="userName" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.userName.resourceKey)}}</span>
					<span v-if="isRequired('userName')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.userName.value" @keyup="onHandelKeyUp('userName')" @change="onHandelChange('userName')" dir="ltr" type="text" class="form-control" id="userName" autocomplete="off">
					<small class="text-danger" v-if="props.userName.errors.length > 0">
						{{getFirstError('userName')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="password" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.password.resourceKey)}}</span>
					<span v-if="isRequired('password')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.password.value" @keyup="onHandelKeyUp('password')" @change="onHandelChange('password')" dir="ltr" type="text" class="form-control" id="password" autocomplete="off">
					<small class="text-danger" v-if="props.password.errors.length > 0">
						{{getFirstError('password')}}
					</small>
				</div>
			</div>


		</div>
		<kaveh-button :is-loading="isLoading" @click="submit" class="btn btn-dark">
			{{$loc.fromResource('action.save')}}
		</kaveh-button>
	</kaveh-content>
</template>
<script>
	import validator from '@/core/validator'
	import { PersonAccess } from '@/modules/people-net/data/person-access/generate'
	import { PersonAccessService } from '@/modules/people-net/services'
	export default {

		data()
		{
			return {
				props: null,
				isLoading: false,
				useValidator: true,
	

			}
		},

		async beforeMount()
		{
			this.props = new PersonAccess().props;

		},

		methods:
		{
			async initList(entityName,propName)
			{
				try {
					this[`${entityName}ListIsLoading`] = true;
					const res = await this.props[propName].data({start:0, length:1000});

					if(res.status == 200){
						this[`${entityName}List`] = res.data.items;
					}

				} catch (e) {
					console.log(e)
				}


				this[`${entityName}ListIsLoading`] = false;
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey,{
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.props) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					const prop = this.props[key];

					value[key] =
						prop.setConfig.inputType == 'checkdrop' ?
							prop.value == 'true' : prop.value
				}

				return value
			},

			async submit()
			{
				try
				{
					const payload = this.getValue();

					if (payload)
					{
						this.isLoading = true;
						const service = new PersonAccessService();
						const res = await service.generate(payload);

						if (res.status == 200)
						{
							this.props = new PersonAccess().props;
						}
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			}
		}
	}
</script>