<template>
	<ul :class="{'kaveh-tree': isRoot }" :style="{'height': (isRoot ? height:'auto' )}">
		<slot></slot>
	</ul>
</template>
<script>
	export default {
		props: {
			isRoot: {
				type: Boolean,
				default: false
			},
			height: {
				type: String,
				default: "200px"
			},
		},
	}
</script>
<style>
	.kaveh-tree {
		list-style: none;
		overflow: auto;
		user-select: none;
		padding: 0 30px 0 0;
		padding-right: 20px;
	}

		.kaveh-tree ul {
			list-style: none;
			position: relative;
			padding-right: 20px;
		}

			.kaveh-tree ul::before {
				content: "";
				position: absolute;
				top: -5px;
				right: 5px;
				width: 1px;
				height: calc(100% + 10px);
				border-right: var(--kaveh-base-color-dark) dotted 1px;
			}


			.kaveh-tree ul li {
				min-height: 30px;
				position: relative;
			}
				.kaveh-tree ul li::before {
					content: "";
					position: absolute;
					top: 12px;
					right: -13px;
					height: 1px;
					width: 12px;
					border-bottom: var(--kaveh-base-color-dark) dotted 1px;
				}


			.kaveh-tree .node .meta {
				width: 100%;
				padding:5px 0px;
				position:relative;
			}

				.kaveh-tree .node .meta .arrow {
					width: 14px;
					height: 14px;
					line-height:14px;
					right: -20px;
					top:5px;
					position: absolute;
					background:#ddd;
					border-radius: 100%;
					z-index:1;
				}
</style>
