<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content width="800px">
		<kaveh-form :key="ver" :entity="entity" :task="submit" />
	</kaveh-content>
</template>
<script>
	import { Country } from '@/modules/people-net/data/country/create'
	import { CountryService } from '@/modules/people-net/services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed: 
		{
			entity()
			{
				return Country
			},
			service()
			{
				return new CountryService()
			},
		},
		methods:
		{
			async submit(payload)
			{
				try
				{
					const res = await this.service.create(payload);
					if (res.status == 200)
					{
						this.ver += 1
					}

				} catch (e) { console.log(e) }
			}
		}
	}
</script>