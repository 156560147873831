//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
export default class BaseCountry extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'country';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'hub.provinceUpdate.self';
	props = {
		countryId: {
			primarykey: true,
			name: 'countryId',
			resourceKey: 'hub.provinceUpdate.country.countryId',
			type: ENUMS.PROP_TYPE.INT16,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: false,  
				textKey: '',
				valueKey: '',
				isUnicode: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		nativeName: {
			name: 'nativeName',
			resourceKey: 'hub.provinceUpdate.country.nativeName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		capitalName: {
			name: 'capitalName',
			resourceKey: 'hub.provinceUpdate.country.capitalName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 50,
				patterns: [],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'hub.provinceUpdate.country.caption',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 100,
				patterns: [],
			},
			errors: [],
			value: null
		},
		code: {
			name: 'code',
			resourceKey: 'hub.provinceUpdate.country.code',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: '',
				valueKey: '',
				stringLength: 2,
				isUnicode: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		uniquekey: { 
			name: 'uniquekey',
			resourceKey: 'data.uniquekey',
			type: ENUMS.PROP_TYPE.STRING,
			dir: ENUMS.DIRECTION_TYPE.RTL,
			display: {
				isVisible: false,
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				dir: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: {
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,
				canUseInExcel: true,
				patterns: [],
			}
		}
	};
}