<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content :loading="loading" width="800px">
		<kaveh-list v-if="temp" :entity="entity" :data="temp" />
	</kaveh-content>
</template>
<script>
	import { PersonCreaditTransactionType } from '@/modules/shop/data/person-creadit-transaction-type/detail'
	import { PersonCreaditTransactionTypeService } from '@/modules/shop/services'
	export default {
		data()
		{
			return {
				temp: null,
				loading: true
			}
		},
		computed:
		{
			entity()
			{
				return PersonCreaditTransactionType
			},

			service()
			{
				return new PersonCreaditTransactionTypeService()
			},
		},
		async beforeMount()
		{
			await this.init()
		},
		methods:
		{
			async init()
			{
				this.temp = await this.getDataFromService();
				if (this.temp == undefined)
				{
					this.$router.go(-1)
				}
			},

			async getDataFromService()
			{
				var result;
				const temp = await this.getDataFromTemp();

				if (temp)
				{
					try
					{
						this.loading = true;
						const payload = {
							'personCreaditTransactionTypeId': temp['personCreaditTransactionTypeId']
						};
						const res = await this.service.detail(payload)
						result = res.data

					} catch (e) { console.log(e) }
				}

				this.loading = false;
				return result;
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp = await store.dispatch('temp/findByQuery', router.query);

				return temp ? temp.value : undefined;
			}
		}
	}
</script>