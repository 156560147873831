<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { WalletTransactionType, WalletTransactionTypeFilter } from '@/modules/financial/data/wallet-transaction-type/list'
	import { WalletTransactionTypeService } from '@/modules/financial/services'
	export default {
		computed:
		{
			entity()
			{
				return WalletTransactionType;
			},
			
			filterEntity()
			{
				return WalletTransactionTypeFilter;
			},

			service()
			{
				return new WalletTransactionTypeService();
			},

			filterData()
			{
				const walletTransactionTypeId =
					this.$route.query['walletTransactionTypeId'];

				if (walletTransactionTypeId)
				{
					return {
						'walletTransactionTypeId': walletTransactionTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>