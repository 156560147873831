
<template>
	<div>
		{{$loc.fromResource(form.caption.resourceKey)}}
		<div v-if="isShowDetail">
			<button @click="goBack" class="btn btn-dark" style="margin-bottom:10px">
				Back
			</button>

			<div v-if="!isLoading">
				<div class="card" v-for="(item,index) in detailList" :key="index" style="margin-top:10px;margin-bottom:10px">
					<h5 class="card-header">{{item.creator}}</h5>
					<div class="card-body">
						<span v-html="item.caption"></span>
					</div>
				</div>
			</div>
			<div v-else> loading...</div>

			<hr style="font-size:revert" />
			<div class="card" v-if="!isSentResponse">
				<h3 class="card-header">Send New Reply</h3>
				<div class="card-body">
					<div class="mb-3 row">
						<label v-once for="caption" class="col-4 col-md-3">
							<span>{{$loc.fromResource(form.caption.resourceKey)}}</span>
							<span v-if="isRequired('caption')" class="text-danger"> * </span>
						</label>
						<div class="col-8 col-md-9">
							<kaveh-text-editor v-model="caption2" dir="ltr" id="caption2" />

						</div>
					</div>
					<button :is-loading="isLoading" @click="submitResponse" class="btn btn-continue">
						<span v-if="!isLoading">Send</span>
						<span v-else> loading... </span>
					</button>
				</div>
			</div>








		</div>
		<div class="row" v-if="isCreateMode">
			<div class="custom-form" v-if="form">

				<div class="mb-3 row">
					<label v-once for="title" class="col-4 col-md-3">
						<span>{{$loc.fromResource(form.title.resourceKey)}}</span>
						<span v-if="isRequired('title')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<input v-model="form.title.value" @keyup="onHandelKeyUp('title')" @change="onHandelChange('title')" dir="ltr" type="text" class="form-control" id="title" autocomplete="off">
						<small class="text-danger" v-if="form.title.errors.length > 0">
							{{getFirstError('title')}}
						</small>
					</div>
				</div>

				<div class="mb-3 row">
					<label v-once for="caption" class="col-4 col-md-3">
						<span>{{$loc.fromResource(form.caption.resourceKey)}}</span>
						<span v-if="isRequired('caption')" class="text-danger"> * </span>
					</label>
					<div class="col-8 col-md-9">
						<kaveh-text-editor v-model="form.caption.value" @keyup="onHandelKeyUp('caption')" @change="onHandelChange('caption')" dir="ltr" id="caption" />
						<small class="text-danger" v-if="form.caption.errors.length > 0">
							{{getFirstError('caption')}}
						</small>
					</div>

				</div>


			</div>

			<button class="btn-continue" @click="submit">
				<div v-if="!isLoading">Save</div>
				<div v-else> loading ...</div>
			</button>



			<button @click="CancelNewTicket" class="btn btn-dark">
				Cancel
			</button>
		</div>

	</div>

	<div v-if="!isCreateMode && !isShowDetail">
		<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
	</div>
</template>
<script>
	import { Ticket, TicketFilter } from '@/modules/ticketing/data/ticket/admin-list'
	import { TicketService } from '@/modules/ticketing/services'
	import { TicketItemService } from '@/modules/ticketing/services'
	
	import { ENUMS } from '@/core/data'
	export default {

		data() {
			return {
				isSentResponse: false,
				ticketId: null,
				isShowDetail: false,
				dt: null,
				caption2: null,
				detailList: null,
				isLoading: false,
				isCreateMode: false,
				form: {
					personInfo: {
						name: 'personInfo',
						resourceKey: 'ticketing.ticketSend.personInfo',
						type: ENUMS.PROP_TYPE.STRING,
						display: {
							isVisible: true,
							displayType: ENUMS.DISPLAY_TYPE.STRING,
							direction: ENUMS.DIRECTION_TYPE.LTR,
						},
						setConfig: {
							inputType: ENUMS.INPUT_TYPE.TEXT,
							canUseInForm: true,
							canUseInExcel: true,
							isUnicode: false,
							isUnique: false,
							patterns: [],
						},
						errors: [],
						value: null
					},
					email: {
						name: 'email',
						resourceKey: 'ticketing.ticketSend.email',
						type: ENUMS.PROP_TYPE.STRING,
						display: {
							isVisible: true,
							displayType: ENUMS.DISPLAY_TYPE.STRING,
							direction: ENUMS.DIRECTION_TYPE.LTR,
						},
						setConfig: {
							inputType: ENUMS.INPUT_TYPE.TEXT,
							canUseInForm: true,
							canUseInExcel: true,
							isUnicode: false,
							isUnique: false,
							patterns: [],
						},
						errors: [],
						value: null
					},
					title: {
						name: 'title',
						resourceKey: 'ticketing.ticketSend.title',
						type: ENUMS.PROP_TYPE.STRING,
						display: {
							isVisible: true,
							displayType: ENUMS.DISPLAY_TYPE.STRING,
							direction: ENUMS.DIRECTION_TYPE.ltr,
						},
						setConfig: {
							inputType: ENUMS.INPUT_TYPE.TEXT,
							canUseInForm: true,
							canUseInExcel: true,
							isUnicode: false,
							isUnique: false,
							patterns: ['required'],
						},
						errors: [],
						value: null
					},
					caption: {
						name: 'caption',
						resourceKey: 'ticketing.ticketSend.caption',
						type: ENUMS.PROP_TYPE.STRING,
						display: {
							isVisible: true,
							displayType: ENUMS.DISPLAY_TYPE.STRING,
							direction: ENUMS.DIRECTION_TYPE.ltr,
						},
						setConfig: {
							inputType: ENUMS.INPUT_TYPE.TEXT_EDITOR,
							canUseInForm: true,
							canUseInExcel: true,
							isUnicode: false,
							isUnique: false,
							patterns: ['required'],
						},
						errors: [],
						value: null
					},
				}
			}
		},
		computed:
		{

			entity() {
				return Ticket;
			},

			filterEntity() {
				return TicketFilter;
			},

			service() {
				return new TicketService();
			},

			actions() {
				return [
					{

						task: this.showDetail,
						resourceKey: 'action.detail',
						iconName: 'bi-plus-circle',
						activeMode: 'single',
					}
				]
			},
		},
		methods:
		{
			goBack() { this.isShowDetail = false; this.ticketId = null; },
			async submitResponse() {
				try {

					if (this.isLoading)
						return;
					const payload = {
						"ticketId": this.ticketId,
						"message": this.caption2
					};
					if (payload) {
						this.isLoading = true;
						const service = new TicketItemService();
						const res = await service.adminResponse(payload);

						if (res.status == 200) {
							await this.showDetail(null);
							this.isSentResponse = true;
							//await this.showDetail([{ ticketId: this.ticketId }]);
							this.caption2 = "";
							this.isLoading = false;
						}
						else {
							// this.isLoading = false;
						}
					}

				} catch (e) { console.log(e) }


			},
			async showDetail(input) {
				try {
					console.log('hi')
					if (input != null && input != undefined) {
						if (input[0].ticketId) {
							this.ticketId = input[0].ticketId;
						}
					}
					this.isSentResponse = false;
					this.isLoading = true;
					this.isShowDetail = true;
					const myService = new TicketService();
					//this.ticketId = input[0].ticketId;
					var payload = { "filter": { "ticketId": this.ticketId } };
					const res = await myService.getItems(payload);
					if (res.status == 200) {
						this.detailList = res.data.items;
						this.isLoading = false;
					}


				} catch (e) {
					console.log(e);
					this.isLoading = false;
				}
			},
			isRequired(name) {
				return this.form[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name) {
				if (this.useValidator) {
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}

				this.form[name].isChanged = true;
			},

			onHandelKeyUp(name) {
				if (this.useValidator && this.form[name].isChanged) {
					this.form[name].errors =
						validator.getPropErrors(this.form[name]);
				}
			},

			getFirstError(name) {
				const prop = this.form[name];

				if (prop.errors.length > 0) {
					return this.$loc.fromResource(prop.errors[0].resourceKey, {
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},
			createNew() {
				this.isCreateMode = true;
				this.ticketId = null;
			},
			async getList(payload) {
				try {
					return await this.service.myList(payload);
				} catch (e) { console.log(e) }

			},
			async getList(payload) {
				try {
					return await this.service.adminList(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>
