//GeneratorFlag:[NoChange]
export default {
	path: 'invoice-statuse',
	name: 'shop.baseSys.invoiceStatuse.layout',
	redirect: to => {
		return {name: 'shop.invoiceStatuse.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/invoice-statuse"],
		resourceKey: 'shop.views.invoiceStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[InvoiceStatuseDetail]"
		{
			path: 'detail',
			name: 'shop.invoiceStatuse.detail',
			component: () => import('@/modules/shop/views/invoice-statuse/invoice-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceStatuse.detail',
				clientAccessKeys:["/shop/base-sys/invoice-statuse/detail"],
				serverAccessKeys:["/shop/invoice-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceStatuseList]"
		{
			path: 'list',
			name: 'shop.invoiceStatuse.list',
			component: () => import('@/modules/shop/views/invoice-statuse/invoice-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceStatuse.list',
				clientAccessKeys:["/shop/base-sys/invoice-statuse/list"],
				serverAccessKeys:["/shop/invoice-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}