//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { PersonAppPurchaseStatuse, PersonAppPurchaseType } from '@/modules/people-net/data/person-app-purchase/list'; 
import { PersonAppPurchaseStatuseService, PersonAppPurchaseTypeService } from '@/modules/people-net/services'; 
export default class PersonAppPurchaseFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'personAppPurchaseFilter';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'global.filters';
	props = {
		personAppPurchaseStatuseId: {
			foreignKey: true,
			name: 'personAppPurchaseStatuseId',
			resourceKey: 'peopleNet.personAppPurchaseList.personAppPurchaseStatuseId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new PersonAppPurchaseStatuseService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personAppPurchaseStatuseId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		factorNumber: {
			name: 'factorNumber',
			resourceKey: 'peopleNet.personAppPurchaseList.factorNumber',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 100,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personAppPurchaseTypeId: {
			foreignKey: true,
			name: 'personAppPurchaseTypeId',
			resourceKey: 'peopleNet.personAppPurchaseList.personAppPurchaseTypeId',
			type: ENUMS.PROP_TYPE.BYTE,
			data: async (params) =>
			{
				const service =
					new PersonAppPurchaseTypeService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.SELECT,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personAppPurchaseTypeId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		displayExpireDate: {
			name: 'displayExpireDate',
			resourceKey: 'peopleNet.personAppPurchaseList.displayExpireDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: true,  
				canUseInExcel: true,  
				stringLength: 50,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}