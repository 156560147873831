//GeneratorFlag:[NoChange]
export default {
	path: 'person-role',
	name: 'hub.personRole.layout',
	redirect: to => {
		return {name: 'hub.personRole.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-info/person-role"],
		resourceKey: 'hub.views.personRole.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonRoleCreate]"
		{
			path: 'create',
			name: 'hub.personRole.create',
			component: () => import('@/modules/hub/views/person-role/person-role-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personRole.create',
				clientAccessKeys:["/hub/base-info/person-role/create"],
				serverAccessKeys:["/hub/person-role/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleImport]"
		{
			path: 'import',
			name: 'hub.personRole.import',
			component: () => import('@/modules/hub/views/person-role/person-role-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personRole.import',
				clientAccessKeys:["/hub/base-info/person-role/import"],
				serverAccessKeys:["/hub/person-role/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleDelete]"
		{
			path: 'delete',
			name: 'hub.personRole.delete',
			component: () => import('@/modules/hub/views/person-role/person-role-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personRole.delete',
				clientAccessKeys:["/hub/base-info/person-role/delete"],
				serverAccessKeys:["/hub/person-role/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleDetail]"
		{
			path: 'detail',
			name: 'hub.personRole.detail',
			component: () => import('@/modules/hub/views/person-role/person-role-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personRole.detail',
				clientAccessKeys:["/hub/base-info/person-role/detail"],
				serverAccessKeys:["/hub/person-role/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleList]"
		{
			path: 'list',
			name: 'hub.personRole.list',
			component: () => import('@/modules/hub/views/person-role/person-role-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personRole.list',
				clientAccessKeys:["/hub/base-info/person-role/list"],
				serverAccessKeys:["/hub/person-role/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRoleUpdate]"
		{
			path: 'update',
			name: 'hub.personRole.update',
			component: () => import('@/modules/hub/views/person-role/person-role-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personRole.update',
				clientAccessKeys:["/hub/base-info/person-role/update"],
				serverAccessKeys:["/hub/person-role/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}