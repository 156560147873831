//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"hub",
	"ext": ext,
	"layout":{
		"notice": "Notice Information", //GeneratorFlag:[NoticeLayout]
		"geographical": "Geographical Information", //GeneratorFlag:[GeographicalLayout]
		"baseInfo": "Basic Information", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "System information", //GeneratorFlag:[BaseSysLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		'noticeConfig': {
			'layout': 'NoticeConfig',
			'create': 'Create New NoticeConfig', //GeneratorFlag:[Views.NoticeConfig.Create]
			'createImport': 'Imports NoticeConfig From Excel', //GeneratorFlag:[Views.NoticeConfig.CreateImport] 
 			'delete': 'Delete NoticeConfig', //GeneratorFlag:[Views.NoticeConfig.Delete] 
 			'detail': 'Detail of NoticeConfig', //GeneratorFlag:[Views.NoticeConfig.Detail] 
 			'list': 'List of NoticeConfig', //GeneratorFlag:[Views.NoticeConfig.List] 
 			'update': 'Edit NoticeConfig', //GeneratorFlag:[Views.NoticeConfig.Update] 
 			'noticeConfigIppanelDetail': 'Detail of NoticeConfigIppanel', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigIppanelDetail] 
 			'noticeConfigIppanelUpdate': 'Edit NoticeConfigIppanel', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigIppanelUpdate] 
 			'noticeConfigKavenegarDetail': 'Detail of NoticeConfigKavenegar', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigKavenegarDetail] 
 			'noticeConfigKavenegarUpdate': 'Edit NoticeConfigKavenegar', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigKavenegarUpdate] 
 //GeneratorFlag:[Views.NoticeConfig]
		},
		'person': {
			'layout': 'Person',
			'create': 'Create New Person', //GeneratorFlag:[Views.Person.Create]
			'createImport': 'Imports Person From Excel', //GeneratorFlag:[Views.Person.CreateImport] 
 			'delete': 'Delete Person', //GeneratorFlag:[Views.Person.Delete] 
 			'detail': 'Detail of Person', //GeneratorFlag:[Views.Person.Detail] 
 			'list': 'List of Person', //GeneratorFlag:[Views.Person.List] 
 			'update': 'Edit Person', //GeneratorFlag:[Views.Person.Update] 
 			'personAccessDetail': 'Detail of PersonAccess', //GeneratorFlag:[Views.Person.PersonAccessDetail] 
 			'personAccessUpdate': 'Edit PersonAccess', //GeneratorFlag:[Views.Person.PersonAccessUpdate] 
 			'personLegalDetail': 'Detail of PersonLegal', //GeneratorFlag:[Views.Person.PersonLegalDetail] 
 			'personLegalUpdate': 'Edit PersonLegal', //GeneratorFlag:[Views.Person.PersonLegalUpdate] 
 			'personRealDetail': 'Detail of PersonReal', //GeneratorFlag:[Views.Person.PersonRealDetail] 
 			'personRealUpdate': 'Edit PersonReal', //GeneratorFlag:[Views.Person.PersonRealUpdate] 
 //GeneratorFlag:[Views.Person]
		},
		'city': {
			'layout': 'Create City',
			'create': 'Create New City', //GeneratorFlag:[Views.City.Create]
			'createImport': 'Imports City From Excel', //GeneratorFlag:[Views.City.CreateImport] 
 			'delete': 'Delete City', //GeneratorFlag:[Views.City.Delete] 
 			'detail': 'Detail of City', //GeneratorFlag:[Views.City.Detail] 
 			'list': 'List of City', //GeneratorFlag:[Views.City.List] 
 			'update': 'Edit City', //GeneratorFlag:[Views.City.Update] 
 //GeneratorFlag:[Views.City]
		},
		'country': {
			'layout': 'Create Country',
			'create': 'Create New Country', //GeneratorFlag:[Views.Country.Create]
			'createImport': 'Imports Country From Excel', //GeneratorFlag:[Views.Country.CreateImport] 
 			'delete': 'Delete Country', //GeneratorFlag:[Views.Country.Delete] 
 			'detail': 'Detail of Country', //GeneratorFlag:[Views.Country.Detail] 
 			'list': 'List of Country', //GeneratorFlag:[Views.Country.List] 
 			'update': 'Edit Country', //GeneratorFlag:[Views.Country.Update] 
 //GeneratorFlag:[Views.Country]
		},
		'personMilitary': {
			'layout': 'Create PersonMilitary',
			'create': 'Create New PersonMilitary', //GeneratorFlag:[Views.PersonMilitary.Create]
			'createImport': 'Imports PersonMilitary From Excel', //GeneratorFlag:[Views.PersonMilitary.CreateImport] 
 			'delete': 'Delete PersonMilitary', //GeneratorFlag:[Views.PersonMilitary.Delete] 
 			'detail': 'Detail of PersonMilitary', //GeneratorFlag:[Views.PersonMilitary.Detail] 
 			'list': 'List of PersonMilitary', //GeneratorFlag:[Views.PersonMilitary.List] 
 			'update': 'Edit PersonMilitary', //GeneratorFlag:[Views.PersonMilitary.Update] 
 //GeneratorFlag:[Views.PersonMilitary]
		},
		'personNationality': {
			'layout': 'Create PersonNationality',
			'create': 'Create New PersonNationality', //GeneratorFlag:[Views.PersonNationality.Create]
			'createImport': 'Imports PersonNationality From Excel', //GeneratorFlag:[Views.PersonNationality.CreateImport] 
 			'delete': 'Delete PersonNationality', //GeneratorFlag:[Views.PersonNationality.Delete] 
 			'detail': 'Detail of PersonNationality', //GeneratorFlag:[Views.PersonNationality.Detail] 
 			'list': 'List of PersonNationality', //GeneratorFlag:[Views.PersonNationality.List] 
 			'update': 'Edit PersonNationality', //GeneratorFlag:[Views.PersonNationality.Update] 
 //GeneratorFlag:[Views.PersonNationality]
		},
		'personRole': {
			'layout': 'Create PersonRole',
			'create': 'Create New PersonRole', //GeneratorFlag:[Views.PersonRole.Create]
			'createImport': 'Imports PersonRole From Excel', //GeneratorFlag:[Views.PersonRole.CreateImport] 
 			'delete': 'Delete PersonRole', //GeneratorFlag:[Views.PersonRole.Delete] 
 			'detail': 'Detail of PersonRole', //GeneratorFlag:[Views.PersonRole.Detail] 
 			'list': 'List of PersonRole', //GeneratorFlag:[Views.PersonRole.List] 
 			'update': 'Edit PersonRole', //GeneratorFlag:[Views.PersonRole.Update] 
 //GeneratorFlag:[Views.PersonRole]
		},
		'province': {
			'layout': 'Create Province',
			'create': 'Create New Province', //GeneratorFlag:[Views.Province.Create]
			'createImport': 'Imports Province From Excel', //GeneratorFlag:[Views.Province.CreateImport] 
 			'delete': 'Delete Province', //GeneratorFlag:[Views.Province.Delete] 
 			'detail': 'Detail of Province', //GeneratorFlag:[Views.Province.Detail] 
 			'list': 'List of Province', //GeneratorFlag:[Views.Province.List] 
 			'update': 'Edit Province', //GeneratorFlag:[Views.Province.Update] 
 //GeneratorFlag:[Views.Province]
		},
		'region': {
			'layout': 'Create Region',
			'create': 'Create New Region', //GeneratorFlag:[Views.Region.Create]
			'createImport': 'Imports Region From Excel', //GeneratorFlag:[Views.Region.CreateImport] 
 			'delete': 'Delete Region', //GeneratorFlag:[Views.Region.Delete] 
 			'detail': 'Detail of Region', //GeneratorFlag:[Views.Region.Detail] 
 			'list': 'List of Region', //GeneratorFlag:[Views.Region.List] 
 			'update': 'Edit Region', //GeneratorFlag:[Views.Region.Update] 
 //GeneratorFlag:[Views.Region]
		},
		'fileExtension': {
			'layout': 'FileExtension',
			'detail': 'Detail of FileExtension', //GeneratorFlag:[Views.FileExtension.Detail]
			'list': 'List of FileExtension', //GeneratorFlag:[Views.FileExtension.List] 
 //GeneratorFlag:[Views.FileExtension]
		},
		'noticeProvider': {
			'layout': 'NoticeProvider',
			'detail': 'Detail of NoticeProvider', //GeneratorFlag:[Views.NoticeProvider.Detail]
			'list': 'List of NoticeProvider', //GeneratorFlag:[Views.NoticeProvider.List] 
 //GeneratorFlag:[Views.NoticeProvider]
		},
		'noticeStatus': {
			'layout': 'NoticeStatus',
			'detail': 'Detail of NoticeStatus', //GeneratorFlag:[Views.NoticeStatus.Detail]
			'list': 'List of NoticeStatus', //GeneratorFlag:[Views.NoticeStatus.List] 
 //GeneratorFlag:[Views.NoticeStatus]
		},
		'noticeType': {
			'layout': 'NoticeType',
			'detail': 'Detail of NoticeType', //GeneratorFlag:[Views.NoticeType.Detail]
			'list': 'List of NoticeType', //GeneratorFlag:[Views.NoticeType.List] 
 //GeneratorFlag:[Views.NoticeType]
		},
		'personEntity': {
			'layout': 'PersonEntity',
			'detail': 'Detail of PersonEntity', //GeneratorFlag:[Views.PersonEntity.Detail]
			'list': 'List of PersonEntity', //GeneratorFlag:[Views.PersonEntity.List] 
 //GeneratorFlag:[Views.PersonEntity]
		},
		'personGender': {
			'layout': 'PersonGender',
			'detail': 'Detail of PersonGender', //GeneratorFlag:[Views.PersonGender.Detail]
			'list': 'List of PersonGender', //GeneratorFlag:[Views.PersonGender.List] 
 //GeneratorFlag:[Views.PersonGender]
		},
		'personGrade': {
			'layout': 'PersonGrade',
			'detail': 'Detail of PersonGrade', //GeneratorFlag:[Views.PersonGrade.Detail]
			'list': 'List of PersonGrade', //GeneratorFlag:[Views.PersonGrade.List] 
 //GeneratorFlag:[Views.PersonGrade]
		},
		'personMarital': {
			'layout': 'PersonMarital',
			'detail': 'Detail of PersonMarital', //GeneratorFlag:[Views.PersonMarital.Detail]
			'list': 'List of PersonMarital', //GeneratorFlag:[Views.PersonMarital.List] 
 //GeneratorFlag:[Views.PersonMarital]
		},
		'accessAgent': {
			'layout': 'Access Agent',
			'save': 'Access Agent', //GeneratorFlag:[Views.AccessAgent.Save]
//GeneratorFlag:[Views.AccessAgent]
		},
		'accessToken': {
			'layout': 'Token generate',
			'generate': 'Token generate', //GeneratorFlag:[Views.AccessToken.Generate]
//GeneratorFlag:[Views.AccessToken]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[NoticeConfig.Create] {
	'noticeConfigCreate': {
		'self': 'noticeConfig',
		"noticeConfigId": "notice config id",
		"title": "title",
		"module": "module",
		"noticeKey": "notice key",
		"propertyOne": "property one",
		"propertyTwo": "property two",
		"propertyThree": "property three",
		"propertyFour": "property four",
		"propertyFive": "property five",
		"propertySix": "property six",
		"noticeTypeId": "notice type",
		"noticeProviderId": "notice prover",
		"identifier": "identifier",
		'noticeConfigIppanel': {
			'self': 'notice config ippanel',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'notice config kavenegar',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
	},
	//GeneratorFlag:[NoticeConfig.Delete] {
	'noticeConfigDelete': {
		'self': 'noticeConfig',
		"noticeConfigId": "notice config id",
	},
	//GeneratorFlag:[NoticeConfig.Detail] {
	'noticeConfigDetail': {
		'self': 'noticeConfig',
		"noticeConfigId": "notice config id",
		"title": "title",
		"module": "module",
		"noticeKey": "notice key",
		"propertyOne": "property one",
		"propertyTwo": "property two",
		"propertyThree": "property three",
		"propertyFour": "property four",
		"propertyFive": "property five",
		"propertySix": "property six",
		"noticeTypeId": "notice type",
		"noticeProviderId": "notice prover",
		'noticeProvider': {
			'self': 'notice provider',
			"noticeProviderId": "notice provider id",
			"title": "عنوان",
			"caption": "عنوان",
		},
		'noticeType': {
			'self': 'notice type',
			"noticeTypeId": "notice type id",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'noticeConfigIppanel': {
			'self': 'notice config ippanel',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'notice config kavenegar',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
		"noticeConfigLogr": "notice config logr",
	},
	//GeneratorFlag:[NoticeConfig.List] {
	'noticeConfigList': {
		'self': 'noticeConfig',
		"noticeConfigId": "notice config id",
		"title": "title",
		"module": "module",
		"noticeKey": "notice key",
		"propertyOne": "property one",
		"propertyTwo": "property two",
		"propertyThree": "property three",
		"propertyFour": "property four",
		"propertyFive": "property five",
		"propertySix": "property six",
		"noticeTypeId": "notice type",
		"noticeProviderId": "notice prover",
		'noticeProvider': {
			'self': 'notice provider',
			"noticeProviderId": "notice provider id",
			"title": "عنوان",
			"caption": "عنوان",
		},
		'noticeType': {
			'self': 'notice type',
			"noticeTypeId": "notice type id",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'noticeConfigIppanel': {
			'self': 'notice config ippanel',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'notice config kavenegar',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
		"noticeConfigLogr": "notice config logr",
	},
	//GeneratorFlag:[NoticeConfig.Filter] {
	'noticeConfigFilter': {
		'self': 'noticeConfig',
		"noticeConfigId": "notice config id",
		"title": "title",
		"module": "module",
		"noticeKey": "notice key",
		"propertyOne": "property one",
		"propertyTwo": "property two",
		"propertyThree": "property three",
		"propertyFour": "property four",
		"propertyFive": "property five",
		"propertySix": "property six",
		"noticeTypeId": "notice type",
		"noticeProviderId": "notice prover",
		'noticeProvider': {
			'self': 'notice provider',
			"noticeProviderId": "notice provider id",
			"title": "عنوان",
			"caption": "عنوان",
		},
		'noticeType': {
			'self': 'notice type',
			"noticeTypeId": "notice type id",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'noticeConfigIppanel': {
			'self': 'notice config ippanel',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'notice config kavenegar',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
		"noticeConfigLogr": "notice config logr",
	},
	//GeneratorFlag:[NoticeConfig.Update] {
	'noticeConfigUpdate': {
		'self': 'noticeConfig',
		"noticeConfigId": "notice config id",
		"title": "title",
		"module": "module",
		"noticeKey": "notice key",
		"propertyOne": "property one",
		"propertyTwo": "property two",
		"propertyThree": "property three",
		"propertyFour": "property four",
		"propertyFive": "property five",
		"propertySix": "property six",
		"noticeTypeId": "notice type",
		"noticeProviderId": "notice prover",
		"identifier": "identifier",
		'noticeConfigIppanel': {
			'self': 'notice config ippanel',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'notice config kavenegar',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
	},
	//GeneratorFlag:[Person.Create] {
	'personCreate': {
		'self': 'person',
		"personId": "person id",
		"personName": "person name",
		"personEntityId": "person entity",
		"personRoleId": "person role",
		"cityId": "city",
		"identifier": "identifier",
		'personAccess': {
			'self': 'person access',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'person legal',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		'personReal': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.Delete] {
	'personDelete': {
		'self': 'person',
		"personId": "person id",
	},
	//GeneratorFlag:[Person.Detail] {
	'personDetail': {
		'self': 'person',
		"personId": "person id",
		"personName": "person name",
		"personEntityId": "person entity",
		"personRoleId": "person role",
		"cityId": "city",
		'city': {
			'self': 'city',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		'personEntity': {
			'self': 'person entity',
			"personEntityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personRole': {
			'self': 'person role',
			"personRoleId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personAccess': {
			'self': 'person access',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'person legal',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		"personLogr": "person logr",
		'personReal': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.List] {
	'personList': {
		'self': 'person',
		"personId": "person id",
		"personName": "person name",
		"personEntityId": "person entity",
		"personRoleId": "person role",
		"cityId": "city",
		'city': {
			'self': 'city',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		'personEntity': {
			'self': 'person entity',
			"personEntityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personRole': {
			'self': 'person role',
			"personRoleId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personAccess': {
			'self': 'person access',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'person legal',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		"personLogr": "person logr",
		'personReal': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.Filter] {
	'personFilter': {
		'self': 'person',
		"personId": "person id",
		"personName": "person name",
		"personEntityId": "person entity",
		"personRoleId": "person role",
		"cityId": "city",
		'city': {
			'self': 'city',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		'personEntity': {
			'self': 'person entity',
			"personEntityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personRole': {
			'self': 'person role',
			"personRoleId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personAccess': {
			'self': 'person access',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'person legal',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		"personLogr": "person logr",
		'personReal': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.Update] {
	'personUpdate': {
		'self': 'person',
		"personId": "person id",
		"personName": "person name",
		"personEntityId": "person entity",
		"personRoleId": "person role",
		"cityId": "city",
		"identifier": "identifier",
		'personAccess': {
			'self': 'person access',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'person legal',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		'personReal': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[City.Create] {
	'cityCreate': {
		'self': 'city',
		"cityId": "city id",
		"nativeName": "native name",
		"caption": "caption",
		"provinceId": "province",
		"identifier": "identifier",
	},
	//GeneratorFlag:[City.Delete] {
	'cityDelete': {
		'self': 'city',
		"cityId": "city id",
	},
	//GeneratorFlag:[City.Detail] {
	'cityDetail': {
		'self': 'city',
		"cityId": "city id",
		"nativeName": "native name",
		"caption": "caption",
		"provinceId": "province",
		'province': {
			'self': 'province',
			"provinceId": "شناسه",
			"nativeName": "نام استان",
			"caption": "توضیحات",
			"countryId": "کشور",
		},
		"cityLogr": "city logr",
	},
	//GeneratorFlag:[City.List] {
	'cityList': {
		'self': 'city',
		"cityId": "city id",
		"nativeName": "native name",
		"caption": "caption",
		"provinceId": "province",
		'province': {
			'self': 'province',
			"provinceId": "شناسه",
			"nativeName": "نام استان",
			"caption": "توضیحات",
			"countryId": "کشور",
		},
		"cityLogr": "city logr",
	},
	//GeneratorFlag:[City.Filter] {
	'cityFilter': {
		'self': 'city',
		"cityId": "city id",
		"nativeName": "native name",
		"caption": "caption",
		"provinceId": "province",
		'province': {
			'self': 'province',
			"provinceId": "شناسه",
			"nativeName": "نام استان",
			"caption": "توضیحات",
			"countryId": "کشور",
		},
		"cityLogr": "city logr",
	},
	//GeneratorFlag:[City.Update] {
	'cityUpdate': {
		'self': 'city',
		"cityId": "city id",
		"nativeName": "native name",
		"caption": "caption",
		"provinceId": "province",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Country.Create] {
	'countryCreate': {
		'self': 'country',
		"countryId": "country id",
		"nativeName": "native name",
		"capitalName": "capital name",
		"caption": "caption",
		"code": "code",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Country.Delete] {
	'countryDelete': {
		'self': 'country',
		"countryId": "country id",
	},
	//GeneratorFlag:[Country.Detail] {
	'countryDetail': {
		'self': 'country',
		"countryId": "country id",
		"nativeName": "native name",
		"capitalName": "capital name",
		"caption": "caption",
		"code": "code",
		"countryLogr": "country logr",
	},
	//GeneratorFlag:[Country.List] {
	'countryList': {
		'self': 'country',
		"countryId": "country id",
		"nativeName": "native name",
		"capitalName": "capital name",
		"caption": "caption",
		"code": "code",
		"countryLogr": "country logr",
	},
	//GeneratorFlag:[Country.Filter] {
	'countryFilter': {
		'self': 'country',
		"countryId": "country id",
		"nativeName": "native name",
		"capitalName": "capital name",
		"caption": "caption",
		"code": "code",
		"countryLogr": "country logr",
	},
	//GeneratorFlag:[Country.Update] {
	'countryUpdate': {
		'self': 'country',
		"countryId": "country id",
		"nativeName": "native name",
		"capitalName": "capital name",
		"caption": "caption",
		"code": "code",
		"identifier": "identifier",
	},
	//GeneratorFlag:[PersonMilitary.Create] {
	'personMilitaryCreate': {
		'self': 'personMilitary',
		"personMilitaryId": "person military id",
		"title": "title",
		"caption": "caption",
		"identifier": "identifier",
	},
	//GeneratorFlag:[PersonMilitary.Delete] {
	'personMilitaryDelete': {
		'self': 'personMilitary',
		"personMilitaryId": "person military id",
	},
	//GeneratorFlag:[PersonMilitary.Detail] {
	'personMilitaryDetail': {
		'self': 'personMilitary',
		"personMilitaryId": "person military id",
		"title": "title",
		"caption": "caption",
		"personMilitaryLogr": "person military logr",
	},
	//GeneratorFlag:[PersonMilitary.List] {
	'personMilitaryList': {
		'self': 'personMilitary',
		"personMilitaryId": "person military id",
		"title": "title",
		"caption": "caption",
		"personMilitaryLogr": "person military logr",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMilitary.Filter] {
	'personMilitaryFilter': {
		'self': 'personMilitary',
		"personMilitaryId": "person military id",
		"title": "title",
		"caption": "caption",
		"personMilitaryLogr": "person military logr",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMilitary.Update] {
	'personMilitaryUpdate': {
		'self': 'personMilitary',
		"personMilitaryId": "person military id",
		"title": "title",
		"caption": "caption",
		"identifier": "identifier",
	},
	//GeneratorFlag:[PersonNationality.Create] {
	'personNationalityCreate': {
		'self': 'personNationality',
		"personNationalityId": "person nationality id",
		"title": "title",
		"caption": "caption",
		"identifier": "identifier",
	},
	//GeneratorFlag:[PersonNationality.Delete] {
	'personNationalityDelete': {
		'self': 'personNationality',
		"personNationalityId": "person nationality id",
	},
	//GeneratorFlag:[PersonNationality.Detail] {
	'personNationalityDetail': {
		'self': 'personNationality',
		"personNationalityId": "person nationality id",
		"title": "title",
		"caption": "caption",
		"personNationalityLogr": "person nationality logr",
	},
	//GeneratorFlag:[PersonNationality.List] {
	'personNationalityList': {
		'self': 'personNationality',
		"personNationalityId": "person nationality id",
		"title": "title",
		"caption": "caption",
		"personNationalityLogr": "person nationality logr",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonNationality.Filter] {
	'personNationalityFilter': {
		'self': 'personNationality',
		"personNationalityId": "person nationality id",
		"title": "title",
		"caption": "caption",
		"personNationalityLogr": "person nationality logr",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonNationality.Update] {
	'personNationalityUpdate': {
		'self': 'personNationality',
		"personNationalityId": "person nationality id",
		"title": "title",
		"caption": "caption",
		"identifier": "identifier",
	},
	//GeneratorFlag:[PersonRole.Create] {
	'personRoleCreate': {
		'self': 'personRole',
		"personRoleId": "person role id",
		"title": "title",
		"caption": "caption",
		"identifier": "identifier",
	},
	//GeneratorFlag:[PersonRole.Delete] {
	'personRoleDelete': {
		'self': 'personRole',
		"personRoleId": "person role id",
	},
	//GeneratorFlag:[PersonRole.Detail] {
	'personRoleDetail': {
		'self': 'personRole',
		"personRoleId": "person role id",
		"title": "title",
		"caption": "caption",
		"personRoleLogr": "person role logr",
	},
	//GeneratorFlag:[PersonRole.List] {
	'personRoleList': {
		'self': 'personRole',
		"personRoleId": "person role id",
		"title": "title",
		"caption": "caption",
		"personRoleLogr": "person role logr",
	},
	//GeneratorFlag:[PersonRole.Filter] {
	'personRoleFilter': {
		'self': 'personRole',
		"personRoleId": "person role id",
		"title": "title",
		"caption": "caption",
		"personRoleLogr": "person role logr",
	},
	//GeneratorFlag:[PersonRole.Update] {
	'personRoleUpdate': {
		'self': 'personRole',
		"personRoleId": "person role id",
		"title": "title",
		"caption": "caption",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Province.Create] {
	'provinceCreate': {
		'self': 'province',
		"provinceId": "province id",
		"nativeName": "native name",
		"caption": "caption",
		"countryId": "country",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Province.Delete] {
	'provinceDelete': {
		'self': 'province',
		"provinceId": "province id",
	},
	//GeneratorFlag:[Province.Detail] {
	'provinceDetail': {
		'self': 'province',
		"provinceId": "province id",
		"nativeName": "native name",
		"caption": "caption",
		"countryId": "country",
		'country': {
			'self': 'country',
			"countryId": "شناسه",
			"nativeName": "نام کشور",
			"capitalName": "نام پایتخت",
			"caption": "توضیحات",
			"code": "کد کشور",
		},
		"provinceLogr": "province logr",
	},
	//GeneratorFlag:[Province.List] {
	'provinceList': {
		'self': 'province',
		"provinceId": "province id",
		"nativeName": "native name",
		"caption": "caption",
		"countryId": "country",
		'country': {
			'self': 'country',
			"countryId": "شناسه",
			"nativeName": "نام کشور",
			"capitalName": "نام پایتخت",
			"caption": "توضیحات",
			"code": "کد کشور",
		},
		"provinceLogr": "province logr",
	},
	//GeneratorFlag:[Province.Filter] {
	'provinceFilter': {
		'self': 'province',
		"provinceId": "province id",
		"nativeName": "native name",
		"caption": "caption",
		"countryId": "country",
		'country': {
			'self': 'country',
			"countryId": "شناسه",
			"nativeName": "نام کشور",
			"capitalName": "نام پایتخت",
			"caption": "توضیحات",
			"code": "کد کشور",
		},
		"provinceLogr": "province logr",
	},
	//GeneratorFlag:[Province.Update] {
	'provinceUpdate': {
		'self': 'province',
		"provinceId": "province id",
		"nativeName": "native name",
		"caption": "caption",
		"countryId": "country",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Region.Create] {
	'regionCreate': {
		'self': 'region',
		"regionId": "region id",
		"nativeName": "native name",
		"caption": "caption",
		"cityId": "city",
		"identifier": "identifier",
	},
	//GeneratorFlag:[Region.Delete] {
	'regionDelete': {
		'self': 'region',
		"regionId": "region id",
	},
	//GeneratorFlag:[Region.Detail] {
	'regionDetail': {
		'self': 'region',
		"regionId": "region id",
		"nativeName": "native name",
		"caption": "caption",
		"cityId": "city",
		'city': {
			'self': 'city',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		"regionLogr": "region logr",
	},
	//GeneratorFlag:[Region.List] {
	'regionList': {
		'self': 'region',
		"regionId": "region id",
		"nativeName": "native name",
		"caption": "caption",
		"cityId": "city",
		'city': {
			'self': 'city',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		"regionLogr": "region logr",
	},
	//GeneratorFlag:[Region.Filter] {
	'regionFilter': {
		'self': 'region',
		"regionId": "region id",
		"nativeName": "native name",
		"caption": "caption",
		"cityId": "city",
		'city': {
			'self': 'city',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		"regionLogr": "region logr",
	},
	//GeneratorFlag:[Region.Update] {
	'regionUpdate': {
		'self': 'region',
		"regionId": "region id",
		"nativeName": "native name",
		"caption": "caption",
		"cityId": "city",
		"identifier": "identifier",
	},
	//GeneratorFlag:[NoticeConfigIppanel.Detail] {
	'noticeConfigIppanelDetail': {
		'self': 'noticeConfigIppanel',
		"noticeConfigId": "notice config id",
		"useName": "use name",
		"password": "password",
		"patternCode": "pattern code",
		"number": "number",
		"apiAdderss": "api adderss",
	},
	//GeneratorFlag:[NoticeConfigIppanel.Update] {
	'noticeConfigIppanelUpdate': {
		'self': 'noticeConfigIppanel',
		"noticeConfigId": "notice config id",
		"useName": "use name",
		"password": "password",
		"patternCode": "pattern code",
		"number": "number",
		"apiAdderss": "api adderss",
	},
	//GeneratorFlag:[NoticeConfigKavenegar.Detail] {
	'noticeConfigKavenegarDetail': {
		'self': 'noticeConfigKavenegar',
		"noticeConfigId": "notice config id",
		"apiKey": "api key",
		"tokenOne": "token one",
		"tokenTwo": "token two",
		"tokenThree": "token three",
	},
	//GeneratorFlag:[NoticeConfigKavenegar.Update] {
	'noticeConfigKavenegarUpdate': {
		'self': 'noticeConfigKavenegar',
		"noticeConfigId": "notice config id",
		"apiKey": "api key",
		"tokenOne": "token one",
		"tokenTwo": "token two",
		"tokenThree": "token three",
	},
	//GeneratorFlag:[PersonAccess.Detail] {
	'personAccessDetail': {
		'self': 'personAccess',
		"personId": "person id",
		"userName": "user name",
		"password": "password",
		"accessIps": "access ips",
		"isActive": "is active",
	},
	//GeneratorFlag:[PersonAccess.Update] {
	'personAccessUpdate': {
		'self': 'personAccess',
		"personId": "person id",
		"userName": "user name",
		"password": "password",
		"accessIps": "access ips",
		"isActive": "is active",
	},
	//GeneratorFlag:[PersonLegal.Detail] {
	'personLegalDetail': {
		'self': 'personLegal',
		"personId": "person id",
		"legalName": "legal name",
		"nationalCode": "national code",
		"registerNumber": "register number",
		"economicNumber": "economic number",
		"phoneNumber": "phone number",
		"fax": "fax",
		"email": "email",
		"postalCode": "postal code",
		"address": "address",
	},
	//GeneratorFlag:[PersonLegal.Update] {
	'personLegalUpdate': {
		'self': 'personLegal',
		"personId": "person id",
		"legalName": "legal name",
		"nationalCode": "national code",
		"registerNumber": "register number",
		"economicNumber": "economic number",
		"phoneNumber": "phone number",
		"fax": "fax",
		"email": "email",
		"postalCode": "postal code",
		"address": "address",
	},
	//GeneratorFlag:[PersonReal.Detail] {
	'personRealDetail': {
		'self': 'personReal',
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"fullName": "full name",
		"fatherName": "father name",
		"motherName": "mother name",
		"nationalCode": "national code",
		"identification": "identification",
		"mobile": "mobile",
		"phoneNumber": "phone number",
		"email": "email",
		"placeOfBirth": "place of birth",
		"postalCode": "postal code",
		"birthDate": "birth date",
		"workAddress": "work address",
		"homeAddress": "home address",
		"personNationalityId": "person nationality",
		"personGenderId": "person gender",
		"personGradeId": "person grade",
		"personMaritalId": "person marital",
		"personMilitaryId": "person military",
		'personGender': {
			'self': 'person gender',
			"personGenderId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personGrade': {
			'self': 'person grade',
			"personGradeId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personMarital': {
			'self': 'person marital',
			"personMaritalId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personMilitary': {
			'self': 'person military',
			"personMilitaryId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personNationality': {
			'self': 'person nationality',
			"personNationalityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
	},
	//GeneratorFlag:[PersonReal.Update] {
	'personRealUpdate': {
		'self': 'personReal',
		"personId": "person id",
		"firstName": "first name",
		"lastName": "last name",
		"fullName": "full name",
		"fatherName": "father name",
		"motherName": "mother name",
		"nationalCode": "national code",
		"identification": "identification",
		"mobile": "mobile",
		"phoneNumber": "phone number",
		"email": "email",
		"placeOfBirth": "place of birth",
		"postalCode": "postal code",
		"birthDate": "birth date",
		"workAddress": "work address",
		"homeAddress": "home address",
		"personNationalityId": "person nationality",
		"personGenderId": "person gender",
		"personGradeId": "person grade",
		"personMaritalId": "person marital",
		"personMilitaryId": "person military",
	},
	//GeneratorFlag:[FileExtension.Detail] {
	'fileExtensionDetail': {
		'self': 'fileExtension',
		"fileExtensionId": "file extension id",
		"extensionName": "extension name",
		"directoryName": "directory name",
		"contentType": "content type",
	},
	//GeneratorFlag:[FileExtension.List] {
	'fileExtensionList': {
		'self': 'fileExtension',
		"fileExtensionId": "file extension id",
		"extensionName": "extension name",
		"directoryName": "directory name",
		"contentType": "content type",
	},
	//GeneratorFlag:[FileExtension.Filter] {
	'fileExtensionFilter': {
		'self': 'fileExtension',
		"fileExtensionId": "file extension id",
		"extensionName": "extension name",
		"directoryName": "directory name",
		"contentType": "content type",
	},
	//GeneratorFlag:[NoticeProvider.Detail] {
	'noticeProviderDetail': {
		'self': 'noticeProvider',
		"noticeProviderId": "notice provider id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeProvider.List] {
	'noticeProviderList': {
		'self': 'noticeProvider',
		"noticeProviderId": "notice provider id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeProvider.Filter] {
	'noticeProviderFilter': {
		'self': 'noticeProvider',
		"noticeProviderId": "notice provider id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeStatus.Detail] {
	'noticeStatusDetail': {
		'self': 'noticeStatus',
		"noticeStatusId": "notice status id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeStatus.List] {
	'noticeStatusList': {
		'self': 'noticeStatus',
		"noticeStatusId": "notice status id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeStatus.Filter] {
	'noticeStatusFilter': {
		'self': 'noticeStatus',
		"noticeStatusId": "notice status id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeType.Detail] {
	'noticeTypeDetail': {
		'self': 'noticeType',
		"noticeTypeId": "notice type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeType.List] {
	'noticeTypeList': {
		'self': 'noticeType',
		"noticeTypeId": "notice type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NoticeType.Filter] {
	'noticeTypeFilter': {
		'self': 'noticeType',
		"noticeTypeId": "notice type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonEntity.Detail] {
	'personEntityDetail': {
		'self': 'personEntity',
		"personEntityId": "person entity id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonEntity.List] {
	'personEntityList': {
		'self': 'personEntity',
		"personEntityId": "person entity id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonEntity.Filter] {
	'personEntityFilter': {
		'self': 'personEntity',
		"personEntityId": "person entity id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonGender.Detail] {
	'personGenderDetail': {
		'self': 'personGender',
		"personGenderId": "person gender id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonGender.List] {
	'personGenderList': {
		'self': 'personGender',
		"personGenderId": "person gender id",
		"title": "title",
		"caption": "caption",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonGender.Filter] {
	'personGenderFilter': {
		'self': 'personGender',
		"personGenderId": "person gender id",
		"title": "title",
		"caption": "caption",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonGrade.Detail] {
	'personGradeDetail': {
		'self': 'personGrade',
		"personGradeId": "person grade id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonGrade.List] {
	'personGradeList': {
		'self': 'personGrade',
		"personGradeId": "person grade id",
		"title": "title",
		"caption": "caption",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonGrade.Filter] {
	'personGradeFilter': {
		'self': 'personGrade',
		"personGradeId": "person grade id",
		"title": "title",
		"caption": "caption",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMarital.Detail] {
	'personMaritalDetail': {
		'self': 'personMarital',
		"personMaritalId": "person marital id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonMarital.List] {
	'personMaritalList': {
		'self': 'personMarital',
		"personMaritalId": "person marital id",
		"title": "title",
		"caption": "caption",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMarital.Filter] {
	'personMaritalFilter': {
		'self': 'personMarital',
		"personMaritalId": "person marital id",
		"title": "title",
		"caption": "caption",
		'personReals': {
			'self': 'person reals',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[AccessAgent.Save] {
	'accessAgentSave': {
		'self': 'accessAgent',
		"personId": "Person",
		"personRoleId": "Person Role",
		'accessKeys': {
			'self': 'Access Keys',
			"keyName": "",
			"groupName": "",
		},
		"isSuccess": "Result",
	},
	//GeneratorFlag:[AccessToken.Generate] {
	'accessTokenGenerate': {
		'self': 'accessToken',
		"userName": "user name",
		"password": "password",
		"tokenType": "token type",
		"expirationDate": "expiration date",
		"token": "token",
	},
//GeneratorFlag:[NewEntity]
}