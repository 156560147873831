
<template>
	<div v-html="$loc.fromResource('com.excel.warning')"></div>
	<div class="mt-3 mb-3 tex-center">
		<button type="button" @click="getSample" class="btn btn-light border me-2">
			{{ $loc.fromResource('com.excel.downloadSampleFile') }}
		</button>
		<div class="btn btn-dark ps-5 pe-5 text-light border position-relative">
			<span>{{ $loc.fromResource('com.excel.chooseFile') }}</span>
			<input class="form-control" type="file" v-on:change="selectFile" accept=".xlsx" />
		</div>
	</div>
</template>
<script>
	import XLSX from 'xlsx'
	import { persian } from '@/plugins'
	export default {
		emits: ['select'],

		props: {
			'entity': {
				type: Function
			}
		},

		methods: {

			selectFile(e) {

				const file = e.target.files[0];
				const allowType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

				if (file.type == allowType) {
					this.$emit('select', file)
				}
			},

			getPropName(prop) {

				const name = this.$loc.fromResource(prop.resourceKey);
				const star = prop.setConfig.patterns.includes('required') ? '*' : ''

				return name + star;
			},

			getSheets(entity, masterPropety) {
				return new Promise(async (resolve, reject) => {

					var sheets = [], item = {
						localName: this.$loc.fromResource(entity.resourceKey),
						name: entity.name,
						headers: {},
						keys: {}
					};


					for (const key in entity.props)
					{
						var prop = entity.props[key];

						if (prop.setConfig.canUseInExcel)
						{
							if (prop.type != 'object') {

								const key = `${(masterPropety ? masterPropety.name : entity.name)}.${prop.name}`;

								item.keys[key] = key;
								item.headers[key] = this.getPropName(prop);

								continue;
							}

							var relationSheets = await this.getSheets(prop.entity, prop);
							sheets.push(relationSheets[0])
						}
					}

					sheets.push(item);
					resolve(sheets);

				});
			},


			getSingleSheet(entity) {
				return new Promise(async (resolve, reject) => {

					var sheet = {
						localName: this.$loc.fromResource(entity.resourceKey),
						name: entity.name,
						headers: {},
						keys: {}
					};

					var getProps = (entity, parentName) =>
					{
						for (const item in entity.props) {
							const prop = entity.props[item];

							if (prop.setConfig.canUseInExcel) {
								if (prop.type != 'object') {

									var key =  prop.name; 
									if (parentName)
									{
										key =`${parentName}.${prop.name}`;
									}

									sheet.keys[key] = key;
									sheet.headers[key] = this.getPropName(prop);

									continue;
								}
								else {
									getProps(prop.entity, prop.name);
								}
							}
						}
					}

					getProps(entity);
					resolve(sheet);
				});
			},


			async getSample() {

				const excel = XLSX;
				const excelUtils = XLSX.utils;
				const sheet = await this.getSingleSheet(new this.entity());

				var wb = excelUtils.book_new()
				excelUtils.book_append_sheet(
					wb, excelUtils.json_to_sheet([sheet.keys, sheet.headers], {
						skipHeader: true
					}),
					sheet.localName
				)

				wb.Workbook = {
					Views: [{ RTL: true }]
				}

				excel.writeFile(wb, `Sample file of ${sheet.name} - ${persian.cleanDateTime()}`   + '.xlsx')
			}
		}
	}
</script>
<style>
	.kaveh-excel-parser input[type='file'] {
		position: absolute;
		opacity: 0;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
</style>
