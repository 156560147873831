//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class WalletService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[Wallet.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet/create', params || { });
	};

	//GeneratorFlag:[Wallet.Delete]
	async delete(walletId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:walletId
		}
	
		return await _server.delete('/financial/wallet/delete', {'params':params})
	};

	//GeneratorFlag:[Wallet.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet/detail', {'params':params});
	};

	//GeneratorFlag:[Wallet.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet/list', {'params':params});
	};

	//GeneratorFlag:[Wallet.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/financial/wallet/update', params || { });
	};

	//GeneratorFlag:[Wallet.GetCredit]
	async getCredit(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet/get-credit', {'params':params});
	};

	//GeneratorFlag:[Wallet.TotalAmount]
	async totalAmount(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/financial/wallet/total-amount', {'params':params});
	};

//GeneratorFlag:[NewAction]
}