//GeneratorFlag:[NoChange]
export default {
	path: 'product-color',
	name: 'shop.baseInfo.productColor.layout',
	redirect: to => {
		return {name: 'shop.productColor.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-info/product-color"],
		resourceKey: 'shop.views.productColor.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductColorCreate]"
		{
			path: 'create',
			name: 'shop.productColor.create',
			component: () => import('@/modules/shop/views/product-color/product-color-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productColor.create',
				clientAccessKeys:["/shop/base-info/product-color/create"],
				serverAccessKeys:["/shop/product-color/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductColorCreateImport]"
		{
			path: 'create-import',
			name: 'shop.productColor.createImport',
			component: () => import('@/modules/shop/views/product-color/product-color-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productColor.createImport',
				clientAccessKeys:["/shop/base-info/product-color/create-import"],
				serverAccessKeys:["/shop/product-color/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductColorDelete]"
		{
			path: 'delete',
			name: 'shop.productColor.delete',
			component: () => import('@/modules/shop/views/product-color/product-color-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productColor.delete',
				clientAccessKeys:["/shop/base-info/product-color/delete"],
				serverAccessKeys:["/shop/product-color/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductColorDetail]"
		{
			path: 'detail',
			name: 'shop.productColor.detail',
			component: () => import('@/modules/shop/views/product-color/product-color-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productColor.detail',
				clientAccessKeys:["/shop/base-info/product-color/detail"],
				serverAccessKeys:["/shop/product-color/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductColorList]"
		{
			path: 'list',
			name: 'shop.productColor.list',
			component: () => import('@/modules/shop/views/product-color/product-color-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productColor.list',
				clientAccessKeys:["/shop/base-info/product-color/list"],
				serverAccessKeys:["/shop/product-color/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductColorUpdate]"
		{
			path: 'update',
			name: 'shop.productColor.update',
			component: () => import('@/modules/shop/views/product-color/product-color-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productColor.update',
				clientAccessKeys:["/shop/base-info/product-color/update"],
				serverAccessKeys:["/shop/product-color/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}