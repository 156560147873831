<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { City, CityFilter } from '@/modules/hub/data/city/full-city'
	import { CityService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return City;
			},
			
			filterEntity()
			{
				return CityFilter;
			},

			service()
			{
				return new CityService();
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				try
				{
					return await this.service.fullCity(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>