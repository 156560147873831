<template>
    <div class="card bg-light justify-content-center mb-2 pt-3 pb-3">
		<div class="card-body">
			<div class="d-flex align-items-center">
				<kaveh-icon-bootstrap color="red" name="bi-info-circle me-3" />
				<span>{{ $loc.fromResource('system.notfound')}}</span>
			</div>
			<div class="d-flex align-items-center mt-5">
				<button class="btn btn-light border" @click="$router.push({name: `${createRouteName}`})">
					{{ $loc.fromResource('action.createNewNode')}}
				</button>
			</div>
		</div>
    </div>
</template>
<script>
	export default {
		props: {
			createRouteName: {
				type: String,
				default:''
			}
		}
	}
</script>
