//GeneratorFlag:[NoChange]
export default {
	path: 'person-credit-transaction',
	name: 'peopleNet.personCreditTransaction.layout',
	redirect: to => {
		return {name: 'peopleNet.personCreditTransaction.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/person-credit-transaction"],
		resourceKey: 'peopleNet.views.personCreditTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonCreditTransactionCreate]"
		{
			path: 'create',
			name: 'peopleNet.personCreditTransaction.create',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.create',
				clientAccessKeys:["/people-net/person-credit-transaction/create"],
				serverAccessKeys:["/people-net/person-credit-transaction/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.personCreditTransaction.createImport',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.createImport',
				clientAccessKeys:["/people-net/person-credit-transaction/create-import"],
				serverAccessKeys:["/people-net/person-credit-transaction/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionDelete]"
		{
			path: 'delete',
			name: 'peopleNet.personCreditTransaction.delete',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.delete',
				clientAccessKeys:["/people-net/person-credit-transaction/delete"],
				serverAccessKeys:["/people-net/person-credit-transaction/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personCreditTransaction.detail',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.detail',
				clientAccessKeys:["/people-net/person-credit-transaction/detail"],
				serverAccessKeys:["/people-net/person-credit-transaction/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionList]"
		{
			path: 'list',
			name: 'peopleNet.personCreditTransaction.list',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.list',
				clientAccessKeys:["/people-net/person-credit-transaction/list"],
				serverAccessKeys:["/people-net/person-credit-transaction/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionUpdate]"
		{
			path: 'update',
			name: 'peopleNet.personCreditTransaction.update',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.update',
				clientAccessKeys:["/people-net/person-credit-transaction/update"],
				serverAccessKeys:["/people-net/person-credit-transaction/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionMyList]"
		{
			path: 'my-list',
			name: 'peopleNet.personCreditTransaction.myList',
			component: () => import('@/modules/people-net/views/person-credit-transaction/person-credit-transaction-my-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransaction.myList',
				clientAccessKeys:["/people-net/person-credit-transaction/my-list"],
				serverAccessKeys:["/people-net/person-credit-transaction/my-list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]







	]
}