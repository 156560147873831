//GeneratorFlag:[NoChange]
export default {
	path: 'plan-transaction',
	name: 'shop.report.planTransaction.layout',
	redirect: to => {
		return {name: 'shop.planTransaction.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/report/plan-transaction"],
		resourceKey: 'shop.views.planTransaction.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanTransactionList]"
		{
			path: 'list',
			name: 'shop.planTransaction.list',
			component: () => import('@/modules/shop/views/plan-transaction/plan-transaction-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransaction.list',
				clientAccessKeys:["/shop/report/plan-transaction/list"],
				serverAccessKeys:["/shop/plan-transaction/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanTransactionTotalReport]"
		{
			path: 'total-report',
			name: 'shop.planTransaction.totalReport',
			component: () => import('@/modules/shop/views/plan-transaction/plan-transaction-total-report.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransaction.totalReport',
				clientAccessKeys:["/shop/report/plan-transaction/total-report"],
				serverAccessKeys:["/shop/plan-transaction/total-report"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}