//GeneratorFlag:[NoChange]
export default {
	path: 'feature-item',
	name: 'shop.baseInfo.featureItem.layout',
	redirect: to => {
		return {name: 'shop.featureItem.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-info/feature-item"],
		resourceKey: 'shop.views.featureItem.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[FeatureItemCreate]"
		{
			path: 'create',
			name: 'shop.featureItem.create',
			component: () => import('@/modules/shop/views/feature-item/feature-item-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.featureItem.create',
				clientAccessKeys:["/shop/base-info/feature-item/create"],
				serverAccessKeys:["/shop/feature-item/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureItemCreateImport]"
		{
			path: 'create-import',
			name: 'shop.featureItem.createImport',
			component: () => import('@/modules/shop/views/feature-item/feature-item-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.featureItem.createImport',
				clientAccessKeys:["/shop/base-info/feature-item/create-import"],
				serverAccessKeys:["/shop/feature-item/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureItemDelete]"
		{
			path: 'delete',
			name: 'shop.featureItem.delete',
			component: () => import('@/modules/shop/views/feature-item/feature-item-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.featureItem.delete',
				clientAccessKeys:["/shop/base-info/feature-item/delete"],
				serverAccessKeys:["/shop/feature-item/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureItemDetail]"
		{
			path: 'detail',
			name: 'shop.featureItem.detail',
			component: () => import('@/modules/shop/views/feature-item/feature-item-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.featureItem.detail',
				clientAccessKeys:["/shop/base-info/feature-item/detail"],
				serverAccessKeys:["/shop/feature-item/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureItemList]"
		{
			path: 'list',
			name: 'shop.featureItem.list',
			component: () => import('@/modules/shop/views/feature-item/feature-item-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.featureItem.list',
				clientAccessKeys:["/shop/base-info/feature-item/list"],
				serverAccessKeys:["/shop/feature-item/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FeatureItemUpdate]"
		{
			path: 'update',
			name: 'shop.featureItem.update',
			component: () => import('@/modules/shop/views/feature-item/feature-item-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.featureItem.update',
				clientAccessKeys:["/shop/base-info/feature-item/update"],
				serverAccessKeys:["/shop/feature-item/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}