//GeneratorFlag:[NoChange]
export default {
	path: 'person-credit-transaction-statuse',
	name: 'peopleNet.baseSys.personCreditTransactionStatuse.layout',
	redirect: to => {
		return {name: 'peopleNet.personCreditTransactionStatuse.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-credit-transaction-statuse"],
		resourceKey: 'peopleNet.views.personCreditTransactionStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonCreditTransactionStatuseDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personCreditTransactionStatuse.detail',
			component: () => import('@/modules/people-net/views/person-credit-transaction-statuse/person-credit-transaction-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransactionStatuse.detail',
				clientAccessKeys:["/people-net/base-sys/person-credit-transaction-statuse/detail"],
				serverAccessKeys:["/people-net/person-credit-transaction-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonCreditTransactionStatuseList]"
		{
			path: 'list',
			name: 'peopleNet.personCreditTransactionStatuse.list',
			component: () => import('@/modules/people-net/views/person-credit-transaction-statuse/person-credit-transaction-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personCreditTransactionStatuse.list',
				clientAccessKeys:["/people-net/base-sys/person-credit-transaction-statuse/list"],
				serverAccessKeys:["/people-net/person-credit-transaction-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}