<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content width="800px">
		<div class="custom-form" v-if="props">
			<div class="mb-3 row">
				<label v-once for="fromDisplayDate" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.fromDisplayDate.resourceKey)}}</span>
					<span v-if="isRequired('fromDisplayDate')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.fromDisplayDate.value" @keyup="onHandelKeyUp('fromDisplayDate')" @change="onHandelChange('fromDisplayDate')" dir="ltr" type="date" class="form-control" id="fromDisplayDate" autocomplete="off">
					<small class="text-danger" v-if="props.fromDisplayDate.errors.length > 0">
						{{getFirstError('fromDisplayDate')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="toDisplayDate" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.toDisplayDate.resourceKey)}}</span>
					<span v-if="isRequired('toDisplayDate')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.toDisplayDate.value" @keyup="onHandelKeyUp('toDisplayDate')" @change="onHandelChange('toDisplayDate')" dir="ltr" type="date" class="form-control" id="toDisplayDate" autocomplete="off">
					<small class="text-danger" v-if="props.toDisplayDate.errors.length > 0">
						{{getFirstError('toDisplayDate')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="planTransactionTypeId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.planTransactionTypeId.resourceKey)}}</span>
					<span v-if="isRequired('planTransactionTypeId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': planTransactionTypeListIsLoading}" v-model="props.planTransactionTypeId.value" @change="onHandelChange('planTransactionTypeId')" id="planTransactionTypeId">
						<option v-for="(item,index) in planTransactionTypeList" :value="item.planTransactionTypeId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.planTransactionTypeId.errors.length > 0">
						{{getFirstError('planTransactionTypeId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="planTransactionStatuseId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.planTransactionStatuseId.resourceKey)}}</span>
					<span v-if="isRequired('planTransactionStatuseId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': planTransactionStatuseListIsLoading}" v-model="props.planTransactionStatuseId.value" @change="onHandelChange('planTransactionStatuseId')" id="planTransactionStatuseId">
						<option v-for="(item,index) in planTransactionStatuseList" :value="item.planTransactionStatuseId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.planTransactionStatuseId.errors.length > 0">
						{{getFirstError('planTransactionStatuseId')}}
					</small>
				</div>
			</div>


		</div>
		<kaveh-button :is-loading="isLoading" @click="submit" class="btn btn-dark">
			{{$loc.fromResource('action.save')}}
		</kaveh-button>
	</kaveh-content>
</template>
<script>
	import validator from '@/core/validator'
	import { PlanTransaction } from '@/modules/shop/data/plan-transaction/total-report'
	import { PlanTransactionService } from '@/modules/shop/services'
	export default {

		data()
		{
			return {
				props: null,
				isLoading: false,
				useValidator: true,
				planTransactionTypeList: [],
				planTransactionStatuseList: [],
	
				planTransactionTypeListIsLoading: true,
				planTransactionStatuseListIsLoading: true,

			}
		},

		async beforeMount()
		{
			this.props = new PlanTransaction().props;
			this.initList('planTransactionType','planTransactionTypeId');
			this.initList('planTransactionStatuse','planTransactionStatuseId');

		},

		methods:
		{
			async initList(entityName,propName)
			{
				try {
					this[`${entityName}ListIsLoading`] = true;
					const res = await this.props[propName].data({start:0, length:1000});

					if(res.status == 200){
						this[`${entityName}List`] = res.data.items;
					}

				} catch (e) {
					console.log(e)
				}


				this[`${entityName}ListIsLoading`] = false;
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey,{
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.props) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					const prop = this.props[key];

					value[key] =
						prop.setConfig.inputType == 'checkdrop' ?
							prop.value == 'true' : prop.value
				}

				return value
			},

			async submit()
			{
				try
				{
					const payload = this.getValue();

					if (payload)
					{
						this.isLoading = true;
						const service = new PlanTransactionService();
						const res = await service.totalReport(payload);

						if (res.status == 200)
						{
							this.props = new PlanTransaction().props;
						}
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			}
		}
	}
</script>