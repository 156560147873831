//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"ticketing",
	"ext": ext,
	"layout":{
		"baseInfo": "Basic Information", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "System information", //GeneratorFlag:[BaseSysLayout]
		"administrator": "Administrator Operation", //GeneratorFlag:[AdministratorLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"ticket": {
			"layout": "Ticket",
			"create": "Create New Ticket", //GeneratorFlag:[Views.Ticket.Create]
			'createImport': 'Imports Ticket From Excel', //GeneratorFlag:[Views.Ticket.CreateImport] 
 			'delete': 'Delete Ticket', //GeneratorFlag:[Views.Ticket.Delete] 
 			'detail': 'Detail of Ticket', //GeneratorFlag:[Views.Ticket.Detail] 
 			'list': 'List of Ticket', //GeneratorFlag:[Views.Ticket.List] 
 			'update': 'Edit Ticket', //GeneratorFlag:[Views.Ticket.Update] 
 			'adminList': 'Customer Tickets', //GeneratorFlag:[Views.Ticket.AdminList] 
 			'close': 'Close Ticket', //GeneratorFlag:[Views.Ticket.Close] 
 			'getItems': 'Ticket Item List', //GeneratorFlag:[Views.Ticket.GetItems] 
 			'myList': 'My Tickets', //GeneratorFlag:[Views.Ticket.MyList] 
 			'send': 'Send Ticket', //GeneratorFlag:[Views.Ticket.Send] 
 //GeneratorFlag:[Views.Ticket]
		},
		"ticketItem": {
			"layout": "TicketItem",
			"create": "Create New TicketItem", //GeneratorFlag:[Views.TicketItem.Create]
			'createImport': 'Imports TicketItem From Excel', //GeneratorFlag:[Views.TicketItem.CreateImport] 
 			'delete': 'Delete TicketItem', //GeneratorFlag:[Views.TicketItem.Delete] 
 			'detail': 'Detail of TicketItem', //GeneratorFlag:[Views.TicketItem.Detail] 
 			'list': 'List of TicketItem', //GeneratorFlag:[Views.TicketItem.List] 
 			'update': 'Edit TicketItem', //GeneratorFlag:[Views.TicketItem.Update] 
 			'adminResponse': 'Send Response', //GeneratorFlag:[Views.TicketItem.AdminResponse] 
 			'response': 'Send Response', //GeneratorFlag:[Views.TicketItem.Response] 
 //GeneratorFlag:[Views.TicketItem]
		},
		"ticketDepartment": {
			"layout": "Create TicketDepartment",
			"create": "Create New TicketDepartment", //GeneratorFlag:[Views.TicketDepartment.Create]
			'createImport': 'Imports TicketDepartment From Excel', //GeneratorFlag:[Views.TicketDepartment.CreateImport] 
 			'delete': 'Delete TicketDepartment', //GeneratorFlag:[Views.TicketDepartment.Delete] 
 			'detail': 'Detail of TicketDepartment', //GeneratorFlag:[Views.TicketDepartment.Detail] 
 			'list': 'List of TicketDepartment', //GeneratorFlag:[Views.TicketDepartment.List] 
 			'update': 'Edit TicketDepartment', //GeneratorFlag:[Views.TicketDepartment.Update] 
 //GeneratorFlag:[Views.TicketDepartment]
		},
		"culture": {
			"layout": "Culture",
			"detail": "Detail of Culture", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'List of Culture', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"ticketStatuse": {
			"layout": "TicketStatuse",
			"detail": "Detail of TicketStatuse", //GeneratorFlag:[Views.TicketStatuse.Detail]
			'list': 'List of TicketStatuse', //GeneratorFlag:[Views.TicketStatuse.List] 
 //GeneratorFlag:[Views.TicketStatuse]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[Ticket.Create] {
	"ticketCreate": {
		"self": "ticket",
		"ticketId": "ticket id",
		"personId": "person",
		"personInfo": "person info",
		"email": "email",
		"ticketStatuseId": "ticket statuse",
		"title": "title",
		"lastUpdate": "last update",
		"displayLastUpdate": "display last update",
	},
	//GeneratorFlag:[Ticket.Delete] {
	"ticketDelete": {
		"self": "ticket",
		"ticketId": "ticket id",
	},
	//GeneratorFlag:[Ticket.Detail] {
	"ticketDetail": {
		"self": "ticket",
		"ticketId": "ticket id",
		"personId": "person",
		"personInfo": "person info",
		"email": "email",
		"ticketStatuseId": "ticket statuse",
		"title": "title",
		"lastUpdate": "last update",
		"displayLastUpdate": "display last update",
		"person": {
			"self": "person",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticketStatuse": {
			"self": "ticket statuse",
			"ticketStatuseId": "شناسه",
			"ticketStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"ticketSubInfo": "ticket sub info",
	},
	//GeneratorFlag:[Ticket.List] {
	"ticketList": {
		"self": "ticket",
		"ticketId": "ticket id",
		"personId": "person",
		"personInfo": "person info",
		"email": "email",
		"ticketStatuseId": "ticket statuse",
		"title": "title",
		"lastUpdate": "last update",
		"displayLastUpdate": "display last update",
		"person": {
			"self": "person",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticketStatuse": {
			"self": "ticket statuse",
			"ticketStatuseId": "شناسه",
			"ticketStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"ticketSubInfo": "ticket sub info",
	},
	//GeneratorFlag:[Ticket.Filter] {
	"ticketFilter": {
		"self": "ticket",
		"ticketId": "ticket id",
		"personId": "person",
		"personInfo": "person info",
		"email": "email",
		"ticketStatuseId": "ticket statuse",
		"title": "title",
		"lastUpdate": "last update",
		"displayLastUpdate": "display last update",
		"person": {
			"self": "person",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticketStatuse": {
			"self": "ticket statuse",
			"ticketStatuseId": "شناسه",
			"ticketStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"ticketSubInfo": "ticket sub info",
	},
	//GeneratorFlag:[Ticket.Update] {
	"ticketUpdate": {
		"self": "ticket",
		"ticketId": "ticket id",
		"personId": "person",
		"personInfo": "person info",
		"email": "email",
		"ticketStatuseId": "ticket statuse",
		"title": "title",
		"lastUpdate": "last update",
		"displayLastUpdate": "display last update",
	},
	//GeneratorFlag:[TicketItem.Create] {
	"ticketItemCreate": {
		"self": "ticketItem",
		"ticketItemId": "ticket item id",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
	},
	//GeneratorFlag:[TicketItem.Delete] {
	"ticketItemDelete": {
		"self": "ticketItem",
		"ticketItemId": "ticket item id",
	},
	//GeneratorFlag:[TicketItem.Detail] {
	"ticketItemDetail": {
		"self": "ticketItem",
		"ticketItemId": "ticket item id",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
		"person": {
			"self": "person",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticket": {
			"self": "ticket",
			"ticketId": "شناسه",
			"personId": "عضو",
			"personInfo": "مشخصات",
			"email": "ایمیل",
			"ticketStatuseId": "وضعیت",
			"title": "عنوان",
			"lastUpdate": "آخرین تغییر",
			"displayLastUpdate": "آخرین تغییر",
		},
		"tciketItemSubInfo": "tciket item sub info",
		"ticketItemSubInfo": "ticket item sub info",
	},
	//GeneratorFlag:[TicketItem.List] {
	"ticketItemList": {
		"self": "ticketItem",
		"ticketItemId": "ticket item id",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
		"person": {
			"self": "person",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticket": {
			"self": "ticket",
			"ticketId": "شناسه",
			"personId": "عضو",
			"personInfo": "مشخصات",
			"email": "ایمیل",
			"ticketStatuseId": "وضعیت",
			"title": "عنوان",
			"lastUpdate": "آخرین تغییر",
			"displayLastUpdate": "آخرین تغییر",
		},
		"tciketItemSubInfo": "tciket item sub info",
		"ticketItemSubInfo": "ticket item sub info",
	},
	//GeneratorFlag:[TicketItem.Filter] {
	"ticketItemFilter": {
		"self": "ticketItem",
		"ticketItemId": "ticket item id",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
		"person": {
			"self": "person",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticket": {
			"self": "ticket",
			"ticketId": "شناسه",
			"personId": "عضو",
			"personInfo": "مشخصات",
			"email": "ایمیل",
			"ticketStatuseId": "وضعیت",
			"title": "عنوان",
			"lastUpdate": "آخرین تغییر",
			"displayLastUpdate": "آخرین تغییر",
		},
		"tciketItemSubInfo": "tciket item sub info",
		"ticketItemSubInfo": "ticket item sub info",
	},
	//GeneratorFlag:[TicketItem.Update] {
	"ticketItemUpdate": {
		"self": "ticketItem",
		"ticketItemId": "ticket item id",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
	},
	//GeneratorFlag:[TicketDepartment.Create] {
	"ticketDepartmentCreate": {
		"self": "ticketDepartment",
		"ticketDepartmentId": "ticket department id",
		"ticketDepartmentKey": "ticket department key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[TicketDepartment.Delete] {
	"ticketDepartmentDelete": {
		"self": "ticketDepartment",
		"ticketDepartmentId": "ticket department id",
	},
	//GeneratorFlag:[TicketDepartment.Detail] {
	"ticketDepartmentDetail": {
		"self": "ticketDepartment",
		"ticketDepartmentId": "ticket department id",
		"ticketDepartmentKey": "ticket department key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
		"ticketDepartmentSubInfo": "ticket department sub info",
	},
	//GeneratorFlag:[TicketDepartment.List] {
	"ticketDepartmentList": {
		"self": "ticketDepartment",
		"ticketDepartmentId": "ticket department id",
		"ticketDepartmentKey": "ticket department key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
		"ticketDepartmentSubInfo": "ticket department sub info",
	},
	//GeneratorFlag:[TicketDepartment.Filter] {
	"ticketDepartmentFilter": {
		"self": "ticketDepartment",
		"ticketDepartmentId": "ticket department id",
		"ticketDepartmentKey": "ticket department key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
		"ticketDepartmentSubInfo": "ticket department sub info",
	},
	//GeneratorFlag:[TicketDepartment.Update] {
	"ticketDepartmentUpdate": {
		"self": "ticketDepartment",
		"ticketDepartmentId": "ticket department id",
		"ticketDepartmentKey": "ticket department key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "culture",
		"cultureId": "culture id",
		"cultureKey": "culture key",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "culture",
		"cultureId": "culture id",
		"cultureKey": "culture key",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "culture",
		"cultureId": "culture id",
		"cultureKey": "culture key",
		"title": "title",
		"cultureSymbol": "culture symbol",
		"cultureFlagImagePath": "culture flag image path",
		"towSymbolCulture": "tow symbol culture",
		"currency": "currency",
		"weightUnit": "weight unit",
		"widthUnit": "width unit",
		"lengthUnit": "length unit",
		"heightUnit": "height unit",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[TicketStatuse.Detail] {
	"ticketStatuseDetail": {
		"self": "ticketStatuse",
		"ticketStatuseId": "ticket statuse id",
		"ticketStatuseKey": "ticket statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
	},
	//GeneratorFlag:[TicketStatuse.List] {
	"ticketStatuseList": {
		"self": "ticketStatuse",
		"ticketStatuseId": "ticket statuse id",
		"ticketStatuseKey": "ticket statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
	},
	//GeneratorFlag:[TicketStatuse.Filter] {
	"ticketStatuseFilter": {
		"self": "ticketStatuse",
		"ticketStatuseId": "ticket statuse id",
		"ticketStatuseKey": "ticket statuse key",
		"cultureId": "culture",
		"title": "title",
		"isOrigin": "is origin",
		"culture": {
			"self": "culture",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
	},
	//GeneratorFlag:[Ticket.AdminList] {
	"ticketAdminList": {
		"self": "ticket",
		"ticketId": "TicketId",
		"title": "Title",
		"memberName": "Member",
		"ticketStatuseTitle": "Status",
	},
	//GeneratorFlag:[Ticket.Close] {
	"ticketClose": {
		"self": "ticket",
		"ticketId": "TicketId",
	},
	//GeneratorFlag:[Ticket.GetItems] {
	"ticketGetItems": {
		"self": "ticket",
		"ticketId": "Ticket ID",
		"ticketItemId": "Ticket Item ID",
		"caption": "Caption",
		"creator": "Creator",
	},
	//GeneratorFlag:[Ticket.MyList] {
	"ticketMyList": {
		"self": "ticket",
		"ticketId": "TicketId",
		"title": "Title",
		"ticketStatuseKey": "TicketStatuse Id",
		"ticketStatuseTitle": "Status",
	},
	//GeneratorFlag:[Ticket.Send] {
	"ticketSend": {
		"self": "ticket",
		"personInfo": "Person Information",
		"email": "Email",
		"title": "Title",
		"caption": "Caption",
	},
	//GeneratorFlag:[TicketItem.AdminResponse] {
	"ticketItemAdminResponse": {
		"self": "ticketItem",
		"ticketId": "Ticket ID",
		"message": "Message",
	},
	//GeneratorFlag:[TicketItem.Response] {
	"ticketItemResponse": {
		"self": "ticketItem",
		"ticketId": "Ticket ID",
		"message": "Message",
		"email": "Email",
	},
//GeneratorFlag:[NewEntity]
}