<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content width="800px">
		<div class="custom-form" v-if="props">
			<div class="mb-3 row">
				<label v-once for="personInfo" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.personInfo.resourceKey)}}</span>
					<span v-if="isRequired('personInfo')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.personInfo.value" @keyup="onHandelKeyUp('personInfo')" @change="onHandelChange('personInfo')" dir="ltr" type="text" class="form-control" id="personInfo" autocomplete="off">
					<small class="text-danger" v-if="props.personInfo.errors.length > 0">
						{{getFirstError('personInfo')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="email" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.email.resourceKey)}}</span>
					<span v-if="isRequired('email')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.email.value" @keyup="onHandelKeyUp('email')" @change="onHandelChange('email')" dir="ltr" type="text" class="form-control" id="email" autocomplete="off">
					<small class="text-danger" v-if="props.email.errors.length > 0">
						{{getFirstError('email')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="title" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.title.resourceKey)}}</span>
					<span v-if="isRequired('title')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.title.value" @keyup="onHandelKeyUp('title')" @change="onHandelChange('title')" dir="rtl" type="text" class="form-control" id="title" autocomplete="off">
					<small class="text-danger" v-if="props.title.errors.length > 0">
						{{getFirstError('title')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="caption" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.caption.resourceKey)}}</span>
					<span v-if="isRequired('caption')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<kaveh-text-editor v-model="props.caption.value" @keyup="onHandelKeyUp('caption')" @change="onHandelChange('caption')" dir="rtl" id="caption" />
					<small class="text-danger" v-if="props.caption.errors.length > 0">
						{{getFirstError('caption')}}
					</small>
				</div>
			</div>


		</div>
		<kaveh-button :is-loading="isLoading" @click="submit" class="btn btn-dark">
			{{$loc.fromResource('action.save')}}
		</kaveh-button>
	</kaveh-content>
</template>
<script>
	import validator from '@/core/validator'
	import { Ticket } from '@/modules/ticketing/data/ticket/send'
	import { TicketService } from '@/modules/ticketing/services'
	export default {

		data()
		{
			return {
				props: null,
				isLoading: false,
				useValidator: true,
	

			}
		},

		async beforeMount()
		{
			this.props = new Ticket().props;

		},

		methods:
		{
			async initList(entityName,propName)
			{
				try {
					this[`${entityName}ListIsLoading`] = true;
					const res = await this.props[propName].data({start:0, length:1000});

					if(res.status == 200){
						this[`${entityName}List`] = res.data.items;
					}

				} catch (e) {
					console.log(e)
				}


				this[`${entityName}ListIsLoading`] = false;
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey,{
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.props) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					const prop = this.props[key];

					value[key] =
						prop.setConfig.inputType == 'checkdrop' ?
							prop.value == 'true' : prop.value
				}

				return value
			},

			async submit()
			{
				try
				{
					const payload = this.getValue();

					if (payload)
					{
						this.isLoading = true;
						const service = new TicketService();
						const res = await service.send(payload);

						if (res.status == 200)
						{
							this.props = new Ticket().props;
						}
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			}
		}
	}
</script>