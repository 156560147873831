import ext from './fa-ext.js'
export default {
	"name":"shop",
	"ext": ext,
	"layout":{
		"baseInfo": "اطلاعات پایه", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "اطلاعات سیستمی", //GeneratorFlag:[BaseSysLayout]
		"report": "گزارش", //GeneratorFlag:[ReportLayout]
		"adminOperation": "ثبت پورسانت مازاد", //GeneratorFlag:[AdminOperationLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"bankPayment": {
			"layout": "درگاه های پرداخت",
			"create": "ثبت جدید درگاه پرداخت", //GeneratorFlag:[Views.BankPayment.Create]
			'createImport': 'ثبت درگاه های پرداخت از طریق اکسل', //GeneratorFlag:[Views.BankPayment.CreateImport] 
 			'delete': 'حذف درگاه پرداخت', //GeneratorFlag:[Views.BankPayment.Delete] 
 			'detail': 'جزئیات اطلاعات درگاه پرداخت', //GeneratorFlag:[Views.BankPayment.Detail] 
 			'list': 'فهرست درگاه های پرداخت', //GeneratorFlag:[Views.BankPayment.List] 
 			'update': 'ویرایش درگاه پرداخت', //GeneratorFlag:[Views.BankPayment.Update] 
 			'getPayment': 'گرفتن درگاه پرداخت', //GeneratorFlag:[Views.BankPayment.GetPayment] 
 //GeneratorFlag:[Views.BankPayment]
		},
		"invoice": {
			"layout": "فاکتور ها",
			"create": "ثبت جدید فاکتور", //GeneratorFlag:[Views.Invoice.Create]
			'createImport': 'ثبت فاکتور ها از طریق اکسل', //GeneratorFlag:[Views.Invoice.CreateImport] 
 			'delete': 'حذف فاکتور', //GeneratorFlag:[Views.Invoice.Delete] 
 			'detail': 'جزئیات اطلاعات فاکتور', //GeneratorFlag:[Views.Invoice.Detail] 
 			'list': 'فهرست فاکتور ها', //GeneratorFlag:[Views.Invoice.List] 
 			'update': 'ویرایش فاکتور', //GeneratorFlag:[Views.Invoice.Update] 
 			'amountSum': 'جمع کل فروش', //GeneratorFlag:[Views.Invoice.AmountSum] 
 			'connectToOther': 'اتصال فاکتور', //GeneratorFlag:[Views.Invoice.ConnectToOther] 
 			'getContracts': 'لیست فاکتور ها', //GeneratorFlag:[Views.Invoice.GetContracts] 
 			'getInvoiceDetail': 'جزئیات فاکتور', //GeneratorFlag:[Views.Invoice.GetInvoiceDetail] 
 			'getMyInvoiceCurrentWeek': 'لیست فاکتور های هفته جاری', //GeneratorFlag:[Views.Invoice.GetMyInvoiceCurrentWeek] 
 			'getMyList': 'لیست فاکتور ها', //GeneratorFlag:[Views.Invoice.GetMyList] 
 			'getMyTeamSellByWeek': 'فروش تیم', //GeneratorFlag:[Views.Invoice.GetMyTeamSellByWeek] 
 			'register': 'ثبت فاکتور', //GeneratorFlag:[Views.Invoice.Register] 
 			'uplineFactorInfo': 'اطلاعات فاکتور بالا سری', //GeneratorFlag:[Views.Invoice.UplineFactorInfo] 
 			'contactSummray': 'گزارش قرارداد ها', //GeneratorFlag:[Views.Invoice.ContactSummray] 
 //GeneratorFlag:[Views.Invoice]
		},
		"invoiceCancel": {
			"layout": "InvoiceCancel",
			"create": "ثبت جدید InvoiceCancel", //GeneratorFlag:[Views.InvoiceCancel.Create]
			'createImport': 'ثبت InvoiceCancel از طریق اکسل', //GeneratorFlag:[Views.InvoiceCancel.CreateImport] 
 			'delete': 'حذف InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.Delete] 
 			'detail': 'جزئیات اطلاعات InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.Detail] 
 			'list': 'فهرست InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.List] 
 			'update': 'ویرایش InvoiceCancel', //GeneratorFlag:[Views.InvoiceCancel.Update] 
 			'contractInfo': 'لغو قرارداد', //GeneratorFlag:[Views.InvoiceCancel.ContractInfo] 
 			'removeContract': 'لغو قرارداد', //GeneratorFlag:[Views.InvoiceCancel.RemoveContract] 
 //GeneratorFlag:[Views.InvoiceCancel]
		},
		"personAddress": {
			"layout": "آدرس ها",
			"create": "ثبت جدید آدرس", //GeneratorFlag:[Views.PersonAddress.Create]
			'createImport': 'ثبت آدرس ها از طریق اکسل', //GeneratorFlag:[Views.PersonAddress.CreateImport] 
 			'delete': 'حذف آدرس', //GeneratorFlag:[Views.PersonAddress.Delete] 
 			'detail': 'جزئیات اطلاعات آدرس', //GeneratorFlag:[Views.PersonAddress.Detail] 
 			'list': 'فهرست آدرس ها', //GeneratorFlag:[Views.PersonAddress.List] 
 			'update': 'ویرایش آدرس', //GeneratorFlag:[Views.PersonAddress.Update] 
 //GeneratorFlag:[Views.PersonAddress]
		},
		"personPreTransaction": {
			"layout": "تراکنش های درخواستی",
			"create": "ثبت جدید درخواست ثبت تراکنش", //GeneratorFlag:[Views.PersonPreTransaction.Create]
			'createImport': 'ثبت تراکنش های درخواستی از طریق اکسل', //GeneratorFlag:[Views.PersonPreTransaction.CreateImport] 
 			'delete': 'حذف درخواست ثبت تراکنش', //GeneratorFlag:[Views.PersonPreTransaction.Delete] 
 			'detail': 'جزئیات اطلاعات درخواست ثبت تراکنش', //GeneratorFlag:[Views.PersonPreTransaction.Detail] 
 			'list': 'فهرست تراکنش های درخواستی', //GeneratorFlag:[Views.PersonPreTransaction.List] 
 			'update': 'ویرایش درخواست ثبت تراکنش', //GeneratorFlag:[Views.PersonPreTransaction.Update] 
 //GeneratorFlag:[Views.PersonPreTransaction]
		},
		"product": {
			"layout": "محصولات",
			"create": "ثبت جدید محصول", //GeneratorFlag:[Views.Product.Create]
			'createImport': 'ثبت محصولات از طریق اکسل', //GeneratorFlag:[Views.Product.CreateImport] 
 			'delete': 'حذف محصول', //GeneratorFlag:[Views.Product.Delete] 
 			'detail': 'جزئیات اطلاعات محصول', //GeneratorFlag:[Views.Product.Detail] 
 			'list': 'فهرست محصولات', //GeneratorFlag:[Views.Product.List] 
 			'update': 'ویرایش محصول', //GeneratorFlag:[Views.Product.Update] 
 			'register': 'ثبت محصول', //GeneratorFlag:[Views.Product.Register] 
 //GeneratorFlag:[Views.Product]
		},
		"productCategory": {
			"layout": "دسته بندی محصولات",
			"create": "ثبت جدید دسته بندی محصول", //GeneratorFlag:[Views.ProductCategory.Create]
			'createImport': 'ثبت دسته بندی محصولات از طریق اکسل', //GeneratorFlag:[Views.ProductCategory.CreateImport] 
 			'delete': 'حذف دسته بندی محصول', //GeneratorFlag:[Views.ProductCategory.Delete] 
 			'detail': 'جزئیات اطلاعات دسته بندی محصول', //GeneratorFlag:[Views.ProductCategory.Detail] 
 			'list': 'فهرست دسته بندی محصولات', //GeneratorFlag:[Views.ProductCategory.List] 
 			'update': 'ویرایش دسته بندی محصول', //GeneratorFlag:[Views.ProductCategory.Update] 
 			'categoryProductRelatedAction': 'محصولات مرتبط دسته بندی', //GeneratorFlag:[Views.ProductCategory.CategoryProductRelatedAction] 
 			'getProductCategory': 'محصولات دسته بندی', //GeneratorFlag:[Views.ProductCategory.GetProductCategory] 
 			'getProductCategoryListAction': 'product category', //GeneratorFlag:[Views.ProductCategory.GetProductCategoryListAction] 
 //GeneratorFlag:[Views.ProductCategory]
		},
		"productDetail": {
			"layout": "جزئیات های محصول",
			"create": "ثبت جدید جزئیات محصول", //GeneratorFlag:[Views.ProductDetail.Create]
			'createImport': 'ثبت جزئیات های محصول از طریق اکسل', //GeneratorFlag:[Views.ProductDetail.CreateImport] 
 			'delete': 'حذف جزئیات محصول', //GeneratorFlag:[Views.ProductDetail.Delete] 
 			'detail': 'جزئیات اطلاعات جزئیات محصول', //GeneratorFlag:[Views.ProductDetail.Detail] 
 			'list': 'فهرست جزئیات های محصول', //GeneratorFlag:[Views.ProductDetail.List] 
 			'update': 'ویرایش جزئیات محصول', //GeneratorFlag:[Views.ProductDetail.Update] 
 //GeneratorFlag:[Views.ProductDetail]
		},
		"productDoc": {
			"layout": "مدیای محصولات",
			"create": "ثبت جدید مدیای محصول", //GeneratorFlag:[Views.ProductDoc.Create]
			'createImport': 'ثبت مدیای محصولات از طریق اکسل', //GeneratorFlag:[Views.ProductDoc.CreateImport] 
 			'delete': 'حذف مدیای محصول', //GeneratorFlag:[Views.ProductDoc.Delete] 
 			'detail': 'جزئیات اطلاعات مدیای محصول', //GeneratorFlag:[Views.ProductDoc.Detail] 
 			'list': 'فهرست مدیای محصولات', //GeneratorFlag:[Views.ProductDoc.List] 
 			'update': 'ویرایش مدیای محصول', //GeneratorFlag:[Views.ProductDoc.Update] 
 //GeneratorFlag:[Views.ProductDoc]
		},
		"productFeature": {
			"layout": "ویژگی های محصول",
			"create": "ثبت جدید ویژگی محصول", //GeneratorFlag:[Views.ProductFeature.Create]
			'createImport': 'ثبت ویژگی های محصول از طریق اکسل', //GeneratorFlag:[Views.ProductFeature.CreateImport] 
 			'delete': 'حذف ویژگی محصول', //GeneratorFlag:[Views.ProductFeature.Delete] 
 			'detail': 'جزئیات اطلاعات ویژگی محصول', //GeneratorFlag:[Views.ProductFeature.Detail] 
 			'list': 'فهرست ویژگی های محصول', //GeneratorFlag:[Views.ProductFeature.List] 
 			'update': 'ویرایش ویژگی محصول', //GeneratorFlag:[Views.ProductFeature.Update] 
 //GeneratorFlag:[Views.ProductFeature]
		},
		"productMonetaryFeature": {
			"layout": "خصوصیت های محصول",
			"create": "ثبت جدید خصوصیت محصول", //GeneratorFlag:[Views.ProductMonetaryFeature.Create]
			'createImport': 'ثبت خصوصیت های محصول از طریق اکسل', //GeneratorFlag:[Views.ProductMonetaryFeature.CreateImport] 
 			'delete': 'حذف خصوصیت محصول', //GeneratorFlag:[Views.ProductMonetaryFeature.Delete] 
 			'detail': 'جزئیات اطلاعات خصوصیت محصول', //GeneratorFlag:[Views.ProductMonetaryFeature.Detail] 
 			'list': 'فهرست خصوصیت های محصول', //GeneratorFlag:[Views.ProductMonetaryFeature.List] 
 			'update': 'ویرایش خصوصیت محصول', //GeneratorFlag:[Views.ProductMonetaryFeature.Update] 
 //GeneratorFlag:[Views.ProductMonetaryFeature]
		},
		"productStore": {
			"layout": "محصولات فروشگاه",
			"create": "ثبت جدید محصول فروشگاه", //GeneratorFlag:[Views.ProductStore.Create]
			'createImport': 'ثبت محصولات فروشگاه از طریق اکسل', //GeneratorFlag:[Views.ProductStore.CreateImport] 
 			'delete': 'حذف محصول فروشگاه', //GeneratorFlag:[Views.ProductStore.Delete] 
 			'detail': 'جزئیات اطلاعات محصول فروشگاه', //GeneratorFlag:[Views.ProductStore.Detail] 
 			'list': 'فهرست محصولات فروشگاه', //GeneratorFlag:[Views.ProductStore.List] 
 			'update': 'ویرایش محصول فروشگاه', //GeneratorFlag:[Views.ProductStore.Update] 
 			'getProductInfo': 'اطلاعات محصول', //GeneratorFlag:[Views.ProductStore.GetProductInfo] 
 //GeneratorFlag:[Views.ProductStore]
		},
		"store": {
			"layout": "فروشگاه ها",
			"create": "ثبت جدید فروشگاه", //GeneratorFlag:[Views.Store.Create]
			'createImport': 'ثبت فروشگاه ها از طریق اکسل', //GeneratorFlag:[Views.Store.CreateImport] 
 			'delete': 'حذف فروشگاه', //GeneratorFlag:[Views.Store.Delete] 
 			'detail': 'جزئیات اطلاعات فروشگاه', //GeneratorFlag:[Views.Store.Detail] 
 			'list': 'فهرست فروشگاه ها', //GeneratorFlag:[Views.Store.List] 
 			'update': 'ویرایش فروشگاه', //GeneratorFlag:[Views.Store.Update] 
 //GeneratorFlag:[Views.Store]
		},
		"bank": {
			"layout": " بانک ها",
			"create": "ثبت جدید بانک", //GeneratorFlag:[Views.Bank.Create]
			'createImport': 'ثبت بانک ها از طریق اکسل', //GeneratorFlag:[Views.Bank.CreateImport] 
 			'delete': 'حذف بانک', //GeneratorFlag:[Views.Bank.Delete] 
 			'detail': 'جزئیات اطلاعات بانک', //GeneratorFlag:[Views.Bank.Detail] 
 			'list': 'فهرست بانک ها', //GeneratorFlag:[Views.Bank.List] 
 			'update': 'ویرایش بانک', //GeneratorFlag:[Views.Bank.Update] 
 //GeneratorFlag:[Views.Bank]
		},
		"category": {
			"layout": " انواع دسته بندی",
			"create": "ثبت جدید دسته بندی", //GeneratorFlag:[Views.Category.Create]
			'createImport': 'ثبت انواع دسته بندی از طریق اکسل', //GeneratorFlag:[Views.Category.CreateImport] 
 			'delete': 'حذف دسته بندی', //GeneratorFlag:[Views.Category.Delete] 
 			'detail': 'جزئیات اطلاعات دسته بندی', //GeneratorFlag:[Views.Category.Detail] 
 			'list': 'فهرست انواع دسته بندی', //GeneratorFlag:[Views.Category.List] 
 			'update': 'ویرایش دسته بندی', //GeneratorFlag:[Views.Category.Update] 
 			'listAction': 'لیست دسته بندی ها', //GeneratorFlag:[Views.Category.ListAction] 
 //GeneratorFlag:[Views.Category]
		},
		"feature": {
			"layout": " ویژگی ها",
			"create": "ثبت جدید ویژگی", //GeneratorFlag:[Views.Feature.Create]
			'createImport': 'ثبت ویژگی ها از طریق اکسل', //GeneratorFlag:[Views.Feature.CreateImport] 
 			'delete': 'حذف ویژگی', //GeneratorFlag:[Views.Feature.Delete] 
 			'detail': 'جزئیات اطلاعات ویژگی', //GeneratorFlag:[Views.Feature.Detail] 
 			'list': 'فهرست ویژگی ها', //GeneratorFlag:[Views.Feature.List] 
 			'update': 'ویرایش ویژگی', //GeneratorFlag:[Views.Feature.Update] 
 //GeneratorFlag:[Views.Feature]
		},
		"featureItem": {
			"layout": " آیتم های ویژگی ها",
			"create": "ثبت جدید آیتم ویژگی", //GeneratorFlag:[Views.FeatureItem.Create]
			'createImport': 'ثبت آیتم های ویژگی ها از طریق اکسل', //GeneratorFlag:[Views.FeatureItem.CreateImport] 
 			'delete': 'حذف آیتم ویژگی', //GeneratorFlag:[Views.FeatureItem.Delete] 
 			'detail': 'جزئیات اطلاعات آیتم ویژگی', //GeneratorFlag:[Views.FeatureItem.Detail] 
 			'list': 'فهرست آیتم های ویژگی ها', //GeneratorFlag:[Views.FeatureItem.List] 
 			'update': 'ویرایش آیتم ویژگی', //GeneratorFlag:[Views.FeatureItem.Update] 
 //GeneratorFlag:[Views.FeatureItem]
		},
		"planPrecent": {
			"layout": " درصد پلن محرمانه",
			"create": "ثبت جدید درصد پلن محرمانه", //GeneratorFlag:[Views.PlanPrecent.Create]
			'createImport': 'ثبت درصد پلن محرمانه از طریق اکسل', //GeneratorFlag:[Views.PlanPrecent.CreateImport] 
 			'delete': 'حذف درصد پلن محرمانه', //GeneratorFlag:[Views.PlanPrecent.Delete] 
 			'detail': 'جزئیات اطلاعات درصد پلن محرمانه', //GeneratorFlag:[Views.PlanPrecent.Detail] 
 			'list': 'فهرست درصد پلن محرمانه', //GeneratorFlag:[Views.PlanPrecent.List] 
 			'update': 'ویرایش درصد پلن محرمانه', //GeneratorFlag:[Views.PlanPrecent.Update] 
 //GeneratorFlag:[Views.PlanPrecent]
		},
		"planSetting": {
			"layout": " تنظیمات پلن(محرمانه)",
			"create": "ثبت جدید تنظیمات پلن(محرمانه)", //GeneratorFlag:[Views.PlanSetting.Create]
			'createImport': 'ثبت تنظیمات پلن(محرمانه) از طریق اکسل', //GeneratorFlag:[Views.PlanSetting.CreateImport] 
 			'delete': 'حذف تنظیمات پلن(محرمانه)', //GeneratorFlag:[Views.PlanSetting.Delete] 
 			'detail': 'جزئیات اطلاعات تنظیمات پلن(محرمانه)', //GeneratorFlag:[Views.PlanSetting.Detail] 
 			'list': 'فهرست تنظیمات پلن(محرمانه)', //GeneratorFlag:[Views.PlanSetting.List] 
 			'update': 'ویرایش تنظیمات پلن(محرمانه)', //GeneratorFlag:[Views.PlanSetting.Update] 
 //GeneratorFlag:[Views.PlanSetting]
		},
		"productColor": {
			"layout": " رنگ محصولات",
			"create": "ثبت جدید رنگ محصولات", //GeneratorFlag:[Views.ProductColor.Create]
			'createImport': 'ثبت رنگ محصولات از طریق اکسل', //GeneratorFlag:[Views.ProductColor.CreateImport] 
 			'delete': 'حذف رنگ محصولات', //GeneratorFlag:[Views.ProductColor.Delete] 
 			'detail': 'جزئیات اطلاعات رنگ محصولات', //GeneratorFlag:[Views.ProductColor.Detail] 
 			'list': 'فهرست رنگ محصولات', //GeneratorFlag:[Views.ProductColor.List] 
 			'update': 'ویرایش رنگ محصولات', //GeneratorFlag:[Views.ProductColor.Update] 
 //GeneratorFlag:[Views.ProductColor]
		},
		"storeProfitRate": {
			"layout": " نرخ های سود ماهانه",
			"create": "ثبت جدید نرخ سود ماهانه", //GeneratorFlag:[Views.StoreProfitRate.Create]
			'createImport': 'ثبت نرخ های سود ماهانه از طریق اکسل', //GeneratorFlag:[Views.StoreProfitRate.CreateImport] 
 			'delete': 'حذف نرخ سود ماهانه', //GeneratorFlag:[Views.StoreProfitRate.Delete] 
 			'detail': 'جزئیات اطلاعات نرخ سود ماهانه', //GeneratorFlag:[Views.StoreProfitRate.Detail] 
 			'list': 'فهرست نرخ های سود ماهانه', //GeneratorFlag:[Views.StoreProfitRate.List] 
 			'update': 'ویرایش نرخ سود ماهانه', //GeneratorFlag:[Views.StoreProfitRate.Update] 
 //GeneratorFlag:[Views.StoreProfitRate]
		},
		"bankPaymentStatuse": {
			"layout": "وضعیت های درگاه",
			"detail": "جزئیات اطلاعات وضعیت درگاه", //GeneratorFlag:[Views.BankPaymentStatuse.Detail]
			'list': 'فهرست وضعیت های درگاه', //GeneratorFlag:[Views.BankPaymentStatuse.List] 
 //GeneratorFlag:[Views.BankPaymentStatuse]
		},
		"culture": {
			"layout": "زبان ها",
			"detail": "جزئیات اطلاعات زبان", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'فهرست زبان ها', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"invoiceCancelStatuse": {
			"layout": "وضعیت کنسل",
			"detail": "جزئیات اطلاعات وضعیت کنسل", //GeneratorFlag:[Views.InvoiceCancelStatuse.Detail]
			'list': 'فهرست وضعیت کنسل', //GeneratorFlag:[Views.InvoiceCancelStatuse.List] 
 //GeneratorFlag:[Views.InvoiceCancelStatuse]
		},
		"invoicePaymentType": {
			"layout": "روش های پرداخت",
			"detail": "جزئیات اطلاعات روش پرداخت", //GeneratorFlag:[Views.InvoicePaymentType.Detail]
			'list': 'فهرست روش های پرداخت', //GeneratorFlag:[Views.InvoicePaymentType.List] 
 //GeneratorFlag:[Views.InvoicePaymentType]
		},
		"invoiceStatuse": {
			"layout": "وضعیت های فاکتور",
			"detail": "جزئیات اطلاعات وضعیت فاکتور", //GeneratorFlag:[Views.InvoiceStatuse.Detail]
			'list': 'فهرست وضعیت های فاکتور', //GeneratorFlag:[Views.InvoiceStatuse.List] 
 //GeneratorFlag:[Views.InvoiceStatuse]
		},
		"invoiceTransactionType": {
			"layout": "انواع تراکنش های فاکتور",
			"detail": "جزئیات اطلاعات نوع تراکنش فاکتور", //GeneratorFlag:[Views.InvoiceTransactionType.Detail]
			'list': 'فهرست انواع تراکنش های فاکتور', //GeneratorFlag:[Views.InvoiceTransactionType.List] 
 //GeneratorFlag:[Views.InvoiceTransactionType]
		},
		"personCreaditTransactionType": {
			"layout": "انواع تراکنش",
			"detail": "جزئیات اطلاعات نوع تراکنش", //GeneratorFlag:[Views.PersonCreaditTransactionType.Detail]
			'list': 'فهرست انواع تراکنش', //GeneratorFlag:[Views.PersonCreaditTransactionType.List] 
 //GeneratorFlag:[Views.PersonCreaditTransactionType]
		},
		"personPreTransactionStatuse": {
			"layout": "انواع وضعیت تراکنش",
			"detail": "جزئیات اطلاعات وضعیت تراکنش", //GeneratorFlag:[Views.PersonPreTransactionStatuse.Detail]
			'list': 'فهرست انواع وضعیت تراکنش', //GeneratorFlag:[Views.PersonPreTransactionStatuse.List] 
 //GeneratorFlag:[Views.PersonPreTransactionStatuse]
		},
		"planTransactionStatuse": {
			"layout": "PlanTransactionStatuse",
			"detail": "جزئیات اطلاعات PlanTransactionStatuse", //GeneratorFlag:[Views.PlanTransactionStatuse.Detail]
			'list': 'فهرست PlanTransactionStatuse', //GeneratorFlag:[Views.PlanTransactionStatuse.List] 
 //GeneratorFlag:[Views.PlanTransactionStatuse]
		},
		"planTransactionType": {
			"layout": "PlanTransactionType",
			"detail": "جزئیات اطلاعات PlanTransactionType", //GeneratorFlag:[Views.PlanTransactionType.Detail]
			'list': 'فهرست PlanTransactionType', //GeneratorFlag:[Views.PlanTransactionType.List] 
 //GeneratorFlag:[Views.PlanTransactionType]
		},
		"productDocType": {
			"layout": "انواع مدیا",
			"detail": "جزئیات اطلاعات نوع مدیا", //GeneratorFlag:[Views.ProductDocType.Detail]
			'list': 'فهرست انواع مدیا', //GeneratorFlag:[Views.ProductDocType.List] 
 //GeneratorFlag:[Views.ProductDocType]
		},
		"productStatus": {
			"layout": "وضعیت های کالا",
			"detail": "جزئیات اطلاعات وضعیت کالا", //GeneratorFlag:[Views.ProductStatus.Detail]
			'list': 'فهرست وضعیت های کالا', //GeneratorFlag:[Views.ProductStatus.List] 
 //GeneratorFlag:[Views.ProductStatus]
		},
		"productTransportCostType": {
			"layout": "نحوه محاسبه هزینه ارسال",
			"detail": "جزئیات اطلاعات نحوه محاسبه هزینه ارسال", //GeneratorFlag:[Views.ProductTransportCostType.Detail]
			'list': 'فهرست نحوه محاسبه هزینه ارسال', //GeneratorFlag:[Views.ProductTransportCostType.List] 
 //GeneratorFlag:[Views.ProductTransportCostType]
		},
		"planLine": {
			"layout": "پورسانت هفته جاری",
			"getCommisionCurrentWeek": "پورسانت هفته جاری", //GeneratorFlag:[Views.PlanLine.GetCommisionCurrentWeek]
			'getMyDirect': 'ورودی های مستقیم من', //GeneratorFlag:[Views.PlanLine.GetMyDirect] 
 //GeneratorFlag:[Views.PlanLine]
		},
		"planTransaction": {
			"layout": "ثبت پورسانت",
			"calculatePlan": "محاسبه پلن", //GeneratorFlag:[Views.PlanTransaction.CalculatePlan]
			'getListCommision': 'لیست پاداش ها', //GeneratorFlag:[Views.PlanTransaction.GetListCommision] 
 			'getReward': 'لیست پاداش ها', //GeneratorFlag:[Views.PlanTransaction.GetReward] 
 			'getVoacherAwards': 'لیست ووچر ها', //GeneratorFlag:[Views.PlanTransaction.GetVoacherAwards] 
 			'list': 'فهرست لیست پاداش ها', //GeneratorFlag:[Views.PlanTransaction.List] 
 			'registerExtraCommision': 'ثبت پورسانت مازاد', //GeneratorFlag:[Views.PlanTransaction.RegisterExtraCommision] 
 			'totalReport': 'مجموع پاداش ها', //GeneratorFlag:[Views.PlanTransaction.TotalReport] 
 			'totalReward': 'مجموع پاداش ها', //GeneratorFlag:[Views.PlanTransaction.TotalReward] 
 //GeneratorFlag:[Views.PlanTransaction]
		},
		"planTransactionCommentType": {
			"layout": "نوع توضیح پلن",
			"detail": "جزئیات اطلاعات نوع توضیح پلن", //GeneratorFlag:[Views.PlanTransactionCommentType.Detail]
			'list': 'فهرست نوع توضیح پلن', //GeneratorFlag:[Views.PlanTransactionCommentType.List] 
 //GeneratorFlag:[Views.PlanTransactionCommentType]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[BankPayment.Create] {
	"bankPaymentCreate": {
		"self": "درگاه پرداخت",
		"bankPaymentId": "شناسه",
		"accountNumber": "شماره حساب",
		"shabaNumber": "شماره شبا",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"sendUrl": "آدرس ارسال",
		"postBackUrl": "آدرس برگشت",
		"additionalData": "اطلاعات بیشتر",
		"storeId": "شناسه فروشگاه",
		"bankId": "شناسه بانک",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "از مبلغ",
		"toRange": "تا مبلغ",
	},
	//GeneratorFlag:[BankPayment.Delete] {
	"bankPaymentDelete": {
		"self": "درگاه پرداخت",
		"bankPaymentId": "شناسه",
	},
	//GeneratorFlag:[BankPayment.Detail] {
	"bankPaymentDetail": {
		"self": "درگاه پرداخت",
		"bankPaymentId": "شناسه",
		"accountNumber": "شماره حساب",
		"shabaNumber": "شماره شبا",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"sendUrl": "آدرس ارسال",
		"postBackUrl": "آدرس برگشت",
		"additionalData": "اطلاعات بیشتر",
		"storeId": "شناسه فروشگاه",
		"bankId": "شناسه بانک",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "از مبلغ",
		"toRange": "تا مبلغ",
		"bank": {
			"self": "شناسه بانک",
			"bankId": "شناسه",
			"title": "نام",
			"iconUrl": "آیکن",
		},
		"bankPaymentStatuse": {
			"self": "BankPaymentStatuseId",
			"bankPaymentStatuseId": "شناسه",
			"bankPaymentStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"store": {
			"self": "شناسه فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"bankPaymentSubInfo": "bank payment sub info",
	},
	//GeneratorFlag:[BankPayment.List] {
	"bankPaymentList": {
		"self": "درگاه پرداخت",
		"bankPaymentId": "شناسه",
		"accountNumber": "شماره حساب",
		"shabaNumber": "شماره شبا",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"sendUrl": "آدرس ارسال",
		"postBackUrl": "آدرس برگشت",
		"additionalData": "اطلاعات بیشتر",
		"storeId": "شناسه فروشگاه",
		"bankId": "شناسه بانک",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "از مبلغ",
		"toRange": "تا مبلغ",
		"bank": {
			"self": "شناسه بانک",
			"bankId": "شناسه",
			"title": "نام",
			"iconUrl": "آیکن",
		},
		"bankPaymentStatuse": {
			"self": "BankPaymentStatuseId",
			"bankPaymentStatuseId": "شناسه",
			"bankPaymentStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"store": {
			"self": "شناسه فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"bankPaymentSubInfo": "bank payment sub info",
	},
	//GeneratorFlag:[BankPayment.Filter] {
	"bankPaymentFilter": {
		"self": "درگاه پرداخت",
		"bankPaymentId": "شناسه",
		"accountNumber": "شماره حساب",
		"shabaNumber": "شماره شبا",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"sendUrl": "آدرس ارسال",
		"postBackUrl": "آدرس برگشت",
		"additionalData": "اطلاعات بیشتر",
		"storeId": "شناسه فروشگاه",
		"bankId": "شناسه بانک",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "از مبلغ",
		"toRange": "تا مبلغ",
		"bank": {
			"self": "شناسه بانک",
			"bankId": "شناسه",
			"title": "نام",
			"iconUrl": "آیکن",
		},
		"bankPaymentStatuse": {
			"self": "BankPaymentStatuseId",
			"bankPaymentStatuseId": "شناسه",
			"bankPaymentStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"store": {
			"self": "شناسه فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"bankPaymentSubInfo": "bank payment sub info",
	},
	//GeneratorFlag:[BankPayment.Update] {
	"bankPaymentUpdate": {
		"self": "درگاه پرداخت",
		"bankPaymentId": "شناسه",
		"accountNumber": "شماره حساب",
		"shabaNumber": "شماره شبا",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"sendUrl": "آدرس ارسال",
		"postBackUrl": "آدرس برگشت",
		"additionalData": "اطلاعات بیشتر",
		"storeId": "شناسه فروشگاه",
		"bankId": "شناسه بانک",
		"bankPaymentStatuseId": "bank payment statuse",
		"fromRange": "از مبلغ",
		"toRange": "تا مبلغ",
	},
	//GeneratorFlag:[Invoice.Create] {
	"invoiceCreate": {
		"self": "فاکتور",
		"invoiceId": "شناسه",
		"personId": "شخص",
		"totalAmount": "جمع کل",
		"discountAmount": "تخفیف",
		"paybleAmount": "قابل پرداخت",
		"paidAmount": "پرداخت شده",
		"totalRemainAmount": "باقی مانده",
		"invoiceStatuseId": "وضعیت",
		"createdDate": "تاریخ صدور",
		"transportCostAmount": "هزینه حمل و نقل",
		"packagingCostAmount": "هزینه بسته بندی",
		"taxAmount": "مالیات",
		"checkoutDate": "تاریخ تسویه",
		"displayCheckoutDate": "تاریخ تسویه",
		"invoicePaymentTypeId": "نوع پرداخت",
		"storeId": "فروشگاه",
		"personAddressId": "آدرس",
		"invoiceSerial": "سریال",
		"isConnected": "منتقل شده",
		"invoiceDetail": {
			"self": "جزئیات محاسبه فاکتور",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
	},
	//GeneratorFlag:[Invoice.Delete] {
	"invoiceDelete": {
		"self": "فاکتور",
		"invoiceId": "شناسه",
	},
	//GeneratorFlag:[Invoice.Detail] {
	"invoiceDetail": {
		"self": "فاکتور",
		"invoiceId": "شناسه",
		"personId": "شخص",
		"totalAmount": "جمع کل",
		"discountAmount": "تخفیف",
		"paybleAmount": "قابل پرداخت",
		"paidAmount": "پرداخت شده",
		"totalRemainAmount": "باقی مانده",
		"invoiceStatuseId": "وضعیت",
		"createdDate": "تاریخ صدور",
		"transportCostAmount": "هزینه حمل و نقل",
		"packagingCostAmount": "هزینه بسته بندی",
		"taxAmount": "مالیات",
		"checkoutDate": "تاریخ تسویه",
		"displayCheckoutDate": "تاریخ تسویه",
		"invoicePaymentTypeId": "نوع پرداخت",
		"storeId": "فروشگاه",
		"personAddressId": "آدرس",
		"invoiceSerial": "سریال",
		"isConnected": "منتقل شده",
		"invoicePaymentType": {
			"self": "نوع پرداخت",
			"invoicePaymentTypeId": "شناسه",
			"invoicePaymentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"invoiceStatuse": {
			"self": "وضعیت",
			"invoiceStatuseId": "شناسه",
			"invoiceStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddress": {
			"self": "آدرس",
			"personAddressId": "شناسه",
			"personId": "شخص",
			"addressTitle": "عنوان آدرس",
			"reciverFirstName": "نام",
			"reciverLastName": "نام خانوادگی",
			"reciverMobile": "همراه",
			"cityId": "شهر",
			"fullAddress": "آدرس",
			"plaque": "پلاک",
			"postalCode": "کد پستی",
		},
		"store": {
			"self": "فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"invoiceDetail": {
			"self": "جزئیات محاسبه فاکتور",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
		"invoiceSubInfo": "invoice sub info",
		"shopPerson": {
			"self": "اشخاص",
			"personId": "person",
			"fullName": "نام و نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankName": "رنک",
		},
	},
	//GeneratorFlag:[Invoice.List] {
	"invoiceList": {
		"self": "فاکتور",
		"invoiceId": "شناسه",
		"personId": "شخص",
		"totalAmount": "جمع کل",
		"discountAmount": "تخفیف",
		"paybleAmount": "قابل پرداخت",
		"paidAmount": "پرداخت شده",
		"totalRemainAmount": "باقی مانده",
		"invoiceStatuseId": "وضعیت",
		"createdDate": "تاریخ صدور",
		"transportCostAmount": "هزینه حمل و نقل",
		"packagingCostAmount": "هزینه بسته بندی",
		"taxAmount": "مالیات",
		"checkoutDate": "تاریخ تسویه",
		"displayCheckoutDate": "تاریخ تسویه",
		"invoicePaymentTypeId": "نوع پرداخت",
		"storeId": "فروشگاه",
		"personAddressId": "آدرس",
		"invoiceSerial": "سریال",
		"isConnected": "منتقل شده",
		"invoicePaymentType": {
			"self": "نوع پرداخت",
			"invoicePaymentTypeId": "شناسه",
			"invoicePaymentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"invoiceStatuse": {
			"self": "وضعیت",
			"invoiceStatuseId": "شناسه",
			"invoiceStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddress": {
			"self": "آدرس",
			"personAddressId": "شناسه",
			"personId": "شخص",
			"addressTitle": "عنوان آدرس",
			"reciverFirstName": "نام",
			"reciverLastName": "نام خانوادگی",
			"reciverMobile": "همراه",
			"cityId": "شهر",
			"fullAddress": "آدرس",
			"plaque": "پلاک",
			"postalCode": "کد پستی",
		},
		"store": {
			"self": "فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"invoiceDetail": {
			"self": "جزئیات محاسبه فاکتور",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
		"invoiceSubInfo": "invoice sub info",
		"shopPerson": {
			"self": "اشخاص",
			"personId": "person",
			"fullName": "نام و نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankName": "رنک",
		},
	},
	//GeneratorFlag:[Invoice.Filter] {
	"invoiceFilter": {
		"self": "فاکتور",
		"invoiceId": "شناسه",
		"personId": "شخص",
		"totalAmount": "جمع کل",
		"discountAmount": "تخفیف",
		"paybleAmount": "قابل پرداخت",
		"paidAmount": "پرداخت شده",
		"totalRemainAmount": "باقی مانده",
		"invoiceStatuseId": "وضعیت",
		"createdDate": "تاریخ صدور",
		"transportCostAmount": "هزینه حمل و نقل",
		"packagingCostAmount": "هزینه بسته بندی",
		"taxAmount": "مالیات",
		"checkoutDate": "تاریخ تسویه",
		"displayCheckoutDate": "تاریخ تسویه",
		"invoicePaymentTypeId": "نوع پرداخت",
		"storeId": "فروشگاه",
		"personAddressId": "آدرس",
		"invoiceSerial": "سریال",
		"isConnected": "منتقل شده",
		"invoicePaymentType": {
			"self": "نوع پرداخت",
			"invoicePaymentTypeId": "شناسه",
			"invoicePaymentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"invoiceStatuse": {
			"self": "وضعیت",
			"invoiceStatuseId": "شناسه",
			"invoiceStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddress": {
			"self": "آدرس",
			"personAddressId": "شناسه",
			"personId": "شخص",
			"addressTitle": "عنوان آدرس",
			"reciverFirstName": "نام",
			"reciverLastName": "نام خانوادگی",
			"reciverMobile": "همراه",
			"cityId": "شهر",
			"fullAddress": "آدرس",
			"plaque": "پلاک",
			"postalCode": "کد پستی",
		},
		"store": {
			"self": "فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"invoiceDetail": {
			"self": "جزئیات محاسبه فاکتور",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
		"invoiceSubInfo": "invoice sub info",
		"shopPerson": {
			"self": "اشخاص",
			"personId": "person",
			"fullName": "نام و نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankName": "رنک",
		},
	},
	//GeneratorFlag:[Invoice.Update] {
	"invoiceUpdate": {
		"self": "فاکتور",
		"invoiceId": "شناسه",
		"personId": "شخص",
		"totalAmount": "جمع کل",
		"discountAmount": "تخفیف",
		"paybleAmount": "قابل پرداخت",
		"paidAmount": "پرداخت شده",
		"totalRemainAmount": "باقی مانده",
		"invoiceStatuseId": "وضعیت",
		"createdDate": "تاریخ صدور",
		"transportCostAmount": "هزینه حمل و نقل",
		"packagingCostAmount": "هزینه بسته بندی",
		"taxAmount": "مالیات",
		"checkoutDate": "تاریخ تسویه",
		"displayCheckoutDate": "تاریخ تسویه",
		"invoicePaymentTypeId": "نوع پرداخت",
		"storeId": "فروشگاه",
		"personAddressId": "آدرس",
		"invoiceSerial": "سریال",
		"isConnected": "منتقل شده",
		"invoiceDetail": {
			"self": "جزئیات محاسبه فاکتور",
			"invoiceId": "شناسه",
			"createDate": "تاریخ شروع قرارداد",
			"firstCalcDate": "اولین تاریخ محاسبه",
			"nextCalcDate": "تاریخ محاسبه بعدی",
			"lastCalcDate": "آخرین تاریخ محاسبه",
			"displayLastCalcDate": "آخرین تاریخ محاسبه",
			"displayCreateDate": "تاریخ شروع قرارداد",
			"displayFirstCalcDate": "اولین تاریخ محاسبه",
			"displayNextCalcDate": "تاریخ محاسبه بعدی",
		},
	},
	//GeneratorFlag:[InvoiceCancel.Create] {
	"invoiceCancelCreate": {
		"self": "InvoiceCancel",
		"invoiceCancelId": "شناسه",
		"invoiceId": "فاکتور",
		"cancelDatetime": "تاریخ",
		"displayCancelDatetime": "تاریخ",
		"ipAddress": "آی پی درخواست",
		"personId": "تایید کننده",
		"totalCommisionFromInvoice": "پورسانت های فاکتور",
		"paybleAmount": "قابل پرداخت",
	},
	//GeneratorFlag:[InvoiceCancel.Delete] {
	"invoiceCancelDelete": {
		"self": "InvoiceCancel",
		"invoiceCancelId": "شناسه",
	},
	//GeneratorFlag:[InvoiceCancel.Detail] {
	"invoiceCancelDetail": {
		"self": "InvoiceCancel",
		"invoiceCancelId": "شناسه",
		"invoiceId": "فاکتور",
		"cancelDatetime": "تاریخ",
		"displayCancelDatetime": "تاریخ",
		"ipAddress": "آی پی درخواست",
		"personId": "تایید کننده",
		"totalCommisionFromInvoice": "پورسانت های فاکتور",
		"paybleAmount": "قابل پرداخت",
		"invoice": {
			"self": "فاکتور",
			"invoiceId": "شناسه",
			"personId": "شخص",
			"totalAmount": "جمع کل",
			"discountAmount": "تخفیف",
			"paybleAmount": "قابل پرداخت",
			"paidAmount": "پرداخت شده",
			"totalRemainAmount": "باقی مانده",
			"invoiceStatuseId": "وضعیت",
			"createdDate": "تاریخ صدور",
			"transportCostAmount": "هزینه حمل و نقل",
			"packagingCostAmount": "هزینه بسته بندی",
			"taxAmount": "مالیات",
			"checkoutDate": "تاریخ تسویه",
			"displayCheckoutDate": "تاریخ تسویه",
			"invoicePaymentTypeId": "نوع پرداخت",
			"storeId": "فروشگاه",
			"personAddressId": "آدرس",
			"invoiceSerial": "سریال",
			"isConnected": "منتقل شده",
		},
		"person": {
			"self": "تایید کننده",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"invoiceCancelSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[InvoiceCancel.List] {
	"invoiceCancelList": {
		"self": "InvoiceCancel",
		"invoiceCancelId": "شناسه",
		"invoiceId": "فاکتور",
		"cancelDatetime": "تاریخ",
		"displayCancelDatetime": "تاریخ",
		"ipAddress": "آی پی درخواست",
		"personId": "تایید کننده",
		"totalCommisionFromInvoice": "پورسانت های فاکتور",
		"paybleAmount": "قابل پرداخت",
		"invoice": {
			"self": "فاکتور",
			"invoiceId": "شناسه",
			"personId": "شخص",
			"totalAmount": "جمع کل",
			"discountAmount": "تخفیف",
			"paybleAmount": "قابل پرداخت",
			"paidAmount": "پرداخت شده",
			"totalRemainAmount": "باقی مانده",
			"invoiceStatuseId": "وضعیت",
			"createdDate": "تاریخ صدور",
			"transportCostAmount": "هزینه حمل و نقل",
			"packagingCostAmount": "هزینه بسته بندی",
			"taxAmount": "مالیات",
			"checkoutDate": "تاریخ تسویه",
			"displayCheckoutDate": "تاریخ تسویه",
			"invoicePaymentTypeId": "نوع پرداخت",
			"storeId": "فروشگاه",
			"personAddressId": "آدرس",
			"invoiceSerial": "سریال",
			"isConnected": "منتقل شده",
		},
		"person": {
			"self": "تایید کننده",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"invoiceCancelSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[InvoiceCancel.Filter] {
	"invoiceCancelFilter": {
		"self": "InvoiceCancel",
		"invoiceCancelId": "شناسه",
		"invoiceId": "فاکتور",
		"cancelDatetime": "تاریخ",
		"displayCancelDatetime": "تاریخ",
		"ipAddress": "آی پی درخواست",
		"personId": "تایید کننده",
		"totalCommisionFromInvoice": "پورسانت های فاکتور",
		"paybleAmount": "قابل پرداخت",
		"invoice": {
			"self": "فاکتور",
			"invoiceId": "شناسه",
			"personId": "شخص",
			"totalAmount": "جمع کل",
			"discountAmount": "تخفیف",
			"paybleAmount": "قابل پرداخت",
			"paidAmount": "پرداخت شده",
			"totalRemainAmount": "باقی مانده",
			"invoiceStatuseId": "وضعیت",
			"createdDate": "تاریخ صدور",
			"transportCostAmount": "هزینه حمل و نقل",
			"packagingCostAmount": "هزینه بسته بندی",
			"taxAmount": "مالیات",
			"checkoutDate": "تاریخ تسویه",
			"displayCheckoutDate": "تاریخ تسویه",
			"invoicePaymentTypeId": "نوع پرداخت",
			"storeId": "فروشگاه",
			"personAddressId": "آدرس",
			"invoiceSerial": "سریال",
			"isConnected": "منتقل شده",
		},
		"person": {
			"self": "تایید کننده",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"invoiceCancelSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[InvoiceCancel.Update] {
	"invoiceCancelUpdate": {
		"self": "InvoiceCancel",
		"invoiceCancelId": "شناسه",
		"invoiceId": "فاکتور",
		"cancelDatetime": "تاریخ",
		"displayCancelDatetime": "تاریخ",
		"ipAddress": "آی پی درخواست",
		"personId": "تایید کننده",
		"totalCommisionFromInvoice": "پورسانت های فاکتور",
		"paybleAmount": "قابل پرداخت",
	},
	//GeneratorFlag:[PersonAddress.Create] {
	"personAddressCreate": {
		"self": "آدرس",
		"personAddressId": "شناسه",
		"personId": "شخص",
		"addressTitle": "عنوان آدرس",
		"reciverFirstName": "نام",
		"reciverLastName": "نام خانوادگی",
		"reciverMobile": "همراه",
		"cityId": "شهر",
		"fullAddress": "آدرس",
		"plaque": "پلاک",
		"postalCode": "کد پستی",
	},
	//GeneratorFlag:[PersonAddress.Delete] {
	"personAddressDelete": {
		"self": "آدرس",
		"personAddressId": "شناسه",
	},
	//GeneratorFlag:[PersonAddress.Detail] {
	"personAddressDetail": {
		"self": "آدرس",
		"personAddressId": "شناسه",
		"personId": "شخص",
		"addressTitle": "عنوان آدرس",
		"reciverFirstName": "نام",
		"reciverLastName": "نام خانوادگی",
		"reciverMobile": "همراه",
		"cityId": "شهر",
		"fullAddress": "آدرس",
		"plaque": "پلاک",
		"postalCode": "کد پستی",
		"city": {
			"self": "شهر",
			"cityId": "شناسه",
			"title": "عنوان",
			"provinceId": "استان",
		},
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddressSubInfo": "person address sub info",
	},
	//GeneratorFlag:[PersonAddress.List] {
	"personAddressList": {
		"self": "آدرس",
		"personAddressId": "شناسه",
		"personId": "شخص",
		"addressTitle": "عنوان آدرس",
		"reciverFirstName": "نام",
		"reciverLastName": "نام خانوادگی",
		"reciverMobile": "همراه",
		"cityId": "شهر",
		"fullAddress": "آدرس",
		"plaque": "پلاک",
		"postalCode": "کد پستی",
		"city": {
			"self": "شهر",
			"cityId": "شناسه",
			"title": "عنوان",
			"provinceId": "استان",
		},
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddressSubInfo": "person address sub info",
	},
	//GeneratorFlag:[PersonAddress.Filter] {
	"personAddressFilter": {
		"self": "آدرس",
		"personAddressId": "شناسه",
		"personId": "شخص",
		"addressTitle": "عنوان آدرس",
		"reciverFirstName": "نام",
		"reciverLastName": "نام خانوادگی",
		"reciverMobile": "همراه",
		"cityId": "شهر",
		"fullAddress": "آدرس",
		"plaque": "پلاک",
		"postalCode": "کد پستی",
		"city": {
			"self": "شهر",
			"cityId": "شناسه",
			"title": "عنوان",
			"provinceId": "استان",
		},
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personAddressSubInfo": "person address sub info",
	},
	//GeneratorFlag:[PersonAddress.Update] {
	"personAddressUpdate": {
		"self": "آدرس",
		"personAddressId": "شناسه",
		"personId": "شخص",
		"addressTitle": "عنوان آدرس",
		"reciverFirstName": "نام",
		"reciverLastName": "نام خانوادگی",
		"reciverMobile": "همراه",
		"cityId": "شهر",
		"fullAddress": "آدرس",
		"plaque": "پلاک",
		"postalCode": "کد پستی",
	},
	//GeneratorFlag:[PersonPreTransaction.Create] {
	"personPreTransactionCreate": {
		"self": "درخواست ثبت تراکنش",
		"personPreTransactionId": "شناسه",
		"transactionFrom": "مبدا",
		"amount": "مقدار",
		"refCode": "کد تراکنش",
		"personPreTransactionStatuseId": "وضعیت",
		"personId": "عضو",
	},
	//GeneratorFlag:[PersonPreTransaction.Delete] {
	"personPreTransactionDelete": {
		"self": "درخواست ثبت تراکنش",
		"personPreTransactionId": "شناسه",
	},
	//GeneratorFlag:[PersonPreTransaction.Detail] {
	"personPreTransactionDetail": {
		"self": "درخواست ثبت تراکنش",
		"personPreTransactionId": "شناسه",
		"transactionFrom": "مبدا",
		"amount": "مقدار",
		"refCode": "کد تراکنش",
		"personPreTransactionStatuseId": "وضعیت",
		"personId": "عضو",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personPreTransactionStatuse": {
			"self": "وضعیت",
			"personPreTransactionStatuseId": "person pre transaction statuse id",
			"personPreTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personPreTransactionSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonPreTransaction.List] {
	"personPreTransactionList": {
		"self": "درخواست ثبت تراکنش",
		"personPreTransactionId": "شناسه",
		"transactionFrom": "مبدا",
		"amount": "مقدار",
		"refCode": "کد تراکنش",
		"personPreTransactionStatuseId": "وضعیت",
		"personId": "عضو",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personPreTransactionStatuse": {
			"self": "وضعیت",
			"personPreTransactionStatuseId": "person pre transaction statuse id",
			"personPreTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personPreTransactionSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonPreTransaction.Filter] {
	"personPreTransactionFilter": {
		"self": "درخواست ثبت تراکنش",
		"personPreTransactionId": "شناسه",
		"transactionFrom": "مبدا",
		"amount": "مقدار",
		"refCode": "کد تراکنش",
		"personPreTransactionStatuseId": "وضعیت",
		"personId": "عضو",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStauseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
		},
		"personPreTransactionStatuse": {
			"self": "وضعیت",
			"personPreTransactionStatuseId": "person pre transaction statuse id",
			"personPreTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personPreTransactionSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonPreTransaction.Update] {
	"personPreTransactionUpdate": {
		"self": "درخواست ثبت تراکنش",
		"personPreTransactionId": "شناسه",
		"transactionFrom": "مبدا",
		"amount": "مقدار",
		"refCode": "کد تراکنش",
		"personPreTransactionStatuseId": "وضعیت",
		"personId": "عضو",
	},
	//GeneratorFlag:[Product.Create] {
	"productCreate": {
		"self": "محصول",
		"productId": "شناسه",
		"productKey": "کلید",
		"cultureId": "زبان",
		"categoryId": "دسته بندی",
		"identifire": "تصاویر",
		"title": "عنوان",
		"sellCount": "تعداد فروش",
		"caption": "توضیحات",
		"firstPrice": "قیمت پایه",
		"totalProductInventory": "مجموع تعداد انبار ها",
		"productCode": "کد",
		"imagePath": "تصویر",
		"productStatusId": "وضعیت",
		"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
		"isOrigin": "ریشه",
		"productSpecification": {
			"self": "توضیحات محصول",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
	},
	//GeneratorFlag:[Product.Delete] {
	"productDelete": {
		"self": "محصول",
		"productId": "شناسه",
	},
	//GeneratorFlag:[Product.Detail] {
	"productDetail": {
		"self": "محصول",
		"productId": "شناسه",
		"productKey": "کلید",
		"cultureId": "زبان",
		"categoryId": "دسته بندی",
		"identifire": "تصاویر",
		"title": "عنوان",
		"sellCount": "تعداد فروش",
		"caption": "توضیحات",
		"firstPrice": "قیمت پایه",
		"totalProductInventory": "مجموع تعداد انبار ها",
		"productCode": "کد",
		"imagePath": "تصویر",
		"productStatusId": "وضعیت",
		"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
		"isOrigin": "ریشه",
		"category": {
			"self": "دسته بندی",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productStatus": {
			"self": "وضعیت",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productSpecification": {
			"self": "توضیحات محصول",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"productSubInfo": "product sub info",
	},
	//GeneratorFlag:[Product.List] {
	"productList": {
		"self": "محصول",
		"productId": "شناسه",
		"productKey": "کلید",
		"cultureId": "زبان",
		"categoryId": "دسته بندی",
		"identifire": "تصاویر",
		"title": "عنوان",
		"sellCount": "تعداد فروش",
		"caption": "توضیحات",
		"firstPrice": "قیمت پایه",
		"totalProductInventory": "مجموع تعداد انبار ها",
		"productCode": "کد",
		"imagePath": "تصویر",
		"productStatusId": "وضعیت",
		"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
		"isOrigin": "ریشه",
		"category": {
			"self": "دسته بندی",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productStatus": {
			"self": "وضعیت",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productSpecification": {
			"self": "توضیحات محصول",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"productSubInfo": "product sub info",
	},
	//GeneratorFlag:[Product.Filter] {
	"productFilter": {
		"self": "محصول",
		"productId": "شناسه",
		"productKey": "کلید",
		"cultureId": "زبان",
		"categoryId": "دسته بندی",
		"identifire": "تصاویر",
		"title": "عنوان",
		"sellCount": "تعداد فروش",
		"caption": "توضیحات",
		"firstPrice": "قیمت پایه",
		"totalProductInventory": "مجموع تعداد انبار ها",
		"productCode": "کد",
		"imagePath": "تصویر",
		"productStatusId": "وضعیت",
		"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
		"isOrigin": "ریشه",
		"category": {
			"self": "دسته بندی",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productStatus": {
			"self": "وضعیت",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productSpecification": {
			"self": "توضیحات محصول",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"productSubInfo": "product sub info",
	},
	//GeneratorFlag:[Product.Update] {
	"productUpdate": {
		"self": "محصول",
		"productId": "شناسه",
		"productKey": "کلید",
		"cultureId": "زبان",
		"categoryId": "دسته بندی",
		"identifire": "تصاویر",
		"title": "عنوان",
		"sellCount": "تعداد فروش",
		"caption": "توضیحات",
		"firstPrice": "قیمت پایه",
		"totalProductInventory": "مجموع تعداد انبار ها",
		"productCode": "کد",
		"imagePath": "تصویر",
		"productStatusId": "وضعیت",
		"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
		"isOrigin": "ریشه",
		"productSpecification": {
			"self": "توضیحات محصول",
			"productId": "شناسه",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
	},
	//GeneratorFlag:[ProductCategory.Create] {
	"productCategoryCreate": {
		"self": "دسته بندی محصول",
		"productCategoryId": "شناسه",
		"categoryId": "زبان",
		"productId": "محصول",
		"cultureId": "زبان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[ProductCategory.Delete] {
	"productCategoryDelete": {
		"self": "دسته بندی محصول",
		"productCategoryId": "شناسه",
	},
	//GeneratorFlag:[ProductCategory.Detail] {
	"productCategoryDetail": {
		"self": "دسته بندی محصول",
		"productCategoryId": "شناسه",
		"categoryId": "زبان",
		"productId": "محصول",
		"cultureId": "زبان",
		"caption": "توضیحات",
		"category": {
			"self": "زبان",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productCategorySubInfo": "product category sub info",
	},
	//GeneratorFlag:[ProductCategory.List] {
	"productCategoryList": {
		"self": "دسته بندی محصول",
		"productCategoryId": "شناسه",
		"categoryId": "زبان",
		"productId": "محصول",
		"cultureId": "زبان",
		"caption": "توضیحات",
		"category": {
			"self": "زبان",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productCategorySubInfo": "product category sub info",
	},
	//GeneratorFlag:[ProductCategory.Filter] {
	"productCategoryFilter": {
		"self": "دسته بندی محصول",
		"productCategoryId": "شناسه",
		"categoryId": "زبان",
		"productId": "محصول",
		"cultureId": "زبان",
		"caption": "توضیحات",
		"category": {
			"self": "زبان",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productCategorySubInfo": "product category sub info",
	},
	//GeneratorFlag:[ProductCategory.Update] {
	"productCategoryUpdate": {
		"self": "دسته بندی محصول",
		"productCategoryId": "شناسه",
		"categoryId": "زبان",
		"productId": "محصول",
		"cultureId": "زبان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[ProductDetail.Create] {
	"productDetailCreate": {
		"self": "جزئیات محصول",
		"productDetailId": "شناسه ریزکالا",
		"productId": "محصول",
		"productColorId": "رنگ",
		"productMonetaryFeatureId": "خصوصیت",
		"adminDisplayName": "نام نمایشی مدیر",
		"cultureId": "زبان",
		"isDefaultProduct": "ویژگی پیش فرض",
		"productStatusId": "وضعیت",
	},
	//GeneratorFlag:[ProductDetail.Delete] {
	"productDetailDelete": {
		"self": "جزئیات محصول",
		"productDetailId": "شناسه ریزکالا",
	},
	//GeneratorFlag:[ProductDetail.Detail] {
	"productDetailDetail": {
		"self": "جزئیات محصول",
		"productDetailId": "شناسه ریزکالا",
		"productId": "محصول",
		"productColorId": "رنگ",
		"productMonetaryFeatureId": "خصوصیت",
		"adminDisplayName": "نام نمایشی مدیر",
		"cultureId": "زبان",
		"isDefaultProduct": "ویژگی پیش فرض",
		"productStatusId": "وضعیت",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productColor": {
			"self": "رنگ",
			"productColorId": "شناسه",
			"productColorKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"colorHexaCode": "کد Hex",
			"isOrigin": "ریشه",
		},
		"productMonetaryFeature": {
			"self": "خصوصیت",
			"productMonetaryFeatureId": "شناسه",
			"productMonetaryFeatureKey": "product monetary feature key",
			"cultureId": "زبان",
			"parentId": "والد",
			"categoryId": "دسته بندی",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productStatus": {
			"self": "وضعیت",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDetailSubInfo": "product detail sub info",
	},
	//GeneratorFlag:[ProductDetail.List] {
	"productDetailList": {
		"self": "جزئیات محصول",
		"productDetailId": "شناسه ریزکالا",
		"productId": "محصول",
		"productColorId": "رنگ",
		"productMonetaryFeatureId": "خصوصیت",
		"adminDisplayName": "نام نمایشی مدیر",
		"cultureId": "زبان",
		"isDefaultProduct": "ویژگی پیش فرض",
		"productStatusId": "وضعیت",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productColor": {
			"self": "رنگ",
			"productColorId": "شناسه",
			"productColorKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"colorHexaCode": "کد Hex",
			"isOrigin": "ریشه",
		},
		"productMonetaryFeature": {
			"self": "خصوصیت",
			"productMonetaryFeatureId": "شناسه",
			"productMonetaryFeatureKey": "product monetary feature key",
			"cultureId": "زبان",
			"parentId": "والد",
			"categoryId": "دسته بندی",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productStatus": {
			"self": "وضعیت",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDetailSubInfo": "product detail sub info",
	},
	//GeneratorFlag:[ProductDetail.Filter] {
	"productDetailFilter": {
		"self": "جزئیات محصول",
		"productDetailId": "شناسه ریزکالا",
		"productId": "محصول",
		"productColorId": "رنگ",
		"productMonetaryFeatureId": "خصوصیت",
		"adminDisplayName": "نام نمایشی مدیر",
		"cultureId": "زبان",
		"isDefaultProduct": "ویژگی پیش فرض",
		"productStatusId": "وضعیت",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productColor": {
			"self": "رنگ",
			"productColorId": "شناسه",
			"productColorKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"colorHexaCode": "کد Hex",
			"isOrigin": "ریشه",
		},
		"productMonetaryFeature": {
			"self": "خصوصیت",
			"productMonetaryFeatureId": "شناسه",
			"productMonetaryFeatureKey": "product monetary feature key",
			"cultureId": "زبان",
			"parentId": "والد",
			"categoryId": "دسته بندی",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productStatus": {
			"self": "وضعیت",
			"productStatusId": "شناسه",
			"productStatusKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDetailSubInfo": "product detail sub info",
	},
	//GeneratorFlag:[ProductDetail.Update] {
	"productDetailUpdate": {
		"self": "جزئیات محصول",
		"productDetailId": "شناسه ریزکالا",
		"productId": "محصول",
		"productColorId": "رنگ",
		"productMonetaryFeatureId": "خصوصیت",
		"adminDisplayName": "نام نمایشی مدیر",
		"cultureId": "زبان",
		"isDefaultProduct": "ویژگی پیش فرض",
		"productStatusId": "وضعیت",
	},
	//GeneratorFlag:[ProductDoc.Create] {
	"productDocCreate": {
		"self": "مدیای محصول",
		"productDocId": "شناسه",
		"productId": "محصول",
		"docAddress": "آدرس",
		"title": "عنوان",
		"caption": "توضیحات",
		"productDocTypeId": "نوع مدیا",
	},
	//GeneratorFlag:[ProductDoc.Delete] {
	"productDocDelete": {
		"self": "مدیای محصول",
		"productDocId": "شناسه",
	},
	//GeneratorFlag:[ProductDoc.Detail] {
	"productDocDetail": {
		"self": "مدیای محصول",
		"productDocId": "شناسه",
		"productId": "محصول",
		"docAddress": "آدرس",
		"title": "عنوان",
		"caption": "توضیحات",
		"productDocTypeId": "نوع مدیا",
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productDocType": {
			"self": "نوع مدیا",
			"productDocTypeId": "شناسه",
			"productDocTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDocSubInfo": "product doc sub info",
	},
	//GeneratorFlag:[ProductDoc.List] {
	"productDocList": {
		"self": "مدیای محصول",
		"productDocId": "شناسه",
		"productId": "محصول",
		"docAddress": "آدرس",
		"title": "عنوان",
		"caption": "توضیحات",
		"productDocTypeId": "نوع مدیا",
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productDocType": {
			"self": "نوع مدیا",
			"productDocTypeId": "شناسه",
			"productDocTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDocSubInfo": "product doc sub info",
	},
	//GeneratorFlag:[ProductDoc.Filter] {
	"productDocFilter": {
		"self": "مدیای محصول",
		"productDocId": "شناسه",
		"productId": "محصول",
		"docAddress": "آدرس",
		"title": "عنوان",
		"caption": "توضیحات",
		"productDocTypeId": "نوع مدیا",
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productDocType": {
			"self": "نوع مدیا",
			"productDocTypeId": "شناسه",
			"productDocTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"productDocSubInfo": "product doc sub info",
	},
	//GeneratorFlag:[ProductDoc.Update] {
	"productDocUpdate": {
		"self": "مدیای محصول",
		"productDocId": "شناسه",
		"productId": "محصول",
		"docAddress": "آدرس",
		"title": "عنوان",
		"caption": "توضیحات",
		"productDocTypeId": "نوع مدیا",
	},
	//GeneratorFlag:[ProductFeature.Create] {
	"productFeatureCreate": {
		"self": "ویژگی محصول",
		"productFeatureId": "شناسه",
		"featureId": "ویژگی",
		"stringValue": "مقدار رشته ای",
		"floatValue": "مقدار اعشاری",
		"intValue": "مقدار عددی",
		"productId": "محصول",
	},
	//GeneratorFlag:[ProductFeature.Delete] {
	"productFeatureDelete": {
		"self": "ویژگی محصول",
		"productFeatureId": "شناسه",
	},
	//GeneratorFlag:[ProductFeature.Detail] {
	"productFeatureDetail": {
		"self": "ویژگی محصول",
		"productFeatureId": "شناسه",
		"featureId": "ویژگی",
		"stringValue": "مقدار رشته ای",
		"floatValue": "مقدار اعشاری",
		"intValue": "مقدار عددی",
		"productId": "محصول",
		"feature": {
			"self": "ویژگی",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productFeatureSubInfo": "product feature sub info",
	},
	//GeneratorFlag:[ProductFeature.List] {
	"productFeatureList": {
		"self": "ویژگی محصول",
		"productFeatureId": "شناسه",
		"featureId": "ویژگی",
		"stringValue": "مقدار رشته ای",
		"floatValue": "مقدار اعشاری",
		"intValue": "مقدار عددی",
		"productId": "محصول",
		"feature": {
			"self": "ویژگی",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productFeatureSubInfo": "product feature sub info",
	},
	//GeneratorFlag:[ProductFeature.Filter] {
	"productFeatureFilter": {
		"self": "ویژگی محصول",
		"productFeatureId": "شناسه",
		"featureId": "ویژگی",
		"stringValue": "مقدار رشته ای",
		"floatValue": "مقدار اعشاری",
		"intValue": "مقدار عددی",
		"productId": "محصول",
		"feature": {
			"self": "ویژگی",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"product": {
			"self": "محصول",
			"productId": "شناسه",
			"productKey": "کلید",
			"cultureId": "زبان",
			"categoryId": "دسته بندی",
			"identifire": "تصاویر",
			"title": "عنوان",
			"sellCount": "تعداد فروش",
			"caption": "توضیحات",
			"firstPrice": "قیمت پایه",
			"totalProductInventory": "مجموع تعداد انبار ها",
			"productCode": "کد",
			"imagePath": "تصویر",
			"productStatusId": "وضعیت",
			"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
			"isOrigin": "ریشه",
		},
		"productFeatureSubInfo": "product feature sub info",
	},
	//GeneratorFlag:[ProductFeature.Update] {
	"productFeatureUpdate": {
		"self": "ویژگی محصول",
		"productFeatureId": "شناسه",
		"featureId": "ویژگی",
		"stringValue": "مقدار رشته ای",
		"floatValue": "مقدار اعشاری",
		"intValue": "مقدار عددی",
		"productId": "محصول",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Create] {
	"productMonetaryFeatureCreate": {
		"self": "خصوصیت محصول",
		"productMonetaryFeatureId": "شناسه",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "زبان",
		"parentId": "والد",
		"categoryId": "دسته بندی",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Delete] {
	"productMonetaryFeatureDelete": {
		"self": "خصوصیت محصول",
		"productMonetaryFeatureId": "شناسه",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Detail] {
	"productMonetaryFeatureDetail": {
		"self": "خصوصیت محصول",
		"productMonetaryFeatureId": "شناسه",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "زبان",
		"parentId": "والد",
		"categoryId": "دسته بندی",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"category": {
			"self": "دسته بندی",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productMonetaryFeatureSubInfo": "product monetary feature sub info",
	},
	//GeneratorFlag:[ProductMonetaryFeature.List] {
	"productMonetaryFeatureList": {
		"self": "خصوصیت محصول",
		"productMonetaryFeatureId": "شناسه",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "زبان",
		"parentId": "والد",
		"categoryId": "دسته بندی",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"category": {
			"self": "دسته بندی",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productMonetaryFeatureSubInfo": "product monetary feature sub info",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Filter] {
	"productMonetaryFeatureFilter": {
		"self": "خصوصیت محصول",
		"productMonetaryFeatureId": "شناسه",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "زبان",
		"parentId": "والد",
		"categoryId": "دسته بندی",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"category": {
			"self": "دسته بندی",
			"categoryId": "شناسه دسته بندی",
			"categoryKey": "کلید",
			"cultureId": "زبان",
			"parentId": "والد",
			"title": "عنوان دسته بندی",
			"categoryGenoString": "شجره",
			"imagePath": "آدرس تصویر",
			"isOrigin": "ریشه",
		},
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productMonetaryFeatureSubInfo": "product monetary feature sub info",
	},
	//GeneratorFlag:[ProductMonetaryFeature.Update] {
	"productMonetaryFeatureUpdate": {
		"self": "خصوصیت محصول",
		"productMonetaryFeatureId": "شناسه",
		"productMonetaryFeatureKey": "product monetary feature key",
		"cultureId": "زبان",
		"parentId": "والد",
		"categoryId": "دسته بندی",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductStore.Create] {
	"productStoreCreate": {
		"self": "محصول فروشگاه",
		"productStoreId": "شناسه فروشگاه کالا",
		"productDetailId": "شناسه محصول",
		"storeId": "شناسه فروشگاه",
		"firstPrice": "قیمت محصول فروشگاه",
		"secondStorePrice": "قیمت محصول فروشگاه",
		"productStoreInventory": "تعداد محصول فروشگاه",
		"productScore": "امتیاز",
		"maxSellCount": "حد اکثر فروش",
		"minSellCount": "حد اقل فروش",
	},
	//GeneratorFlag:[ProductStore.Delete] {
	"productStoreDelete": {
		"self": "محصول فروشگاه",
		"productStoreId": "شناسه فروشگاه کالا",
	},
	//GeneratorFlag:[ProductStore.Detail] {
	"productStoreDetail": {
		"self": "محصول فروشگاه",
		"productStoreId": "شناسه فروشگاه کالا",
		"productDetailId": "شناسه محصول",
		"storeId": "شناسه فروشگاه",
		"firstPrice": "قیمت محصول فروشگاه",
		"secondStorePrice": "قیمت محصول فروشگاه",
		"productStoreInventory": "تعداد محصول فروشگاه",
		"productScore": "امتیاز",
		"maxSellCount": "حد اکثر فروش",
		"minSellCount": "حد اقل فروش",
		"productDetail": {
			"self": "شناسه محصول",
			"productDetailId": "شناسه ریزکالا",
			"productId": "محصول",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "خصوصیت",
			"adminDisplayName": "نام نمایشی مدیر",
			"cultureId": "زبان",
			"isDefaultProduct": "ویژگی پیش فرض",
			"productStatusId": "وضعیت",
		},
		"store": {
			"self": "شناسه فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"productStoreSubInfo": "product store sub info",
	},
	//GeneratorFlag:[ProductStore.List] {
	"productStoreList": {
		"self": "محصول فروشگاه",
		"productStoreId": "شناسه فروشگاه کالا",
		"productDetailId": "شناسه محصول",
		"storeId": "شناسه فروشگاه",
		"firstPrice": "قیمت محصول فروشگاه",
		"secondStorePrice": "قیمت محصول فروشگاه",
		"productStoreInventory": "تعداد محصول فروشگاه",
		"productScore": "امتیاز",
		"maxSellCount": "حد اکثر فروش",
		"minSellCount": "حد اقل فروش",
		"productDetail": {
			"self": "شناسه محصول",
			"productDetailId": "شناسه ریزکالا",
			"productId": "محصول",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "خصوصیت",
			"adminDisplayName": "نام نمایشی مدیر",
			"cultureId": "زبان",
			"isDefaultProduct": "ویژگی پیش فرض",
			"productStatusId": "وضعیت",
		},
		"store": {
			"self": "شناسه فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"productStoreSubInfo": "product store sub info",
	},
	//GeneratorFlag:[ProductStore.Filter] {
	"productStoreFilter": {
		"self": "محصول فروشگاه",
		"productStoreId": "شناسه فروشگاه کالا",
		"productDetailId": "شناسه محصول",
		"storeId": "شناسه فروشگاه",
		"firstPrice": "قیمت محصول فروشگاه",
		"secondStorePrice": "قیمت محصول فروشگاه",
		"productStoreInventory": "تعداد محصول فروشگاه",
		"productScore": "امتیاز",
		"maxSellCount": "حد اکثر فروش",
		"minSellCount": "حد اقل فروش",
		"productDetail": {
			"self": "شناسه محصول",
			"productDetailId": "شناسه ریزکالا",
			"productId": "محصول",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "خصوصیت",
			"adminDisplayName": "نام نمایشی مدیر",
			"cultureId": "زبان",
			"isDefaultProduct": "ویژگی پیش فرض",
			"productStatusId": "وضعیت",
		},
		"store": {
			"self": "شناسه فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"productStoreSubInfo": "product store sub info",
	},
	//GeneratorFlag:[ProductStore.Update] {
	"productStoreUpdate": {
		"self": "محصول فروشگاه",
		"productStoreId": "شناسه فروشگاه کالا",
		"productDetailId": "شناسه محصول",
		"storeId": "شناسه فروشگاه",
		"firstPrice": "قیمت محصول فروشگاه",
		"secondStorePrice": "قیمت محصول فروشگاه",
		"productStoreInventory": "تعداد محصول فروشگاه",
		"productScore": "امتیاز",
		"maxSellCount": "حد اکثر فروش",
		"minSellCount": "حد اقل فروش",
	},
	//GeneratorFlag:[Store.Create] {
	"storeCreate": {
		"self": "فروشگاه",
		"storeId": "شناسه فروشگاه",
		"storeName": "نام فروشگاه",
		"valueAddedTaxPrecent": "درصد ارزش افزوده",
		"complicationsTaxPrecent": "درصد عوارض",
		"transportCost": "هزینه ارسال",
		"transportCostFreeFrom": "هزینه ارسال رایگان از",
	},
	//GeneratorFlag:[Store.Delete] {
	"storeDelete": {
		"self": "فروشگاه",
		"storeId": "شناسه فروشگاه",
	},
	//GeneratorFlag:[Store.Detail] {
	"storeDetail": {
		"self": "فروشگاه",
		"storeId": "شناسه فروشگاه",
		"storeName": "نام فروشگاه",
		"valueAddedTaxPrecent": "درصد ارزش افزوده",
		"complicationsTaxPrecent": "درصد عوارض",
		"transportCost": "هزینه ارسال",
		"transportCostFreeFrom": "هزینه ارسال رایگان از",
		"storeSubInfo": "store sub info",
	},
	//GeneratorFlag:[Store.List] {
	"storeList": {
		"self": "فروشگاه",
		"storeId": "شناسه فروشگاه",
		"storeName": "نام فروشگاه",
		"valueAddedTaxPrecent": "درصد ارزش افزوده",
		"complicationsTaxPrecent": "درصد عوارض",
		"transportCost": "هزینه ارسال",
		"transportCostFreeFrom": "هزینه ارسال رایگان از",
		"storeSubInfo": "store sub info",
	},
	//GeneratorFlag:[Store.Filter] {
	"storeFilter": {
		"self": "فروشگاه",
		"storeId": "شناسه فروشگاه",
		"storeName": "نام فروشگاه",
		"valueAddedTaxPrecent": "درصد ارزش افزوده",
		"complicationsTaxPrecent": "درصد عوارض",
		"transportCost": "هزینه ارسال",
		"transportCostFreeFrom": "هزینه ارسال رایگان از",
		"storeSubInfo": "store sub info",
	},
	//GeneratorFlag:[Store.Update] {
	"storeUpdate": {
		"self": "فروشگاه",
		"storeId": "شناسه فروشگاه",
		"storeName": "نام فروشگاه",
		"valueAddedTaxPrecent": "درصد ارزش افزوده",
		"complicationsTaxPrecent": "درصد عوارض",
		"transportCost": "هزینه ارسال",
		"transportCostFreeFrom": "هزینه ارسال رایگان از",
	},
	//GeneratorFlag:[Bank.Create] {
	"bankCreate": {
		"self": "بانک",
		"bankId": "شناسه",
		"title": "نام",
		"iconUrl": "آیکن",
	},
	//GeneratorFlag:[Bank.Delete] {
	"bankDelete": {
		"self": "بانک",
		"bankId": "شناسه",
	},
	//GeneratorFlag:[Bank.Detail] {
	"bankDetail": {
		"self": "بانک",
		"bankId": "شناسه",
		"title": "نام",
		"iconUrl": "آیکن",
		"bankSubInfo": "bank sub info",
	},
	//GeneratorFlag:[Bank.List] {
	"bankList": {
		"self": "بانک",
		"bankId": "شناسه",
		"title": "نام",
		"iconUrl": "آیکن",
		"bankSubInfo": "bank sub info",
	},
	//GeneratorFlag:[Bank.Filter] {
	"bankFilter": {
		"self": "بانک",
		"bankId": "شناسه",
		"title": "نام",
		"iconUrl": "آیکن",
		"bankSubInfo": "bank sub info",
	},
	//GeneratorFlag:[Bank.Update] {
	"bankUpdate": {
		"self": "بانک",
		"bankId": "شناسه",
		"title": "نام",
		"iconUrl": "آیکن",
	},
	//GeneratorFlag:[Category.Create] {
	"categoryCreate": {
		"self": "دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"categoryKey": "کلید",
		"cultureId": "زبان",
		"parentId": "والد",
		"title": "عنوان دسته بندی",
		"categoryGenoString": "شجره",
		"imagePath": "آدرس تصویر",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[Category.Delete] {
	"categoryDelete": {
		"self": "دسته بندی",
		"categoryId": "شناسه دسته بندی",
	},
	//GeneratorFlag:[Category.Detail] {
	"categoryDetail": {
		"self": "دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"categoryKey": "کلید",
		"cultureId": "زبان",
		"parentId": "والد",
		"title": "عنوان دسته بندی",
		"categoryGenoString": "شجره",
		"imagePath": "آدرس تصویر",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"categorySubInfo": "category sub info",
	},
	//GeneratorFlag:[Category.List] {
	"categoryList": {
		"self": "دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"categoryKey": "کلید",
		"cultureId": "زبان",
		"parentId": "والد",
		"title": "عنوان دسته بندی",
		"categoryGenoString": "شجره",
		"imagePath": "آدرس تصویر",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"categorySubInfo": "category sub info",
	},
	//GeneratorFlag:[Category.Filter] {
	"categoryFilter": {
		"self": "دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"categoryKey": "کلید",
		"cultureId": "زبان",
		"parentId": "والد",
		"title": "عنوان دسته بندی",
		"categoryGenoString": "شجره",
		"imagePath": "آدرس تصویر",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"categorySubInfo": "category sub info",
	},
	//GeneratorFlag:[Category.Update] {
	"categoryUpdate": {
		"self": "دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"categoryKey": "کلید",
		"cultureId": "زبان",
		"parentId": "والد",
		"title": "عنوان دسته بندی",
		"categoryGenoString": "شجره",
		"imagePath": "آدرس تصویر",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[Feature.Create] {
	"featureCreate": {
		"self": "ویژگی",
		"featureId": "شناسه ویژگی",
		"featureKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[Feature.Delete] {
	"featureDelete": {
		"self": "ویژگی",
		"featureId": "شناسه ویژگی",
	},
	//GeneratorFlag:[Feature.Detail] {
	"featureDetail": {
		"self": "ویژگی",
		"featureId": "شناسه ویژگی",
		"featureKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"caption": "توضیحات",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"featureSubInfo": "feature sub info",
	},
	//GeneratorFlag:[Feature.List] {
	"featureList": {
		"self": "ویژگی",
		"featureId": "شناسه ویژگی",
		"featureKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"caption": "توضیحات",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"featureSubInfo": "feature sub info",
	},
	//GeneratorFlag:[Feature.Filter] {
	"featureFilter": {
		"self": "ویژگی",
		"featureId": "شناسه ویژگی",
		"featureKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"caption": "توضیحات",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"featureSubInfo": "feature sub info",
	},
	//GeneratorFlag:[Feature.Update] {
	"featureUpdate": {
		"self": "ویژگی",
		"featureId": "شناسه ویژگی",
		"featureKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[FeatureItem.Create] {
	"featureItemCreate": {
		"self": "آیتم ویژگی",
		"featureItemId": "شناسه",
		"featureItemKey": "کلید",
		"cultureId": "زبان",
		"featureId": "ویژگی",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[FeatureItem.Delete] {
	"featureItemDelete": {
		"self": "آیتم ویژگی",
		"featureItemId": "شناسه",
	},
	//GeneratorFlag:[FeatureItem.Detail] {
	"featureItemDetail": {
		"self": "آیتم ویژگی",
		"featureItemId": "شناسه",
		"featureItemKey": "کلید",
		"cultureId": "زبان",
		"featureId": "ویژگی",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"feature": {
			"self": "ویژگی",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"featureItemSubInfo": "feature item sub info",
	},
	//GeneratorFlag:[FeatureItem.List] {
	"featureItemList": {
		"self": "آیتم ویژگی",
		"featureItemId": "شناسه",
		"featureItemKey": "کلید",
		"cultureId": "زبان",
		"featureId": "ویژگی",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"feature": {
			"self": "ویژگی",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"featureItemSubInfo": "feature item sub info",
	},
	//GeneratorFlag:[FeatureItem.Filter] {
	"featureItemFilter": {
		"self": "آیتم ویژگی",
		"featureItemId": "شناسه",
		"featureItemKey": "کلید",
		"cultureId": "زبان",
		"featureId": "ویژگی",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"feature": {
			"self": "ویژگی",
			"featureId": "شناسه ویژگی",
			"featureKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		"featureItemSubInfo": "feature item sub info",
	},
	//GeneratorFlag:[FeatureItem.Update] {
	"featureItemUpdate": {
		"self": "آیتم ویژگی",
		"featureItemId": "شناسه",
		"featureItemKey": "کلید",
		"cultureId": "زبان",
		"featureId": "ویژگی",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanPrecent.Create] {
	"planPrecentCreate": {
		"self": "درصد پلن محرمانه",
		"planPrecentId": "شناسه",
		"lineOrder": "شاخه",
		"linePrecent": "درصد",
	},
	//GeneratorFlag:[PlanPrecent.Delete] {
	"planPrecentDelete": {
		"self": "درصد پلن محرمانه",
		"planPrecentId": "شناسه",
	},
	//GeneratorFlag:[PlanPrecent.Detail] {
	"planPrecentDetail": {
		"self": "درصد پلن محرمانه",
		"planPrecentId": "شناسه",
		"lineOrder": "شاخه",
		"linePrecent": "درصد",
		"planPrecentSubInfo": "plan precent sub info",
	},
	//GeneratorFlag:[PlanPrecent.List] {
	"planPrecentList": {
		"self": "درصد پلن محرمانه",
		"planPrecentId": "شناسه",
		"lineOrder": "شاخه",
		"linePrecent": "درصد",
		"planPrecentSubInfo": "plan precent sub info",
	},
	//GeneratorFlag:[PlanPrecent.Filter] {
	"planPrecentFilter": {
		"self": "درصد پلن محرمانه",
		"planPrecentId": "شناسه",
		"lineOrder": "شاخه",
		"linePrecent": "درصد",
		"planPrecentSubInfo": "plan precent sub info",
	},
	//GeneratorFlag:[PlanPrecent.Update] {
	"planPrecentUpdate": {
		"self": "درصد پلن محرمانه",
		"planPrecentId": "شناسه",
		"lineOrder": "شاخه",
		"linePrecent": "درصد",
	},
	//GeneratorFlag:[PlanSetting.Create] {
	"planSettingCreate": {
		"self": "تنظیمات پلن(محرمانه)",
		"planSettingId": "شناسه",
		"planMaximunPayOutPerLineValue": "بیشترین مقدار خروجی هر شاخه",
		"planPayOutCommisionPrecent": "درصد خروجی هر شاخه",
		"planPayOutVoacherPrecent": "درصد خروجی وچر",
		"planSaveExpiretionDayCount": "تعداد روز های منقضی شده",
		"planMaximunPayoutTotal": "مبلغ خروجی پلن بر اساس هر هفته",
	},
	//GeneratorFlag:[PlanSetting.Delete] {
	"planSettingDelete": {
		"self": "تنظیمات پلن(محرمانه)",
		"planSettingId": "شناسه",
	},
	//GeneratorFlag:[PlanSetting.Detail] {
	"planSettingDetail": {
		"self": "تنظیمات پلن(محرمانه)",
		"planSettingId": "شناسه",
		"planMaximunPayOutPerLineValue": "بیشترین مقدار خروجی هر شاخه",
		"planPayOutCommisionPrecent": "درصد خروجی هر شاخه",
		"planPayOutVoacherPrecent": "درصد خروجی وچر",
		"planSaveExpiretionDayCount": "تعداد روز های منقضی شده",
		"planMaximunPayoutTotal": "مبلغ خروجی پلن بر اساس هر هفته",
		"planSettingSubInfo": "plan setting sub info",
	},
	//GeneratorFlag:[PlanSetting.List] {
	"planSettingList": {
		"self": "تنظیمات پلن(محرمانه)",
		"planSettingId": "شناسه",
		"planMaximunPayOutPerLineValue": "بیشترین مقدار خروجی هر شاخه",
		"planPayOutCommisionPrecent": "درصد خروجی هر شاخه",
		"planPayOutVoacherPrecent": "درصد خروجی وچر",
		"planSaveExpiretionDayCount": "تعداد روز های منقضی شده",
		"planMaximunPayoutTotal": "مبلغ خروجی پلن بر اساس هر هفته",
		"planSettingSubInfo": "plan setting sub info",
	},
	//GeneratorFlag:[PlanSetting.Filter] {
	"planSettingFilter": {
		"self": "تنظیمات پلن(محرمانه)",
		"planSettingId": "شناسه",
		"planMaximunPayOutPerLineValue": "بیشترین مقدار خروجی هر شاخه",
		"planPayOutCommisionPrecent": "درصد خروجی هر شاخه",
		"planPayOutVoacherPrecent": "درصد خروجی وچر",
		"planSaveExpiretionDayCount": "تعداد روز های منقضی شده",
		"planMaximunPayoutTotal": "مبلغ خروجی پلن بر اساس هر هفته",
		"planSettingSubInfo": "plan setting sub info",
	},
	//GeneratorFlag:[PlanSetting.Update] {
	"planSettingUpdate": {
		"self": "تنظیمات پلن(محرمانه)",
		"planSettingId": "شناسه",
		"planMaximunPayOutPerLineValue": "بیشترین مقدار خروجی هر شاخه",
		"planPayOutCommisionPrecent": "درصد خروجی هر شاخه",
		"planPayOutVoacherPrecent": "درصد خروجی وچر",
		"planSaveExpiretionDayCount": "تعداد روز های منقضی شده",
		"planMaximunPayoutTotal": "مبلغ خروجی پلن بر اساس هر هفته",
	},
	//GeneratorFlag:[ProductColor.Create] {
	"productColorCreate": {
		"self": "رنگ محصولات",
		"productColorId": "شناسه",
		"productColorKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"colorHexaCode": "کد Hex",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductColor.Delete] {
	"productColorDelete": {
		"self": "رنگ محصولات",
		"productColorId": "شناسه",
	},
	//GeneratorFlag:[ProductColor.Detail] {
	"productColorDetail": {
		"self": "رنگ محصولات",
		"productColorId": "شناسه",
		"productColorKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"colorHexaCode": "کد Hex",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productColorSubInfo": "product color sub info",
	},
	//GeneratorFlag:[ProductColor.List] {
	"productColorList": {
		"self": "رنگ محصولات",
		"productColorId": "شناسه",
		"productColorKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"colorHexaCode": "کد Hex",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productColorSubInfo": "product color sub info",
	},
	//GeneratorFlag:[ProductColor.Filter] {
	"productColorFilter": {
		"self": "رنگ محصولات",
		"productColorId": "شناسه",
		"productColorKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"colorHexaCode": "کد Hex",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"productColorSubInfo": "product color sub info",
	},
	//GeneratorFlag:[ProductColor.Update] {
	"productColorUpdate": {
		"self": "رنگ محصولات",
		"productColorId": "شناسه",
		"productColorKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"colorHexaCode": "کد Hex",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[StoreProfitRate.Create] {
	"storeProfitRateCreate": {
		"self": "نرخ سود ماهانه",
		"storeProfitRateId": "شناسه",
		"precentProfit": "درصد محاسبه",
		"title": "عنوان",
		"caption": "توضیحات",
		"storeId": "فروشگاه",
	},
	//GeneratorFlag:[StoreProfitRate.Delete] {
	"storeProfitRateDelete": {
		"self": "نرخ سود ماهانه",
		"storeProfitRateId": "شناسه",
	},
	//GeneratorFlag:[StoreProfitRate.Detail] {
	"storeProfitRateDetail": {
		"self": "نرخ سود ماهانه",
		"storeProfitRateId": "شناسه",
		"precentProfit": "درصد محاسبه",
		"title": "عنوان",
		"caption": "توضیحات",
		"storeId": "فروشگاه",
		"store": {
			"self": "فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"storeProfitRateSubInfo": "store profit rate sub info",
	},
	//GeneratorFlag:[StoreProfitRate.List] {
	"storeProfitRateList": {
		"self": "نرخ سود ماهانه",
		"storeProfitRateId": "شناسه",
		"precentProfit": "درصد محاسبه",
		"title": "عنوان",
		"caption": "توضیحات",
		"storeId": "فروشگاه",
		"store": {
			"self": "فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"storeProfitRateSubInfo": "store profit rate sub info",
	},
	//GeneratorFlag:[StoreProfitRate.Filter] {
	"storeProfitRateFilter": {
		"self": "نرخ سود ماهانه",
		"storeProfitRateId": "شناسه",
		"precentProfit": "درصد محاسبه",
		"title": "عنوان",
		"caption": "توضیحات",
		"storeId": "فروشگاه",
		"store": {
			"self": "فروشگاه",
			"storeId": "شناسه فروشگاه",
			"storeName": "نام فروشگاه",
			"valueAddedTaxPrecent": "درصد ارزش افزوده",
			"complicationsTaxPrecent": "درصد عوارض",
			"transportCost": "هزینه ارسال",
			"transportCostFreeFrom": "هزینه ارسال رایگان از",
		},
		"storeProfitRateSubInfo": "store profit rate sub info",
	},
	//GeneratorFlag:[StoreProfitRate.Update] {
	"storeProfitRateUpdate": {
		"self": "نرخ سود ماهانه",
		"storeProfitRateId": "شناسه",
		"precentProfit": "درصد محاسبه",
		"title": "عنوان",
		"caption": "توضیحات",
		"storeId": "فروشگاه",
	},
	//GeneratorFlag:[BankPaymentStatuse.Detail] {
	"bankPaymentStatuseDetail": {
		"self": "وضعیت درگاه",
		"bankPaymentStatuseId": "شناسه",
		"bankPaymentStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[BankPaymentStatuse.List] {
	"bankPaymentStatuseList": {
		"self": "وضعیت درگاه",
		"bankPaymentStatuseId": "شناسه",
		"bankPaymentStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[BankPaymentStatuse.Filter] {
	"bankPaymentStatuseFilter": {
		"self": "وضعیت درگاه",
		"bankPaymentStatuseId": "شناسه",
		"bankPaymentStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "زبان",
		"cultureId": "شناسه",
		"title": "عنوان",
		"cultureSymbol": "نشانه زبان",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "اختصار",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "زبان",
		"cultureId": "شناسه",
		"title": "عنوان",
		"cultureSymbol": "نشانه زبان",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "اختصار",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "زبان",
		"cultureId": "شناسه",
		"title": "عنوان",
		"cultureSymbol": "نشانه زبان",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "اختصار",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[InvoiceCancelStatuse.Detail] {
	"invoiceCancelStatuseDetail": {
		"self": "وضعیت کنسل",
		"invoiceCancelStatuseId": "شناسه",
		"invoiceCancelStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[InvoiceCancelStatuse.List] {
	"invoiceCancelStatuseList": {
		"self": "وضعیت کنسل",
		"invoiceCancelStatuseId": "شناسه",
		"invoiceCancelStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[InvoiceCancelStatuse.Filter] {
	"invoiceCancelStatuseFilter": {
		"self": "وضعیت کنسل",
		"invoiceCancelStatuseId": "شناسه",
		"invoiceCancelStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[InvoicePaymentType.Detail] {
	"invoicePaymentTypeDetail": {
		"self": "روش پرداخت",
		"invoicePaymentTypeId": "شناسه",
		"invoicePaymentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[InvoicePaymentType.List] {
	"invoicePaymentTypeList": {
		"self": "روش پرداخت",
		"invoicePaymentTypeId": "شناسه",
		"invoicePaymentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[InvoicePaymentType.Filter] {
	"invoicePaymentTypeFilter": {
		"self": "روش پرداخت",
		"invoicePaymentTypeId": "شناسه",
		"invoicePaymentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[InvoiceStatuse.Detail] {
	"invoiceStatuseDetail": {
		"self": "وضعیت فاکتور",
		"invoiceStatuseId": "شناسه",
		"invoiceStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceStatuse.List] {
	"invoiceStatuseList": {
		"self": "وضعیت فاکتور",
		"invoiceStatuseId": "شناسه",
		"invoiceStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceStatuse.Filter] {
	"invoiceStatuseFilter": {
		"self": "وضعیت فاکتور",
		"invoiceStatuseId": "شناسه",
		"invoiceStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceTransactionType.Detail] {
	"invoiceTransactionTypeDetail": {
		"self": "نوع تراکنش فاکتور",
		"invoiceTransactionTypeId": "شناسه",
		"invoiceTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceTransactionType.List] {
	"invoiceTransactionTypeList": {
		"self": "نوع تراکنش فاکتور",
		"invoiceTransactionTypeId": "شناسه",
		"invoiceTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[InvoiceTransactionType.Filter] {
	"invoiceTransactionTypeFilter": {
		"self": "نوع تراکنش فاکتور",
		"invoiceTransactionTypeId": "شناسه",
		"invoiceTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreaditTransactionType.Detail] {
	"personCreaditTransactionTypeDetail": {
		"self": "نوع تراکنش",
		"personCreaditTransactionTypeId": "شناسه",
		"personCreaditTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreaditTransactionType.List] {
	"personCreaditTransactionTypeList": {
		"self": "نوع تراکنش",
		"personCreaditTransactionTypeId": "شناسه",
		"personCreaditTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreaditTransactionType.Filter] {
	"personCreaditTransactionTypeFilter": {
		"self": "نوع تراکنش",
		"personCreaditTransactionTypeId": "شناسه",
		"personCreaditTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonPreTransactionStatuse.Detail] {
	"personPreTransactionStatuseDetail": {
		"self": "وضعیت تراکنش",
		"personPreTransactionStatuseId": "person pre transaction statuse id",
		"personPreTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonPreTransactionStatuse.List] {
	"personPreTransactionStatuseList": {
		"self": "وضعیت تراکنش",
		"personPreTransactionStatuseId": "person pre transaction statuse id",
		"personPreTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonPreTransactionStatuse.Filter] {
	"personPreTransactionStatuseFilter": {
		"self": "وضعیت تراکنش",
		"personPreTransactionStatuseId": "person pre transaction statuse id",
		"personPreTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionStatuse.Detail] {
	"planTransactionStatuseDetail": {
		"self": "PlanTransactionStatuse",
		"planTransactionStatuseId": "شناسه",
		"planTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionStatuse.List] {
	"planTransactionStatuseList": {
		"self": "PlanTransactionStatuse",
		"planTransactionStatuseId": "شناسه",
		"planTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionStatuse.Filter] {
	"planTransactionStatuseFilter": {
		"self": "PlanTransactionStatuse",
		"planTransactionStatuseId": "شناسه",
		"planTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionType.Detail] {
	"planTransactionTypeDetail": {
		"self": "PlanTransactionType",
		"planTransactionTypeId": "شناسه",
		"personPreTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionType.List] {
	"planTransactionTypeList": {
		"self": "PlanTransactionType",
		"planTransactionTypeId": "شناسه",
		"personPreTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionType.Filter] {
	"planTransactionTypeFilter": {
		"self": "PlanTransactionType",
		"planTransactionTypeId": "شناسه",
		"personPreTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductDocType.Detail] {
	"productDocTypeDetail": {
		"self": "نوع مدیا",
		"productDocTypeId": "شناسه",
		"productDocTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductDocType.List] {
	"productDocTypeList": {
		"self": "نوع مدیا",
		"productDocTypeId": "شناسه",
		"productDocTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductDocType.Filter] {
	"productDocTypeFilter": {
		"self": "نوع مدیا",
		"productDocTypeId": "شناسه",
		"productDocTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductStatus.Detail] {
	"productStatusDetail": {
		"self": "وضعیت کالا",
		"productStatusId": "شناسه",
		"productStatusKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductStatus.List] {
	"productStatusList": {
		"self": "وضعیت کالا",
		"productStatusId": "شناسه",
		"productStatusKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductStatus.Filter] {
	"productStatusFilter": {
		"self": "وضعیت کالا",
		"productStatusId": "شناسه",
		"productStatusKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[ProductTransportCostType.Detail] {
	"productTransportCostTypeDetail": {
		"self": "نحوه محاسبه هزینه ارسال",
		"productTransportCostTypeId": "شناسه",
		"productTransportCostTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductTransportCostType.List] {
	"productTransportCostTypeList": {
		"self": "نحوه محاسبه هزینه ارسال",
		"productTransportCostTypeId": "شناسه",
		"productTransportCostTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[ProductTransportCostType.Filter] {
	"productTransportCostTypeFilter": {
		"self": "نحوه محاسبه هزینه ارسال",
		"productTransportCostTypeId": "شناسه",
		"productTransportCostTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"title": "عنوان",
			"cultureSymbol": "نشانه زبان",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "اختصار",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[BankPayment.GetPayment] {
	"bankPaymentGetPayment": {
		"self": "گرفتن درگاه پرداخت",
		"price": "مبلغ",
		"storeId": "فروشگاه",
		"gateWayAddress": "درگاه",
	},
	//GeneratorFlag:[Category.ListAction] {
	"categoryListAction": {
		"self": "لیست دسته بندی ها",
		"cultureId": "زبان",
		"categoryId": "شناسه",
		"items": {
			"self": "category list item",
			"title": "عنوان",
			"categoryId": "شناسه",
			"parentId": "والد",
			"imagePath": "آدرس تصویر",
			"categoryGenoString": "شجره",
		},
	},
	//GeneratorFlag:[Invoice.AmountSum] {
	"invoiceAmountSum": {
		"self": "جمع کل فروش",
		"totalFactorAmount": "جمع کل فاکتور",
		"savedValue": "مقدار سیو",
	},
	//GeneratorFlag:[Invoice.ConnectToOther] {
	"invoiceConnectToOther": {
		"self": "اتصال فاکتور",
		"invoiceId": "فاکتور",
		"personId": "شخحص",
		"result": "خروجی",
		"message": "پیغام",
	},
	//GeneratorFlag:[Invoice.GetContracts] {
	"invoiceGetContracts": {
		"self": "لیست فاکتور ها",
		"invoiceId": "",
		"totalAmount": "جمع کل",
		"serial": "سریال قرارداد",
		"beginDate": "تاریخ شروع",
		"endDate": "تاریخ پایان",
		"remainTenTimes": "مانده ده برابری",
	},
	//GeneratorFlag:[Invoice.GetInvoiceDetail] {
	"invoiceGetInvoiceDetail": {
		"self": "جزئیات فاکتور",
		"invoiceSerial": "سریال",
		"personId": "شخص",
		"personCode": "کد شخص",
		"invoiceId": "شناسه",
		"paybleAmount": "قابل پرداخت",
		"totalAmont": "جمع کل",
		"discountAmount": "تخفیف",
		"paidAmount": "پرداخت شده",
		"totalRemainAmount": "باقی مانده",
		"transportCostAmount": "حمل و نقل",
		"packagingCostAmount": "بسته بندی",
		"taxAmount": "مالیات",
		"displayCheckoutDate": "تاریخ تسویه",
		"invoiceStatuseName": "وضعیت",
		"invoicePaymentTypeName": "نوع پرداخت",
		"storeName": "فروشگاه",
		"isConnected": "وضعیت انتقال",
		"personAddressCountryName": "کشور",
		"personAddressProvinceName": "استان",
		"personAddressCity": "شهر",
		"reciverFirstName": "نام",
		"reciverLastName": "نام خانوادگی",
		"reciverMobile": "موبایل",
		"fullAddress": "آدرس",
		"plaque": "پلاک",
		"postalCode": "کدپستی",
		"items": {
			"self": "product invoice item",
			"productName": "کالا",
			"productColorHex": "کدرنگ",
			"productColorName": "رنگ",
			"productCount": "تعداد",
			"basePrice": "قیمت پایه",
			"totalPrice": "جمع کل",
		},
	},
	//GeneratorFlag:[Invoice.GetMyInvoiceCurrentWeek] {
	"invoiceGetMyInvoiceCurrentWeek": {
		"self": "لیست فاکتور های هفته جاری",
		"invoiceSerial": "سریال",
		"invoiceId": "شناسه",
		"paybleAmount": "قابل پرداخت",
	},
	//GeneratorFlag:[Invoice.GetMyList] {
	"invoiceGetMyList": {
		"self": "لیست فاکتور ها",
		"fromDisplayDate": "",
		"toDisplayDate": "",
		"invoiceProductItems": {
			"self": "get my list invoice product item message",
			"productName": "کالا",
			"porductCount": "تعداد",
			"productMonetaryFeatureTitle": "ویژگی",
			"productColorTitle": "رنگ",
			"description": "توضیحات",
		},
		"invoicePaymentTypeId": "شناسه نوع پرداخت",
		"invoicePaymentTypeTitle": "نوع پرداخت",
		"storeTitle": "فروشگاه",
		"description": "توضیحات",
		"invoiceId": "",
		"invoiceStatusTitle": "وضعیت",
		"totalPayble": "قابل پرداخت",
		"totalRemain": "باقی مانده",
		"totalAmount": "جمع کل",
		"unitCurrency": "واحد پولی",
		"transportCost": "حمل و نقل",
		"invoiceSerial": "سریال",
	},
	//GeneratorFlag:[Invoice.GetMyTeamSellByWeek] {
	"invoiceGetMyTeamSellByWeek": {
		"self": "فروش تیم",
		"fromDisplayDate": "از تاریخ",
		"toDisplayDate": "تا تاریخ",
		"personId": "شخص",
		"rowID": "ردیف",
		"personName": "شخص",
		"personCode": "کد",
		"totalSell": "فروش کل",
		"totalScore": "امتیاز کل",
		"totalInvoice": "تعداد فاکتور",
		"savedValue": "مقدار ذخیره",
		"personalSell": "فروش شخصی",
	},
	//GeneratorFlag:[Invoice.Register] {
	"invoiceRegister": {
		"self": "ثبت فاکتور",
		"personId": "",
		"personInvoiceId": "",
		"disountCode": "",
		"invoiceProductItems": {
			"self": "invoice product item",
			"productId": "",
			"porductCount": "",
			"productMonetaryFeatureId": "",
			"productColorId": "",
			"description": "",
		},
		"invoicePaymentTypeId": "",
		"storeId": "",
		"description": "",
		"invoiceId": "",
		"additionalData": "",
		"totalPayble": "",
		"postBackUrl": "",
		"transportCost": "",
		"personAddressId": "",
		"invoiceSerial": "",
	},
	//GeneratorFlag:[Invoice.UplineFactorInfo] {
	"invoiceUplineFactorInfo": {
		"self": "اطلاعات فاکتور بالا سری",
		"personId": "شخص",
		"count": "تعداد",
		"firstFactorAmount": "مبلغ اولین فاکتور",
		"totalFactorAmount": "جمع کل فروش",
	},
	//GeneratorFlag:[InvoiceCancel.ContractInfo] {
	"invoiceCancelContractInfo": {
		"self": "لغو قرارداد",
		"invoiceId": "شناسه فاکتور",
		"info": "جمع کل پورسانت",
		"totalCommision": "جمع کل پورسانت",
		"contractAmount": "جمع کل پورسانت",
		"finalAmount": "قابل پرداخت",
	},
	//GeneratorFlag:[InvoiceCancel.RemoveContract] {
	"invoiceCancelRemoveContract": {
		"self": "لغو قرارداد",
		"invoiceId": "شناسه فاکتور",
	},
	//GeneratorFlag:[PlanLine.GetCommisionCurrentWeek] {
	"planLineGetCommisionCurrentWeek": {
		"self": "پورسانت هفته جاری",
		"fromDisplayDate": "از تاریخ",
		"toDisplayDate": "تا تاریخ",
		"personId": "شخص",
		"rowID": "ردیف",
		"personName": "شخص",
		"personCode": "کد",
		"totalSale": "فروش کل",
		"finalTotalSale": "فروش نهایی",
		"linePrecent": "درصد",
		"commission": "پورسانت",
		"savedValue": "مقدار ذخیره",
	},
	//GeneratorFlag:[PlanLine.Filter] {
	"planLineFilter": {
		"self": "پورسانت هفته جاری",
		"fromDisplayDate": "از تاریخ",
		"toDisplayDate": "تا تاریخ",
		"personId": "شخص",
		"rowID": "ردیف",
		"personName": "شخص",
		"personCode": "کد",
		"totalSale": "فروش کل",
		"finalTotalSale": "فروش نهایی",
		"linePrecent": "درصد",
		"commission": "پورسانت",
		"savedValue": "مقدار ذخیره",
	},
	//GeneratorFlag:[PlanLine.GetMyDirect] {
	"planLineGetMyDirect": {
		"self": "ورودی های مستقیم من",
		"personId": "شناسه",
		"personFullName": "شخص",
		"personCode": "کد",
	},
	//GeneratorFlag:[PlanTransaction.CalculatePlan] {
	"planTransactionCalculatePlan": {
		"self": "محاسبه پلن",
		"apiSecretKey": "کلید",
		"beginDate": "تاریخ شروع",
		"personCode": "کد",
	},
	//GeneratorFlag:[PlanTransaction.Filter] {
	"planTransactionFilter": {
		"self": "محاسبه پلن",
		"apiSecretKey": "کلید",
		"beginDate": "تاریخ شروع",
		"personCode": "کد",
	},
	//GeneratorFlag:[PlanTransaction.GetListCommision] {
	"planTransactionGetListCommision": {
		"self": "لیست پاداش ها",
		"fromDisplayDate": "از",
		"toDisplayDate": "تا",
		"personId": "شناسه شخص",
		"totalCommision": "پورسانت کل",
		"finalCommision": "قابل پرداخت",
		"savedPerson": "دست ذخیره",
		"secondPersonLine": "دست دوم بزرگ",
		"firstLineTotalSell": "فروش دست بزرگ",
		"secondLineTotalSell ": "فروش دومین دست بزرگ",
		"displayDate": "تاریخ",
	},
	//GeneratorFlag:[PlanTransaction.GetReward] {
	"planTransactionGetReward": {
		"self": "لیست پاداش ها",
		"displayDate": "تاریخ",
		"totalCommision": "پورسانت کل",
		"finalCommision": "قابل پرداخت",
		"typeName": "نوع",
		"statusName": "وضعیت",
	},
	//GeneratorFlag:[PlanTransaction.GetVoacherAwards] {
	"planTransactionGetVoacherAwards": {
		"self": "لیست ووچر ها",
		"finalVoacher": "مقدار",
		"date": "تاریخ",
	},
	//GeneratorFlag:[PlanTransaction.List] {
	"planTransactionList": {
		"self": "لیست پاداش ها",
		"fromDisplayDate": "از تاریخ",
		"toDisplayDate": "تا تاریخ",
		"displayDate": "تاریخ",
		"personInfo": "عضو",
		"totalCommision": "پورسانت کل",
		"finalCommision": "قابل پرداخت",
		"planType": "نوع",
		"statuse": "وضعیت",
		"savedValue": "ذخیره",
		"level": "سطح",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PlanTransaction.RegisterExtraCommision] {
	"planTransactionRegisterExtraCommision": {
		"self": "ثبت پورسانت مازاد",
		"personId": "عضو",
		"totalCommision": "کل پورسانت",
		"finalCommision": "پورسانت نهایی",
		"date": "تاریخ",
		"planTransactionTypeId": "نوع",
		"caption": "توضیحات",
		"planTransactionId": "",
	},
	//GeneratorFlag:[PlanTransaction.TotalReport] {
	"planTransactionTotalReport": {
		"self": "مجموع پاداش ها",
		"fromDisplayDate": "از تاریخ",
		"toDisplayDate": "تا تاریخ",
		"planTransactionTypeId": "نوع",
		"planTransactionStatuseId": "وضعیت",
		"totalCommision": "پورسانت کل",
		"finalCommision": "قابل پرداخت",
		"totalSavedValue": "جمع کل ذخیره ها",
	},
	//GeneratorFlag:[PlanTransaction.TotalReward] {
	"planTransactionTotalReward": {
		"self": "مجموع پاداش ها",
		"totalCommision": "پورسانت کل",
		"finalCommision": "قابل پرداخت",
	},
	//GeneratorFlag:[Product.Register] {
	"productRegister": {
		"self": "ثبت محصول",
		"productId": "شناسه",
		"productKey": "محصول کلید",
		"cultureId": "زبان",
		"categoryId": "دسته بندی",
		"title": "عنوان",
		"caption": "توضیحات",
		"firstPrice": "قیمت پایه",
		"productCode": "کد",
		"imagePath": "تصویر",
		"productStatusId": "وضعیت",
		"productMonetaryFeatureTitle": "عنوان خصوصیت کالا",
		"productSpecification": {
			"self": "توضیحات محصول",
			"weight": "وزن",
			"width": "عرض",
			"length": "طول",
			"height": "ارتفاع",
			"sentCost": "هزینه ارسال",
			"isTaxable": "مشمول مالیات",
			"productTransportCostTypeId": "نوع هزینه ارسال",
		},
		"details": {
			"self": "جزئیات",
			"productDetailId": "شناسه جزئیات",
			"productStoreId": "شناسه جزئیات فروشگاه",
			"cultureId": "زبان",
			"storeId": "فروشگاه",
			"productColorId": "رنگ",
			"productMonetaryFeatureId": "ویژگی انتخابی",
			"productStatusId": "وضعیت",
			"adminDisplayName": "نام نمایش مدیر",
			"firstPrice": "قیمت اول",
			"secondPrice": "قیمت دوم",
			"productStoreInventory": "موجودی انبار",
			"productScore": "امتیاز کالا",
			"maxSellCount": "حداکثردر فاکتور",
			"minSellCount": "حداقل در فاکتور",
		},
		"images": {
			"self": "تصاویر",
			"productDocId": "شناسه تصویر",
			"docAddress": "تصویر",
			"base64String": "تصویر",
			"title": "عنوان",
			"caption": "توضیحات",
			"productDocTypeId": "نوع مدیا",
		},
	},
	//GeneratorFlag:[ProductCategory.CategoryProductRelatedAction] {
	"productCategoryCategoryProductRelatedAction": {
		"self": "محصولات مرتبط دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"title": "عنوان",
		"productId": "شناسه کالا",
		"productDetailId": "شناسه جزئیات",
		"imagePath": "تصویر",
		"firstPrice": "قیمت اول",
		"secondPrice": "قیمت دوم",
		"categoryName": "دسته بندی",
		"inverstory": "موجودی",
		"firstUnitPrice": "واحد قیمت اول",
		"secondUnitPrice": "واحد قیمت دوم",
		"linePrice": "قیمت خط خورده",
		"description": "توضیحات",
	},
	//GeneratorFlag:[ProductCategory.GetProductCategory] {
	"productCategoryGetProductCategory": {
		"self": "محصولات دسته بندی",
		"categoryId": "شناسه دسته بندی",
		"title": "عنوان",
		"productId": "شناسه کالا",
		"productDetailId": "شناسه جزئیات",
		"imagePath": "تصویر",
		"firstPrice": "قیمت اول",
		"secondPrice": "قیمت دوم",
		"categoryName": "دسته بندی",
		"inverstory": "موجودی",
		"firstUnitPrice": "واحد قیمت اول",
		"secondUnitPrice": "واحد قیمت دوم",
		"linePrice": "قیمت خط خورده",
		"description": "توضیحات",
	},
	//GeneratorFlag:[ProductCategory.GetProductCategoryListAction] {
	"productCategoryGetProductCategoryListAction": {
		"self": "product category",
		"categoryId": "",
		"cultureId": "",
		"title": "",
		"productId": "",
		"productDetailId": "",
		"imagePath": "",
		"basePrice": "",
		"secondPrice": "",
		"categoryName": "",
	},
	//GeneratorFlag:[ProductStore.GetProductInfo] {
	"productStoreGetProductInfo": {
		"self": "اطلاعات محصول",
		"productId": "شناسه محصول",
		"storeId": "شناسه فروشگاه",
		"productStoreId": "شناسه محصول فروشگاه",
		"cultureId": "شناسه زبان",
		"title": "عنوان",
		"productMonetaryFeatureTitle": "ویژگی انتخابی",
		"firstPrice": "قیمت اول",
		"scondPrice": "قیمت دوم",
		"productStoreInventory": "موجودی",
		"caption": "توضیحات",
		"currency": "واحد",
		"maxSellCount": "حد اکثر تعداد خرید",
		"minSellCount": "حد اقل تعداد خرید",
		"productColorId": "شناسه رنگ",
		"productMonetaryFeatureId": "شناسه انتخابی",
		"categoryId": "شناسه دسته بندی",
		"categoryName": "دسته بندی",
		"storeName": "فروشگاه",
		"width": "ارتفاع",
		"weight": "وزن",
		"height": "عرض",
		"lenght": "طول",
		"productStatusId": "شناسه وضعیت",
		"productStatusTitle": "وضعیت",
		"images": {
			"self": "تصاویر محصول",
			"id": "شناسه عکس",
			"title": "",
			"caption": "",
			"imagePath": "",
			"productDocTypeId": "",
		},
		"colors": {
			"self": "رنگ ها",
			"id": "",
			"productColorId": "",
			"title": "",
			"colorHexaCode": "",
			"firstPrice": "",
			"secondPrice": "",
			"productMonetaryFeatureId": "",
			"productStoreInventory": "",
		},
		"monetaryFeatures": {
			"self": "ویژگی های انتخابی",
			"productMonetaryFeatureId": "",
			"title": "",
			"firstPrice": "",
			"secondPrice": "",
			"productColorId": "",
			"productStoreInventory": "",
		},
	},
	//GeneratorFlag:[Invoice.ContactSummray] {
	"invoiceContactSummray": {
		"self": "گزارش قرارداد ها",
		"contractCount": "تعداد کل",
		"totalContractAmount": "مجموع قرارداد ها",
		"endedContractCount": "تعداد پایان یافته",
		"totalEndedContractAmount": "مجموع پایان یافته",
		"totalCancelledContract": "مجموع فسخ شده",
		"totalCancelledContractCount": "تعداد فسخ شده",
	},
	//GeneratorFlag:[PlanTransactionCommentType.Detail] {
	"planTransactionCommentTypeDetail": {
		"self": "نوع توضیح پلن",
		"planTransactionCommentTypeId": "شناسه",
		"planTransactionCommentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionCommentType.List] {
	"planTransactionCommentTypeList": {
		"self": "نوع توضیح پلن",
		"planTransactionCommentTypeId": "شناسه",
		"planTransactionCommentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PlanTransactionCommentType.Filter] {
	"planTransactionCommentTypeFilter": {
		"self": "نوع توضیح پلن",
		"planTransactionCommentTypeId": "شناسه",
		"planTransactionCommentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
//GeneratorFlag:[NewEntity]
}