//GeneratorFlag:[NoChange]
export default {
	path: 'person-gender',
	name: 'peopleNet.baseSys.personGender.layout',
	redirect: to => {
		return {name: 'peopleNet.personGender.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-gender"],
		resourceKey: 'peopleNet.views.personGender.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonGenderDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personGender.detail',
			component: () => import('@/modules/people-net/views/person-gender/person-gender-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personGender.detail',
				clientAccessKeys:["/people-net/base-sys/person-gender/detail"],
				serverAccessKeys:["/people-net/person-gender/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonGenderList]"
		{
			path: 'list',
			name: 'peopleNet.personGender.list',
			component: () => import('@/modules/people-net/views/person-gender/person-gender-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personGender.list',
				clientAccessKeys:["/people-net/base-sys/person-gender/list"],
				serverAccessKeys:["/people-net/person-gender/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}