//GeneratorFlag:[NoChange]
export default {
	path: 'notice-config',
	name: 'hub.notice.noticeConfig.layout',
	redirect: to => {
		return {name: 'hub.noticeConfig.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/notice/notice-config"],
		resourceKey: 'hub.views.noticeConfig.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NoticeConfigCreate]"
		{
			path: 'create',
			name: 'hub.noticeConfig.create',
			component: () => import('@/modules/hub/views/notice-config/notice-config-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.create',
				clientAccessKeys:["/hub/notice/notice-config/create"],
				serverAccessKeys:["/hub/notice-config/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigCreateImport]"
		{
			path: 'create-import',
			name: 'hub.noticeConfig.createImport',
			component: () => import('@/modules/hub/views/notice-config/notice-config-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.createImport',
				clientAccessKeys:["/hub/notice/notice-config/create-import"],
				serverAccessKeys:["/hub/notice-config/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigDelete]"
		{
			path: 'delete',
			name: 'hub.noticeConfig.delete',
			component: () => import('@/modules/hub/views/notice-config/notice-config-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.delete',
				clientAccessKeys:["/hub/notice/notice-config/delete"],
				serverAccessKeys:["/hub/notice-config/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigDetail]"
		{
			path: 'detail',
			name: 'hub.noticeConfig.detail',
			component: () => import('@/modules/hub/views/notice-config/notice-config-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.detail',
				clientAccessKeys:["/hub/notice/notice-config/detail"],
				serverAccessKeys:["/hub/notice-config/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigList]"
		{
			path: 'list',
			name: 'hub.noticeConfig.list',
			component: () => import('@/modules/hub/views/notice-config/notice-config-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.list',
				clientAccessKeys:["/hub/notice/notice-config/list"],
				serverAccessKeys:["/hub/notice-config/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigUpdate]"
		{
			path: 'update',
			name: 'hub.noticeConfig.update',
			component: () => import('@/modules/hub/views/notice-config/notice-config-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.update',
				clientAccessKeys:["/hub/notice/notice-config/update"],
				serverAccessKeys:["/hub/notice-config/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigIppanelDetail]"
		{
			path: 'notice-config-ippanel-detail',
			name: 'hub.noticeConfig.noticeConfigIppanelDetail',
			component: () => import('@/modules/hub/views/notice-config/notice-config-ippanel-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.noticeConfigIppanelDetail',
				clientAccessKeys:["/hub/notice/notice-config/notice-config-ippanel-detail"],
				serverAccessKeys:["/hub/notice-config-ippanel/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigIppanelUpdate]"
		{
			path: 'notice-config-ippanel-update',
			name: 'hub.noticeConfig.noticeConfigIppanelUpdate',
			component: () => import('@/modules/hub/views/notice-config/notice-config-ippanel-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.noticeConfigIppanelUpdate',
				clientAccessKeys:["/hub/notice/notice-config/notice-config-ippanel-update"],
				serverAccessKeys:["/hub/notice-config-ippanel/update","/hub/notice-config-ippanel/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigKavenegarDetail]"
		{
			path: 'notice-config-kavenegar-detail',
			name: 'hub.noticeConfig.noticeConfigKavenegarDetail',
			component: () => import('@/modules/hub/views/notice-config/notice-config-kavenegar-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.noticeConfigKavenegarDetail',
				clientAccessKeys:["/hub/notice/notice-config/notice-config-kavenegar-detail"],
				serverAccessKeys:["/hub/notice-config-kavenegar/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NoticeConfigKavenegarUpdate]"
		{
			path: 'notice-config-kavenegar-update',
			name: 'hub.noticeConfig.noticeConfigKavenegarUpdate',
			component: () => import('@/modules/hub/views/notice-config/notice-config-kavenegar-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.noticeConfig.noticeConfigKavenegarUpdate',
				clientAccessKeys:["/hub/notice/notice-config/notice-config-kavenegar-update"],
				serverAccessKeys:["/hub/notice-config-kavenegar/update","/hub/notice-config-kavenegar/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]










	]
}