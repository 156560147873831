//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"hub",
	"ext": ext,
	"layout":{
		"notice": "مدیریت اطلاعیه ها", //GeneratorFlag:[NoticeLayout]
		"geographical": "اطلاعات جغرافیایی", //GeneratorFlag:[GeographicalLayout]
		"baseInfo": "اطلاعات پایه", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "اطلاعات سیستمی", //GeneratorFlag:[BaseSysLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		'noticeConfig': {
			'layout': 'پیکربندی اطلاعایه ها',
			'create': 'ثبت پیکربندی اطلاعیه جدید', //GeneratorFlag:[Views.NoticeConfig.Create]
			'createImport': 'ثبت پیکربندی اطلاعایه ها از طریق اکسل', //GeneratorFlag:[Views.NoticeConfig.CreateImport] 
 			'delete': 'حذف پیکربندی اطلاعیه', //GeneratorFlag:[Views.NoticeConfig.Delete] 
 			'detail': 'جزئیات اطلاعات پیکربندی اطلاعیه', //GeneratorFlag:[Views.NoticeConfig.Detail] 
 			'list': 'فهرست پیکربندی اطلاعایه ها', //GeneratorFlag:[Views.NoticeConfig.List] 
 			'update': 'ویرایش پیکربندی اطلاعیه', //GeneratorFlag:[Views.NoticeConfig.Update] 
 			'noticeConfigIppanelDetail': 'جزئیات اطلاعات پنل آیپی پنل', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigIppanelDetail] 
 			'noticeConfigIppanelUpdate': 'ویرایش اطلاعات پنل آیپی پنل', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigIppanelUpdate] 
 			'noticeConfigKavenegarDetail': 'جزئیات اطلاعات پنل کاوه نگار', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigKavenegarDetail] 
 			'noticeConfigKavenegarUpdate': 'ویرایش اطلاعات پنل کاوه نگار', //GeneratorFlag:[Views.NoticeConfig.NoticeConfigKavenegarUpdate] 
 //GeneratorFlag:[Views.NoticeConfig]
		},
		'person': {
			'layout': 'اشخاص',
			'create': 'ثبت شخص جدید', //GeneratorFlag:[Views.Person.Create]
			'createImport': 'ثبت اشخاص از طریق اکسل', //GeneratorFlag:[Views.Person.CreateImport] 
 			'delete': 'حذف شخص', //GeneratorFlag:[Views.Person.Delete] 
 			'detail': 'جزئیات اطلاعات شخص', //GeneratorFlag:[Views.Person.Detail] 
 			'list': 'فهرست اشخاص', //GeneratorFlag:[Views.Person.List] 
 			'update': 'ویرایش شخص', //GeneratorFlag:[Views.Person.Update] 
 			'personAccessDetail': 'جزئیات اطلاعات دسترسی', //GeneratorFlag:[Views.Person.PersonAccessDetail] 
 			'personAccessUpdate': 'ویرایش اطلاعات دسترسی', //GeneratorFlag:[Views.Person.PersonAccessUpdate] 
 			'personLegalDetail': 'جزئیات اطلاعات حقوقی', //GeneratorFlag:[Views.Person.PersonLegalDetail] 
 			'personLegalUpdate': 'ویرایش اطلاعات حقوقی', //GeneratorFlag:[Views.Person.PersonLegalUpdate] 
 			'personRealDetail': 'جزئیات اطلاعات حقیقی', //GeneratorFlag:[Views.Person.PersonRealDetail] 
 			'personRealUpdate': 'ویرایش اطلاعات حقیقی', //GeneratorFlag:[Views.Person.PersonRealUpdate] 
 //GeneratorFlag:[Views.Person]
		},
		'city': {
			'layout': ' شهرها',
			'create': 'ثبت شهر جدید', //GeneratorFlag:[Views.City.Create]
			'createImport': 'ثبت شهرها از طریق اکسل', //GeneratorFlag:[Views.City.CreateImport] 
 			'delete': 'حذف شهر', //GeneratorFlag:[Views.City.Delete] 
 			'detail': 'جزئیات اطلاعات شهر', //GeneratorFlag:[Views.City.Detail] 
 			'list': 'فهرست شهرها', //GeneratorFlag:[Views.City.List] 
 			'update': 'ویرایش شهر', //GeneratorFlag:[Views.City.Update] 
 //GeneratorFlag:[Views.City]
		},
		'country': {
			'layout': ' کشور ها',
			'create': 'ثبت کشور جدید', //GeneratorFlag:[Views.Country.Create]
			'createImport': 'ثبت کشور ها از طریق اکسل', //GeneratorFlag:[Views.Country.CreateImport] 
 			'delete': 'حذف کشور', //GeneratorFlag:[Views.Country.Delete] 
 			'detail': 'جزئیات اطلاعات کشور', //GeneratorFlag:[Views.Country.Detail] 
 			'list': 'فهرست کشور ها', //GeneratorFlag:[Views.Country.List] 
 			'update': 'ویرایش کشور', //GeneratorFlag:[Views.Country.Update] 
 //GeneratorFlag:[Views.Country]
		},
		'personMilitary': {
			'layout': ' وضعیت های سربازی',
			'create': 'ثبت وضعیت سربازی جدید', //GeneratorFlag:[Views.PersonMilitary.Create]
			'createImport': 'ثبت وضعیت های سربازی از طریق اکسل', //GeneratorFlag:[Views.PersonMilitary.CreateImport] 
 			'delete': 'حذف وضعیت سربازی', //GeneratorFlag:[Views.PersonMilitary.Delete] 
 			'detail': 'جزئیات اطلاعات وضعیت سربازی', //GeneratorFlag:[Views.PersonMilitary.Detail] 
 			'list': 'فهرست وضعیت های سربازی', //GeneratorFlag:[Views.PersonMilitary.List] 
 			'update': 'ویرایش وضعیت سربازی', //GeneratorFlag:[Views.PersonMilitary.Update] 
 //GeneratorFlag:[Views.PersonMilitary]
		},
		'personNationality': {
			'layout': ' ملیت ها',
			'create': 'ثبت ملیت جدید', //GeneratorFlag:[Views.PersonNationality.Create]
			'createImport': 'ثبت ملیت ها از طریق اکسل', //GeneratorFlag:[Views.PersonNationality.CreateImport] 
 			'delete': 'حذف ملیت', //GeneratorFlag:[Views.PersonNationality.Delete] 
 			'detail': 'جزئیات اطلاعات ملیت', //GeneratorFlag:[Views.PersonNationality.Detail] 
 			'list': 'فهرست ملیت ها', //GeneratorFlag:[Views.PersonNationality.List] 
 			'update': 'ویرایش ملیت', //GeneratorFlag:[Views.PersonNationality.Update] 
 //GeneratorFlag:[Views.PersonNationality]
		},
		'personRole': {
			'layout': ' نقش های کاربری',
			'create': 'ثبت نقش کاربری جدید', //GeneratorFlag:[Views.PersonRole.Create]
			'createImport': 'ثبت نقش های کاربری از طریق اکسل', //GeneratorFlag:[Views.PersonRole.CreateImport] 
 			'delete': 'حذف نقش کاربری', //GeneratorFlag:[Views.PersonRole.Delete] 
 			'detail': 'جزئیات اطلاعات نقش کاربری', //GeneratorFlag:[Views.PersonRole.Detail] 
 			'list': 'فهرست نقش های کاربری', //GeneratorFlag:[Views.PersonRole.List] 
 			'update': 'ویرایش نقش کاربری', //GeneratorFlag:[Views.PersonRole.Update] 
 //GeneratorFlag:[Views.PersonRole]
		},
		'province': {
			'layout': ' استان ها',
			'create': 'ثبت استان جدید', //GeneratorFlag:[Views.Province.Create]
			'createImport': 'ثبت استان ها از طریق اکسل', //GeneratorFlag:[Views.Province.CreateImport] 
 			'delete': 'حذف استان', //GeneratorFlag:[Views.Province.Delete] 
 			'detail': 'جزئیات اطلاعات استان', //GeneratorFlag:[Views.Province.Detail] 
 			'list': 'فهرست استان ها', //GeneratorFlag:[Views.Province.List] 
 			'update': 'ویرایش استان', //GeneratorFlag:[Views.Province.Update] 
 //GeneratorFlag:[Views.Province]
		},
		'region': {
			'layout': ' مناطق',
			'create': 'ثبت منطقه جدید', //GeneratorFlag:[Views.Region.Create]
			'createImport': 'ثبت مناطق از طریق اکسل', //GeneratorFlag:[Views.Region.CreateImport] 
 			'delete': 'حذف منطقه', //GeneratorFlag:[Views.Region.Delete] 
 			'detail': 'جزئیات اطلاعات منطقه', //GeneratorFlag:[Views.Region.Detail] 
 			'list': 'فهرست مناطق', //GeneratorFlag:[Views.Region.List] 
 			'update': 'ویرایش منطقه', //GeneratorFlag:[Views.Region.Update] 
 //GeneratorFlag:[Views.Region]
		},
		'fileExtension': {
			'layout': 'پسوندهای فایل',
			'detail': 'جزئیات اطلاعات پسوند فایل', //GeneratorFlag:[Views.FileExtension.Detail]
			'list': 'فهرست پسوندهای فایل', //GeneratorFlag:[Views.FileExtension.List] 
 //GeneratorFlag:[Views.FileExtension]
		},
		'noticeProvider': {
			'layout': 'NoticeProvider',
			'detail': 'جزئیات اطلاعات NoticeProvider', //GeneratorFlag:[Views.NoticeProvider.Detail]
			'list': 'فهرست NoticeProvider', //GeneratorFlag:[Views.NoticeProvider.List] 
 //GeneratorFlag:[Views.NoticeProvider]
		},
		'noticeStatus': {
			'layout': 'NoticeStatus',
			'detail': 'جزئیات اطلاعات NoticeStatus', //GeneratorFlag:[Views.NoticeStatus.Detail]
			'list': 'فهرست NoticeStatus', //GeneratorFlag:[Views.NoticeStatus.List] 
 //GeneratorFlag:[Views.NoticeStatus]
		},
		'noticeType': {
			'layout': 'انواع اطلاعیه',
			'detail': 'جزئیات اطلاعات نوع اطلاعیه', //GeneratorFlag:[Views.NoticeType.Detail]
			'list': 'فهرست انواع اطلاعیه', //GeneratorFlag:[Views.NoticeType.List] 
 //GeneratorFlag:[Views.NoticeType]
		},
		'personEntity': {
			'layout': 'شخصیت ها',
			'detail': 'جزئیات اطلاعات شخصیت', //GeneratorFlag:[Views.PersonEntity.Detail]
			'list': 'فهرست شخصیت ها', //GeneratorFlag:[Views.PersonEntity.List] 
 //GeneratorFlag:[Views.PersonEntity]
		},
		'personGender': {
			'layout': 'جنسیت ها',
			'detail': 'جزئیات اطلاعات جنسیت', //GeneratorFlag:[Views.PersonGender.Detail]
			'list': 'فهرست جنسیت ها', //GeneratorFlag:[Views.PersonGender.List] 
 //GeneratorFlag:[Views.PersonGender]
		},
		'personGrade': {
			'layout': 'مدارک تحصیلی',
			'detail': 'جزئیات اطلاعات مدرک تحصیلی', //GeneratorFlag:[Views.PersonGrade.Detail]
			'list': 'فهرست مدارک تحصیلی', //GeneratorFlag:[Views.PersonGrade.List] 
 //GeneratorFlag:[Views.PersonGrade]
		},
		'personMarital': {
			'layout': 'وضعیت های تاهل',
			'detail': 'جزئیات اطلاعات وضعیت تاهل', //GeneratorFlag:[Views.PersonMarital.Detail]
			'list': 'فهرست وضعیت های تاهل', //GeneratorFlag:[Views.PersonMarital.List] 
 //GeneratorFlag:[Views.PersonMarital]
		},
		'accessAgent': {
			'layout': 'مدیریت دسترسی ها',
			'save': 'مدیریت دسترسی ها', //GeneratorFlag:[Views.AccessAgent.Save]
//GeneratorFlag:[Views.AccessAgent]
		},
		'accessToken': {
			'layout': 'دریافت توکن',
			'generate': 'دریافت توکن', //GeneratorFlag:[Views.AccessToken.Generate]
//GeneratorFlag:[Views.AccessToken]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[NoticeConfig.Create] {
	'noticeConfigCreate': {
		'self': 'پیکربندی اطلاعیه',
		"noticeConfigId": "شناسه",
		"title": "عنوان",
		"module": "ماژول",
		"noticeKey": "کلید سیستمی",
		"propertyOne": "کلید شماره یک",
		"propertyTwo": "کلید شماره دو",
		"propertyThree": "کلید شماره سه",
		"propertyFour": "کلید شماره چهار",
		"propertyFive": "کلید شماره پنج",
		"propertySix": "کلید شماره شش",
		"noticeTypeId": "نوع اطلاعیه",
		"noticeProviderId": "notice prover",
		"identifier": "کلید یکتا",
		'noticeConfigIppanel': {
			'self': 'اطلاعات پنل آیپی پنل',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'اطلاعات پنل کاوه نگار',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
	},
	//GeneratorFlag:[NoticeConfig.Delete] {
	'noticeConfigDelete': {
		'self': 'پیکربندی اطلاعیه',
		"noticeConfigId": "شناسه",
	},
	//GeneratorFlag:[NoticeConfig.Detail] {
	'noticeConfigDetail': {
		'self': 'پیکربندی اطلاعیه',
		"noticeConfigId": "شناسه",
		"title": "عنوان",
		"module": "ماژول",
		"noticeKey": "کلید سیستمی",
		"propertyOne": "کلید شماره یک",
		"propertyTwo": "کلید شماره دو",
		"propertyThree": "کلید شماره سه",
		"propertyFour": "کلید شماره چهار",
		"propertyFive": "کلید شماره پنج",
		"propertySix": "کلید شماره شش",
		"noticeTypeId": "نوع اطلاعیه",
		"noticeProviderId": "notice prover",
		'noticeProvider': {
			'self': 'NoticeProviderId',
			"noticeProviderId": "notice provider id",
			"title": "عنوان",
			"caption": "عنوان",
		},
		'noticeType': {
			'self': 'نوع اطلاعیه',
			"noticeTypeId": "notice type id",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'noticeConfigIppanel': {
			'self': 'اطلاعات پنل آیپی پنل',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'اطلاعات پنل کاوه نگار',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
		"noticeConfigLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[NoticeConfig.List] {
	'noticeConfigList': {
		'self': 'پیکربندی اطلاعیه',
		"noticeConfigId": "شناسه",
		"title": "عنوان",
		"module": "ماژول",
		"noticeKey": "کلید سیستمی",
		"propertyOne": "کلید شماره یک",
		"propertyTwo": "کلید شماره دو",
		"propertyThree": "کلید شماره سه",
		"propertyFour": "کلید شماره چهار",
		"propertyFive": "کلید شماره پنج",
		"propertySix": "کلید شماره شش",
		"noticeTypeId": "نوع اطلاعیه",
		"noticeProviderId": "notice prover",
		'noticeProvider': {
			'self': 'NoticeProviderId',
			"noticeProviderId": "notice provider id",
			"title": "عنوان",
			"caption": "عنوان",
		},
		'noticeType': {
			'self': 'نوع اطلاعیه',
			"noticeTypeId": "notice type id",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'noticeConfigIppanel': {
			'self': 'اطلاعات پنل آیپی پنل',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'اطلاعات پنل کاوه نگار',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
		"noticeConfigLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[NoticeConfig.Filter] {
	'noticeConfigFilter': {
		'self': 'پیکربندی اطلاعیه',
		"noticeConfigId": "شناسه",
		"title": "عنوان",
		"module": "ماژول",
		"noticeKey": "کلید سیستمی",
		"propertyOne": "کلید شماره یک",
		"propertyTwo": "کلید شماره دو",
		"propertyThree": "کلید شماره سه",
		"propertyFour": "کلید شماره چهار",
		"propertyFive": "کلید شماره پنج",
		"propertySix": "کلید شماره شش",
		"noticeTypeId": "نوع اطلاعیه",
		"noticeProviderId": "notice prover",
		'noticeProvider': {
			'self': 'NoticeProviderId',
			"noticeProviderId": "notice provider id",
			"title": "عنوان",
			"caption": "عنوان",
		},
		'noticeType': {
			'self': 'نوع اطلاعیه',
			"noticeTypeId": "notice type id",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'noticeConfigIppanel': {
			'self': 'اطلاعات پنل آیپی پنل',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'اطلاعات پنل کاوه نگار',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
		"noticeConfigLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[NoticeConfig.Update] {
	'noticeConfigUpdate': {
		'self': 'پیکربندی اطلاعیه',
		"noticeConfigId": "شناسه",
		"title": "عنوان",
		"module": "ماژول",
		"noticeKey": "کلید سیستمی",
		"propertyOne": "کلید شماره یک",
		"propertyTwo": "کلید شماره دو",
		"propertyThree": "کلید شماره سه",
		"propertyFour": "کلید شماره چهار",
		"propertyFive": "کلید شماره پنج",
		"propertySix": "کلید شماره شش",
		"noticeTypeId": "نوع اطلاعیه",
		"noticeProviderId": "notice prover",
		"identifier": "کلید یکتا",
		'noticeConfigIppanel': {
			'self': 'اطلاعات پنل آیپی پنل',
			"noticeConfigId": "شناسه",
			"useName": "نام کاربری",
			"password": "کلمه عبور",
			"patternCode": "کد الگو",
			"number": "سرشماره",
			"apiAdderss": "آدرس api",
		},
		'noticeConfigKavenegar': {
			'self': 'اطلاعات پنل کاوه نگار',
			"noticeConfigId": "شناسه",
			"apiKey": "api key",
			"tokenOne": "کلید معادل توکن",
			"tokenTwo": "کلید معادل توکن 2",
			"tokenThree": "کلید معادل توکن 3",
		},
	},
	//GeneratorFlag:[Person.Create] {
	'personCreate': {
		'self': 'شخص',
		"personId": "شناسه",
		"personName": "نام",
		"personEntityId": "نوع شخصیت",
		"personRoleId": "نقش کاربری",
		"cityId": "شهر",
		"identifier": "کلید یکتا",
		'personAccess': {
			'self': 'اطلاعات دسترسی',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'اطلاعات حقوقی',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		'personReal': {
			'self': 'اطلاعات حقیقی',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.Delete] {
	'personDelete': {
		'self': 'شخص',
		"personId": "شناسه",
	},
	//GeneratorFlag:[Person.Detail] {
	'personDetail': {
		'self': 'شخص',
		"personId": "شناسه",
		"personName": "نام",
		"personEntityId": "نوع شخصیت",
		"personRoleId": "نقش کاربری",
		"cityId": "شهر",
		'city': {
			'self': 'شهر',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		'personEntity': {
			'self': 'نوع شخصیت',
			"personEntityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personRole': {
			'self': 'نقش کاربری',
			"personRoleId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personAccess': {
			'self': 'اطلاعات دسترسی',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'اطلاعات حقوقی',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		"personLogr": "اطلاعات ثبت",
		'personReal': {
			'self': 'اطلاعات حقیقی',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.List] {
	'personList': {
		'self': 'شخص',
		"personId": "شناسه",
		"personName": "نام",
		"personEntityId": "نوع شخصیت",
		"personRoleId": "نقش کاربری",
		"cityId": "شهر",
		'city': {
			'self': 'شهر',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		'personEntity': {
			'self': 'نوع شخصیت',
			"personEntityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personRole': {
			'self': 'نقش کاربری',
			"personRoleId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personAccess': {
			'self': 'اطلاعات دسترسی',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'اطلاعات حقوقی',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		"personLogr": "اطلاعات ثبت",
		'personReal': {
			'self': 'اطلاعات حقیقی',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.Filter] {
	'personFilter': {
		'self': 'شخص',
		"personId": "شناسه",
		"personName": "نام",
		"personEntityId": "نوع شخصیت",
		"personRoleId": "نقش کاربری",
		"cityId": "شهر",
		'city': {
			'self': 'شهر',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		'personEntity': {
			'self': 'نوع شخصیت',
			"personEntityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personRole': {
			'self': 'نقش کاربری',
			"personRoleId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personAccess': {
			'self': 'اطلاعات دسترسی',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'اطلاعات حقوقی',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		"personLogr": "اطلاعات ثبت",
		'personReal': {
			'self': 'اطلاعات حقیقی',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[Person.Update] {
	'personUpdate': {
		'self': 'شخص',
		"personId": "شناسه",
		"personName": "نام",
		"personEntityId": "نوع شخصیت",
		"personRoleId": "نقش کاربری",
		"cityId": "شهر",
		"identifier": "کلید یکتا",
		'personAccess': {
			'self': 'اطلاعات دسترسی',
			"personId": "شناسه شخص",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
			"accessIps": "آیپی های مجاز",
			"isActive": "وضعیت",
		},
		'personLegal': {
			'self': 'اطلاعات حقوقی',
			"personId": "person",
			"legalName": "نام حقوقی",
			"nationalCode": "شناسه ملی",
			"registerNumber": "شناسه ثبت",
			"economicNumber": "شناسه اقتصادی",
			"phoneNumber": "شماره تماس ثابت",
			"fax": "فکس",
			"email": "ایمیل",
			"postalCode": "کد پستی",
			"address": "آدرس",
		},
		'personReal': {
			'self': 'اطلاعات حقیقی',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[City.Create] {
	'cityCreate': {
		'self': 'شهر',
		"cityId": "شناسه",
		"nativeName": "نام شهر",
		"caption": "توضیحات",
		"provinceId": "استان",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[City.Delete] {
	'cityDelete': {
		'self': 'شهر',
		"cityId": "شناسه",
	},
	//GeneratorFlag:[City.Detail] {
	'cityDetail': {
		'self': 'شهر',
		"cityId": "شناسه",
		"nativeName": "نام شهر",
		"caption": "توضیحات",
		"provinceId": "استان",
		'province': {
			'self': 'استان',
			"provinceId": "شناسه",
			"nativeName": "نام استان",
			"caption": "توضیحات",
			"countryId": "کشور",
		},
		"cityLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[City.List] {
	'cityList': {
		'self': 'شهر',
		"cityId": "شناسه",
		"nativeName": "نام شهر",
		"caption": "توضیحات",
		"provinceId": "استان",
		'province': {
			'self': 'استان',
			"provinceId": "شناسه",
			"nativeName": "نام استان",
			"caption": "توضیحات",
			"countryId": "کشور",
		},
		"cityLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[City.Filter] {
	'cityFilter': {
		'self': 'شهر',
		"cityId": "شناسه",
		"nativeName": "نام شهر",
		"caption": "توضیحات",
		"provinceId": "استان",
		'province': {
			'self': 'استان',
			"provinceId": "شناسه",
			"nativeName": "نام استان",
			"caption": "توضیحات",
			"countryId": "کشور",
		},
		"cityLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[City.Update] {
	'cityUpdate': {
		'self': 'شهر',
		"cityId": "شناسه",
		"nativeName": "نام شهر",
		"caption": "توضیحات",
		"provinceId": "استان",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[Country.Create] {
	'countryCreate': {
		'self': 'کشور',
		"countryId": "شناسه",
		"nativeName": "نام کشور",
		"capitalName": "نام پایتخت",
		"caption": "توضیحات",
		"code": "کد کشور",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[Country.Delete] {
	'countryDelete': {
		'self': 'کشور',
		"countryId": "شناسه",
	},
	//GeneratorFlag:[Country.Detail] {
	'countryDetail': {
		'self': 'کشور',
		"countryId": "شناسه",
		"nativeName": "نام کشور",
		"capitalName": "نام پایتخت",
		"caption": "توضیحات",
		"code": "کد کشور",
		"countryLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Country.List] {
	'countryList': {
		'self': 'کشور',
		"countryId": "شناسه",
		"nativeName": "نام کشور",
		"capitalName": "نام پایتخت",
		"caption": "توضیحات",
		"code": "کد کشور",
		"countryLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Country.Filter] {
	'countryFilter': {
		'self': 'کشور',
		"countryId": "شناسه",
		"nativeName": "نام کشور",
		"capitalName": "نام پایتخت",
		"caption": "توضیحات",
		"code": "کد کشور",
		"countryLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Country.Update] {
	'countryUpdate': {
		'self': 'کشور',
		"countryId": "شناسه",
		"nativeName": "نام کشور",
		"capitalName": "نام پایتخت",
		"caption": "توضیحات",
		"code": "کد کشور",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[PersonMilitary.Create] {
	'personMilitaryCreate': {
		'self': 'وضعیت سربازی',
		"personMilitaryId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[PersonMilitary.Delete] {
	'personMilitaryDelete': {
		'self': 'وضعیت سربازی',
		"personMilitaryId": "شناسه",
	},
	//GeneratorFlag:[PersonMilitary.Detail] {
	'personMilitaryDetail': {
		'self': 'وضعیت سربازی',
		"personMilitaryId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personMilitaryLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[PersonMilitary.List] {
	'personMilitaryList': {
		'self': 'وضعیت سربازی',
		"personMilitaryId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personMilitaryLogr": "اطلاعات ثبت",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMilitary.Filter] {
	'personMilitaryFilter': {
		'self': 'وضعیت سربازی',
		"personMilitaryId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personMilitaryLogr": "اطلاعات ثبت",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMilitary.Update] {
	'personMilitaryUpdate': {
		'self': 'وضعیت سربازی',
		"personMilitaryId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[PersonNationality.Create] {
	'personNationalityCreate': {
		'self': 'ملیت',
		"personNationalityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[PersonNationality.Delete] {
	'personNationalityDelete': {
		'self': 'ملیت',
		"personNationalityId": "شناسه",
	},
	//GeneratorFlag:[PersonNationality.Detail] {
	'personNationalityDetail': {
		'self': 'ملیت',
		"personNationalityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personNationalityLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[PersonNationality.List] {
	'personNationalityList': {
		'self': 'ملیت',
		"personNationalityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personNationalityLogr": "اطلاعات ثبت",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonNationality.Filter] {
	'personNationalityFilter': {
		'self': 'ملیت',
		"personNationalityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personNationalityLogr": "اطلاعات ثبت",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonNationality.Update] {
	'personNationalityUpdate': {
		'self': 'ملیت',
		"personNationalityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[PersonRole.Create] {
	'personRoleCreate': {
		'self': 'نقش کاربری',
		"personRoleId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[PersonRole.Delete] {
	'personRoleDelete': {
		'self': 'نقش کاربری',
		"personRoleId": "شناسه",
	},
	//GeneratorFlag:[PersonRole.Detail] {
	'personRoleDetail': {
		'self': 'نقش کاربری',
		"personRoleId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personRoleLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[PersonRole.List] {
	'personRoleList': {
		'self': 'نقش کاربری',
		"personRoleId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personRoleLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[PersonRole.Filter] {
	'personRoleFilter': {
		'self': 'نقش کاربری',
		"personRoleId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"personRoleLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[PersonRole.Update] {
	'personRoleUpdate': {
		'self': 'نقش کاربری',
		"personRoleId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[Province.Create] {
	'provinceCreate': {
		'self': 'استان',
		"provinceId": "شناسه",
		"nativeName": "نام استان",
		"caption": "توضیحات",
		"countryId": "کشور",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[Province.Delete] {
	'provinceDelete': {
		'self': 'استان',
		"provinceId": "شناسه",
	},
	//GeneratorFlag:[Province.Detail] {
	'provinceDetail': {
		'self': 'استان',
		"provinceId": "شناسه",
		"nativeName": "نام استان",
		"caption": "توضیحات",
		"countryId": "کشور",
		'country': {
			'self': 'کشور',
			"countryId": "شناسه",
			"nativeName": "نام کشور",
			"capitalName": "نام پایتخت",
			"caption": "توضیحات",
			"code": "کد کشور",
		},
		"provinceLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Province.List] {
	'provinceList': {
		'self': 'استان',
		"provinceId": "شناسه",
		"nativeName": "نام استان",
		"caption": "توضیحات",
		"countryId": "کشور",
		'country': {
			'self': 'کشور',
			"countryId": "شناسه",
			"nativeName": "نام کشور",
			"capitalName": "نام پایتخت",
			"caption": "توضیحات",
			"code": "کد کشور",
		},
		"provinceLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Province.Filter] {
	'provinceFilter': {
		'self': 'استان',
		"provinceId": "شناسه",
		"nativeName": "نام استان",
		"caption": "توضیحات",
		"countryId": "کشور",
		'country': {
			'self': 'کشور',
			"countryId": "شناسه",
			"nativeName": "نام کشور",
			"capitalName": "نام پایتخت",
			"caption": "توضیحات",
			"code": "کد کشور",
		},
		"provinceLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Province.Update] {
	'provinceUpdate': {
		'self': 'استان',
		"provinceId": "شناسه",
		"nativeName": "نام استان",
		"caption": "توضیحات",
		"countryId": "کشور",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[Region.Create] {
	'regionCreate': {
		'self': 'منطقه',
		"regionId": "شناسه",
		"nativeName": "نام منطقه",
		"caption": "توضیحات",
		"cityId": "شهر",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[Region.Delete] {
	'regionDelete': {
		'self': 'منطقه',
		"regionId": "شناسه",
	},
	//GeneratorFlag:[Region.Detail] {
	'regionDetail': {
		'self': 'منطقه',
		"regionId": "شناسه",
		"nativeName": "نام منطقه",
		"caption": "توضیحات",
		"cityId": "شهر",
		'city': {
			'self': 'شهر',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		"regionLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Region.List] {
	'regionList': {
		'self': 'منطقه',
		"regionId": "شناسه",
		"nativeName": "نام منطقه",
		"caption": "توضیحات",
		"cityId": "شهر",
		'city': {
			'self': 'شهر',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		"regionLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Region.Filter] {
	'regionFilter': {
		'self': 'منطقه',
		"regionId": "شناسه",
		"nativeName": "نام منطقه",
		"caption": "توضیحات",
		"cityId": "شهر",
		'city': {
			'self': 'شهر',
			"cityId": "شناسه",
			"nativeName": "نام شهر",
			"caption": "توضیحات",
			"provinceId": "استان",
		},
		"regionLogr": "اطلاعات ثبت",
	},
	//GeneratorFlag:[Region.Update] {
	'regionUpdate': {
		'self': 'منطقه',
		"regionId": "شناسه",
		"nativeName": "نام منطقه",
		"caption": "توضیحات",
		"cityId": "شهر",
		"identifier": "کلید یکتا",
	},
	//GeneratorFlag:[NoticeConfigIppanel.Detail] {
	'noticeConfigIppanelDetail': {
		'self': 'اطلاعات پنل آیپی پنل',
		"noticeConfigId": "شناسه",
		"useName": "نام کاربری",
		"password": "کلمه عبور",
		"patternCode": "کد الگو",
		"number": "سرشماره",
		"apiAdderss": "آدرس api",
	},
	//GeneratorFlag:[NoticeConfigIppanel.Update] {
	'noticeConfigIppanelUpdate': {
		'self': 'اطلاعات پنل آیپی پنل',
		"noticeConfigId": "شناسه",
		"useName": "نام کاربری",
		"password": "کلمه عبور",
		"patternCode": "کد الگو",
		"number": "سرشماره",
		"apiAdderss": "آدرس api",
	},
	//GeneratorFlag:[NoticeConfigKavenegar.Detail] {
	'noticeConfigKavenegarDetail': {
		'self': 'اطلاعات پنل کاوه نگار',
		"noticeConfigId": "شناسه",
		"apiKey": "api key",
		"tokenOne": "کلید معادل توکن",
		"tokenTwo": "کلید معادل توکن 2",
		"tokenThree": "کلید معادل توکن 3",
	},
	//GeneratorFlag:[NoticeConfigKavenegar.Update] {
	'noticeConfigKavenegarUpdate': {
		'self': 'اطلاعات پنل کاوه نگار',
		"noticeConfigId": "شناسه",
		"apiKey": "api key",
		"tokenOne": "کلید معادل توکن",
		"tokenTwo": "کلید معادل توکن 2",
		"tokenThree": "کلید معادل توکن 3",
	},
	//GeneratorFlag:[PersonAccess.Detail] {
	'personAccessDetail': {
		'self': 'اطلاعات دسترسی',
		"personId": "شناسه شخص",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"accessIps": "آیپی های مجاز",
		"isActive": "وضعیت",
	},
	//GeneratorFlag:[PersonAccess.Update] {
	'personAccessUpdate': {
		'self': 'اطلاعات دسترسی',
		"personId": "شناسه شخص",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"accessIps": "آیپی های مجاز",
		"isActive": "وضعیت",
	},
	//GeneratorFlag:[PersonLegal.Detail] {
	'personLegalDetail': {
		'self': 'اطلاعات حقوقی',
		"personId": "person",
		"legalName": "نام حقوقی",
		"nationalCode": "شناسه ملی",
		"registerNumber": "شناسه ثبت",
		"economicNumber": "شناسه اقتصادی",
		"phoneNumber": "شماره تماس ثابت",
		"fax": "فکس",
		"email": "ایمیل",
		"postalCode": "کد پستی",
		"address": "آدرس",
	},
	//GeneratorFlag:[PersonLegal.Update] {
	'personLegalUpdate': {
		'self': 'اطلاعات حقوقی',
		"personId": "person",
		"legalName": "نام حقوقی",
		"nationalCode": "شناسه ملی",
		"registerNumber": "شناسه ثبت",
		"economicNumber": "شناسه اقتصادی",
		"phoneNumber": "شماره تماس ثابت",
		"fax": "فکس",
		"email": "ایمیل",
		"postalCode": "کد پستی",
		"address": "آدرس",
	},
	//GeneratorFlag:[PersonReal.Detail] {
	'personRealDetail': {
		'self': 'اطلاعات حقیقی',
		"personId": "شناسه شخص",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"fullName": "نام و نام خانوادگی",
		"fatherName": "نام پدر",
		"motherName": "نام مادر",
		"nationalCode": "کد ملی",
		"identification": "شماره شناسنامه",
		"mobile": "موبایل",
		"phoneNumber": "شماره تماس ثابت",
		"email": "ایمیل",
		"placeOfBirth": "محل تولد",
		"postalCode": "کد پستی",
		"birthDate": "تاریخ تولد",
		"workAddress": "آدرس محل کار",
		"homeAddress": "آدرس منزل",
		"personNationalityId": "ملیت",
		"personGenderId": "جنسیت",
		"personGradeId": "مدرک تحصیلی",
		"personMaritalId": "وضعیت تاهل",
		"personMilitaryId": "وضعیت سربازی",
		'personGender': {
			'self': 'جنسیت',
			"personGenderId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personGrade': {
			'self': 'مدرک تحصیلی',
			"personGradeId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personMarital': {
			'self': 'وضعیت تاهل',
			"personMaritalId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personMilitary': {
			'self': 'وضعیت سربازی',
			"personMilitaryId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
		'personNationality': {
			'self': 'ملیت',
			"personNationalityId": "شناسه",
			"title": "عنوان",
			"caption": "توضیحات",
		},
	},
	//GeneratorFlag:[PersonReal.Update] {
	'personRealUpdate': {
		'self': 'اطلاعات حقیقی',
		"personId": "شناسه شخص",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"fullName": "نام و نام خانوادگی",
		"fatherName": "نام پدر",
		"motherName": "نام مادر",
		"nationalCode": "کد ملی",
		"identification": "شماره شناسنامه",
		"mobile": "موبایل",
		"phoneNumber": "شماره تماس ثابت",
		"email": "ایمیل",
		"placeOfBirth": "محل تولد",
		"postalCode": "کد پستی",
		"birthDate": "تاریخ تولد",
		"workAddress": "آدرس محل کار",
		"homeAddress": "آدرس منزل",
		"personNationalityId": "ملیت",
		"personGenderId": "جنسیت",
		"personGradeId": "مدرک تحصیلی",
		"personMaritalId": "وضعیت تاهل",
		"personMilitaryId": "وضعیت سربازی",
	},
	//GeneratorFlag:[FileExtension.Detail] {
	'fileExtensionDetail': {
		'self': 'پسوند فایل',
		"fileExtensionId": "شناسه",
		"extensionName": "نام پسوند",
		"directoryName": "مسیر",
		"contentType": "نوع محتوا",
	},
	//GeneratorFlag:[FileExtension.List] {
	'fileExtensionList': {
		'self': 'پسوند فایل',
		"fileExtensionId": "شناسه",
		"extensionName": "نام پسوند",
		"directoryName": "مسیر",
		"contentType": "نوع محتوا",
	},
	//GeneratorFlag:[FileExtension.Filter] {
	'fileExtensionFilter': {
		'self': 'پسوند فایل',
		"fileExtensionId": "شناسه",
		"extensionName": "نام پسوند",
		"directoryName": "مسیر",
		"contentType": "نوع محتوا",
	},
	//GeneratorFlag:[NoticeProvider.Detail] {
	'noticeProviderDetail': {
		'self': 'NoticeProvider',
		"noticeProviderId": "notice provider id",
		"title": "عنوان",
		"caption": "عنوان",
	},
	//GeneratorFlag:[NoticeProvider.List] {
	'noticeProviderList': {
		'self': 'NoticeProvider',
		"noticeProviderId": "notice provider id",
		"title": "عنوان",
		"caption": "عنوان",
	},
	//GeneratorFlag:[NoticeProvider.Filter] {
	'noticeProviderFilter': {
		'self': 'NoticeProvider',
		"noticeProviderId": "notice provider id",
		"title": "عنوان",
		"caption": "عنوان",
	},
	//GeneratorFlag:[NoticeStatus.Detail] {
	'noticeStatusDetail': {
		'self': 'NoticeStatus',
		"noticeStatusId": "notice status id",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[NoticeStatus.List] {
	'noticeStatusList': {
		'self': 'NoticeStatus',
		"noticeStatusId": "notice status id",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[NoticeStatus.Filter] {
	'noticeStatusFilter': {
		'self': 'NoticeStatus',
		"noticeStatusId": "notice status id",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[NoticeType.Detail] {
	'noticeTypeDetail': {
		'self': 'نوع اطلاعیه',
		"noticeTypeId": "notice type id",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[NoticeType.List] {
	'noticeTypeList': {
		'self': 'نوع اطلاعیه',
		"noticeTypeId": "notice type id",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[NoticeType.Filter] {
	'noticeTypeFilter': {
		'self': 'نوع اطلاعیه',
		"noticeTypeId": "notice type id",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonEntity.Detail] {
	'personEntityDetail': {
		'self': 'شخصیت',
		"personEntityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonEntity.List] {
	'personEntityList': {
		'self': 'شخصیت',
		"personEntityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonEntity.Filter] {
	'personEntityFilter': {
		'self': 'شخصیت',
		"personEntityId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonGender.Detail] {
	'personGenderDetail': {
		'self': 'جنسیت',
		"personGenderId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonGender.List] {
	'personGenderList': {
		'self': 'جنسیت',
		"personGenderId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonGender.Filter] {
	'personGenderFilter': {
		'self': 'جنسیت',
		"personGenderId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonGrade.Detail] {
	'personGradeDetail': {
		'self': 'مدرک تحصیلی',
		"personGradeId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonGrade.List] {
	'personGradeList': {
		'self': 'مدرک تحصیلی',
		"personGradeId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonGrade.Filter] {
	'personGradeFilter': {
		'self': 'مدرک تحصیلی',
		"personGradeId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMarital.Detail] {
	'personMaritalDetail': {
		'self': 'وضعیت تاهل',
		"personMaritalId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonMarital.List] {
	'personMaritalList': {
		'self': 'وضعیت تاهل',
		"personMaritalId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[PersonMarital.Filter] {
	'personMaritalFilter': {
		'self': 'وضعیت تاهل',
		"personMaritalId": "شناسه",
		"title": "عنوان",
		"caption": "توضیحات",
		'personReals': {
			'self': 'person real',
			"personId": "شناسه شخص",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"fullName": "نام و نام خانوادگی",
			"fatherName": "نام پدر",
			"motherName": "نام مادر",
			"nationalCode": "کد ملی",
			"identification": "شماره شناسنامه",
			"mobile": "موبایل",
			"phoneNumber": "شماره تماس ثابت",
			"email": "ایمیل",
			"placeOfBirth": "محل تولد",
			"postalCode": "کد پستی",
			"birthDate": "تاریخ تولد",
			"workAddress": "آدرس محل کار",
			"homeAddress": "آدرس منزل",
			"personNationalityId": "ملیت",
			"personGenderId": "جنسیت",
			"personGradeId": "مدرک تحصیلی",
			"personMaritalId": "وضعیت تاهل",
			"personMilitaryId": "وضعیت سربازی",
		},
	},
	//GeneratorFlag:[AccessAgent.Save] {
	'accessAgentSave': {
		'self': 'مدیریت دسترسی ها',
		"personId": "کاربر",
		"personRoleId": "نقش کاربری",
		'accessKeys': {
			'self': 'کلید ها',
			"keyName": "",
			"groupName": "",
		},
		"isSuccess": "نتیجه",
	},
	//GeneratorFlag:[AccessToken.Generate] {
	'accessTokenGenerate': {
		'self': 'دریافت توکن',
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"tokenType": "نوع توکن",
		"expirationDate": "تاریخ انقضا",
		"token": "توکن",
	},
//GeneratorFlag:[NewEntity]
}