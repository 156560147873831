//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class NoticeProviderService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[NoticeProvider.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/notice-provider/detail', {'params':params});
	};

	//GeneratorFlag:[NoticeProvider.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/notice-provider/list', {'params':params});
	};

//GeneratorFlag:[NewAction]
}