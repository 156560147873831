//GeneratorFlag:[NoChange]
export default {
	path: 'person-app-purchase-statuse',
	name: 'peopleNet.baseSys.personAppPurchaseStatuse.layout',
	redirect: to => {
		return {name: 'peopleNet.personAppPurchaseStatuse.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-app-purchase-statuse"],
		resourceKey: 'peopleNet.views.personAppPurchaseStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonAppPurchaseStatuseDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personAppPurchaseStatuse.detail',
			component: () => import('@/modules/people-net/views/person-app-purchase-statuse/person-app-purchase-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchaseStatuse.detail',
				clientAccessKeys:["/people-net/base-sys/person-app-purchase-statuse/detail"],
				serverAccessKeys:["/people-net/person-app-purchase-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseStatuseList]"
		{
			path: 'list',
			name: 'peopleNet.personAppPurchaseStatuse.list',
			component: () => import('@/modules/people-net/views/person-app-purchase-statuse/person-app-purchase-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchaseStatuse.list',
				clientAccessKeys:["/people-net/base-sys/person-app-purchase-statuse/list"],
				serverAccessKeys:["/people-net/person-app-purchase-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}