<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Wallet, WalletFilter } from '@/modules/financial/data/wallet/list'
	import { WalletService } from '@/modules/financial/services'
	export default {
		computed:
		{
			entity()
			{
				return Wallet;
			},
			
			filterEntity()
			{
				return WalletFilter;
			},

			service()
			{
				return new WalletService();
			},

			filterData()
			{
				const walletId =
					this.$route.query['walletId'];

				if (walletId)
				{
					return {
						'walletId': walletId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'financial.wallet.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'financial.wallet.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'financial.wallet.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'financial.wallet.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'financial.wallet.detail',
							resourceKey: 'financial.views.wallet.detail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>