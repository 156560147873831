<template>
	<kaveh-tree :isRoot="isRoot" height="400px">
		<kaveh-tree-node v-for="(node,index) in items" :key="index" :is-expand="false">
			<label class="cursor-pointer">

				<input class="me-2"
					   type="checkbox"
					   v-model="node.checked"
					   @change="handleChange(node)" />

				<span class="me-2" v-if="node.meta.label">
					<span>{{node.meta.label}}</span>
				</span>
				<span class="me-2" v-else>
					<span>{{$loc.fromResource(node.meta.resourceKey)}}</span>
				</span>

			</label>
			<template v-slot:children v-if="hasChildren(node)">
				<route-tree-node @change="handleChange"
								  :items="node.children"
								  :is-root="false" />
			</template>
		</kaveh-tree-node>
	</kaveh-tree>
</template>
<script>

	export default {
		emits: ['change'],
		props: {
			items: {
				type: Array
			},
		},

		data()
		{
			return {
				isRoot: true
			}
		},

		methods:
		{
			hasChildren(node)
			{
				return (node.children || []).length > 0
			},


			handleChange(node)
			{
				this.$emit('change', node)
			}
		}
	}
</script>
