<template>
	<div v-if="mode == 'generator'" class="row mb-1 mb-md-2">
		<div :for="prop.name" class="col-4 col-md-3 pe-none">
			<form-label :use-validation="useValidation" :prop="prop" />
		</div>
		<div class="col-8 col-md-9 pe-none">
			<slot></slot>
			<form-error :prop="prop" />
		</div>
		<div class="con-12">
			<button class="btn" @click="$emit('generator-remove', prop.name)">
				<kaveh-icon-bootstrap name="bi-trash" />
			</button>
			<button class="btn" @click="$emit('generator-down', prop.name)">
				<kaveh-icon-bootstrap name="bi-arrow-up" />
			</button>
			<button class="btn" @click="$emit('generator-up', prop.name)">
				<kaveh-icon-bootstrap name="bi-arrow-down" />
			</button>
		</div>
	</div>
	<div v-else class="row mb-1 mb-md-2">
		<div :for="prop.name" class="col-4 col-md-3">
			<form-label :use-validation="useValidation" :prop="prop" />
		</div>
		<div class="col-8 col-md-9">
			<slot></slot>
			<form-error :prop="prop" />
		</div>
	</div>
</template>
<script>
	import formError from './form-error'
	import formLabel from './form-label'

	export default {
		emits: ['generator-remove', 'generator-up', 'generator-down', 'generator-select'],

		components: {
			'form-error': formError,
			'form-label': formLabel
		},

		props:
		{
			'prop': {
				type: Object
			},

			'mode': {
				type: String
			},

			'useValidation': {
				type: Boolean,
				default: true
			}
		},
	}
</script>
