<template>
	<form-entry v-if="mode == 'entry' || mode == 'preview'"
				:task="task"
				:value="value"
				:entity="entity"
				:display-type="displayType"
				:props="props"
				:mode="mode"
				:use-validation="useValidation"
				:submit-resource-key="submitResourceKey"
				ref="FormEntryRef"/>

	<form-generator v-else :props="props" />
</template>

<script>
	import FormEntry from './form-entry'
	import FormGenerator from './form-generator'

	export default {

		components:
		{
			'form-entry': FormEntry,
			'form-generator': FormGenerator
		},

		props:
		{
			'entity': {
				type: Function
			},

			'props': {
				type: Object
			},

			'task': {
				type: Function
			},

			'displayType': {
				type: String
			},

			'mode': {
				type: String,
				default: 'entry'
			},

			'value': {
				type: Object,
				default: null
			},

			'useValidation': {
				type: Boolean,
				default: true
			},

			'submitResourceKey': {
				type: String,
				default: 'global.save'
			},
		},


		methods: {
			getValue() {

				if (this.mode == 'entry') {
					return this.$refs.FormEntryRef.getValue()
				}

				return undefined;
			}
		}
	}
</script>
