<template>
	<kaveh-collapse :collapsed="parent">
		<template v-slot:header>
			<span>{{ $loc.fromResource(resourceKey) }}</span>
		</template>
		<template v-slot:body>
			<template :key="i" v-for="(prop,i) in getProps()">
				<list-item v-if="!useCollapse(prop)" :item="prop" />
				<list-collapse v-else-if="useCollapse(prop) && prop.value"
							   :parent="false"
							   :entity="prop.entity"
							   :prop-resource-key="prop.resourceKey" />
			</template>
		</template>
	</kaveh-collapse>
</template>
<script>
	import listItem from './list-item'
	export default {

		components: {
			'list-item': listItem
		},

		props: {

			'entity': {
				type: Object
			},

			'parent': {
				type: Boolean,
				default: true
			},

			'propResourceKey': {
				type: String,
				default: undefined
			},
		},

		computed:
		{
			resourceKey()
			{
				return this.propResourceKey || this.entity.resourceKey
			}
		},


		methods:
		{
			getProps()
			{
				var props = []
				for (var key in this.entity.getCleanProps())
				{
					var prop = this.entity.props[key];
					if (prop.display.isVisible)
					{
						props.push(this.entity.props[key])
					}
				}

				return props
			},

			useCollapse(prop)
			{
				if (prop.type == 'object' && prop.entity)
				{
					if (prop.entity.schema != 'baseSys')
						return true
				}

				return false
			}
		}
	}
</script>
