//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"peopleNet",
	"ext": ext,
	"layout":{
		"baseInfo": "اطلاعات پایه", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "اطلاعات سیستمی", //GeneratorFlag:[BaseSysLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"news": {
			"layout": "News",
			"create": "ثبت جدید News", //GeneratorFlag:[Views.News.Create]
			'createImport': 'ثبت News از طریق اکسل', //GeneratorFlag:[Views.News.CreateImport] 
 			'delete': 'حذف News', //GeneratorFlag:[Views.News.Delete] 
 			'detail': 'جزئیات اطلاعات News', //GeneratorFlag:[Views.News.Detail] 
 			'list': 'فهرست News', //GeneratorFlag:[Views.News.List] 
 			'update': 'ویرایش News', //GeneratorFlag:[Views.News.Update] 
 //GeneratorFlag:[Views.News]
		},
		"person": {
			"layout": "اعضا",
			"create": "ثبت جدید اعضا", //GeneratorFlag:[Views.Person.Create]
			'createImport': 'ثبت اعضا از طریق اکسل', //GeneratorFlag:[Views.Person.CreateImport] 
 			'delete': 'حذف اعضا', //GeneratorFlag:[Views.Person.Delete] 
 			'detail': 'جزئیات اطلاعات اعضا', //GeneratorFlag:[Views.Person.Detail] 
 			'list': 'فهرست اعضا', //GeneratorFlag:[Views.Person.List] 
 			'update': 'ویرایش اعضا', //GeneratorFlag:[Views.Person.Update] 
 			'changeEmail': 'تغییر ایمیل', //GeneratorFlag:[Views.Person.ChangeEmail] 
 			'changeSecurityCode': 'تغییر ایمیل', //GeneratorFlag:[Views.Person.ChangeSecurityCode] 
 			'checkUpline': 'بررسی آپلاین', //GeneratorFlag:[Views.Person.CheckUpline] 
 			'getCredit': 'گرفتن اعتبار فرد', //GeneratorFlag:[Views.Person.GetCredit] 
 			'getPersonByData': 'جستجو در لیست زیر مجموعه', //GeneratorFlag:[Views.Person.GetPersonByData] 
 			'getProfile': 'پروفایل', //GeneratorFlag:[Views.Person.GetProfile] 
 			'getUplineInfo': 'person', //GeneratorFlag:[Views.Person.GetUplineInfo] 
 			'registerByEmail': 'person', //GeneratorFlag:[Views.Person.RegisterByEmail] 
 			'register': 'جستجو در لیست زیر مجموعه', //GeneratorFlag:[Views.Person.Register] 
 			'registerShop': 'person', //GeneratorFlag:[Views.Person.RegisterShop] 
 			'searchTeam': 'لیست افراد زیر مجموعه', //GeneratorFlag:[Views.Person.SearchTeam] 
 			'treeList': 'نمایش درختی تیم', //GeneratorFlag:[Views.Person.TreeList] 
 //GeneratorFlag:[Views.Person]
		},
		"personAppPurchase": {
			"layout": "PersonAppPurchase",
			"create": "ثبت جدید PersonAppPurchase", //GeneratorFlag:[Views.PersonAppPurchase.Create]
			'createImport': 'ثبت PersonAppPurchase از طریق اکسل', //GeneratorFlag:[Views.PersonAppPurchase.CreateImport] 
 			'delete': 'حذف PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.Delete] 
 			'detail': 'جزئیات اطلاعات PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.Detail] 
 			'list': 'فهرست PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.List] 
 			'update': 'ویرایش PersonAppPurchase', //GeneratorFlag:[Views.PersonAppPurchase.Update] 
 			'checkCredit': 'person app purchase', //GeneratorFlag:[Views.PersonAppPurchase.CheckCredit] 
 //GeneratorFlag:[Views.PersonAppPurchase]
		},
		"personCreditTransaction": {
			"layout": "تراکنش های اعتباری",
			"create": "ثبت جدید تراکنش های اعتباری", //GeneratorFlag:[Views.PersonCreditTransaction.Create]
			'createImport': 'ثبت تراکنش های اعتباری از طریق اکسل', //GeneratorFlag:[Views.PersonCreditTransaction.CreateImport] 
 			'delete': 'حذف تراکنش های اعتباری', //GeneratorFlag:[Views.PersonCreditTransaction.Delete] 
 			'detail': 'جزئیات اطلاعات تراکنش های اعتباری', //GeneratorFlag:[Views.PersonCreditTransaction.Detail] 
 			'list': 'فهرست تراکنش های اعتباری', //GeneratorFlag:[Views.PersonCreditTransaction.List] 
 			'update': 'ویرایش تراکنش های اعتباری', //GeneratorFlag:[Views.PersonCreditTransaction.Update] 
 			'charge': 'شارژ کیف پول', //GeneratorFlag:[Views.PersonCreditTransaction.Charge] 
 			'myList': 'لیست تراکنش ها', //GeneratorFlag:[Views.PersonCreditTransaction.MyList] 
 //GeneratorFlag:[Views.PersonCreditTransaction]
		},
		"personDocument": {
			"layout": "مدارک اعضا",
			"create": "ثبت جدید مدارک اعضا", //GeneratorFlag:[Views.PersonDocument.Create]
			'createImport': 'ثبت مدارک اعضا از طریق اکسل', //GeneratorFlag:[Views.PersonDocument.CreateImport] 
 			'delete': 'حذف مدارک اعضا', //GeneratorFlag:[Views.PersonDocument.Delete] 
 			'detail': 'جزئیات اطلاعات مدارک اعضا', //GeneratorFlag:[Views.PersonDocument.Detail] 
 			'list': 'فهرست مدارک اعضا', //GeneratorFlag:[Views.PersonDocument.List] 
 			'update': 'ویرایش مدارک اعضا', //GeneratorFlag:[Views.PersonDocument.Update] 
 //GeneratorFlag:[Views.PersonDocument]
		},
		"personRequestDipost": {
			"layout": "درخواست های واریز",
			"create": "ثبت جدید درخواست واریز", //GeneratorFlag:[Views.PersonRequestDipost.Create]
			'createImport': 'ثبت درخواست های واریز از طریق اکسل', //GeneratorFlag:[Views.PersonRequestDipost.CreateImport] 
 			'delete': 'حذف درخواست واریز', //GeneratorFlag:[Views.PersonRequestDipost.Delete] 
 			'detail': 'جزئیات اطلاعات درخواست واریز', //GeneratorFlag:[Views.PersonRequestDipost.Detail] 
 			'list': 'فهرست درخواست های واریز', //GeneratorFlag:[Views.PersonRequestDipost.List] 
 			'update': 'ویرایش درخواست واریز', //GeneratorFlag:[Views.PersonRequestDipost.Update] 
 			'summrayRequest': 'گزارش درخواست ها', //GeneratorFlag:[Views.PersonRequestDipost.SummrayRequest] 
 //GeneratorFlag:[Views.PersonRequestDipost]
		},
		"city": {
			"layout": " شهر",
			"create": "ثبت جدید شهر", //GeneratorFlag:[Views.City.Create]
			'createImport': 'ثبت شهر از طریق اکسل', //GeneratorFlag:[Views.City.CreateImport] 
 			'delete': 'حذف شهر', //GeneratorFlag:[Views.City.Delete] 
 			'detail': 'جزئیات اطلاعات شهر', //GeneratorFlag:[Views.City.Detail] 
 			'list': 'فهرست شهر', //GeneratorFlag:[Views.City.List] 
 			'update': 'ویرایش شهر', //GeneratorFlag:[Views.City.Update] 
 //GeneratorFlag:[Views.City]
		},
		"company": {
			"layout": " شرکت",
			"create": "ثبت جدید شرکت", //GeneratorFlag:[Views.Company.Create]
			'createImport': 'ثبت شرکت از طریق اکسل', //GeneratorFlag:[Views.Company.CreateImport] 
 			'delete': 'حذف شرکت', //GeneratorFlag:[Views.Company.Delete] 
 			'detail': 'جزئیات اطلاعات شرکت', //GeneratorFlag:[Views.Company.Detail] 
 			'list': 'فهرست شرکت', //GeneratorFlag:[Views.Company.List] 
 			'update': 'ویرایش شرکت', //GeneratorFlag:[Views.Company.Update] 
 //GeneratorFlag:[Views.Company]
		},
		"companyRank": {
			"layout": " رنک های شرکت",
			"create": "ثبت جدید رنک های شرکت", //GeneratorFlag:[Views.CompanyRank.Create]
			'createImport': 'ثبت رنک های شرکت از طریق اکسل', //GeneratorFlag:[Views.CompanyRank.CreateImport] 
 			'delete': 'حذف رنک های شرکت', //GeneratorFlag:[Views.CompanyRank.Delete] 
 			'detail': 'جزئیات اطلاعات رنک های شرکت', //GeneratorFlag:[Views.CompanyRank.Detail] 
 			'list': 'فهرست رنک های شرکت', //GeneratorFlag:[Views.CompanyRank.List] 
 			'update': 'ویرایش رنک های شرکت', //GeneratorFlag:[Views.CompanyRank.Update] 
 //GeneratorFlag:[Views.CompanyRank]
		},
		"country": {
			"layout": " کشور",
			"create": "ثبت جدید کشور", //GeneratorFlag:[Views.Country.Create]
			'createImport': 'ثبت کشور از طریق اکسل', //GeneratorFlag:[Views.Country.CreateImport] 
 			'delete': 'حذف کشور', //GeneratorFlag:[Views.Country.Delete] 
 			'detail': 'جزئیات اطلاعات کشور', //GeneratorFlag:[Views.Country.Detail] 
 			'list': 'فهرست کشور', //GeneratorFlag:[Views.Country.List] 
 			'update': 'ویرایش کشور', //GeneratorFlag:[Views.Country.Update] 
 //GeneratorFlag:[Views.Country]
		},
		"militaryType": {
			"layout": " وضعیت نظام وضیفه",
			"create": "ثبت جدید وضعیت نظام وضیفه", //GeneratorFlag:[Views.MilitaryType.Create]
			'createImport': 'ثبت وضعیت نظام وضیفه از طریق اکسل', //GeneratorFlag:[Views.MilitaryType.CreateImport] 
 			'delete': 'حذف وضعیت نظام وضیفه', //GeneratorFlag:[Views.MilitaryType.Delete] 
 			'detail': 'جزئیات اطلاعات وضعیت نظام وضیفه', //GeneratorFlag:[Views.MilitaryType.Detail] 
 			'list': 'فهرست وضعیت نظام وضیفه', //GeneratorFlag:[Views.MilitaryType.List] 
 			'update': 'ویرایش وضعیت نظام وضیفه', //GeneratorFlag:[Views.MilitaryType.Update] 
 //GeneratorFlag:[Views.MilitaryType]
		},
		"nationalityType": {
			"layout": " NationalityType",
			"create": "ثبت جدید NationalityType", //GeneratorFlag:[Views.NationalityType.Create]
			'createImport': 'ثبت NationalityType از طریق اکسل', //GeneratorFlag:[Views.NationalityType.CreateImport] 
 			'delete': 'حذف NationalityType', //GeneratorFlag:[Views.NationalityType.Delete] 
 			'detail': 'جزئیات اطلاعات NationalityType', //GeneratorFlag:[Views.NationalityType.Detail] 
 			'list': 'فهرست NationalityType', //GeneratorFlag:[Views.NationalityType.List] 
 			'update': 'ویرایش NationalityType', //GeneratorFlag:[Views.NationalityType.Update] 
 //GeneratorFlag:[Views.NationalityType]
		},
		"personBankAccount": {
			"layout": " حساب های بانکی",
			"create": "ثبت جدید حساب بانکی", //GeneratorFlag:[Views.PersonBankAccount.Create]
			'createImport': 'ثبت حساب های بانکی از طریق اکسل', //GeneratorFlag:[Views.PersonBankAccount.CreateImport] 
 			'delete': 'حذف حساب بانکی', //GeneratorFlag:[Views.PersonBankAccount.Delete] 
 			'detail': 'جزئیات اطلاعات حساب بانکی', //GeneratorFlag:[Views.PersonBankAccount.Detail] 
 			'list': 'فهرست حساب های بانکی', //GeneratorFlag:[Views.PersonBankAccount.List] 
 			'update': 'ویرایش حساب بانکی', //GeneratorFlag:[Views.PersonBankAccount.Update] 
 			'getWallet': 'کیف پول', //GeneratorFlag:[Views.PersonBankAccount.GetWallet] 
 			'registerBank': 'ثبت بانک', //GeneratorFlag:[Views.PersonBankAccount.RegisterBank] 
 //GeneratorFlag:[Views.PersonBankAccount]
		},
		"province": {
			"layout": " استان",
			"create": "ثبت جدید استان", //GeneratorFlag:[Views.Province.Create]
			'createImport': 'ثبت استان از طریق اکسل', //GeneratorFlag:[Views.Province.CreateImport] 
 			'delete': 'حذف استان', //GeneratorFlag:[Views.Province.Delete] 
 			'detail': 'جزئیات اطلاعات استان', //GeneratorFlag:[Views.Province.Detail] 
 			'list': 'فهرست استان', //GeneratorFlag:[Views.Province.List] 
 			'update': 'ویرایش استان', //GeneratorFlag:[Views.Province.Update] 
 //GeneratorFlag:[Views.Province]
		},
		"rank": {
			"layout": " رنک تیم",
			"create": "ثبت جدید رنک تیم", //GeneratorFlag:[Views.Rank.Create]
			'createImport': 'ثبت رنک تیم از طریق اکسل', //GeneratorFlag:[Views.Rank.CreateImport] 
 			'delete': 'حذف رنک تیم', //GeneratorFlag:[Views.Rank.Delete] 
 			'detail': 'جزئیات اطلاعات رنک تیم', //GeneratorFlag:[Views.Rank.Detail] 
 			'list': 'فهرست رنک تیم', //GeneratorFlag:[Views.Rank.List] 
 			'update': 'ویرایش رنک تیم', //GeneratorFlag:[Views.Rank.Update] 
 //GeneratorFlag:[Views.Rank]
		},
		"team": {
			"layout": " Team",
			"create": "ثبت جدید Team", //GeneratorFlag:[Views.Team.Create]
			'createImport': 'ثبت Team از طریق اکسل', //GeneratorFlag:[Views.Team.CreateImport] 
 			'delete': 'حذف Team', //GeneratorFlag:[Views.Team.Delete] 
 			'detail': 'جزئیات اطلاعات Team', //GeneratorFlag:[Views.Team.Detail] 
 			'list': 'فهرست Team', //GeneratorFlag:[Views.Team.List] 
 			'update': 'ویرایش Team', //GeneratorFlag:[Views.Team.Update] 
 //GeneratorFlag:[Views.Team]
		},
		"culture": {
			"layout": "زبان",
			"detail": "جزئیات اطلاعات زبان", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'فهرست زبان', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"personAppPurchaseStatuse": {
			"layout": "وضعیت تراکنش",
			"detail": "جزئیات اطلاعات وضعیت تراکنش", //GeneratorFlag:[Views.PersonAppPurchaseStatuse.Detail]
			'list': 'فهرست وضعیت تراکنش', //GeneratorFlag:[Views.PersonAppPurchaseStatuse.List] 
 //GeneratorFlag:[Views.PersonAppPurchaseStatuse]
		},
		"personAppPurchaseType": {
			"layout": "نوع اپ",
			"detail": "جزئیات اطلاعات نوع اپ", //GeneratorFlag:[Views.PersonAppPurchaseType.Detail]
			'list': 'فهرست نوع اپ', //GeneratorFlag:[Views.PersonAppPurchaseType.List] 
 //GeneratorFlag:[Views.PersonAppPurchaseType]
		},
		"personBankAccountType": {
			"layout": "انواع حساب",
			"detail": "جزئیات اطلاعات نوع حساب", //GeneratorFlag:[Views.PersonBankAccountType.Detail]
			'list': 'فهرست انواع حساب', //GeneratorFlag:[Views.PersonBankAccountType.List] 
 //GeneratorFlag:[Views.PersonBankAccountType]
		},
		"personCreditTransactionStatuse": {
			"layout": "وضعیت تراکنش",
			"detail": "جزئیات اطلاعات وضعیت تراکنش", //GeneratorFlag:[Views.PersonCreditTransactionStatuse.Detail]
			'list': 'فهرست وضعیت تراکنش', //GeneratorFlag:[Views.PersonCreditTransactionStatuse.List] 
 //GeneratorFlag:[Views.PersonCreditTransactionStatuse]
		},
		"personCreditTransactionType": {
			"layout": "نوع تراکنش",
			"detail": "جزئیات اطلاعات نوع تراکنش", //GeneratorFlag:[Views.PersonCreditTransactionType.Detail]
			'list': 'فهرست نوع تراکنش', //GeneratorFlag:[Views.PersonCreditTransactionType.List] 
 //GeneratorFlag:[Views.PersonCreditTransactionType]
		},
		"personDocumentType": {
			"layout": "نوع مدرک",
			"detail": "جزئیات اطلاعات نوع مدرک", //GeneratorFlag:[Views.PersonDocumentType.Detail]
			'list': 'فهرست نوع مدرک', //GeneratorFlag:[Views.PersonDocumentType.List] 
 //GeneratorFlag:[Views.PersonDocumentType]
		},
		"personGender": {
			"layout": "جنسیت اعضا",
			"detail": "جزئیات اطلاعات جنسیت اعضا", //GeneratorFlag:[Views.PersonGender.Detail]
			'list': 'فهرست جنسیت اعضا', //GeneratorFlag:[Views.PersonGender.List] 
 //GeneratorFlag:[Views.PersonGender]
		},
		"personRequestDipostStatuse": {
			"layout": "وضعیت تراکنش ها",
			"detail": "جزئیات اطلاعات وضعیت تراکنش", //GeneratorFlag:[Views.PersonRequestDipostStatuse.Detail]
			'list': 'فهرست وضعیت تراکنش ها', //GeneratorFlag:[Views.PersonRequestDipostStatuse.List] 
 //GeneratorFlag:[Views.PersonRequestDipostStatuse]
		},
		"personRequestDipostType": {
			"layout": "نوع درخواست واریز",
			"detail": "جزئیات اطلاعات نوع درخواست واریز", //GeneratorFlag:[Views.PersonRequestDipostType.Detail]
			'list': 'فهرست نوع درخواست واریز', //GeneratorFlag:[Views.PersonRequestDipostType.List] 
 //GeneratorFlag:[Views.PersonRequestDipostType]
		},
		"personStatuse": {
			"layout": "وضعیت عضو",
			"detail": "جزئیات اطلاعات وضعیت عضو", //GeneratorFlag:[Views.PersonStatuse.Detail]
			'list': 'فهرست وضعیت عضو', //GeneratorFlag:[Views.PersonStatuse.List] 
 //GeneratorFlag:[Views.PersonStatuse]
		},
		"accessAgent": {
			"layout": "مدیریت دسترسی ها",
			"save": "مدیریت دسترسی ها", //GeneratorFlag:[Views.AccessAgent.Save]
//GeneratorFlag:[Views.AccessAgent]
		},
		"accessToken": {
			"layout": "access token",
			"myFunc": "access token", //GeneratorFlag:[Views.AccessToken.MyFunc]
//GeneratorFlag:[Views.AccessToken]
		},
		"ipValidation": {
			"layout": "ip validation",
			"validateIp": "ip validation", //GeneratorFlag:[Views.IpValidation.ValidateIp]
//GeneratorFlag:[Views.IpValidation]
		},
		"personAccess": {
			"layout": "person access",
			"changePassword": "person access", //GeneratorFlag:[Views.PersonAccess.ChangePassword]
			'checkAccess': 'person access', //GeneratorFlag:[Views.PersonAccess.CheckAccess] 
 			'deactiveToken': 'person access', //GeneratorFlag:[Views.PersonAccess.DeactiveToken] 
 			'fortgetPassword': 'person access', //GeneratorFlag:[Views.PersonAccess.FortgetPassword] 
 			'generate': 'person access', //GeneratorFlag:[Views.PersonAccess.Generate] 
 //GeneratorFlag:[Views.PersonAccess]
		},
		"personLogin": {
			"layout": "person login",
			"register": "person login", //GeneratorFlag:[Views.PersonLogin.Register]
//GeneratorFlag:[Views.PersonLogin]
		},
		"personValidation": {
			"layout": "person validation",
			"validate": "person validation", //GeneratorFlag:[Views.PersonValidation.Validate]
			'validateCode': 'person validation', //GeneratorFlag:[Views.PersonValidation.ValidateCode] 
 //GeneratorFlag:[Views.PersonValidation]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[News.Create] {
	"newsCreate": {
		"self": "News",
		"newsId": "خبر|اخبار",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[News.Delete] {
	"newsDelete": {
		"self": "News",
		"newsId": "خبر|اخبار",
	},
	//GeneratorFlag:[News.Detail] {
	"newsDetail": {
		"self": "News",
		"newsId": "خبر|اخبار",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
		"newsSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[News.List] {
	"newsList": {
		"self": "News",
		"newsId": "خبر|اخبار",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
		"newsSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[News.Filter] {
	"newsFilter": {
		"self": "News",
		"newsId": "خبر|اخبار",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
		"newsSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[News.Update] {
	"newsUpdate": {
		"self": "News",
		"newsId": "خبر|اخبار",
		"title": "title",
		"caption": "caption",
		"expireDate": "expire date",
		"isShow": "is show",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[Person.Create] {
	"personCreate": {
		"self": "اعضا",
		"personId": "شناسه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"nationalNo": "کد ملی",
		"passportNo": "پاسپورت",
		"parentId": "بالاسری",
		"genoString": "شجره",
		"rankId": "رنک",
		"companyRankId": "رنک شرکت",
		"companyId": "شرکت",
		"mobile": "همراه",
		"email": "email",
		"personStatuseId": "وضعیت",
		"personGenderId": "جنسیت",
		"teamId": "تیم",
		"personCode": "کد",
		"levelOfHead": "عمق از ریشه",
		"creditValue": "مقدار اعتبار",
		"personRoleId": "person role id",
		"personAccess": {
			"self": "حساب کاربری",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
	},
	//GeneratorFlag:[Person.Delete] {
	"personDelete": {
		"self": "اعضا",
		"personId": "شناسه",
	},
	//GeneratorFlag:[Person.Detail] {
	"personDetail": {
		"self": "اعضا",
		"personId": "شناسه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"nationalNo": "کد ملی",
		"passportNo": "پاسپورت",
		"parentId": "بالاسری",
		"genoString": "شجره",
		"rankId": "رنک",
		"companyRankId": "رنک شرکت",
		"companyId": "شرکت",
		"mobile": "همراه",
		"email": "email",
		"personStatuseId": "وضعیت",
		"personGenderId": "جنسیت",
		"teamId": "تیم",
		"personCode": "کد",
		"levelOfHead": "عمق از ریشه",
		"creditValue": "مقدار اعتبار",
		"personRoleId": "person role id",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"companyRank": {
			"self": "رنک شرکت",
			"companyRankId": "شناسه",
			"companyRankKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"companyId": "شرکت",
			"fromRange": "از رنج",
			"toRange": "تا رنج",
			"maxLevelCommision": "سقف نسل",
			"dailyIncomeLimit": "سقف درامد روزانه",
		},
		"personGender": {
			"self": "جنسیت",
			"personGenderId": "شناسه",
			"personGenderKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personStatuse": {
			"self": "وضعیت",
			"personStatuseId": "شناسه",
			"personStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"rank": {
			"self": "رنک",
			"rankId": "شناسه",
			"orderRank": "ردیف",
			"title": "عنوان",
			"teamId": "تیم",
			"companyId": "شرکت",
			"status": "وضعیت فعال",
		},
		"team": {
			"self": "تیم",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"personAccess": {
			"self": "حساب کاربری",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
		"personSubInfo": "person sub info",
	},
	//GeneratorFlag:[Person.List] {
	"personList": {
		"self": "اعضا",
		"personId": "شناسه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"nationalNo": "کد ملی",
		"passportNo": "پاسپورت",
		"parentId": "بالاسری",
		"genoString": "شجره",
		"rankId": "رنک",
		"companyRankId": "رنک شرکت",
		"companyId": "شرکت",
		"mobile": "همراه",
		"email": "email",
		"personStatuseId": "وضعیت",
		"personGenderId": "جنسیت",
		"teamId": "تیم",
		"personCode": "کد",
		"levelOfHead": "عمق از ریشه",
		"creditValue": "مقدار اعتبار",
		"personRoleId": "person role id",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"companyRank": {
			"self": "رنک شرکت",
			"companyRankId": "شناسه",
			"companyRankKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"companyId": "شرکت",
			"fromRange": "از رنج",
			"toRange": "تا رنج",
			"maxLevelCommision": "سقف نسل",
			"dailyIncomeLimit": "سقف درامد روزانه",
		},
		"personGender": {
			"self": "جنسیت",
			"personGenderId": "شناسه",
			"personGenderKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personStatuse": {
			"self": "وضعیت",
			"personStatuseId": "شناسه",
			"personStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"rank": {
			"self": "رنک",
			"rankId": "شناسه",
			"orderRank": "ردیف",
			"title": "عنوان",
			"teamId": "تیم",
			"companyId": "شرکت",
			"status": "وضعیت فعال",
		},
		"team": {
			"self": "تیم",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"personAccess": {
			"self": "حساب کاربری",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
		"personSubInfo": "person sub info",
	},
	//GeneratorFlag:[Person.Filter] {
	"personFilter": {
		"self": "اعضا",
		"personId": "شناسه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"nationalNo": "کد ملی",
		"passportNo": "پاسپورت",
		"parentId": "بالاسری",
		"genoString": "شجره",
		"rankId": "رنک",
		"companyRankId": "رنک شرکت",
		"companyId": "شرکت",
		"mobile": "همراه",
		"email": "email",
		"personStatuseId": "وضعیت",
		"personGenderId": "جنسیت",
		"teamId": "تیم",
		"personCode": "کد",
		"levelOfHead": "عمق از ریشه",
		"creditValue": "مقدار اعتبار",
		"personRoleId": "person role id",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"companyRank": {
			"self": "رنک شرکت",
			"companyRankId": "شناسه",
			"companyRankKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"companyId": "شرکت",
			"fromRange": "از رنج",
			"toRange": "تا رنج",
			"maxLevelCommision": "سقف نسل",
			"dailyIncomeLimit": "سقف درامد روزانه",
		},
		"personGender": {
			"self": "جنسیت",
			"personGenderId": "شناسه",
			"personGenderKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personStatuse": {
			"self": "وضعیت",
			"personStatuseId": "شناسه",
			"personStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"rank": {
			"self": "رنک",
			"rankId": "شناسه",
			"orderRank": "ردیف",
			"title": "عنوان",
			"teamId": "تیم",
			"companyId": "شرکت",
			"status": "وضعیت فعال",
		},
		"team": {
			"self": "تیم",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"personAccess": {
			"self": "حساب کاربری",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
		"personSubInfo": "person sub info",
	},
	//GeneratorFlag:[Person.Update] {
	"personUpdate": {
		"self": "اعضا",
		"personId": "شناسه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"nationalNo": "کد ملی",
		"passportNo": "پاسپورت",
		"parentId": "بالاسری",
		"genoString": "شجره",
		"rankId": "رنک",
		"companyRankId": "رنک شرکت",
		"companyId": "شرکت",
		"mobile": "همراه",
		"email": "email",
		"personStatuseId": "وضعیت",
		"personGenderId": "جنسیت",
		"teamId": "تیم",
		"personCode": "کد",
		"levelOfHead": "عمق از ریشه",
		"creditValue": "مقدار اعتبار",
		"personRoleId": "person role id",
		"personAccess": {
			"self": "حساب کاربری",
			"personId": "شناسه",
			"userName": "نام کاربری",
			"password": "کلمه عبور",
		},
		"personDetail": {
			"self": "person detail",
			"personId": "person",
			"cityId": "شهر",
			"profileImagePath": "آواتار",
			"heirName": "وارث",
			"securityCode": "کد امنیتی",
		},
	},
	//GeneratorFlag:[PersonAppPurchase.Create] {
	"personAppPurchaseCreate": {
		"self": "PersonAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[PersonAppPurchase.Delete] {
	"personAppPurchaseDelete": {
		"self": "PersonAppPurchase",
		"personAppPurchaseId": "person app purchase id",
	},
	//GeneratorFlag:[PersonAppPurchase.Detail] {
	"personAppPurchaseDetail": {
		"self": "PersonAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": {
			"self": "PersonAppPurchaseStatuseId",
			"personAppPurchaseStatuseId": "شناسه",
			"personAppPurchaseStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseType": {
			"self": "PersonAppPurchaseTypeId",
			"personAppPurchaseTypeId": "شناسه",
			"personAppPurchaseTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseSubInfo": "person app purchase sub info",
	},
	//GeneratorFlag:[PersonAppPurchase.List] {
	"personAppPurchaseList": {
		"self": "PersonAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": {
			"self": "PersonAppPurchaseStatuseId",
			"personAppPurchaseStatuseId": "شناسه",
			"personAppPurchaseStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseType": {
			"self": "PersonAppPurchaseTypeId",
			"personAppPurchaseTypeId": "شناسه",
			"personAppPurchaseTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseSubInfo": "person app purchase sub info",
	},
	//GeneratorFlag:[PersonAppPurchase.Filter] {
	"personAppPurchaseFilter": {
		"self": "PersonAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
		"personAppPurchaseStatuse": {
			"self": "PersonAppPurchaseStatuseId",
			"personAppPurchaseStatuseId": "شناسه",
			"personAppPurchaseStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseType": {
			"self": "PersonAppPurchaseTypeId",
			"personAppPurchaseTypeId": "شناسه",
			"personAppPurchaseTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personAppPurchaseSubInfo": "person app purchase sub info",
	},
	//GeneratorFlag:[PersonAppPurchase.Update] {
	"personAppPurchaseUpdate": {
		"self": "PersonAppPurchase",
		"personAppPurchaseId": "person app purchase id",
		"personId": "person id",
		"createDate": "create date",
		"personAppPurchaseStatuseId": "person app purchase statuse",
		"factorNumber": "factor number",
		"factorValue": "factor value",
		"personAppPurchaseTypeId": "person app purchase type",
		"expireDate": "expire date",
		"displayExpireDate": "display expire date",
	},
	//GeneratorFlag:[PersonCreditTransaction.Create] {
	"personCreditTransactionCreate": {
		"self": "تراکنش های اعتباری",
		"personCreditTransactionId": "شناسه",
		"personId": "شخص",
		"personCreditTransactionTypeId": "نوع",
		"personCreditTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"transactionRefCode": "کد رهگیری تراکنش",
		"displayTransactionDate": "تاریخ نمایش",
		"transactionDate": "تاریخ میلادی تراکنش",
		"creditValue": "مقدار",
		"personCommiter": "تایید کننده",
		"commiterDescription": "توضیحات ادمین",
	},
	//GeneratorFlag:[PersonCreditTransaction.Delete] {
	"personCreditTransactionDelete": {
		"self": "تراکنش های اعتباری",
		"personCreditTransactionId": "شناسه",
	},
	//GeneratorFlag:[PersonCreditTransaction.Detail] {
	"personCreditTransactionDetail": {
		"self": "تراکنش های اعتباری",
		"personCreditTransactionId": "شناسه",
		"personId": "شخص",
		"personCreditTransactionTypeId": "نوع",
		"personCreditTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"transactionRefCode": "کد رهگیری تراکنش",
		"displayTransactionDate": "تاریخ نمایش",
		"transactionDate": "تاریخ میلادی تراکنش",
		"creditValue": "مقدار",
		"personCommiter": "تایید کننده",
		"commiterDescription": "توضیحات ادمین",
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personCreditTransactionStatuse": {
			"self": "وضعیت",
			"personCreditTransactionStatuseId": "شناسه",
			"personCreditTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionType": {
			"self": "نوع",
			"personCreditTransactionTypeId": "شناسه",
			"personCreditTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionSubInfo": "person credit transaction sub info",
	},
	//GeneratorFlag:[PersonCreditTransaction.List] {
	"personCreditTransactionList": {
		"self": "تراکنش های اعتباری",
		"personCreditTransactionId": "شناسه",
		"personId": "شخص",
		"personCreditTransactionTypeId": "نوع",
		"personCreditTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"transactionRefCode": "کد رهگیری تراکنش",
		"displayTransactionDate": "تاریخ نمایش",
		"transactionDate": "تاریخ میلادی تراکنش",
		"creditValue": "مقدار",
		"personCommiter": "تایید کننده",
		"commiterDescription": "توضیحات ادمین",
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personCreditTransactionStatuse": {
			"self": "وضعیت",
			"personCreditTransactionStatuseId": "شناسه",
			"personCreditTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionType": {
			"self": "نوع",
			"personCreditTransactionTypeId": "شناسه",
			"personCreditTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionSubInfo": "person credit transaction sub info",
	},
	//GeneratorFlag:[PersonCreditTransaction.Filter] {
	"personCreditTransactionFilter": {
		"self": "تراکنش های اعتباری",
		"personCreditTransactionId": "شناسه",
		"personId": "شخص",
		"personCreditTransactionTypeId": "نوع",
		"personCreditTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"transactionRefCode": "کد رهگیری تراکنش",
		"displayTransactionDate": "تاریخ نمایش",
		"transactionDate": "تاریخ میلادی تراکنش",
		"creditValue": "مقدار",
		"personCommiter": "تایید کننده",
		"commiterDescription": "توضیحات ادمین",
		"person": {
			"self": "شخص",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personCreditTransactionStatuse": {
			"self": "وضعیت",
			"personCreditTransactionStatuseId": "شناسه",
			"personCreditTransactionStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionType": {
			"self": "نوع",
			"personCreditTransactionTypeId": "شناسه",
			"personCreditTransactionTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personCreditTransactionSubInfo": "person credit transaction sub info",
	},
	//GeneratorFlag:[PersonCreditTransaction.Update] {
	"personCreditTransactionUpdate": {
		"self": "تراکنش های اعتباری",
		"personCreditTransactionId": "شناسه",
		"personId": "شخص",
		"personCreditTransactionTypeId": "نوع",
		"personCreditTransactionStatuseId": "وضعیت",
		"caption": "توضیحات",
		"transactionRefCode": "کد رهگیری تراکنش",
		"displayTransactionDate": "تاریخ نمایش",
		"transactionDate": "تاریخ میلادی تراکنش",
		"creditValue": "مقدار",
		"personCommiter": "تایید کننده",
		"commiterDescription": "توضیحات ادمین",
	},
	//GeneratorFlag:[PersonDocument.Create] {
	"personDocumentCreate": {
		"self": "مدارک اعضا",
		"personDocumentId": "شناسه",
		"personId": "عضو",
		"personDocumentTypeId": "نوع",
		"base64FileString": "آدرس",
		"addressFile": "آدرس",
		"fullAddressFile": "آدرس کامل",
	},
	//GeneratorFlag:[PersonDocument.Delete] {
	"personDocumentDelete": {
		"self": "مدارک اعضا",
		"personDocumentId": "شناسه",
	},
	//GeneratorFlag:[PersonDocument.Detail] {
	"personDocumentDetail": {
		"self": "مدارک اعضا",
		"personDocumentId": "شناسه",
		"personId": "عضو",
		"personDocumentTypeId": "نوع",
		"base64FileString": "آدرس",
		"addressFile": "آدرس",
		"fullAddressFile": "آدرس کامل",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personDocumentType": {
			"self": "نوع",
			"personDocumentTypeId": "شناسه",
			"personDocumentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personDocumentSubInfo": "person document sub info",
	},
	//GeneratorFlag:[PersonDocument.List] {
	"personDocumentList": {
		"self": "مدارک اعضا",
		"personDocumentId": "شناسه",
		"personId": "عضو",
		"personDocumentTypeId": "نوع",
		"base64FileString": "آدرس",
		"addressFile": "آدرس",
		"fullAddressFile": "آدرس کامل",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personDocumentType": {
			"self": "نوع",
			"personDocumentTypeId": "شناسه",
			"personDocumentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personDocumentSubInfo": "person document sub info",
	},
	//GeneratorFlag:[PersonDocument.Filter] {
	"personDocumentFilter": {
		"self": "مدارک اعضا",
		"personDocumentId": "شناسه",
		"personId": "عضو",
		"personDocumentTypeId": "نوع",
		"base64FileString": "آدرس",
		"addressFile": "آدرس",
		"fullAddressFile": "آدرس کامل",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personDocumentType": {
			"self": "نوع",
			"personDocumentTypeId": "شناسه",
			"personDocumentTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personDocumentSubInfo": "person document sub info",
	},
	//GeneratorFlag:[PersonDocument.Update] {
	"personDocumentUpdate": {
		"self": "مدارک اعضا",
		"personDocumentId": "شناسه",
		"personId": "عضو",
		"personDocumentTypeId": "نوع",
		"base64FileString": "آدرس",
		"addressFile": "آدرس",
		"fullAddressFile": "آدرس کامل",
	},
	//GeneratorFlag:[PersonRequestDipost.Create] {
	"personRequestDipostCreate": {
		"self": "درخواست واریز",
		"personRequestDipostId": "شناسه",
		"personRequestDipostTypeId": "نوع درخواست واریز",
		"personId": "عضو",
		"amount": "مقدار",
		"transactionHashCode": "هش تراکنش",
		"fromSourceWallet": "مبدا واریز",
		"toSourceWallet": "مقصد واریز",
		"personRequestDipostStatuseId": "وضعیت",
		"financialTransactionWalletId": "شناسه تراکنش کیف پول",
		"amountBack": "مقدار واریز برگشت",
	},
	//GeneratorFlag:[PersonRequestDipost.Delete] {
	"personRequestDipostDelete": {
		"self": "درخواست واریز",
		"personRequestDipostId": "شناسه",
	},
	//GeneratorFlag:[PersonRequestDipost.Detail] {
	"personRequestDipostDetail": {
		"self": "درخواست واریز",
		"personRequestDipostId": "شناسه",
		"personRequestDipostTypeId": "نوع درخواست واریز",
		"personId": "عضو",
		"amount": "مقدار",
		"transactionHashCode": "هش تراکنش",
		"fromSourceWallet": "مبدا واریز",
		"toSourceWallet": "مقصد واریز",
		"personRequestDipostStatuseId": "وضعیت",
		"financialTransactionWalletId": "شناسه تراکنش کیف پول",
		"amountBack": "مقدار واریز برگشت",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personRequestDipostStatuse": {
			"self": "وضعیت",
			"personRequestDipostStatuseId": "شناسه",
			"personRequestDipostStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostType": {
			"self": "نوع درخواست واریز",
			"personRequestDipostTypeId": "شناسه",
			"personRequestDipostTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonRequestDipost.List] {
	"personRequestDipostList": {
		"self": "درخواست واریز",
		"personRequestDipostId": "شناسه",
		"personRequestDipostTypeId": "نوع درخواست واریز",
		"personId": "عضو",
		"amount": "مقدار",
		"transactionHashCode": "هش تراکنش",
		"fromSourceWallet": "مبدا واریز",
		"toSourceWallet": "مقصد واریز",
		"personRequestDipostStatuseId": "وضعیت",
		"financialTransactionWalletId": "شناسه تراکنش کیف پول",
		"amountBack": "مقدار واریز برگشت",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personRequestDipostStatuse": {
			"self": "وضعیت",
			"personRequestDipostStatuseId": "شناسه",
			"personRequestDipostStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostType": {
			"self": "نوع درخواست واریز",
			"personRequestDipostTypeId": "شناسه",
			"personRequestDipostTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonRequestDipost.Filter] {
	"personRequestDipostFilter": {
		"self": "درخواست واریز",
		"personRequestDipostId": "شناسه",
		"personRequestDipostTypeId": "نوع درخواست واریز",
		"personId": "عضو",
		"amount": "مقدار",
		"transactionHashCode": "هش تراکنش",
		"fromSourceWallet": "مبدا واریز",
		"toSourceWallet": "مقصد واریز",
		"personRequestDipostStatuseId": "وضعیت",
		"financialTransactionWalletId": "شناسه تراکنش کیف پول",
		"amountBack": "مقدار واریز برگشت",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personRequestDipostStatuse": {
			"self": "وضعیت",
			"personRequestDipostStatuseId": "شناسه",
			"personRequestDipostStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostType": {
			"self": "نوع درخواست واریز",
			"personRequestDipostTypeId": "شناسه",
			"personRequestDipostTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personRequestDipostSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonRequestDipost.Update] {
	"personRequestDipostUpdate": {
		"self": "درخواست واریز",
		"personRequestDipostId": "شناسه",
		"personRequestDipostTypeId": "نوع درخواست واریز",
		"personId": "عضو",
		"amount": "مقدار",
		"transactionHashCode": "هش تراکنش",
		"fromSourceWallet": "مبدا واریز",
		"toSourceWallet": "مقصد واریز",
		"personRequestDipostStatuseId": "وضعیت",
		"financialTransactionWalletId": "شناسه تراکنش کیف پول",
		"amountBack": "مقدار واریز برگشت",
		"isJustEdit": "ویرایش مقادیر؟",
	},
	//GeneratorFlag:[City.Create] {
	"cityCreate": {
		"self": "شهر",
		"cityId": "شناسه",
		"title": "عنوان",
		"provinceId": "استان",
	},
	//GeneratorFlag:[City.Delete] {
	"cityDelete": {
		"self": "شهر",
		"cityId": "شناسه",
	},
	//GeneratorFlag:[City.Detail] {
	"cityDetail": {
		"self": "شهر",
		"cityId": "شناسه",
		"title": "عنوان",
		"provinceId": "استان",
		"province": {
			"self": "استان",
			"provinceId": "شناسه",
			"countryId": "کشور",
			"title": "عنوان",
		},
		"citySubInfo": "city sub info",
	},
	//GeneratorFlag:[City.List] {
	"cityList": {
		"self": "شهر",
		"cityId": "شناسه",
		"title": "عنوان",
		"provinceId": "استان",
		"province": {
			"self": "استان",
			"provinceId": "شناسه",
			"countryId": "کشور",
			"title": "عنوان",
		},
		"citySubInfo": "city sub info",
	},
	//GeneratorFlag:[City.Filter] {
	"cityFilter": {
		"self": "شهر",
		"cityId": "شناسه",
		"title": "عنوان",
		"provinceId": "استان",
		"province": {
			"self": "استان",
			"provinceId": "شناسه",
			"countryId": "کشور",
			"title": "عنوان",
		},
		"citySubInfo": "city sub info",
	},
	//GeneratorFlag:[City.Update] {
	"cityUpdate": {
		"self": "شهر",
		"cityId": "شناسه",
		"title": "عنوان",
		"provinceId": "استان",
	},
	//GeneratorFlag:[Company.Create] {
	"companyCreate": {
		"self": "شرکت",
		"companyId": "شناسه",
		"title": "نام",
	},
	//GeneratorFlag:[Company.Delete] {
	"companyDelete": {
		"self": "شرکت",
		"companyId": "شناسه",
	},
	//GeneratorFlag:[Company.Detail] {
	"companyDetail": {
		"self": "شرکت",
		"companyId": "شناسه",
		"title": "نام",
		"companySubInfo": "company sub info",
	},
	//GeneratorFlag:[Company.List] {
	"companyList": {
		"self": "شرکت",
		"companyId": "شناسه",
		"title": "نام",
		"companySubInfo": "company sub info",
	},
	//GeneratorFlag:[Company.Filter] {
	"companyFilter": {
		"self": "شرکت",
		"companyId": "شناسه",
		"title": "نام",
		"companySubInfo": "company sub info",
	},
	//GeneratorFlag:[Company.Update] {
	"companyUpdate": {
		"self": "شرکت",
		"companyId": "شناسه",
		"title": "نام",
	},
	//GeneratorFlag:[CompanyRank.Create] {
	"companyRankCreate": {
		"self": "رنک های شرکت",
		"companyRankId": "شناسه",
		"companyRankKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"companyId": "شرکت",
		"fromRange": "از رنج",
		"toRange": "تا رنج",
		"maxLevelCommision": "سقف نسل",
		"dailyIncomeLimit": "سقف درامد روزانه",
	},
	//GeneratorFlag:[CompanyRank.Delete] {
	"companyRankDelete": {
		"self": "رنک های شرکت",
		"companyRankId": "شناسه",
	},
	//GeneratorFlag:[CompanyRank.Detail] {
	"companyRankDetail": {
		"self": "رنک های شرکت",
		"companyRankId": "شناسه",
		"companyRankKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"companyId": "شرکت",
		"fromRange": "از رنج",
		"toRange": "تا رنج",
		"maxLevelCommision": "سقف نسل",
		"dailyIncomeLimit": "سقف درامد روزانه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"companyRankSubInfo": "company rank sub info",
	},
	//GeneratorFlag:[CompanyRank.List] {
	"companyRankList": {
		"self": "رنک های شرکت",
		"companyRankId": "شناسه",
		"companyRankKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"companyId": "شرکت",
		"fromRange": "از رنج",
		"toRange": "تا رنج",
		"maxLevelCommision": "سقف نسل",
		"dailyIncomeLimit": "سقف درامد روزانه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"companyRankSubInfo": "company rank sub info",
	},
	//GeneratorFlag:[CompanyRank.Filter] {
	"companyRankFilter": {
		"self": "رنک های شرکت",
		"companyRankId": "شناسه",
		"companyRankKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"companyId": "شرکت",
		"fromRange": "از رنج",
		"toRange": "تا رنج",
		"maxLevelCommision": "سقف نسل",
		"dailyIncomeLimit": "سقف درامد روزانه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
		"companyRankSubInfo": "company rank sub info",
	},
	//GeneratorFlag:[CompanyRank.Update] {
	"companyRankUpdate": {
		"self": "رنک های شرکت",
		"companyRankId": "شناسه",
		"companyRankKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"companyId": "شرکت",
		"fromRange": "از رنج",
		"toRange": "تا رنج",
		"maxLevelCommision": "سقف نسل",
		"dailyIncomeLimit": "سقف درامد روزانه",
	},
	//GeneratorFlag:[Country.Create] {
	"countryCreate": {
		"self": "کشور",
		"countryId": "شناسه",
		"title": "عنوان",
		"countryCode": "کد کشور",
		"flagUrl": "پرچم",
	},
	//GeneratorFlag:[Country.Delete] {
	"countryDelete": {
		"self": "کشور",
		"countryId": "شناسه",
	},
	//GeneratorFlag:[Country.Detail] {
	"countryDetail": {
		"self": "کشور",
		"countryId": "شناسه",
		"title": "عنوان",
		"countryCode": "کد کشور",
		"flagUrl": "پرچم",
		"countrySubInfo": "country sub info",
	},
	//GeneratorFlag:[Country.List] {
	"countryList": {
		"self": "کشور",
		"countryId": "شناسه",
		"title": "عنوان",
		"countryCode": "کد کشور",
		"flagUrl": "پرچم",
		"countrySubInfo": "country sub info",
	},
	//GeneratorFlag:[Country.Filter] {
	"countryFilter": {
		"self": "کشور",
		"countryId": "شناسه",
		"title": "عنوان",
		"countryCode": "کد کشور",
		"flagUrl": "پرچم",
		"countrySubInfo": "country sub info",
	},
	//GeneratorFlag:[Country.Update] {
	"countryUpdate": {
		"self": "کشور",
		"countryId": "شناسه",
		"title": "عنوان",
		"countryCode": "کد کشور",
		"flagUrl": "پرچم",
	},
	//GeneratorFlag:[MilitaryType.Create] {
	"militaryTypeCreate": {
		"self": "وضعیت نظام وضیفه",
		"militaryTypeId": "شناسه",
		"militaryTypeKey": "military type key",
		"title": "عنوان",
		"caption": "caption",
	},
	//GeneratorFlag:[MilitaryType.Delete] {
	"militaryTypeDelete": {
		"self": "وضعیت نظام وضیفه",
		"militaryTypeId": "شناسه",
	},
	//GeneratorFlag:[MilitaryType.Detail] {
	"militaryTypeDetail": {
		"self": "وضعیت نظام وضیفه",
		"militaryTypeId": "شناسه",
		"militaryTypeKey": "military type key",
		"title": "عنوان",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",
	},
	//GeneratorFlag:[MilitaryType.List] {
	"militaryTypeList": {
		"self": "وضعیت نظام وضیفه",
		"militaryTypeId": "شناسه",
		"militaryTypeKey": "military type key",
		"title": "عنوان",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",
	},
	//GeneratorFlag:[MilitaryType.Filter] {
	"militaryTypeFilter": {
		"self": "وضعیت نظام وضیفه",
		"militaryTypeId": "شناسه",
		"militaryTypeKey": "military type key",
		"title": "عنوان",
		"caption": "caption",
		"militaryTypeSubInfo": "military type sub info",
	},
	//GeneratorFlag:[MilitaryType.Update] {
	"militaryTypeUpdate": {
		"self": "وضعیت نظام وضیفه",
		"militaryTypeId": "شناسه",
		"militaryTypeKey": "military type key",
		"title": "عنوان",
		"caption": "caption",
	},
	//GeneratorFlag:[NationalityType.Create] {
	"nationalityTypeCreate": {
		"self": "NationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[NationalityType.Delete] {
	"nationalityTypeDelete": {
		"self": "NationalityType",
		"nationalityTypeId": "nationality type id",
	},
	//GeneratorFlag:[NationalityType.Detail] {
	"nationalityTypeDetail": {
		"self": "NationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",
	},
	//GeneratorFlag:[NationalityType.List] {
	"nationalityTypeList": {
		"self": "NationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",
	},
	//GeneratorFlag:[NationalityType.Filter] {
	"nationalityTypeFilter": {
		"self": "NationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
		"nationalityTypeSubInfo": "nationality type sub info",
	},
	//GeneratorFlag:[NationalityType.Update] {
	"nationalityTypeUpdate": {
		"self": "NationalityType",
		"nationalityTypeId": "nationality type id",
		"title": "title",
		"caption": "caption",
	},
	//GeneratorFlag:[PersonBankAccount.Create] {
	"personBankAccountCreate": {
		"self": "حساب بانکی",
		"personBankAccountId": "شناسه",
		"personBankAccountTypeId": "نوع",
		"personId": "عضو",
		"accountName": "عنوان",
		"accountId": "شناسه حساب",
		"shabaCode": "شبا",
		"cardNumber": "شماره کارت",
		"walletAddress": "آدرس کیف پول دیجیتال",
	},
	//GeneratorFlag:[PersonBankAccount.Delete] {
	"personBankAccountDelete": {
		"self": "حساب بانکی",
		"personBankAccountId": "شناسه",
	},
	//GeneratorFlag:[PersonBankAccount.Detail] {
	"personBankAccountDetail": {
		"self": "حساب بانکی",
		"personBankAccountId": "شناسه",
		"personBankAccountTypeId": "نوع",
		"personId": "عضو",
		"accountName": "عنوان",
		"accountId": "شناسه حساب",
		"shabaCode": "شبا",
		"cardNumber": "شماره کارت",
		"walletAddress": "آدرس کیف پول دیجیتال",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personBankAccountType": {
			"self": "نوع",
			"personBankAccountTypeId": "شناسه",
			"personBankAccountTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personBankAccountSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonBankAccount.List] {
	"personBankAccountList": {
		"self": "حساب بانکی",
		"personBankAccountId": "شناسه",
		"personBankAccountTypeId": "نوع",
		"personId": "عضو",
		"accountName": "عنوان",
		"accountId": "شناسه حساب",
		"shabaCode": "شبا",
		"cardNumber": "شماره کارت",
		"walletAddress": "آدرس کیف پول دیجیتال",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personBankAccountType": {
			"self": "نوع",
			"personBankAccountTypeId": "شناسه",
			"personBankAccountTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personBankAccountSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonBankAccount.Filter] {
	"personBankAccountFilter": {
		"self": "حساب بانکی",
		"personBankAccountId": "شناسه",
		"personBankAccountTypeId": "نوع",
		"personId": "عضو",
		"accountName": "عنوان",
		"accountId": "شناسه حساب",
		"shabaCode": "شبا",
		"cardNumber": "شماره کارت",
		"walletAddress": "آدرس کیف پول دیجیتال",
		"person": {
			"self": "عضو",
			"personId": "شناسه",
			"firstName": "نام",
			"lastName": "نام خانوادگی",
			"nationalNo": "کد ملی",
			"passportNo": "پاسپورت",
			"parentId": "بالاسری",
			"genoString": "شجره",
			"rankId": "رنک",
			"companyRankId": "رنک شرکت",
			"companyId": "شرکت",
			"mobile": "همراه",
			"email": "email",
			"personStatuseId": "وضعیت",
			"personGenderId": "جنسیت",
			"teamId": "تیم",
			"personCode": "کد",
			"levelOfHead": "عمق از ریشه",
			"creditValue": "مقدار اعتبار",
			"personRoleId": "person role id",
		},
		"personBankAccountType": {
			"self": "نوع",
			"personBankAccountTypeId": "شناسه",
			"personBankAccountTypeKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"personBankAccountSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[PersonBankAccount.Update] {
	"personBankAccountUpdate": {
		"self": "حساب بانکی",
		"personBankAccountId": "شناسه",
		"personBankAccountTypeId": "نوع",
		"personId": "عضو",
		"accountName": "عنوان",
		"accountId": "شناسه حساب",
		"shabaCode": "شبا",
		"cardNumber": "شماره کارت",
		"walletAddress": "آدرس کیف پول دیجیتال",
	},
	//GeneratorFlag:[Province.Create] {
	"provinceCreate": {
		"self": "استان",
		"provinceId": "شناسه",
		"countryId": "کشور",
		"title": "عنوان",
	},
	//GeneratorFlag:[Province.Delete] {
	"provinceDelete": {
		"self": "استان",
		"provinceId": "شناسه",
	},
	//GeneratorFlag:[Province.Detail] {
	"provinceDetail": {
		"self": "استان",
		"provinceId": "شناسه",
		"countryId": "کشور",
		"title": "عنوان",
		"country": {
			"self": "کشور",
			"countryId": "شناسه",
			"title": "عنوان",
			"countryCode": "کد کشور",
			"flagUrl": "پرچم",
		},
		"provinceSubInfo": "province sub info",
	},
	//GeneratorFlag:[Province.List] {
	"provinceList": {
		"self": "استان",
		"provinceId": "شناسه",
		"countryId": "کشور",
		"title": "عنوان",
		"country": {
			"self": "کشور",
			"countryId": "شناسه",
			"title": "عنوان",
			"countryCode": "کد کشور",
			"flagUrl": "پرچم",
		},
		"provinceSubInfo": "province sub info",
	},
	//GeneratorFlag:[Province.Filter] {
	"provinceFilter": {
		"self": "استان",
		"provinceId": "شناسه",
		"countryId": "کشور",
		"title": "عنوان",
		"country": {
			"self": "کشور",
			"countryId": "شناسه",
			"title": "عنوان",
			"countryCode": "کد کشور",
			"flagUrl": "پرچم",
		},
		"provinceSubInfo": "province sub info",
	},
	//GeneratorFlag:[Province.Update] {
	"provinceUpdate": {
		"self": "استان",
		"provinceId": "شناسه",
		"countryId": "کشور",
		"title": "عنوان",
	},
	//GeneratorFlag:[Rank.Create] {
	"rankCreate": {
		"self": "رنک تیم",
		"rankId": "شناسه",
		"orderRank": "ردیف",
		"title": "عنوان",
		"teamId": "تیم",
		"companyId": "شرکت",
		"status": "وضعیت فعال",
	},
	//GeneratorFlag:[Rank.Delete] {
	"rankDelete": {
		"self": "رنک تیم",
		"rankId": "شناسه",
	},
	//GeneratorFlag:[Rank.Detail] {
	"rankDetail": {
		"self": "رنک تیم",
		"rankId": "شناسه",
		"orderRank": "ردیف",
		"title": "عنوان",
		"teamId": "تیم",
		"companyId": "شرکت",
		"status": "وضعیت فعال",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"team": {
			"self": "تیم",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"rankSubInfo": "rank sub info",
	},
	//GeneratorFlag:[Rank.List] {
	"rankList": {
		"self": "رنک تیم",
		"rankId": "شناسه",
		"orderRank": "ردیف",
		"title": "عنوان",
		"teamId": "تیم",
		"companyId": "شرکت",
		"status": "وضعیت فعال",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"team": {
			"self": "تیم",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"rankSubInfo": "rank sub info",
	},
	//GeneratorFlag:[Rank.Filter] {
	"rankFilter": {
		"self": "رنک تیم",
		"rankId": "شناسه",
		"orderRank": "ردیف",
		"title": "عنوان",
		"teamId": "تیم",
		"companyId": "شرکت",
		"status": "وضعیت فعال",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"team": {
			"self": "تیم",
			"teamId": "شناسه",
			"title": "شناسه",
			"teamGenoString": "شجره",
			"companyId": "شرکت",
		},
		"rankSubInfo": "rank sub info",
	},
	//GeneratorFlag:[Rank.Update] {
	"rankUpdate": {
		"self": "رنک تیم",
		"rankId": "شناسه",
		"orderRank": "ردیف",
		"title": "عنوان",
		"teamId": "تیم",
		"companyId": "شرکت",
		"status": "وضعیت فعال",
	},
	//GeneratorFlag:[Team.Create] {
	"teamCreate": {
		"self": "Team",
		"teamId": "شناسه",
		"title": "شناسه",
		"teamGenoString": "شجره",
		"companyId": "شرکت",
	},
	//GeneratorFlag:[Team.Delete] {
	"teamDelete": {
		"self": "Team",
		"teamId": "شناسه",
	},
	//GeneratorFlag:[Team.Detail] {
	"teamDetail": {
		"self": "Team",
		"teamId": "شناسه",
		"title": "شناسه",
		"teamGenoString": "شجره",
		"companyId": "شرکت",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"teamSubInfo": "team sub info",
	},
	//GeneratorFlag:[Team.List] {
	"teamList": {
		"self": "Team",
		"teamId": "شناسه",
		"title": "شناسه",
		"teamGenoString": "شجره",
		"companyId": "شرکت",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"teamSubInfo": "team sub info",
	},
	//GeneratorFlag:[Team.Filter] {
	"teamFilter": {
		"self": "Team",
		"teamId": "شناسه",
		"title": "شناسه",
		"teamGenoString": "شجره",
		"companyId": "شرکت",
		"company": {
			"self": "شرکت",
			"companyId": "شناسه",
			"title": "نام",
		},
		"teamSubInfo": "team sub info",
	},
	//GeneratorFlag:[Team.Update] {
	"teamUpdate": {
		"self": "Team",
		"teamId": "شناسه",
		"title": "شناسه",
		"teamGenoString": "شجره",
		"companyId": "شرکت",
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "زبان",
		"cultureId": "شناسه",
		"cultureKey": "کلید",
		"title": "عنوان",
		"cultureSymbol": "سیمبل",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "نشانه دو حرفی",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "واحد طول",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "زبان",
		"cultureId": "شناسه",
		"cultureKey": "کلید",
		"title": "عنوان",
		"cultureSymbol": "سیمبل",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "نشانه دو حرفی",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "واحد طول",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "زبان",
		"cultureId": "شناسه",
		"cultureKey": "کلید",
		"title": "عنوان",
		"cultureSymbol": "سیمبل",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "نشانه دو حرفی",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "واحد طول",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "standard time zone id",
	},
	//GeneratorFlag:[PersonAppPurchaseStatuse.Detail] {
	"personAppPurchaseStatuseDetail": {
		"self": "وضعیت تراکنش",
		"personAppPurchaseStatuseId": "شناسه",
		"personAppPurchaseStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseStatuse.List] {
	"personAppPurchaseStatuseList": {
		"self": "وضعیت تراکنش",
		"personAppPurchaseStatuseId": "شناسه",
		"personAppPurchaseStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseStatuse.Filter] {
	"personAppPurchaseStatuseFilter": {
		"self": "وضعیت تراکنش",
		"personAppPurchaseStatuseId": "شناسه",
		"personAppPurchaseStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseType.Detail] {
	"personAppPurchaseTypeDetail": {
		"self": "نوع اپ",
		"personAppPurchaseTypeId": "شناسه",
		"personAppPurchaseTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseType.List] {
	"personAppPurchaseTypeList": {
		"self": "نوع اپ",
		"personAppPurchaseTypeId": "شناسه",
		"personAppPurchaseTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonAppPurchaseType.Filter] {
	"personAppPurchaseTypeFilter": {
		"self": "نوع اپ",
		"personAppPurchaseTypeId": "شناسه",
		"personAppPurchaseTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonBankAccountType.Detail] {
	"personBankAccountTypeDetail": {
		"self": "نوع حساب",
		"personBankAccountTypeId": "شناسه",
		"personBankAccountTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonBankAccountType.List] {
	"personBankAccountTypeList": {
		"self": "نوع حساب",
		"personBankAccountTypeId": "شناسه",
		"personBankAccountTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonBankAccountType.Filter] {
	"personBankAccountTypeFilter": {
		"self": "نوع حساب",
		"personBankAccountTypeId": "شناسه",
		"personBankAccountTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonCreditTransactionStatuse.Detail] {
	"personCreditTransactionStatuseDetail": {
		"self": "وضعیت تراکنش",
		"personCreditTransactionStatuseId": "شناسه",
		"personCreditTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionStatuse.List] {
	"personCreditTransactionStatuseList": {
		"self": "وضعیت تراکنش",
		"personCreditTransactionStatuseId": "شناسه",
		"personCreditTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionStatuse.Filter] {
	"personCreditTransactionStatuseFilter": {
		"self": "وضعیت تراکنش",
		"personCreditTransactionStatuseId": "شناسه",
		"personCreditTransactionStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionType.Detail] {
	"personCreditTransactionTypeDetail": {
		"self": "نوع تراکنش",
		"personCreditTransactionTypeId": "شناسه",
		"personCreditTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionType.List] {
	"personCreditTransactionTypeList": {
		"self": "نوع تراکنش",
		"personCreditTransactionTypeId": "شناسه",
		"personCreditTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonCreditTransactionType.Filter] {
	"personCreditTransactionTypeFilter": {
		"self": "نوع تراکنش",
		"personCreditTransactionTypeId": "شناسه",
		"personCreditTransactionTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonDocumentType.Detail] {
	"personDocumentTypeDetail": {
		"self": "نوع مدرک",
		"personDocumentTypeId": "شناسه",
		"personDocumentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonDocumentType.List] {
	"personDocumentTypeList": {
		"self": "نوع مدرک",
		"personDocumentTypeId": "شناسه",
		"personDocumentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonDocumentType.Filter] {
	"personDocumentTypeFilter": {
		"self": "نوع مدرک",
		"personDocumentTypeId": "شناسه",
		"personDocumentTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonGender.Detail] {
	"personGenderDetail": {
		"self": "جنسیت اعضا",
		"personGenderId": "شناسه",
		"personGenderKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonGender.List] {
	"personGenderList": {
		"self": "جنسیت اعضا",
		"personGenderId": "شناسه",
		"personGenderKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonGender.Filter] {
	"personGenderFilter": {
		"self": "جنسیت اعضا",
		"personGenderId": "شناسه",
		"personGenderKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostStatuse.Detail] {
	"personRequestDipostStatuseDetail": {
		"self": "وضعیت تراکنش",
		"personRequestDipostStatuseId": "شناسه",
		"personRequestDipostStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostStatuse.List] {
	"personRequestDipostStatuseList": {
		"self": "وضعیت تراکنش",
		"personRequestDipostStatuseId": "شناسه",
		"personRequestDipostStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostStatuse.Filter] {
	"personRequestDipostStatuseFilter": {
		"self": "وضعیت تراکنش",
		"personRequestDipostStatuseId": "شناسه",
		"personRequestDipostStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonRequestDipostType.Detail] {
	"personRequestDipostTypeDetail": {
		"self": "نوع درخواست واریز",
		"personRequestDipostTypeId": "شناسه",
		"personRequestDipostTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonRequestDipostType.List] {
	"personRequestDipostTypeList": {
		"self": "نوع درخواست واریز",
		"personRequestDipostTypeId": "شناسه",
		"personRequestDipostTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonRequestDipostType.Filter] {
	"personRequestDipostTypeFilter": {
		"self": "نوع درخواست واریز",
		"personRequestDipostTypeId": "شناسه",
		"personRequestDipostTypeKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[PersonStatuse.Detail] {
	"personStatuseDetail": {
		"self": "وضعیت عضو",
		"personStatuseId": "شناسه",
		"personStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonStatuse.List] {
	"personStatuseList": {
		"self": "وضعیت عضو",
		"personStatuseId": "شناسه",
		"personStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[PersonStatuse.Filter] {
	"personStatuseFilter": {
		"self": "وضعیت عضو",
		"personStatuseId": "شناسه",
		"personStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "standard time zone id",
		},
	},
	//GeneratorFlag:[AccessAgent.Save] {
	"accessAgentSave": {
		"self": "مدیریت دسترسی ها",
		"personId": "کاربر",
		"personRoleId": "نقش کاربری",
		"accessKeys": {
			"self": "کلید ها",
			"keyName": "",
			"groupName": "",
		},
		"isSuccess": "نتیجه",
	},
	//GeneratorFlag:[AccessToken.MyFunc] {
	"accessTokenMyFunc": {
		"self": "access token",
		"personId": "",
		"currentPassword": "",
		"newPassword": "",
		"confirmedPassword": "",
		"result": "",
	},
	//GeneratorFlag:[IpValidation.ValidateIp] {
	"ipValidationValidateIp": {
		"self": "ip validation",
		"iPAddress": "",
		"country": "",
	},
	//GeneratorFlag:[Person.ChangeEmail] {
	"personChangeEmail": {
		"self": "تغییر ایمیل",
		"newEmail": "ایمیل",
		"personValidationId": "شناسه تایید",
		"varificationCode": "کد تایید",
	},
	//GeneratorFlag:[Person.ChangeSecurityCode] {
	"personChangeSecurityCode": {
		"self": "تغییر ایمیل",
		"isConfirmed": "تایید",
		"securityCode": "کد امنیتی",
	},
	//GeneratorFlag:[Person.CheckUpline] {
	"personCheckUpline": {
		"self": "بررسی آپلاین",
		"isValid": "وضعیت اعتبار",
		"parentId": "کد بالاسری",
		"uplineInfo": "نام و نام خانوادگی",
		"personCode": "کد شخص",
	},
	//GeneratorFlag:[Person.GetCredit] {
	"personGetCredit": {
		"self": "گرفتن اعتبار فرد",
		"creditValue": "مقدار اعتبار",
		"currency": "واحد پولی",
	},
	//GeneratorFlag:[Person.GetPersonByData] {
	"personGetPersonByData": {
		"self": "جستجو در لیست زیر مجموعه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"parentId": "بالا سری",
		"parentName": "بالا سری",
		"levelGenerationOfMe": "نسل",
		"mobile": "همراه",
		"personId": "شناسه",
		"personCode": "کد شخص",
	},
	//GeneratorFlag:[Person.GetProfile] {
	"personGetProfile": {
		"self": "پروفایل",
		"imagePath": "آدرس",
		"email": "ایمیل",
		"userName": "نام کاربری",
		"parentCode": "کد معرف",
		"createDate": "تاریخ ثبت نام",
	},
	//GeneratorFlag:[Person.GetUplineInfo] {
	"personGetUplineInfo": {
		"self": "person",
		"isValid": "وضعیت اعتبار",
		"parentId": "کد بالاسری",
		"uplineInfo": "نام و نام خانوادگی",
		"personCode": "کد شخص",
	},
	//GeneratorFlag:[Person.RegisterByEmail] {
	"personRegisterByEmail": {
		"self": "person",
		"userName": "",
		"email": "",
		"heirName": "وارث",
		"fromSourceWallet": "آدرس کیف پول مبدا",
		"toSourceWallet": "آدرس کیف پول مبدا",
		"investmentAmount": "مبلغ سرمایه گذاری",
		"parentId": "",
		"parentIdInvoice": "",
		"password": "",
		"confirmedPassword": "",
		"transactionHash": "کد هش تراکنش",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
	},
	//GeneratorFlag:[Person.Register] {
	"personRegister": {
		"self": "جستجو در لیست زیر مجموعه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"parentId": "کد شخص",
		"nationalNo": "",
		"passportNo": "",
		"companyId": "",
		"countryId": "",
		"mobile": "",
		"email": "",
		"personGenderId": "",
		"cityId": "",
		"password": "",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
	},
	//GeneratorFlag:[Person.RegisterShop] {
	"personRegisterShop": {
		"self": "person",
		"firstName": "",
		"lastName": "",
		"heirName": "وارث",
		"parentId": "",
		"nationalNo": "",
		"passportNo": "",
		"companyId": "",
		"countryId": "",
		"mobile": "",
		"email": "",
		"personGenderId": "",
		"cityId": "",
		"password": "",
		"confirmedPassword": "",
		"passportImageFile": "",
		"parentIdCode": "",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
	},
	//GeneratorFlag:[Person.SearchTeam] {
	"personSearchTeam": {
		"self": "لیست افراد زیر مجموعه",
		"firstName": "نام",
		"lastName": "نام خانوادگی",
		"parentId": "شناسه بالاسری",
		"parentName": "معرف",
		"levelGenerationOfMe": "سطح",
		"mobile": "همراه",
		"personId": "شناسه شخص",
		"personCode": "کد شخص",
		"teamRankName": "رنک تیمی",
		"companyRankName": "رنک شرکت",
	},
	//GeneratorFlag:[Person.TreeList] {
	"personTreeList": {
		"self": "نمایش درختی تیم",
		"personId": "شناسه",
		"fromDateDisply": "از تاریخ",
		"toDateDisply": "تا تاریخ",
		"includeReportSell": "گزارش فروش",
		"items": {
			"self": "لیست اعضا",
			"personId": "شناسه",
			"personCode": "کد",
			"userName": "نام کاربری",
			"firstName": "نام",
			"parentId": "والد",
			"lastName": "نام خانوادگی",
			"avatarUrl": "آواتار",
			"companyRankName": "رنک شرکت",
			"teamRankName": "رنک تیمی",
			"G": "G",
			"companyRanId": "رنک شرکت",
			"teamRankId": "رنک تیمی",
			"memberCount": "تعداد مجموعه",
			"level": "سطح",
			"totalSell": "فروش کل",
			"personalSell": "فروش شخصی",
			"savedValue": "مقدار سیو",
			"totalScore": "امتیاز کل",
			"totalInvoice": "تعداد فاکتور",
			"fullName": "مشخصات",
		},
	},
	//GeneratorFlag:[PersonAccess.ChangePassword] {
	"personAccessChangePassword": {
		"self": "person access",
		"personId": "",
		"currentPassword": "",
		"newPassword": "",
		"confirmedPassword": "",
		"result": "",
	},
	//GeneratorFlag:[PersonAccess.CheckAccess] {
	"personAccessCheckAccess": {
		"self": "person access",
		"key": "",
		"result": "",
	},
	//GeneratorFlag:[PersonAccess.DeactiveToken] {
	"personAccessDeactiveToken": {
		"self": "person access",
		"isDone": "",
	},
	//GeneratorFlag:[PersonAccess.FortgetPassword] {
	"personAccessFortgetPassword": {
		"self": "person access",
		"email": "",
		"securityCode": "",
		"result": "",
	},
	//GeneratorFlag:[PersonAccess.Generate] {
	"personAccessGenerate": {
		"self": "person access",
		"userName": "نام کاربری",
		"password": "کلمه عبور",
		"tokenType": "",
		"expirationDate": "",
		"token": "",
		"profileUrl": "",
		"rankName": "",
		"parentName": "",
		"rankLogo": "",
	},
	//GeneratorFlag:[PersonAppPurchase.CheckCredit] {
	"personAppPurchaseCheckCredit": {
		"self": "person app purchase",
		"isValid": "",
		"expireDate": "",
		"displayExpireDate": "",
	},
	//GeneratorFlag:[PersonBankAccount.GetWallet] {
	"personBankAccountGetWallet": {
		"self": "کیف پول",
		"walletAddress": "آدرس کیف پول",
		"accountName": "نام حساب",
		"personBankAccountId": "شناسه",
	},
	//GeneratorFlag:[PersonBankAccount.RegisterBank] {
	"personBankAccountRegisterBank": {
		"self": "ثبت بانک",
		"walletAddress": "آدرس کیف پول",
		"accountName": "نام حساب",
		"secuirtyCode": "کد امنیتی",
		"personBankAccountId": "شناسه",
	},
	//GeneratorFlag:[PersonCreditTransaction.Charge] {
	"personCreditTransactionCharge": {
		"self": "شارژ کیف پول",
		"personCreditTransactionId": "شناسه",
		"creditValue": "مبلغ",
		"transactionHash": "هش تراکنش",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[PersonCreditTransaction.MyList] {
	"personCreditTransactionMyList": {
		"self": "لیست تراکنش ها",
		"personCreditTransactionId": "شناسه",
		"personCreditTransactionTypeName": "نوع",
		"personCreditTransactionStatuseName": "وضعیت",
		"caption": "توضیحات",
		"transactionRefCode": "کد رهگیری تراکنش",
		"transactionDate": "تاریخ میلادی تراکنش",
		"creditValue": "مقدار",
		"personCommiter": "تایید کننده",
		"commiterDescription": "توضیحات ادمین",
	},
	//GeneratorFlag:[PersonLogin.Register] {
	"personLoginRegister": {
		"self": "person login",
		"personId": "",
		"ipAddress": "",
		"isSuccess": "",
	},
	//GeneratorFlag:[PersonRequestDipost.SummrayRequest] {
	"personRequestDipostSummrayRequest": {
		"self": "گزارش درخواست ها",
		"pendingRequestCount": "تعداد کل درانتظار",
		"totalPendingRequestAmount": "مجموع درخواست در انتظار",
		"totalPendingRequestChargeWallet": "درخواست شارژ کیف پول",
		"totalPendingRequestChargeWalletCount": "تعداد شارژ کیف پول در انتظار",
	},
	//GeneratorFlag:[PersonValidation.Validate] {
	"personValidationValidate": {
		"self": "person validation",
		"mobile": "",
		"email": "",
		"countryCode": "",
		"personValidationId": "",
	},
	//GeneratorFlag:[PersonValidation.ValidateCode] {
	"personValidationValidateCode": {
		"self": "person validation",
		"personValidationId": "",
		"userValidationCode": "",
		"validationResult": "",
	},
//GeneratorFlag:[NewEntity]
}