//GeneratorFlag:[NoChange]
export default {
	path: 'team',
	name: 'peopleNet.baseInfo.team.layout',
	redirect: to => {
		return {name: 'peopleNet.team.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-info/team"],
		resourceKey: 'peopleNet.views.team.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[TeamCreate]"
		{
			path: 'create',
			name: 'peopleNet.team.create',
			component: () => import('@/modules/people-net/views/team/team-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.create',
				clientAccessKeys:["/people-net/base-info/team/create"],
				serverAccessKeys:["/people-net/team/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TeamCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.team.createImport',
			component: () => import('@/modules/people-net/views/team/team-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.createImport',
				clientAccessKeys:["/people-net/base-info/team/create-import"],
				serverAccessKeys:["/people-net/team/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TeamDelete]"
		{
			path: 'delete',
			name: 'peopleNet.team.delete',
			component: () => import('@/modules/people-net/views/team/team-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.delete',
				clientAccessKeys:["/people-net/base-info/team/delete"],
				serverAccessKeys:["/people-net/team/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TeamDetail]"
		{
			path: 'detail',
			name: 'peopleNet.team.detail',
			component: () => import('@/modules/people-net/views/team/team-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.detail',
				clientAccessKeys:["/people-net/base-info/team/detail"],
				serverAccessKeys:["/people-net/team/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TeamList]"
		{
			path: 'list',
			name: 'peopleNet.team.list',
			component: () => import('@/modules/people-net/views/team/team-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.list',
				clientAccessKeys:["/people-net/base-info/team/list"],
				serverAccessKeys:["/people-net/team/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[TeamUpdate]"
		{
			path: 'update',
			name: 'peopleNet.team.update',
			component: () => import('@/modules/people-net/views/team/team-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.team.update',
				clientAccessKeys:["/people-net/base-info/team/update"],
				serverAccessKeys:["/people-net/team/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}