//GeneratorFlag:[NoChange]
export default {
	path: 'person-document-type',
	name: 'peopleNet.baseSys.personDocumentType.layout',
	redirect: to => {
		return {name: 'peopleNet.personDocumentType.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-sys/person-document-type"],
		resourceKey: 'peopleNet.views.personDocumentType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonDocumentTypeDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personDocumentType.detail',
			component: () => import('@/modules/people-net/views/person-document-type/person-document-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocumentType.detail',
				clientAccessKeys:["/people-net/base-sys/person-document-type/detail"],
				serverAccessKeys:["/people-net/person-document-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonDocumentTypeList]"
		{
			path: 'list',
			name: 'peopleNet.personDocumentType.list',
			component: () => import('@/modules/people-net/views/person-document-type/person-document-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personDocumentType.list',
				clientAccessKeys:["/people-net/base-sys/person-document-type/list"],
				serverAccessKeys:["/people-net/person-document-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}