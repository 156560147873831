//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity } from '@/core/data'
import { PersonService } from '@/modules/shop/services'; 
export default class InvoiceFilter extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'invoiceFilter';
	schema = ENUMS.SCHEMA_TYPE.NONE;
	resourceKey = 'global.filters';
	props = {
		fromDisplayDate: {
			name: 'fromDisplayDate',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.fromDisplayDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		toDisplayDate: {
			name: 'toDisplayDate',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.toDisplayDate',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.DATE,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.DATE,
				canUseInForm: true,  
				canUseInExcel: true,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		personId: {
			foreignKey: true,
			name: 'personId',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.personId',
			type: ENUMS.PROP_TYPE.INT32,
			data: async (params) =>
			{
				const service =
					new PersonService();
				return await service.list(params);
			},
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: true,  
				canUseInExcel: true,  
				textKey: 'title',
				valueKey: 'personId',
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		rowID: {
			name: 'rowID',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.rowID',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personName: {
			name: 'personName',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.personName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personCode: {
			name: 'personCode',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.personCode',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		totalSell: {
			name: 'totalSell',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.totalSell',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		totalScore: {
			name: 'totalScore',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.totalScore',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		totalInvoice: {
			name: 'totalInvoice',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.totalInvoice',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		savedValue: {
			name: 'savedValue',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.savedValue',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		personalSell: {
			name: 'personalSell',
			resourceKey: 'shop.invoiceGetMyTeamSellByWeek.personalSell',
			type: ENUMS.PROP_TYPE.DOUBLE,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}