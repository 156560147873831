<template>
	<input v-bind:id="prop.name"
		   v-bind:dir="prop.display.direction"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelKeyUp"
		   v-imask="mask"
		   :value="value"
		   type="text"
		   class="form-control"
		   autocomplete="off" />
</template>
<script>
	import { IMaskDirective } from 'vue-imask';
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null,
				mask: {
					mask: '{00}`:{00}',
					placeholderChar: '-',
					lazy: false,
				},
			}
		},

		beforeMount()
		{
			if (this.prop.value) {
				this.value = this.prop.value
			}
		},

		methods: {

			onHandelChange(e)
			{
				this.$emit('change', e.target.value)
			},

			onHandelKeyUp(e) {
				if (e.key === 'Enter' || e.keyCode === 13) {
					this.$emit('submit')
				}
				else {
					this.$emit('keyup', this.value)
				}
			}
		},
		directives: {
			imask: IMaskDirective
		}
	}
</script>
