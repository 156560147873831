//GeneratorFlag:[NoChange]
import Person from './person';
import City from './city';
import PersonEntity from './person-entity';
import PersonRole from './person-role';
import PersonAccess from './person-access';
import PersonLegal from './person-legal';
import PersonReal from './person-real';

export default {Person, City, PersonEntity, PersonRole, PersonAccess, PersonLegal, PersonReal}
export{Person, City, PersonEntity, PersonRole, PersonAccess, PersonLegal, PersonReal}