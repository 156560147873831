<template>
	<div class="bg-light p-3">
		<span>{{ $loc.fromResource('com.task.progressing') }}</span>
		<div class="progress rounded-0 mt-2 mb-1">
			<div class="progress-bar progress-bar-animated" v-bind:style="{'width' : percent}">
			</div>
		</div>
		<table class="table table-light mt-4">
			<tbody>
				<tr>
					<td style="width:250px;">درصد پیشرفت</td>
					<td>{{ value }} درصد</td>
				</tr>
				<tr>
					<td>زمان حدودی باقیمانده تا پایان عملیات</td>
					<td>{{ remainingTasksTime }}</td>
				</tr>
				<tr>
					<td>تعداد رکوردهای در صف</td>
					<td>{{ remainingTasksLength }}</td>
				</tr>
				<tr>
					<td>تعداد رکوردهای موفق</td>
					<td>{{ succeedTasksLength }}</td>
				</tr>
				<tr>
					<td>تعداد رکوردهای ناموفق</td>
					<td>{{ failedTasksLength }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	export default {
		props: {
			'totalTasksLength': {
				type: Number,
				default: 0
			},

			'currentTasksLength': {
				type: Number,
				default: 0
			},


			'succeedTasksLength': {
				type: Number,
				default: 0
			},

			'failedTasksLength': {
				type: Number,
				default: 0
			},

			'remainingMilliseconds': {
				type: Number,
				default: 0
			},
		},

		computed:
		{
			remainingTasksLength()
			{
				return this.totalTasksLength - this.currentTasksLength
			},

			remainingTasksTime()
			{
				var duration =
					this.remainingMilliseconds;

				var milliseconds = parseInt((duration % 1000) / 100),
					seconds = Math.floor((duration / 1000) % 60),
					minutes = Math.floor((duration / (1000 * 60)) % 60),
					hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

				hours = (hours < 10) ? "0" + hours : hours;
				minutes = (minutes < 10) ? "0" + minutes : minutes;
				seconds = (seconds < 10) ? "0" + seconds : seconds;

				return hours + ":" + minutes + ":" + seconds;
			},

			value()
			{
				return Math.round((100 / this.totalTasksLength) * this.currentTasksLength)
			},

			percent()
			{
				return this.value + '%'
			},
		}
	}
</script>
