<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonAppPurchaseType, PersonAppPurchaseTypeFilter } from '@/modules/people-net/data/person-app-purchase-type/list'
	import { PersonAppPurchaseTypeService } from '@/modules/people-net/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonAppPurchaseType;
			},
			
			filterEntity()
			{
				return PersonAppPurchaseTypeFilter;
			},

			service()
			{
				return new PersonAppPurchaseTypeService();
			},

			filterData()
			{
				const personAppPurchaseTypeId =
					this.$route.query['personAppPurchaseTypeId'];

				if (personAppPurchaseTypeId)
				{
					return {
						'personAppPurchaseTypeId': personAppPurchaseTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>