//GeneratorFlag:[NoChange]
export default {
	path: 'person-access',
	name: 'peopleNet.personAccess.layout',
	redirect: to => {
		return {name: 'peopleNet.personAccess.changePassword'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/person-access"],
		resourceKey: 'peopleNet.views.personAccess.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonAccessGenerate]"
		{
			path: 'generate',
			name: 'peopleNet.personAccess.generate',
			component: () => import('@/modules/people-net/views/person-access/person-access-generate.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAccess.generate',
				clientAccessKeys:["/people-net/person-access/generate"],
				serverAccessKeys:["/people-net/person-access/generate"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]

	]
}