//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonNationalityService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonNationality.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person-nationality/create', params || { });
	};

	//GeneratorFlag:[PersonNationality.Delete]
	async delete(personNationalityId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:personNationalityId
		}
	
		return await _server.delete('/hub/person-nationality/delete', {'params':params})
	};

	//GeneratorFlag:[PersonNationality.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/person-nationality/detail', {'params':params});
	};

	//GeneratorFlag:[PersonNationality.List]
	async list(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/person-nationality/list', {'params':params});
	};

	//GeneratorFlag:[PersonNationality.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person-nationality/update', params || { });
	};

//GeneratorFlag:[NewAction]
}