
<template>
	<kaveh-content width="800px">
		<div class="custom-form" v-if="props">
			<div class="mb-3 row">
				<label v-once for="personId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.personId.resourceKey)}}</span>
					<span v-if="isRequired('personId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': personListIsLoading}" v-model="props.personId.value" @change="onHandelChange('personId')" id="personId">
						<option v-for="(item,index) in personList" :value="item.personId" :key="index">
							{{item.personCode}}
						</option>
					</select>
					<small class="text-danger" v-if="props.personId.errors.length > 0">
						{{getFirstError('personId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="totalCommision" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.totalCommision.resourceKey)}}</span>
					<span v-if="isRequired('totalCommision')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.totalCommision.value" @keyup="onHandelKeyUp('totalCommision')" @change="onHandelChange('totalCommision')" dir="ltr" type="number" class="form-control" id="totalCommision" autocomplete="off">
					<small class="text-danger" v-if="props.totalCommision.errors.length > 0">
						{{getFirstError('totalCommision')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="finalCommision" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.finalCommision.resourceKey)}}</span>
					<span v-if="isRequired('finalCommision')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.finalCommision.value" @keyup="onHandelKeyUp('finalCommision')" @change="onHandelChange('finalCommision')" dir="ltr" type="number" class="form-control" id="finalCommision" autocomplete="off">
					<small class="text-danger" v-if="props.finalCommision.errors.length > 0">
						{{getFirstError('finalCommision')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="date" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.date.resourceKey)}}</span>
					<span v-if="isRequired('date')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.date.value" @keyup="onHandelKeyUp('date')" @change="onHandelChange('date')" dir="ltr" type="date" class="form-control" id="date" autocomplete="off">
					<small class="text-danger" v-if="props.date.errors.length > 0">
						{{getFirstError('date')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="planTransactionTypeId" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.planTransactionTypeId.resourceKey)}}</span>
					<span v-if="isRequired('planTransactionTypeId')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<select class="form-select" v-bind:class="{'is-loading': planTransactionTypeListIsLoading}" v-model="props.planTransactionTypeId.value" @change="onHandelChange('planTransactionTypeId')" id="planTransactionTypeId">
						<option v-for="(item,index) in planTransactionTypeList" :value="item.planTransactionTypeId" :key="index">
							{{item.title}}
						</option>
					</select>
					<small class="text-danger" v-if="props.planTransactionTypeId.errors.length > 0">
						{{getFirstError('planTransactionTypeId')}}
					</small>
				</div>
			</div>

			<div class="mb-3 row">
				<label v-once for="caption" class="col-4 col-md-3">
					<span>{{$loc.fromResource(props.caption.resourceKey)}}</span>
					<span v-if="isRequired('caption')" class="text-danger"> * </span>
				</label>
				<div class="col-8 col-md-9">
					<input v-model="props.caption.value" @keyup="onHandelKeyUp('caption')" @change="onHandelChange('caption')" dir="rtl" type="text" class="form-control" id="caption" autocomplete="off">
					<small class="text-danger" v-if="props.caption.errors.length > 0">
						{{getFirstError('caption')}}
					</small>
				</div>
			</div>


		</div>
		<kaveh-button :is-loading="isLoading" @click="submit" class="btn btn-dark">
			{{$loc.fromResource('action.save')}}
		</kaveh-button>
	</kaveh-content>
</template>
<script>
	import validator from '@/core/validator'
	import { PlanTransaction } from '@/modules/shop/data/plan-transaction/register-extra-commision'
	import { PlanTransactionService } from '@/modules/shop/services'
	export default {

		data()
		{
			return {
				props: null,
				isLoading: false,
				useValidator: true,
				personList: [],
				planTransactionTypeList: [],
	
				personListIsLoading: true,
				planTransactionTypeListIsLoading: true,

			}
		},

		async beforeMount()
		{
			this.props = new PlanTransaction().props;
			this.initList('person','personId');
			this.initList('planTransactionType','planTransactionTypeId');

		},

		methods:
		{
			async initList(entityName,propName)
			{
				try {
					this[`${entityName}ListIsLoading`] = true;
					const res = await this.props[propName].data({start:0, length:1000});

					if(res.status == 200){
						this[`${entityName}List`] = res.data.items;
					}

				} catch (e) {
					console.log(e)
				}


				this[`${entityName}ListIsLoading`] = false;
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey,{
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				//if (this.useValidator)
				//{
				//	if (validator.getObjectErrorLength(this.props) > 0)
				//	{
				//		return undefined;
				//	}
				//}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					const prop = this.props[key];

					value[key] =
						prop.setConfig.inputType == 'checkdrop' ?
							prop.value == 'true' : prop.value
				}

				return value
			},

			async submit()
			{
				try
				{
					const payload = this.getValue();
					console.log(payload);
					if (payload)
					{
						this.isLoading = true;
						const service = new PlanTransactionService();
						const res = await service.registerExtraCommision(payload);

						if (res.status == 200)
						{
							this.props = new PlanTransaction().props;
						}
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			}
		}
	}
</script>
