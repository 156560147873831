<template>
	<div class="kaveh-file-uploader" :style="{'background': background}">
		<div class="body">
			<div class="choose" v-if="items.length == 0">
				<div>{{$loc.fromResource('com.uploader.chooseMessage')}}</div>
				<label class="btn btn-outline-dark mt-5" @click="chooseFile">
					{{$loc.fromResource('com.uploader.chooseButton')}}
				</label>
			</div>
			<div class="files" v-else>
				<div class="row pb-3">
					<div class="col pt-2">
						<label>{{$loc.fromResource('com.uploader.count', {'label': items.length})}}</label>
					</div>
					<div class="col text-end">
						<label class="btn btn-light border" @click="chooseFile">
							{{$loc.fromResource('com.uploader.addFile')}}
						</label>
					</div>
				</div>
				<div class="row">
					<div class="col-12" v-for="(item, index) in items" :key="index">
						<div class="file">
							<strong class="me-2">{{ index + 1 }}.</strong>
							<kaveh-icon-bootstrap :name="getIcon(item.type)"
												  color="var(--kaveh-base-color-dark)"
												  class="me-3"
												  size="30px" />
							<span dir="ltr">{{ item.name }}</span>&nbsp;
							<small class="text-muted" dir="ltr">({{ Math.round(item.size/1024) }} kb)</small>
							<button type="button" class="btn float-end" @click="items.splice(index,1)">
								<kaveh-icon-bootstrap color="red" name="bi-trash" size="16px" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="extensions pt-2">
				<small class="text-muted float-start">{{$loc.fromResource('com.uploader.allowExtensions')}}</small>
				<small class="text-muted float-end">{{ joinedExtensions }}</small>
			</div>
		</div>
	</div>
</template>
<script>
	import { toast } from '@/plugins'
	export default {
		emit: ['change', 'update:modelValue'],

		props: {
			background: {
				type: String,
				default: "#f8f8f8",
			}
		},

		data()
		{
			return {
				isloading: false,
				items: [],
				extensions: [
					{
						icon: "bi-filetype-jpg",
						name: "jpg",
						type: "image/jpeg"
					},
					{
						icon: "bi-filetype-jpg",
						name: "jpeg",
						type: "image/jpeg"
					},
					{
						icon: "bi-filetype-png",
						name: "png",
						type: "image/png"
					},
					{
						icon: "bi-filetype-gif",
						name: "gif",
						type: "image/gif"
					},
					{
						icon: "bi-filetype-tiff",
						name: "tiff",
						type: "image/tiff"
					},
					{
						icon: "bi-filetype-mp4",
						name: "mp4",
						type: "video/mp4"
					},
					{
						icon: "bi-filetype-mp3",
						name: "mp3",
						type: "audio/mp3"
					},
					{
						icon: "bi-filetype-txt",
						name: "txt",
						type: "text/plain"
					},
					{
						icon: "bi-filetype-pdf",
						name: "pdf",
						type: "application/pdf"
					},
					{
						icon: "bi-filetype-docx",
						name: "docx",
						type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
					},
					{
						icon: "bi-filetype-doc",
						name: "doc",
						type: "application/msword"
					},
					{
						icon: "bi-filetype-ppt",
						name: "ppt",
						type: "application/vnd.ms-powerpoint"
					},
					{
						icon: "bi-filetype-pptx",
						name: "pptx",
						type: "application/vnd.openxmlformats-officedocument.presentationml.presentation"
					},
					{
						icon: "bi-filetype-xls",
						name: "xls",
						type: "application/vnd.ms-excel"
					},
					{
						icon: "bi-filetype-xlsx",
						name: "xlsx",
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					},
					{
						icon: "bi-file-zip",
						name: "rar",
						type: "application/x-rar"
					},
					{
						icon: "bi-file-zip",
						name: "zip",
						type: "application/x-zip-compressed"
					},
					{
						icon: "bi-file-zip",
						name: "zip",
						type: "application/zip"
					}
				]

			}
		},

		computed:
		{
			joinedExtensions()
			{
				return this.extensions.reduce(function (a, b)
				{
					return (a.name || a) + ", " + b.name
				})
			}
		},

		methods:
		{
			chooseFile()
			{
				var input =
					document.createElement("INPUT");

				input.setAttribute("type", "file");
				input.setAttribute("multiple", "multiple");

				input.onchange = async () =>
				{
					var files = [];
					for (var i = 0; i < input.files.length; i++)
					{
						const file =
							input.files[i];


						if (this.fileIsValid(file) == false)
						{
							const message =
								this.$loc.fromResource(
									'com.uploader.errors.invalidType', { label: file.name }
								);

							toast.error(message);

							continue;
						}

						if (this.fileIsDuplicate(file) == true)
						{
							const message =
								this.$loc.fromResource(
									'com.uploader.errors.duplicate', { label: file.name }
								);

							toast.error(message);

							continue;
						}

						files.push({
							name: file.name,
							type: file.type,
							size: file.size,
							dataUrl: await this.toDataUrl(file)
						});

					}

					this.items = [...this.items, ...files];

					const value = this.getValue();
					this.$emit('update:modelValue', value);
					this.$emit('change', value)
				}

				input.click();
			},

			fileIsValid(file)
			{
				const index = this.extensions.findIndex((x) =>
				{
					return x.type == file.type;

				});

				return index > -1;
			},

			fileIsDuplicate(file)
			{
				const index = this.items.findIndex((x) =>
				{
					return x.type == file.type && x.name == file.name && x.size == file.size;
				});

				return index > -1;
			},

			getIcon(type)
			{
				const extention = this.extensions.find((x) =>
				{
					return x.type == type
				});

				return extention ? extention.icon : "";
			},

			async toDataUrl(file)
			{
				return new Promise((resolve, reject) =>
				{
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = error => reject(error);
				});
			},


			getValue()
			{
				var value = [];

				for (var i = 0; i < this.items.length; i++)
				{
					const item = this.items[i];

					value.push({
						fileName: item.name,
						contentType: item.type,
						contentLength: item.size,
						dataUrl: item.dataUrl
					})
				}

				return this.items;
			}
		}
	}
</script>
<style>

	.kaveh-file-uploader,
	.kaveh-file-uploader .body .choose,
	.kaveh-file-uploader .body .files {
		width: 100%;
	}

		.kaveh-file-uploader .body {
			min-height: 300px;
			border: #ddd solid 1px;
			background: #fff;
			border-radius:5px;
		}

		.kaveh-file-uploader .body {
		}

			.kaveh-file-uploader .body .choose {
				color: #000;
				padding-top: 100px;
				text-align: center;
				font-size: 15px;
			}

				.kaveh-file-uploader .body .choose .btn {
					padding: 10px 30px;
				}

			.kaveh-file-uploader .body .files,
			.kaveh-file-uploader .body .files .file {
				padding: 10px;
			}

				.kaveh-file-uploader .body .files .file {
					min-width: 100%;
					min-height: 40px;
					margin-bottom: 10px;
					background: #f8f8f8;
				}
</style>
