<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { WalletTransaction, WalletTransactionFilter } from '@/modules/financial/data/wallet-transaction/list'
	import { WalletTransactionService } from '@/modules/financial/services'
	export default {
		computed:
		{
			entity()
			{
				return WalletTransaction;
			},
			
			filterEntity()
			{
				return WalletTransactionFilter;
			},

			service()
			{
				return new WalletTransactionService();
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				try
				{
					return await this.service.financial(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>