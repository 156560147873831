//GeneratorFlag:[NoChange]
export default {
	path: 'person-gender',
	name: 'hub.baseSys.personGender.layout',
	redirect: to => {
		return {name: 'hub.personGender.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-sys/person-gender"],
		resourceKey: 'hub.views.personGender.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonGenderDetail]"
		{
			path: 'detail',
			name: 'hub.personGender.detail',
			component: () => import('@/modules/hub/views/person-gender/person-gender-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personGender.detail',
				clientAccessKeys:["/hub/base-sys/person-gender/detail"],
				serverAccessKeys:["/hub/person-gender/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonGenderList]"
		{
			path: 'list',
			name: 'hub.personGender.list',
			component: () => import('@/modules/hub/views/person-gender/person-gender-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personGender.list',
				clientAccessKeys:["/hub/base-sys/person-gender/list"],
				serverAccessKeys:["/hub/person-gender/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}