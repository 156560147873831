<template>
	<div class="kaveh-collapse row">
		<div class="col">
			<div class="collapse-header" @click="onHandelClick">
				<kaveh-icon-bootstrap size="14px" v-bind:name="getIconName()" class="me-2" />
				<slot name="header"></slot>
			</div>
			<div class="collapse-body" v-if="open">
				<slot name="body"></slot>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		props: {
			collapsed: {
				type: Boolean,
				default: false
			}
		},

		data()
		{
			return {
				open: this.collapsed
			}
		},

		computed:
		{
			direction()
			{
				return this.$store.state.app.direction
			},
		},

		methods:
		{
			getIconName()
			{
				if (this.direction == 'rtl')
				{
					return this.open ? 'bi-arrow-90deg-down' : 'bi-arrow-90deg-up'
				}

				return this.open ? 'bi-arrow-90deg-down' : 'bi-arrow-90deg-up'
			},

			onHandelClick()
			{
				//if (!this.collapsed) { this.open = !this.open }
				this.open = !this.open
			}
		}

	}
</script>

<style scoped>

		.kaveh-collapse .collapse-header,
		.kaveh-collapse .collapse-body {
			float: left;
			width: 100%;
			min-height: 40px;
		}

		.kaveh-collapse .collapse-header {
			height: 30px;
			padding-top: 10px;
			cursor: pointer;
			user-select: none;
			margin-top: 3px;
			font-size: 15px;
			transition: all .3s;
		}

			.kaveh-collapse .collapse-header * {
				margin-left: 10px;
			}

		.kaveh-collapse .collapse-body {
			padding: 20px 0;
		}
</style>
