//GeneratorFlag:[NoChange]
export default {
	path: 'person-nationality',
	name: 'hub.personNationality.layout',
	redirect: to => {
		return {name: 'hub.personNationality.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-sys/person-nationality"],
		resourceKey: 'hub.views.personNationality.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonNationalityDetail]"
		{
			path: 'detail',
			name: 'hub.personNationality.detail',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.detail',
				clientAccessKeys:["/hub/base-sys/person-nationality/detail"],
				serverAccessKeys:["/hub/person-nationality/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityList]"
		{
			path: 'list',
			name: 'hub.personNationality.list',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.list',
				clientAccessKeys:["/hub/base-sys/person-nationality/list"],
				serverAccessKeys:["/hub/person-nationality/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}