//GeneratorFlag:[NoChange]
export default {
	path: 'person-app-purchase',
	name: 'peopleNet.personAppPurchase.layout',
	redirect: to => {
		return {name: 'peopleNet.personAppPurchase.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/person-app-purchase"],
		resourceKey: 'peopleNet.views.personAppPurchase.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonAppPurchaseCreate]"
		{
			path: 'create',
			name: 'peopleNet.personAppPurchase.create',
			component: () => import('@/modules/people-net/views/person-app-purchase/person-app-purchase-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchase.create',
				clientAccessKeys:["/people-net/person-app-purchase/create"],
				serverAccessKeys:["/people-net/person-app-purchase/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.personAppPurchase.createImport',
			component: () => import('@/modules/people-net/views/person-app-purchase/person-app-purchase-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchase.createImport',
				clientAccessKeys:["/people-net/person-app-purchase/create-import"],
				serverAccessKeys:["/people-net/person-app-purchase/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseDelete]"
		{
			path: 'delete',
			name: 'peopleNet.personAppPurchase.delete',
			component: () => import('@/modules/people-net/views/person-app-purchase/person-app-purchase-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchase.delete',
				clientAccessKeys:["/people-net/person-app-purchase/delete"],
				serverAccessKeys:["/people-net/person-app-purchase/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personAppPurchase.detail',
			component: () => import('@/modules/people-net/views/person-app-purchase/person-app-purchase-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchase.detail',
				clientAccessKeys:["/people-net/person-app-purchase/detail"],
				serverAccessKeys:["/people-net/person-app-purchase/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseList]"
		{
			path: 'list',
			name: 'peopleNet.personAppPurchase.list',
			component: () => import('@/modules/people-net/views/person-app-purchase/person-app-purchase-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchase.list',
				clientAccessKeys:["/people-net/person-app-purchase/list"],
				serverAccessKeys:["/people-net/person-app-purchase/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonAppPurchaseUpdate]"
		{
			path: 'update',
			name: 'peopleNet.personAppPurchase.update',
			component: () => import('@/modules/people-net/views/person-app-purchase/person-app-purchase-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personAppPurchase.update',
				clientAccessKeys:["/people-net/person-app-purchase/update"],
				serverAccessKeys:["/people-net/person-app-purchase/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}