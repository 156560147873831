//GeneratorFlag:[NoChange]
export default {
	path: 'invoice-cancel',
	name: 'shop.invoiceCancel.layout',
	redirect: to => {
		return {name: 'shop.invoiceCancel.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/invoice-cancel"],
		resourceKey: 'shop.views.invoiceCancel.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[InvoiceCancelCreate]"
		{
			path: 'create',
			name: 'shop.invoiceCancel.create',
			component: () => import('@/modules/shop/views/invoice-cancel/invoice-cancel-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceCancel.create',
				clientAccessKeys:["/shop/invoice-cancel/create"],
				serverAccessKeys:["/shop/invoice-cancel/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCancelCreateImport]"
		{
			path: 'create-import',
			name: 'shop.invoiceCancel.createImport',
			component: () => import('@/modules/shop/views/invoice-cancel/invoice-cancel-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceCancel.createImport',
				clientAccessKeys:["/shop/invoice-cancel/create-import"],
				serverAccessKeys:["/shop/invoice-cancel/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCancelDelete]"
		{
			path: 'delete',
			name: 'shop.invoiceCancel.delete',
			component: () => import('@/modules/shop/views/invoice-cancel/invoice-cancel-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceCancel.delete',
				clientAccessKeys:["/shop/invoice-cancel/delete"],
				serverAccessKeys:["/shop/invoice-cancel/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCancelDetail]"
		{
			path: 'detail',
			name: 'shop.invoiceCancel.detail',
			component: () => import('@/modules/shop/views/invoice-cancel/invoice-cancel-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceCancel.detail',
				clientAccessKeys:["/shop/invoice-cancel/detail"],
				serverAccessKeys:["/shop/invoice-cancel/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCancelList]"
		{
			path: 'list',
			name: 'shop.invoiceCancel.list',
			component: () => import('@/modules/shop/views/invoice-cancel/invoice-cancel-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceCancel.list',
				clientAccessKeys:["/shop/invoice-cancel/list"],
				serverAccessKeys:["/shop/invoice-cancel/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoiceCancelUpdate]"
		{
			path: 'update',
			name: 'shop.invoiceCancel.update',
			component: () => import('@/modules/shop/views/invoice-cancel/invoice-cancel-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoiceCancel.update',
				clientAccessKeys:["/shop/invoice-cancel/update"],
				serverAccessKeys:["/shop/invoice-cancel/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}