//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"ticketing",
	"ext": ext,
	"layout":{
		"baseInfo": "اطلاعات پایه", //GeneratorFlag:[BaseInfoLayout]
		"baseSys": "اطلاعات سیستمی", //GeneratorFlag:[BaseSysLayout]
		"administrator": "عملیات مدیر", //GeneratorFlag:[AdministratorLayout]
//GeneratorFlag:[NewLayout]
	},
	"views": {
		"ticket": {
			"layout": "تیکت ها",
			"create": "ثبت جدید تیکت", //GeneratorFlag:[Views.Ticket.Create]
			'createImport': 'ثبت تیکت ها از طریق اکسل', //GeneratorFlag:[Views.Ticket.CreateImport] 
 			'delete': 'حذف تیکت', //GeneratorFlag:[Views.Ticket.Delete] 
 			'detail': 'جزئیات اطلاعات تیکت', //GeneratorFlag:[Views.Ticket.Detail] 
 			'list': 'فهرست تیکت ها', //GeneratorFlag:[Views.Ticket.List] 
 			'update': 'ویرایش تیکت', //GeneratorFlag:[Views.Ticket.Update] 
 			'adminList': 'تیکت های مشتری', //GeneratorFlag:[Views.Ticket.AdminList] 
 			'close': 'بستن تیکت', //GeneratorFlag:[Views.Ticket.Close] 
 			'getItems': 'لیست مکالمات', //GeneratorFlag:[Views.Ticket.GetItems] 
 			'myList': 'تیکت های من', //GeneratorFlag:[Views.Ticket.MyList] 
 			'send': 'ارسال تیکت', //GeneratorFlag:[Views.Ticket.Send] 
 //GeneratorFlag:[Views.Ticket]
		},
		"ticketItem": {
			"layout": "پاسخ های تیکت",
			"create": "ثبت جدید پاسخ تیکت", //GeneratorFlag:[Views.TicketItem.Create]
			'createImport': 'ثبت پاسخ های تیکت از طریق اکسل', //GeneratorFlag:[Views.TicketItem.CreateImport] 
 			'delete': 'حذف پاسخ تیکت', //GeneratorFlag:[Views.TicketItem.Delete] 
 			'detail': 'جزئیات اطلاعات پاسخ تیکت', //GeneratorFlag:[Views.TicketItem.Detail] 
 			'list': 'فهرست پاسخ های تیکت', //GeneratorFlag:[Views.TicketItem.List] 
 			'update': 'ویرایش پاسخ تیکت', //GeneratorFlag:[Views.TicketItem.Update] 
 			'adminResponse': 'ارسال پاسخ', //GeneratorFlag:[Views.TicketItem.AdminResponse] 
 			'response': 'ارسال پاسخ', //GeneratorFlag:[Views.TicketItem.Response] 
 //GeneratorFlag:[Views.TicketItem]
		},
		"ticketDepartment": {
			"layout": " بخش تیکت",
			"create": "ثبت جدید بخش تیکت", //GeneratorFlag:[Views.TicketDepartment.Create]
			'createImport': 'ثبت بخش تیکت از طریق اکسل', //GeneratorFlag:[Views.TicketDepartment.CreateImport] 
 			'delete': 'حذف بخش تیکت', //GeneratorFlag:[Views.TicketDepartment.Delete] 
 			'detail': 'جزئیات اطلاعات بخش تیکت', //GeneratorFlag:[Views.TicketDepartment.Detail] 
 			'list': 'فهرست بخش تیکت', //GeneratorFlag:[Views.TicketDepartment.List] 
 			'update': 'ویرایش بخش تیکت', //GeneratorFlag:[Views.TicketDepartment.Update] 
 //GeneratorFlag:[Views.TicketDepartment]
		},
		"culture": {
			"layout": "زبان",
			"detail": "جزئیات اطلاعات زبان", //GeneratorFlag:[Views.Culture.Detail]
			'list': 'فهرست زبان', //GeneratorFlag:[Views.Culture.List] 
 //GeneratorFlag:[Views.Culture]
		},
		"ticketStatuse": {
			"layout": "وضعیت تیکت",
			"detail": "جزئیات اطلاعات وضعیت تیکت", //GeneratorFlag:[Views.TicketStatuse.Detail]
			'list': 'فهرست وضعیت تیکت', //GeneratorFlag:[Views.TicketStatuse.List] 
 //GeneratorFlag:[Views.TicketStatuse]
		},
//GeneratorFlag:[NewView]
	},
	//GeneratorFlag:[Ticket.Create] {
	"ticketCreate": {
		"self": "تیکت",
		"ticketId": "شناسه",
		"personId": "عضو",
		"personInfo": "مشخصات",
		"email": "ایمیل",
		"ticketStatuseId": "وضعیت",
		"title": "عنوان",
		"lastUpdate": "آخرین تغییر",
		"displayLastUpdate": "آخرین تغییر",
	},
	//GeneratorFlag:[Ticket.Delete] {
	"ticketDelete": {
		"self": "تیکت",
		"ticketId": "شناسه",
	},
	//GeneratorFlag:[Ticket.Detail] {
	"ticketDetail": {
		"self": "تیکت",
		"ticketId": "شناسه",
		"personId": "عضو",
		"personInfo": "مشخصات",
		"email": "ایمیل",
		"ticketStatuseId": "وضعیت",
		"title": "عنوان",
		"lastUpdate": "آخرین تغییر",
		"displayLastUpdate": "آخرین تغییر",
		"person": {
			"self": "عضو",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticketStatuse": {
			"self": "وضعیت",
			"ticketStatuseId": "شناسه",
			"ticketStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"ticketSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[Ticket.List] {
	"ticketList": {
		"self": "تیکت",
		"ticketId": "شناسه",
		"personId": "عضو",
		"personInfo": "مشخصات",
		"email": "ایمیل",
		"ticketStatuseId": "وضعیت",
		"title": "عنوان",
		"lastUpdate": "آخرین تغییر",
		"displayLastUpdate": "آخرین تغییر",
		"person": {
			"self": "عضو",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticketStatuse": {
			"self": "وضعیت",
			"ticketStatuseId": "شناسه",
			"ticketStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"ticketSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[Ticket.Filter] {
	"ticketFilter": {
		"self": "تیکت",
		"ticketId": "شناسه",
		"personId": "عضو",
		"personInfo": "مشخصات",
		"email": "ایمیل",
		"ticketStatuseId": "وضعیت",
		"title": "عنوان",
		"lastUpdate": "آخرین تغییر",
		"displayLastUpdate": "آخرین تغییر",
		"person": {
			"self": "عضو",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticketStatuse": {
			"self": "وضعیت",
			"ticketStatuseId": "شناسه",
			"ticketStatuseKey": "کلید",
			"cultureId": "زبان",
			"title": "عنوان",
			"isOrigin": "ریشه",
		},
		"ticketSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[Ticket.Update] {
	"ticketUpdate": {
		"self": "تیکت",
		"ticketId": "شناسه",
		"personId": "عضو",
		"personInfo": "مشخصات",
		"email": "ایمیل",
		"ticketStatuseId": "وضعیت",
		"title": "عنوان",
		"lastUpdate": "آخرین تغییر",
		"displayLastUpdate": "آخرین تغییر",
	},
	//GeneratorFlag:[TicketItem.Create] {
	"ticketItemCreate": {
		"self": "پاسخ تیکت",
		"ticketItemId": "شناسه",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
	},
	//GeneratorFlag:[TicketItem.Delete] {
	"ticketItemDelete": {
		"self": "پاسخ تیکت",
		"ticketItemId": "شناسه",
	},
	//GeneratorFlag:[TicketItem.Detail] {
	"ticketItemDetail": {
		"self": "پاسخ تیکت",
		"ticketItemId": "شناسه",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
		"person": {
			"self": "PersonId",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticket": {
			"self": "TicketId",
			"ticketId": "شناسه",
			"personId": "عضو",
			"personInfo": "مشخصات",
			"email": "ایمیل",
			"ticketStatuseId": "وضعیت",
			"title": "عنوان",
			"lastUpdate": "آخرین تغییر",
			"displayLastUpdate": "آخرین تغییر",
		},
		"tciketItemSubInfo": "اطلاعات تکمیلی",
		"ticketItemSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[TicketItem.List] {
	"ticketItemList": {
		"self": "پاسخ تیکت",
		"ticketItemId": "شناسه",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
		"person": {
			"self": "PersonId",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticket": {
			"self": "TicketId",
			"ticketId": "شناسه",
			"personId": "عضو",
			"personInfo": "مشخصات",
			"email": "ایمیل",
			"ticketStatuseId": "وضعیت",
			"title": "عنوان",
			"lastUpdate": "آخرین تغییر",
			"displayLastUpdate": "آخرین تغییر",
		},
		"tciketItemSubInfo": "اطلاعات تکمیلی",
		"ticketItemSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[TicketItem.Filter] {
	"ticketItemFilter": {
		"self": "پاسخ تیکت",
		"ticketItemId": "شناسه",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
		"person": {
			"self": "PersonId",
			"personId": "person id",
			"firstName": "first name",
			"lastName": "last name",
			"fullName": "full name",
			"email": "email",
			"mobile": "mobile",
			"nationalCode": "national code",
			"personCode": "person code",
		},
		"ticket": {
			"self": "TicketId",
			"ticketId": "شناسه",
			"personId": "عضو",
			"personInfo": "مشخصات",
			"email": "ایمیل",
			"ticketStatuseId": "وضعیت",
			"title": "عنوان",
			"lastUpdate": "آخرین تغییر",
			"displayLastUpdate": "آخرین تغییر",
		},
		"tciketItemSubInfo": "اطلاعات تکمیلی",
		"ticketItemSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[TicketItem.Update] {
	"ticketItemUpdate": {
		"self": "پاسخ تیکت",
		"ticketItemId": "شناسه",
		"ticketId": "ticket",
		"personId": "person",
		"caption": "caption",
	},
	//GeneratorFlag:[TicketDepartment.Create] {
	"ticketDepartmentCreate": {
		"self": "بخش تیکت",
		"ticketDepartmentId": "شناسه",
		"ticketDepartmentKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[TicketDepartment.Delete] {
	"ticketDepartmentDelete": {
		"self": "بخش تیکت",
		"ticketDepartmentId": "شناسه",
	},
	//GeneratorFlag:[TicketDepartment.Detail] {
	"ticketDepartmentDetail": {
		"self": "بخش تیکت",
		"ticketDepartmentId": "شناسه",
		"ticketDepartmentKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
		"ticketDepartmentSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[TicketDepartment.List] {
	"ticketDepartmentList": {
		"self": "بخش تیکت",
		"ticketDepartmentId": "شناسه",
		"ticketDepartmentKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
		"ticketDepartmentSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[TicketDepartment.Filter] {
	"ticketDepartmentFilter": {
		"self": "بخش تیکت",
		"ticketDepartmentId": "شناسه",
		"ticketDepartmentKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
		"ticketDepartmentSubInfo": "اطلاعات تکمیلی",
	},
	//GeneratorFlag:[TicketDepartment.Update] {
	"ticketDepartmentUpdate": {
		"self": "بخش تیکت",
		"ticketDepartmentId": "شناسه",
		"ticketDepartmentKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
	},
	//GeneratorFlag:[Culture.Detail] {
	"cultureDetail": {
		"self": "زبان",
		"cultureId": "شناسه",
		"cultureKey": "کلید",
		"title": "عنوان",
		"cultureSymbol": "سیمبل",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "نشانه دو حرفی",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "واحد طول",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "منطقه زمانی",
	},
	//GeneratorFlag:[Culture.List] {
	"cultureList": {
		"self": "زبان",
		"cultureId": "شناسه",
		"cultureKey": "کلید",
		"title": "عنوان",
		"cultureSymbol": "سیمبل",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "نشانه دو حرفی",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "واحد طول",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "منطقه زمانی",
	},
	//GeneratorFlag:[Culture.Filter] {
	"cultureFilter": {
		"self": "زبان",
		"cultureId": "شناسه",
		"cultureKey": "کلید",
		"title": "عنوان",
		"cultureSymbol": "سیمبل",
		"cultureFlagImagePath": "پرچم",
		"towSymbolCulture": "نشانه دو حرفی",
		"currency": "واحد پولی",
		"weightUnit": "واحد وزن",
		"widthUnit": "واحد عرض",
		"lengthUnit": "واحد طول",
		"heightUnit": "واحد ارتفاع",
		"standardTimeZoneId": "منطقه زمانی",
	},
	//GeneratorFlag:[TicketStatuse.Detail] {
	"ticketStatuseDetail": {
		"self": "وضعیت تیکت",
		"ticketStatuseId": "شناسه",
		"ticketStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
	},
	//GeneratorFlag:[TicketStatuse.List] {
	"ticketStatuseList": {
		"self": "وضعیت تیکت",
		"ticketStatuseId": "شناسه",
		"ticketStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
	},
	//GeneratorFlag:[TicketStatuse.Filter] {
	"ticketStatuseFilter": {
		"self": "وضعیت تیکت",
		"ticketStatuseId": "شناسه",
		"ticketStatuseKey": "کلید",
		"cultureId": "زبان",
		"title": "عنوان",
		"isOrigin": "ریشه",
		"culture": {
			"self": "زبان",
			"cultureId": "شناسه",
			"cultureKey": "کلید",
			"title": "عنوان",
			"cultureSymbol": "سیمبل",
			"cultureFlagImagePath": "پرچم",
			"towSymbolCulture": "نشانه دو حرفی",
			"currency": "واحد پولی",
			"weightUnit": "واحد وزن",
			"widthUnit": "واحد عرض",
			"lengthUnit": "واحد طول",
			"heightUnit": "واحد ارتفاع",
			"standardTimeZoneId": "منطقه زمانی",
		},
	},
	//GeneratorFlag:[Ticket.AdminList] {
	"ticketAdminList": {
		"self": "تیکت های مشتری",
		"ticketId": "شناسه",
		"title": "عنوان",
		"memberName": "عضو",
		"ticketStatuseTitle": "وضعیت",
	},
	//GeneratorFlag:[Ticket.Close] {
	"ticketClose": {
		"self": "بستن تیکت",
		"ticketId": "شناسه تیکت",
	},
	//GeneratorFlag:[Ticket.GetItems] {
	"ticketGetItems": {
		"self": "لیست مکالمات",
		"ticketId": "شناسه تیکت",
		"ticketItemId": "شناسه",
		"caption": "توضیحات",
		"creator": "توسط",
	},
	//GeneratorFlag:[Ticket.MyList] {
	"ticketMyList": {
		"self": "تیکت های من",
		"ticketId": "شناسه",
		"title": "عنوان",
		"ticketStatuseKey": "شناسه نوع تیکت",
		"ticketStatuseTitle": "وضعیت",
	},
	//GeneratorFlag:[Ticket.Send] {
	"ticketSend": {
		"self": "ارسال تیکت",
		"personInfo": "مشخصات",
		"email": "ایمیل",
		"title": "عنوان",
		"caption": "توضیحات",
	},
	//GeneratorFlag:[TicketItem.AdminResponse] {
	"ticketItemAdminResponse": {
		"self": "ارسال پاسخ",
		"ticketId": "شناسه تیکت",
		"message": "پاسخ",
	},
	//GeneratorFlag:[TicketItem.Response] {
	"ticketItemResponse": {
		"self": "ارسال پاسخ",
		"ticketId": "شناسه تیکت",
		"message": "پاسخ",
		"email": "ایمیل",
	},
//GeneratorFlag:[NewEntity]
}