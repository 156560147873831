<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { NoticeStatus, NoticeStatusFilter } from '@/modules/hub/data/notice-status/list'
	import { NoticeStatusService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return NoticeStatus;
			},
			
			filterEntity()
			{
				return NoticeStatusFilter;
			},

			service()
			{
				return new NoticeStatusService();
			},

			filterData()
			{
				const noticeStatusId =
					this.$route.query['noticeStatusId'];

				if (noticeStatusId)
				{
					return {
						'noticeStatusId': noticeStatusId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>