<template>
	<div v-if="mode == 'generator'" class="row mb-3">
		<div class="col-12">
			<div class="bg-light border border-2 p-2 cursor-pointer"
				 v-bind:class="[generatorConfig.selected == true ? 'border-info': 'border-white' ]"
				 v-on:click="handelGeneratorEvent($event, 'select')">
				<div class="mb-3">
					<form-label :use-validation="useValidation" :prop="prop" />
					<button class="btn btn-sm bg-white border me-1 float-end" @click="handelGeneratorEvent($event, 'remove')">
						<kaveh-icon-bootstrap color="red" name="bi-trash" size="15px" />
					</button>
					<button v-if="isShowUp" class="btn btn-sm bg-white border me-1 float-end" @click="handelGeneratorEvent($event, 'up')">
						<kaveh-icon-bootstrap name="bi-arrow-up" size="15px" />
					</button>
					<button v-if="isShowDown" class="btn btn-sm bg-white border me-1 float-end" @click="handelGeneratorEvent($event, 'down')">
						<kaveh-icon-bootstrap name="bi-arrow-down" size="15px" />
					</button>
				</div>
				<div class="pe-none">
					<slot></slot>
					<form-error :prop="prop" />
				</div>
			</div>
		</div>

	</div>
	<div v-else class="row mb-1 mb-md-2">
		<div :for="prop.name" class="col-12 mb-1">
			<form-label :use-validation="useValidation" :prop="prop" />
		</div>
		<div class="col-12">
			<slot></slot>
			<form-error :prop="prop" />
		</div>
	</div>
</template>

<script>
	import formError from './form-error'
	import formLabel from './form-label'

	export default {
		emits: ['generator-remove', 'generator-up', 'generator-down', 'generator-select'],

		components: {
			'form-error': formError,
			'form-label': formLabel
		},

		props:
		{
			'prop': {
				type: Object
			},

			'mode': {
				type: String
			},

			'useValidation': {
				type: Boolean,
				default: true
			}
		},

		computed: {
			generatorConfig() {
				return this.prop.generatorConfig || {}
			},

			isShowUp() {

				if (this.generatorConfig.isFirst && this.generatorConfig.isLast) {
					return false;
				}

				return this.generatorConfig.isFirst == false
			},

			isShowDown() {

				if (this.generatorConfig.isFirst && this.generatorConfig.isLast) {
					return false;
				}

				return this.generatorConfig.isLast == false
			},

		},

		methods: {

			handelGeneratorEvent(e, name) {
				e.stopPropagation()
				this.$emit(`generator-${name}`, this.prop.name);
			}
		}
	}
</script>
