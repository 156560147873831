//GeneratorFlag:[NoChange]
export default {
	path: 'product-doc',
	name: 'shop.productDoc.layout',
	redirect: to => {
		return {name: 'shop.productDoc.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/product-doc"],
		resourceKey: 'shop.views.productDoc.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[ProductDocCreate]"
		{
			path: 'create',
			name: 'shop.productDoc.create',
			component: () => import('@/modules/shop/views/product-doc/product-doc-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDoc.create',
				clientAccessKeys:["/shop/product-doc/create"],
				serverAccessKeys:["/shop/product-doc/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDocCreateImport]"
		{
			path: 'create-import',
			name: 'shop.productDoc.createImport',
			component: () => import('@/modules/shop/views/product-doc/product-doc-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDoc.createImport',
				clientAccessKeys:["/shop/product-doc/create-import"],
				serverAccessKeys:["/shop/product-doc/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDocDelete]"
		{
			path: 'delete',
			name: 'shop.productDoc.delete',
			component: () => import('@/modules/shop/views/product-doc/product-doc-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDoc.delete',
				clientAccessKeys:["/shop/product-doc/delete"],
				serverAccessKeys:["/shop/product-doc/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDocDetail]"
		{
			path: 'detail',
			name: 'shop.productDoc.detail',
			component: () => import('@/modules/shop/views/product-doc/product-doc-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDoc.detail',
				clientAccessKeys:["/shop/product-doc/detail"],
				serverAccessKeys:["/shop/product-doc/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDocList]"
		{
			path: 'list',
			name: 'shop.productDoc.list',
			component: () => import('@/modules/shop/views/product-doc/product-doc-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDoc.list',
				clientAccessKeys:["/shop/product-doc/list"],
				serverAccessKeys:["/shop/product-doc/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[ProductDocUpdate]"
		{
			path: 'update',
			name: 'shop.productDoc.update',
			component: () => import('@/modules/shop/views/product-doc/product-doc-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.productDoc.update',
				clientAccessKeys:["/shop/product-doc/update"],
				serverAccessKeys:["/shop/product-doc/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}