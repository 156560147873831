//GeneratorFlag:[NoChange]
export default {
	path: 'country',
	name: 'hub.country.layout',
	redirect: to => {
		return {name: 'hub.country.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/country"],
		resourceKey: 'hub.views.country.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[CountryCreate]"
		{
			path: 'create',
			name: 'hub.country.create',
			component: () => import('@/modules/hub/views/country/country-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.country.create',
				clientAccessKeys:["/hub/country/create"],
				serverAccessKeys:["/hub/country/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryImport]"
		{
			path: 'import',
			name: 'hub.country.import',
			component: () => import('@/modules/hub/views/country/country-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.country.import',
				clientAccessKeys:["/hub/country/import"],
				serverAccessKeys:["/hub/country/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryDelete]"
		{
			path: 'delete',
			name: 'hub.country.delete',
			component: () => import('@/modules/hub/views/country/country-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.country.delete',
				clientAccessKeys:["/hub/country/delete"],
				serverAccessKeys:["/hub/country/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryDetail]"
		{
			path: 'detail',
			name: 'hub.country.detail',
			component: () => import('@/modules/hub/views/country/country-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.country.detail',
				clientAccessKeys:["/hub/country/detail"],
				serverAccessKeys:["/hub/country/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryList]"
		{
			path: 'list',
			name: 'hub.country.list',
			component: () => import('@/modules/hub/views/country/country-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.country.list',
				clientAccessKeys:["/hub/country/list"],
				serverAccessKeys:["/hub/country/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CountryUpdate]"
		{
			path: 'update',
			name: 'hub.country.update',
			component: () => import('@/modules/hub/views/country/country-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.country.update',
				clientAccessKeys:["/hub/country/update"],
				serverAccessKeys:["/hub/country/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}