<template>
	<div class="kaveh-camera">
		<div class="camera-button mb-2">
			<button type="button" class="btn rounded" v-bind:class="{ 'btn-primary' : !isCameraOpen, 'btn-danger' : isCameraOpen}" @click="toggleCamera">
				<span v-if="!isCameraOpen">{{$loc.fromResource('com.camera.open')}}</span>
				<span v-else>{{$loc.fromResource('com.camera.close')}}</span>
			</button>
		</div>

		<div v-show="isCameraOpen && isLoading" class="mt-3">
			<div class="spinner-border" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>

		<div v-if="isCameraOpen" v-show="!isLoading" class="camera-box" v-bind:class="{ 'flash' : isShotPhoto }">
			<div class="camera-shutter" v-bind:class="{'flash' : isShotPhoto}"></div>
			<video v-show="!isPhotoTaken" ref="camera" v-bind:width="width" v-bind:height="height" autoplay></video>
			<canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" v-bind:width="width" v-bind:height="height"></canvas>
		</div>

		<div v-if="isCameraOpen && !isLoading">
			<button type="button" class="btn btn-dark" @click="takePhoto">
				<kaveh-icon-bootstrap name="bi-camera2" size="15px" class="me-2" />
				<span>{{$loc.fromResource('com.camera.take')}}</span>
			</button>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['take'],

		props: {
			width: {
				type: String,
				default:'400'
			},
			height: {
				type: String,
				default: '300'
			},
		},

		data()
		{
			return {
				isCameraOpen: false,
				isPhotoTaken: false,
				isShotPhoto: false,
				isLoading: false,
			}
		},

		methods: {
			toggleCamera()
			{
				if (this.isCameraOpen)
				{
					this.isCameraOpen = false;
					this.isPhotoTaken = false;
					this.isShotPhoto = false;
					this.stopCameraStream();
				} else
				{
					this.isCameraOpen = true;
					this.createCameraElement();
				}
			},

			createCameraElement()
			{
				this.isLoading = true;

				const constraints = (window.constraints = {
					audio: false,
					video: true
				});


				navigator.mediaDevices
					.getUserMedia(constraints)
					.then(stream =>
					{
						this.isLoading = false;
						this.$refs.camera.srcObject = stream;
					})
					.catch(error =>
					{
						this.isLoading = false;
						alert("May the browser didn't support or there is some errors.");
					});
			},

			stopCameraStream()
			{
				let tracks = this.$refs.camera.srcObject.getTracks();

				tracks.forEach(track =>
				{
					track.stop();
				});
			},

			takePhoto()
			{
				if (!this.isPhotoTaken)
				{
					this.isShotPhoto = true;

					const FLASH_TIMEOUT = 50;

					setTimeout(() =>
					{
						this.isShotPhoto = false;
					}, FLASH_TIMEOUT);
				}

				this.isPhotoTaken = !this.isPhotoTaken;

				const context = this.$refs.canvas.getContext('2d');
				context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);


				const dataUrl = this.$refs.canvas.toDataURL("image/jpeg");
				this.$emit('take', dataUrl)
			},
		}
	}
</script>
<style>
	.kaveh-camera {
		margin: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}


		.kaveh-camera .camera-box .camera-shutter {
			opacity: 0;
			background-color: #fff;
			position: absolute;
		}

			.kaveh-camera .camera-box .camera-shutter.flash {
				opacity: 1;
			}
</style>
