<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-content width="800px">
		<div class="custom-form" v-if="props">

		</div>
		<kaveh-button :is-loading="isLoading" @click="submit" class="btn btn-dark">
			{{$loc.fromResource('action.save')}}
		</kaveh-button>
	</kaveh-content>
</template>
<script>
	import validator from '@/core/validator'
	import { WalletWithDraw } from '@/modules/financial/data/wallet-with-draw/total-request'
	import { WalletWithDrawService } from '@/modules/financial/services'
	export default {

		data()
		{
			return {
				props: null,
				isLoading: false,
				useValidator: true,
	

			}
		},

		async beforeMount()
		{
			this.props = new WalletWithDraw().props;

		},

		methods:
		{
			async initList(entityName,propName)
			{
				try {
					this[`${entityName}ListIsLoading`] = true;
					const res = await this.props[propName].data({start:0, length:1000});

					if(res.status == 200){
						this[`${entityName}List`] = res.data.items;
					}

				} catch (e) {
					console.log(e)
				}


				this[`${entityName}ListIsLoading`] = false;
			},

			isRequired(name)
			{
				return this.props[name].setConfig.patterns.includes('required');
			},

			onHandelChange(name)
			{
				if (this.useValidator)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}

				this.props[name].isChanged = true;
			},

			onHandelKeyUp(name)
			{
				if (this.useValidator && this.props[name].isChanged)
				{
					this.props[name].errors =
						validator.getPropErrors(this.props[name]);
				}
			},

			getFirstError(name)
			{
				const prop = this.props[name];

				if (prop.errors.length > 0)
				{
					return this.$loc.fromResource(prop.errors[0].resourceKey,{
						label: this.$loc.fromResource(prop.resourceKey),
					})
				}

				return ''
			},

			getValue()
			{
				if (this.useValidator)
				{
					if (validator.getObjectErrorLength(this.props) > 0)
					{
						return undefined;
					}
				}

				var value = {};
				var keys = Object.keys(this.props);
				for (var i = 0; i < keys.length; i++)
				{
					const key = keys[i];
					const prop = this.props[key];

					value[key] =
						prop.setConfig.inputType == 'checkdrop' ?
							prop.value == 'true' : prop.value
				}

				return value
			},

			async submit()
			{
				try
				{
					const payload = this.getValue();

					if (payload)
					{
						this.isLoading = true;
						const service = new WalletWithDrawService();
						const res = await service.totalRequest(payload);

						if (res.status == 200)
						{
							this.props = new WalletWithDraw().props;
						}
					}

				} catch (e) { console.log(e) }

				this.isLoading = false;
			}
		}
	}
</script>