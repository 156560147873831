//GeneratorFlag:[NoChange]
export default {
	path: 'news',
	name: 'peopleNet.news.layout',
	redirect: to => {
		return {name: 'peopleNet.news.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/news"],
		resourceKey: 'peopleNet.views.news.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NewsCreate]"
		{
			path: 'create',
			name: 'peopleNet.news.create',
			component: () => import('@/modules/people-net/views/news/news-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.news.create',
				clientAccessKeys:["/people-net/news/create"],
				serverAccessKeys:["/people-net/news/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewsCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.news.createImport',
			component: () => import('@/modules/people-net/views/news/news-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.news.createImport',
				clientAccessKeys:["/people-net/news/create-import"],
				serverAccessKeys:["/people-net/news/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewsDelete]"
		{
			path: 'delete',
			name: 'peopleNet.news.delete',
			component: () => import('@/modules/people-net/views/news/news-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.news.delete',
				clientAccessKeys:["/people-net/news/delete"],
				serverAccessKeys:["/people-net/news/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewsDetail]"
		{
			path: 'detail',
			name: 'peopleNet.news.detail',
			component: () => import('@/modules/people-net/views/news/news-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.news.detail',
				clientAccessKeys:["/people-net/news/detail"],
				serverAccessKeys:["/people-net/news/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewsList]"
		{
			path: 'list',
			name: 'peopleNet.news.list',
			component: () => import('@/modules/people-net/views/news/news-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.news.list',
				clientAccessKeys:["/people-net/news/list"],
				serverAccessKeys:["/people-net/news/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewsUpdate]"
		{
			path: 'update',
			name: 'peopleNet.news.update',
			component: () => import('@/modules/people-net/views/news/news-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.news.update',
				clientAccessKeys:["/people-net/news/update"],
				serverAccessKeys:["/people-net/news/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}