//GeneratorFlag:[NoChange]
import Server from '@/core/server'
export default class PersonLegalService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PersonLegal.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person-legal/create', params || { });
	};

	//GeneratorFlag:[PersonLegal.Delete]
	async delete(personId, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		const params = {
			id:personId
		}
	
		return await _server.delete('/hub/person-legal/delete', {'params':params})
	};

	//GeneratorFlag:[PersonLegal.Detail]
	async detail(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = false;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.get('/hub/person-legal/detail', {'params':params});
	};

	//GeneratorFlag:[PersonLegal.Update]
	async update(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/hub/person-legal/update', params || { });
	};

//GeneratorFlag:[NewAction]
}