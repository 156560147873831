//GeneratorFlag:[NoChange]
export default {
	path: 'company-rank',
	name: 'peopleNet.baseInfo.companyRank.layout',
	redirect: to => {
		return {name: 'peopleNet.companyRank.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-info/company-rank"],
		resourceKey: 'peopleNet.views.companyRank.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[CompanyRankCreate]"
		{
			path: 'create',
			name: 'peopleNet.companyRank.create',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.create',
				clientAccessKeys:["/people-net/base-info/company-rank/create"],
				serverAccessKeys:["/people-net/company-rank/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyRankCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.companyRank.createImport',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.createImport',
				clientAccessKeys:["/people-net/base-info/company-rank/create-import"],
				serverAccessKeys:["/people-net/company-rank/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyRankDelete]"
		{
			path: 'delete',
			name: 'peopleNet.companyRank.delete',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.delete',
				clientAccessKeys:["/people-net/base-info/company-rank/delete"],
				serverAccessKeys:["/people-net/company-rank/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyRankDetail]"
		{
			path: 'detail',
			name: 'peopleNet.companyRank.detail',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.detail',
				clientAccessKeys:["/people-net/base-info/company-rank/detail"],
				serverAccessKeys:["/people-net/company-rank/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyRankList]"
		{
			path: 'list',
			name: 'peopleNet.companyRank.list',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.list',
				clientAccessKeys:["/people-net/base-info/company-rank/list"],
				serverAccessKeys:["/people-net/company-rank/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[CompanyRankUpdate]"
		{
			path: 'update',
			name: 'peopleNet.companyRank.update',
			component: () => import('@/modules/people-net/views/company-rank/company-rank-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.companyRank.update',
				clientAccessKeys:["/people-net/base-info/company-rank/update"],
				serverAccessKeys:["/people-net/company-rank/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}