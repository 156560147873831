//GeneratorFlag:[NoChange]
export default {
	path: 'person-request-dipost',
	name: 'peopleNet.personRequestDipost.layout',
	redirect: to => {
		return {name: 'peopleNet.personRequestDipost.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/person-request-dipost"],
		resourceKey: 'peopleNet.views.personRequestDipost.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonRequestDipostCreate]"
		{
			path: 'create',
			name: 'peopleNet.personRequestDipost.create',
			component: () => import('@/modules/people-net/views/person-request-dipost/person-request-dipost-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipost.create',
				clientAccessKeys:["/people-net/person-request-dipost/create"],
				serverAccessKeys:["/people-net/person-request-dipost/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRequestDipostCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.personRequestDipost.createImport',
			component: () => import('@/modules/people-net/views/person-request-dipost/person-request-dipost-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipost.createImport',
				clientAccessKeys:["/people-net/person-request-dipost/create-import"],
				serverAccessKeys:["/people-net/person-request-dipost/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRequestDipostDelete]"
		{
			path: 'delete',
			name: 'peopleNet.personRequestDipost.delete',
			component: () => import('@/modules/people-net/views/person-request-dipost/person-request-dipost-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipost.delete',
				clientAccessKeys:["/people-net/person-request-dipost/delete"],
				serverAccessKeys:["/people-net/person-request-dipost/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRequestDipostDetail]"
		{
			path: 'detail',
			name: 'peopleNet.personRequestDipost.detail',
			component: () => import('@/modules/people-net/views/person-request-dipost/person-request-dipost-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipost.detail',
				clientAccessKeys:["/people-net/person-request-dipost/detail"],
				serverAccessKeys:["/people-net/person-request-dipost/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRequestDipostList]"
		{
			path: 'list',
			name: 'peopleNet.personRequestDipost.list',
			component: () => import('@/modules/people-net/views/person-request-dipost/person-request-dipost-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipost.list',
				clientAccessKeys:["/people-net/person-request-dipost/list"],
				serverAccessKeys:["/people-net/person-request-dipost/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonRequestDipostUpdate]"
		{
			path: 'update',
			name: 'peopleNet.personRequestDipost.update',
			component: () => import('@/modules/people-net/views/person-request-dipost/person-request-dipost-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.personRequestDipost.update',
				clientAccessKeys:["/people-net/person-request-dipost/update"],
				serverAccessKeys:["/people-net/person-request-dipost/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}