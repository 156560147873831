//GeneratorFlag:[NoChange]
export default {
	path: 'person-pre-transaction-statuse',
	name: 'shop.baseSys.personPreTransactionStatuse.layout',
	redirect: to => {
		return {name: 'shop.personPreTransactionStatuse.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/person-pre-transaction-statuse"],
		resourceKey: 'shop.views.personPreTransactionStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonPreTransactionStatuseDetail]"
		{
			path: 'detail',
			name: 'shop.personPreTransactionStatuse.detail',
			component: () => import('@/modules/shop/views/person-pre-transaction-statuse/person-pre-transaction-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransactionStatuse.detail',
				clientAccessKeys:["/shop/base-sys/person-pre-transaction-statuse/detail"],
				serverAccessKeys:["/shop/person-pre-transaction-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonPreTransactionStatuseList]"
		{
			path: 'list',
			name: 'shop.personPreTransactionStatuse.list',
			component: () => import('@/modules/shop/views/person-pre-transaction-statuse/person-pre-transaction-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.personPreTransactionStatuse.list',
				clientAccessKeys:["/shop/base-sys/person-pre-transaction-statuse/list"],
				serverAccessKeys:["/shop/person-pre-transaction-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}