//GeneratorFlag:[NoChange]
export default {
	path: 'invoice-payment-type',
	name: 'shop.baseSys.invoicePaymentType.layout',
	redirect: to => {
		return {name: 'shop.invoicePaymentType.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/invoice-payment-type"],
		resourceKey: 'shop.views.invoicePaymentType.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[InvoicePaymentTypeDetail]"
		{
			path: 'detail',
			name: 'shop.invoicePaymentType.detail',
			component: () => import('@/modules/shop/views/invoice-payment-type/invoice-payment-type-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoicePaymentType.detail',
				clientAccessKeys:["/shop/base-sys/invoice-payment-type/detail"],
				serverAccessKeys:["/shop/invoice-payment-type/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[InvoicePaymentTypeList]"
		{
			path: 'list',
			name: 'shop.invoicePaymentType.list',
			component: () => import('@/modules/shop/views/invoice-payment-type/invoice-payment-type-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.invoicePaymentType.list',
				clientAccessKeys:["/shop/base-sys/invoice-payment-type/list"],
				serverAccessKeys:["/shop/invoice-payment-type/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}