import qs from 'qs';
import _ from 'lodash';
import axios from 'axios'
import plugins from '@/plugins'
import store from '@/core/store'
import Localization from '../localization'


class ServerUtility {
	constructor() {
		this.loc = new Localization();
	}

	getErrorMessage(status, message) {
		if (status == undefined) {
			return this.loc.fromResource(`status.noResponse`)
		}

		message = message || "";
		status = _.camelCase(status);

		switch (message) {
			case "Permission Denied":
				message = this.loc.fromResource(`message.permissionDenied`);
				break;
			case "Delete Failed - Relation Error":
				message = this.loc.fromResource(`message.deleteRelationError`);
				break;
			case "Unavailable Service":
				message = this.loc.fromResource(`message.unavailableService`);
				break;
		}


		return this.loc.fromResource(`status.${status}`, { message: message })
	}

}

class Server {
	constructor(config) {
		this.gateway = 'public';
		this.config = config || {};
		this.loc = new Localization();

		var server = axios.create({
			baseURL: store.state.server.baseUrl
		});

		server.interceptors.request.use((config) => {
			config.headers['Authorization'] = this.getAuthorization();
			config.paramsSerializer = params => {
				return qs.stringify(params, {
					arrayFormat: "repeat",
					allowDots: true,
					encode: false
				});
			};

			return config;

		}, (error) => { return Promise.reject(error) });


		server.interceptors.response.use((response) => {

			if (this.config.HSR) {
				this.handelSuccessResponse(response);
			}

			return response;

		}, (error) => {

			if (this.config.HER) {
				this.handleErrorResponse(error);
			}

			return Promise.reject(error)
		});

		this.get = server.get;
		this.post = server.post;
		this.delete = server.delete;
	}

	getAuthorization() {
		const accessToken = localStorage.getItem('kaveh.hub.accessToken');
		return accessToken ? `Bearer ${accessToken}` : "";
	};

	getBaseURL() {
		for (var gate of this.gateways) {
			if (gate.name == this.gateway) {
				return gate.baseURL;
			}
		}
	};

	handelSuccessResponse() {
		plugins.toast.success('status.ok');
	}

	handleErrorResponse(error) {

		if (error.response == undefined) {
			plugins.toast.warning('message.noResponse');
			return;
		}

		var message =
			new ServerUtility().getErrorMessage(
				error.response.data.status, error.response.data.message
			)

		plugins.toast.error(null, message);
	};
}

export { ServerUtility }
export default Server
