<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" />
</template>
<script>
	import { Store, StoreFilter } from '@/modules/shop/data/store/list'
	import { StoreService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return Store;
			},
			
			filterEntity()
			{
				return StoreFilter;
			},

			service()
			{
				return new StoreService();
			},

			filterData()
			{
				const storeId =
					this.$route.query['storeId'];

				if (storeId)
				{
					return {
						'storeId': storeId
					};
				};
				return null;
			},

			actions()
			{
				return [
					{
						routeName: 'shop.store.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'shop.store.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'shop.store.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'shop.store.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'shop.store.detail',
							resourceKey: 'shop.views.store.detail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>