<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { PersonMarital, PersonMaritalFilter } from '@/modules/hub/data/person-marital/list'
	import { PersonMaritalService } from '@/modules/hub/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonMarital;
			},
			
			filterEntity()
			{
				return PersonMaritalFilter;
			},

			service()
			{
				return new PersonMaritalService();
			},

			filterData()
			{
				const personMaritalId =
					this.$route.query['personMaritalId'];

				if (personMaritalId)
				{
					return {
						'personMaritalId': personMaritalId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>