<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { PersonCreditTransaction, PersonCreditTransactionFilter } from '@/modules/people-net/data/person-credit-transaction/list'
	import { PersonCreditTransactionService } from '@/modules/people-net/services'
	export default {
		computed:
		{
			entity()
			{
				return PersonCreditTransaction;
			},
			
			filterEntity()
			{
				return PersonCreditTransactionFilter;
			},

			service()
			{
				return new PersonCreditTransactionService();
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				try
				{
					return await this.service.peopleNet(payload);
				} catch (e) { console.log(e) }
			}
		}
	}
</script>