<template>
	<kaveh-collapse :collapsed="isParent">
		<template v-slot:header>
			<span>{{ $loc.fromResource(resourceKey) }}</span>
			<small v-if="isRequired" class="text-danger"> * </small>
			<span v-if="showError" class="text-danger float-end me-2 ms-2">
				<kaveh-icon-bootstrap size="17px" name="bi-exclamation-circle" />
			</span>
		</template>
		<template v-slot:body>
			<template :key="i" v-for="(prop,i) in props">

				<form-collapse v-if="prop.type == 'object' && prop.entity"
							   :mode="mode"
							   :is-parent="false"
							   :entity="prop.entity"
							   :display-type="displayType"
							   :prop-resource-key="prop.resourceKey"
							   :use-validation="useValidation"
							   :is-required="entity.propHasRequired(prop)"
							   v-on:generator-up="(x) => {$emit('generator-up', x)}"
							   v-on:generator-down="(x) => {$emit('generator-down', x)}"
							   v-on:generator-remove="(x) => {$emit('generator-remove', x)}"
							   v-on:generator-select="(x) => {$emit('generator-select', x)}" />

				<form-control v-else
							  :mode="mode"
							  :prop="prop"
							  :display-type="displayType"
							  :use-validation="useValidation"
							  v-on:submit="$parent.submit"
							  v-on:generator-up="(x) => {$emit('generator-up', x)}"
							  v-on:generator-down="(x) => {$emit('generator-down', x)}"
							  v-on:generator-remove="(x) => {$emit('generator-remove', x)}"
							  v-on:generator-select="(x) => {$emit('generator-select', x)}" />

			</template>
		</template>
	</kaveh-collapse>
</template>
<script>
	import formControl from './form-control'
	export default {
		emits: ['generator-remove', 'generator-up', 'generator-down', 'generator-select'],

		components: {
			'form-control': formControl
		},

		props: {

			'entity': {
				type: Object
			},

			'mode': {
				type: String
			},

			'displayType': {
				type: String
			},

			'isParent': {
				type: Boolean,
				default: true
			},

			'isRequired': {
				type: Boolean,
				default: false
			},

			'useValidation': {
				type: Boolean,
				default: true
			},

			'propResourceKey': {
				type: String,
				default: undefined
			},
		},

		computed:
		{
			showError() {
				if (this.entity && this.entity.type == 'object') {
					return (this.useValidation && this.entity.getFormErrorLength() > 0)
				}

				return false
			},

			props() {
				return this.entity.getFormProps();
			},

			resourceKey() {
				return this.propResourceKey || this.entity.resourceKey
			}
		}
	}
</script>
