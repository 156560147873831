//GeneratorFlag:[NoChange]
export default {
	path: 'person-nationality',
	name: 'hub.baseInfo.personNationality.layout',
	redirect: to => {
		return {name: 'hub.personNationality.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-info/person-nationality"],
		resourceKey: 'hub.views.personNationality.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonNationalityCreate]"
		{
			path: 'create',
			name: 'hub.personNationality.create',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.create',
				clientAccessKeys:["/hub/base-info/person-nationality/create"],
				serverAccessKeys:["/hub/person-nationality/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityCreateImport]"
		{
			path: 'create-import',
			name: 'hub.personNationality.createImport',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.createImport',
				clientAccessKeys:["/hub/base-info/person-nationality/create-import"],
				serverAccessKeys:["/hub/person-nationality/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityDelete]"
		{
			path: 'delete',
			name: 'hub.personNationality.delete',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.delete',
				clientAccessKeys:["/hub/base-info/person-nationality/delete"],
				serverAccessKeys:["/hub/person-nationality/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityDetail]"
		{
			path: 'detail',
			name: 'hub.personNationality.detail',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.detail',
				clientAccessKeys:["/hub/base-info/person-nationality/detail"],
				serverAccessKeys:["/hub/person-nationality/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityList]"
		{
			path: 'list',
			name: 'hub.personNationality.list',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.list',
				clientAccessKeys:["/hub/base-info/person-nationality/list"],
				serverAccessKeys:["/hub/person-nationality/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonNationalityUpdate]"
		{
			path: 'update',
			name: 'hub.personNationality.update',
			component: () => import('@/modules/hub/views/person-nationality/person-nationality-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personNationality.update',
				clientAccessKeys:["/hub/base-info/person-nationality/update"],
				serverAccessKeys:["/hub/person-nationality/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}