<template>
	<span dir="ltr" v-if="hasValue" class="kaveh-table-color" :title="data.value" :style="{'background':data.value}">
	</span>
</template>
<script>
	export default {
		props:
		{
			data: Object
		},

		computed:
		{

			hasValue()
			{
				return this.data.value != null && this.data.value != undefined
			}
		}
	}
</script>
<style>
	.kaveh-table-color {
		width: 25px;
		height: 25px;
		border: #ddd solid 1px;
		display: inline-block;
		border-radius:100%;
	}
</style>
