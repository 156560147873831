//GeneratorFlag:[NoChange]
export default {
	path: 'person-entity',
	name: 'hub.baseSys.personEntity.layout',
	redirect: to => {
		return {name: 'hub.personEntity.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/base-sys/person-entity"],
		resourceKey: 'hub.views.personEntity.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PersonEntityDetail]"
		{
			path: 'detail',
			name: 'hub.personEntity.detail',
			component: () => import('@/modules/hub/views/person-entity/person-entity-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personEntity.detail',
				clientAccessKeys:["/hub/base-sys/person-entity/detail"],
				serverAccessKeys:["/hub/person-entity/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PersonEntityList]"
		{
			path: 'list',
			name: 'hub.personEntity.list',
			component: () => import('@/modules/hub/views/person-entity/person-entity-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.views.personEntity.list',
				clientAccessKeys:["/hub/base-sys/person-entity/list"],
				serverAccessKeys:["/hub/person-entity/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}