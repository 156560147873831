<template>
	<input v-bind:id="prop.name"
		   v-on:change="onHandelChange"
		   v-on:keyup="onHandelChange"
		   inputmode="numeric"
		   dir="ltr"
		   v-model="value"
		   type="text"
		   class="form-control"
		   autocomplete="off" />
	<!--<small v-if="value && value != ''" class="text-muted d-block mt-1 mb-1">{{getWords()}}</small>-->
</template>
<script>
	import { persian } from '@/plugins'
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				value: null
			}
		},

		beforeMount()
		{
			if (this.prop.value) { this.value = String(this.prop.value) }
		},

		methods: {

			getNumberValue() {
				return Number(this.getStringValue());
			},

			getStringValue() {
				return (this.value || "").replace(/,/gi, "");
			},

			getWords() {
				var value =
					this.getStringValue();

				return value != "" ? persian.tools(value).digitsToWords() : "" ;
			},

			getCommaValue() {
				return this.getStringValue().split(/(?=(?:\d{3})+$)/).join(",");
			},

			onHandelChange()
			{
				const numberValue = this.getNumberValue()
				const stringValue = this.getStringValue()


				if (stringValue == "" || !Number.isInteger(numberValue)) {
					this.value = "";
					this.$emit('change', "");
				}
				else {
					this.value = this.getCommaValue()
					this.$emit('change', numberValue);
				}
			},

			onHandelKeyUp(e) {

				if (e.key === 'Enter' || e.keyCode === 13) {
					this.$emit('submit')
				}
				else {

					this.onHandelChange()
				}
			}
		}
	}
</script>
