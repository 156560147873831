
const INPUT_TYPE =
{
	TEXT: 'text',
	HIDDEN: 'hidden',
	TEXTAREA: 'textarea',
	NUMBER: 'number',
	PASSWORD: 'password',
	EMAIL: 'email',
	DATE: 'date',
	TIME: 'time',
	HIDDEN: 'hidden',
	SELECT: 'select',
	CHECKBOX:'checkbox',
	CHECKDROP:'checkdrop',
	COLOR: 'color',
	NONE: 'none',
	FORM:'form',
	UPLOADER:'uploader',
	DATA_PICKER: 'date-picker',
	TIME_PICKER: 'time-picker',
	TEXT_EDITOR:'text-editor',
	SIGNATURE_PAD:'signature-pad',
};

export default Object.freeze(INPUT_TYPE);
