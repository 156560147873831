//GeneratorFlag:[NoChange]
export default {
	path: 'plan-transaction-statuse',
	name: 'shop.baseSys.planTransactionStatuse.layout',
	redirect: to => {
		return {name: 'shop.planTransactionStatuse.list'};
	},
	component: () => import('@/modules/shop/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/shop/base-sys/plan-transaction-statuse"],
		resourceKey: 'shop.views.planTransactionStatuse.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PlanTransactionStatuseDetail]"
		{
			path: 'detail',
			name: 'shop.planTransactionStatuse.detail',
			component: () => import('@/modules/shop/views/plan-transaction-statuse/plan-transaction-statuse-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransactionStatuse.detail',
				clientAccessKeys:["/shop/base-sys/plan-transaction-statuse/detail"],
				serverAccessKeys:["/shop/plan-transaction-statuse/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PlanTransactionStatuseList]"
		{
			path: 'list',
			name: 'shop.planTransactionStatuse.list',
			component: () => import('@/modules/shop/views/plan-transaction-statuse/plan-transaction-statuse-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'shop.views.planTransactionStatuse.list',
				clientAccessKeys:["/shop/base-sys/plan-transaction-statuse/list"],
				serverAccessKeys:["/shop/plan-transaction-statuse/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]


	]
}