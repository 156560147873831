//GeneratorFlag:[NoChange]
export default {
	path: 'rank',
	name: 'peopleNet.baseInfo.rank.layout',
	redirect: to => {
		return {name: 'peopleNet.rank.list'};
	},
	component: () => import('@/modules/people-net/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/people-net/base-info/rank"],
		resourceKey: 'peopleNet.views.rank.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[RankCreate]"
		{
			path: 'create',
			name: 'peopleNet.rank.create',
			component: () => import('@/modules/people-net/views/rank/rank-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.create',
				clientAccessKeys:["/people-net/base-info/rank/create"],
				serverAccessKeys:["/people-net/rank/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RankCreateImport]"
		{
			path: 'create-import',
			name: 'peopleNet.rank.createImport',
			component: () => import('@/modules/people-net/views/rank/rank-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.createImport',
				clientAccessKeys:["/people-net/base-info/rank/create-import"],
				serverAccessKeys:["/people-net/rank/create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RankDelete]"
		{
			path: 'delete',
			name: 'peopleNet.rank.delete',
			component: () => import('@/modules/people-net/views/rank/rank-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.delete',
				clientAccessKeys:["/people-net/base-info/rank/delete"],
				serverAccessKeys:["/people-net/rank/delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RankDetail]"
		{
			path: 'detail',
			name: 'peopleNet.rank.detail',
			component: () => import('@/modules/people-net/views/rank/rank-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.detail',
				clientAccessKeys:["/people-net/base-info/rank/detail"],
				serverAccessKeys:["/people-net/rank/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RankList]"
		{
			path: 'list',
			name: 'peopleNet.rank.list',
			component: () => import('@/modules/people-net/views/rank/rank-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.list',
				clientAccessKeys:["/people-net/base-info/rank/list"],
				serverAccessKeys:["/people-net/rank/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[RankUpdate]"
		{
			path: 'update',
			name: 'peopleNet.rank.update',
			component: () => import('@/modules/people-net/views/rank/rank-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'peopleNet.views.rank.update',
				clientAccessKeys:["/people-net/base-info/rank/update"],
				serverAccessKeys:["/people-net/rank/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]






	]
}