//GeneratorFlag:[NoChange]
import noticeNoticeConfigRoute from './notice/notice-config-route'; //GeneratorFlag:[NoticeNoticeConfig]
import personRoute from './person-route'; //GeneratorFlag:[Person]
import geographicalCityRoute from './geographical/city-route'; //GeneratorFlag:[GeographicalCity]
import geographicalCountryRoute from './geographical/country-route'; //GeneratorFlag:[GeographicalCountry]
import baseInfoPersonMilitaryRoute from './base-info/person-military-route'; //GeneratorFlag:[BaseInfoPersonMilitary]
import baseInfoPersonNationalityRoute from './base-info/person-nationality-route'; //GeneratorFlag:[BaseInfoPersonNationality]
import baseInfoPersonRoleRoute from './base-info/person-role-route'; //GeneratorFlag:[BaseInfoPersonRole]
import geographicalProvinceRoute from './geographical/province-route'; //GeneratorFlag:[GeographicalProvince]
import geographicalRegionRoute from './geographical/region-route'; //GeneratorFlag:[GeographicalRegion]
import baseSysFileExtensionRoute from './base-sys/file-extension-route'; //GeneratorFlag:[BaseSysFileExtension]
import noticeNoticeTypeRoute from './notice/notice-type-route'; //GeneratorFlag:[NoticeNoticeType]
import baseSysPersonEntityRoute from './base-sys/person-entity-route'; //GeneratorFlag:[BaseSysPersonEntity]
import baseSysPersonGenderRoute from './base-sys/person-gender-route'; //GeneratorFlag:[BaseSysPersonGender]
import baseSysPersonGradeRoute from './base-sys/person-grade-route'; //GeneratorFlag:[BaseSysPersonGrade]
import baseSysPersonMaritalRoute from './base-sys/person-marital-route'; //GeneratorFlag:[BaseSysPersonMarital]
import accessAgentRoute from './access-agent-route'; //GeneratorFlag:[AccessAgent]
import baseSysNoticeProviderRoute from './base-sys/notice-provider-route'; //GeneratorFlag:[BaseSysNoticeProvider]
import baseSysNoticeStatusRoute from './base-sys/notice-status-route'; //GeneratorFlag:[BaseSysNoticeStatus]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'hub',
	name: 'hub',
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/hub'],
		serverAccessKeys:[],
		resourceKey: 'service.hub',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-intersect',
		}
	},
	children: [
personRoute, accessAgentRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[NoticeLayout]
		{
			path: 'notice',
			name: 'hub.notice',
			component: () => import('@/modules/hub/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.layout.notice',
				clientAccessKeys:['/hub/notice'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			noticeNoticeConfigRoute, noticeNoticeTypeRoute, //GeneratorFlag:[NewNoticeChild]
			]
		},
		//GeneratorFlag:[GeographicalLayout]
		{
			path: 'geographical',
			name: 'hub.geographical',
			component: () => import('@/modules/hub/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.layout.geographical',
				clientAccessKeys:['/hub/geographical'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-layers-half',
				}
			},
			children: [
			geographicalCityRoute, geographicalCountryRoute, geographicalProvinceRoute, geographicalRegionRoute, //GeneratorFlag:[NewGeographicalChild]
			]
		},
		//GeneratorFlag:[BaseInfoLayout]
		{
			path: 'base-info',
			name: 'hub.baseInfo',
			component: () => import('@/modules/hub/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.layout.baseInfo',
				clientAccessKeys:['/hub/base-info'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			baseInfoPersonMilitaryRoute, baseInfoPersonNationalityRoute, baseInfoPersonRoleRoute, //GeneratorFlag:[NewBaseInfoChild]
			]
		},
		//GeneratorFlag:[BaseSysLayout]
		{
			path: 'base-sys',
			name: 'hub.baseSys',
			component: () => import('@/modules/hub/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'hub.layout.baseSys',
				clientAccessKeys:['/hub/base-sys'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-lock',
				}
			},
			children: [
			baseSysFileExtensionRoute, baseSysPersonEntityRoute, baseSysPersonGenderRoute, baseSysPersonGradeRoute, baseSysPersonMaritalRoute, baseSysNoticeProviderRoute, baseSysNoticeStatusRoute, //GeneratorFlag:[NewBaseSysChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
noticeNoticeConfigRoute, personRoute, geographicalCityRoute, geographicalCountryRoute, baseInfoPersonMilitaryRoute, baseInfoPersonNationalityRoute, baseInfoPersonRoleRoute, geographicalProvinceRoute, geographicalRegionRoute, baseSysFileExtensionRoute, noticeNoticeTypeRoute, baseSysPersonEntityRoute, baseSysPersonGenderRoute, baseSysPersonGradeRoute, baseSysPersonMaritalRoute, accessAgentRoute, baseSysNoticeProviderRoute, baseSysNoticeStatusRoute, //GeneratorFlag:[NewExport]
}