<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-data="filterData" :filter-entity="filterEntity" :selectable="false" />
</template>
<script>
	import { InvoicePaymentType, InvoicePaymentTypeFilter } from '@/modules/shop/data/invoice-payment-type/list'
	import { InvoicePaymentTypeService } from '@/modules/shop/services'
	export default {
		computed:
		{
			entity()
			{
				return InvoicePaymentType;
			},
			
			filterEntity()
			{
				return InvoicePaymentTypeFilter;
			},

			service()
			{
				return new InvoicePaymentTypeService();
			},

			filterData()
			{
				const invoicePaymentTypeId =
					this.$route.query['invoicePaymentTypeId'];

				if (invoicePaymentTypeId)
				{
					return {
						'invoicePaymentTypeId': invoicePaymentTypeId
					};
				};
				return null;
			},

			actions()
			{
				return []
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>