<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-excel-import :key="ver" :entity="entity" :task="import" @close="reset" @cancel="reset" />
</template>
<script>
	import { PersonRole } from '@/modules/hub/data/person-role/create'
	import { PersonRoleService } from '@/modules/hub/services'
	export default {
		data()
		{
			return {
				ver: 0
			}
		},
		computed:
		{
			entity()
			{
				return PersonRole;
			},

			service()
			{
				return new PersonRoleService();
			}
		},
		methods:
		{
			async import(payload)
			{
				return await this.service.create(payload, {HSR:false, HER:false});
			},

			reset()
			{
				this.ver = this.ver + 1;
			}
		}
	}
</script>