//GeneratorFlag:[NoChange]
export default {
	path: 'person-legal',
	name: 'hub.personLegal.layout',
	redirect: to => {
		return {name: 'hub.personLegal.list'};
	},
	component: () => import('@/modules/hub/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/hub/person-legal"],
		resourceKey: 'hub.views.personLegal.layout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[NewRoute]
	]
}