<template>
	<div class="row mt-2" :key="version">
		<kaveh-overlay class="col">
			<route-tree-node :items="routes" @change="handleChange" />
		</kaveh-overlay>
	</div>
</template>
<script>
	import RouteTreeNode from './route-tree-node'

	export default {
		emits: ['change'],

		props: {
			serverKeys: {
				type: Array
			},
			mode: {
				type: String,
				default: ''
			}
		},

		components: {
			'route-tree-node': RouteTreeNode
		},

		data()
		{
			return {
				routes: [],
				version:1,
			}
		},

		beforeMount()
		{
			this.init()
		},

		methods: {

			init()
			{
				var routes = []
				if (this.serverKeys && this.mode == 'server')
				{
					for (var i = 0; i < this.serverKeys.length; i++)
					{
						routes.push({
							meta: {
								label: this.serverKeys[i],
								serverAccessKeys: [this.serverKeys[i]]
							}
						})
					}
				}
				else
				{
					routes = JSON.parse(JSON.stringify(this.$router.options.routes[0].children))
				}

				this.routes = routes
			},

			bindCheckedChildren(item, checked)
			{
				for (var i = 0; i < (item.children || []).length; i++)
				{
					item.children[i].checked = checked;
					if (item.children[i].children)
					{
						this.bindCheckedChildren(item.children[i], checked)
					}
				}
			},


			getCheckedKeys(routes)
			{
				var result = [];
				routes = routes || this.routes;

				for (var i = 0; i < routes.length; i++)
				{
					const route = routes[i];

					if (route.checked)
					{
						const serverKeys = route.meta.serverAccessKeys || [];
						const clientKeys = route.meta.clientAccessKeys || [];

						if (serverKeys.length > 0)
						{
							for (var si = 0; si < serverKeys.length; si++)
							{
								result.push({ keyName: serverKeys[si], groupName: 'server' })
							}
						}

						if (clientKeys.length > 0)
						{
							for (var ci = 0; ci < clientKeys.length; ci++)
							{
								result.push({ keyName: clientKeys[ci], groupName: 'client-web' })
							}
						}
					}

					if (route.children)
					{
						const childResult =
							this.getCheckedKeys(route.children);
						result = [...result, ...childResult]
					}
				}

				return result;
			},

			handleChange(node)
			{
				this.bindCheckedChildren(node, node.checked);
				var keys = this.getCheckedKeys();

				this.$emit('change', keys)
			},

			cleanTreeChecked()
			{
				this.init();
				this.version = this.version + 1; 
			},

			setTreeChecked(accesskeys, routes)
			{

				routes = routes || this.routes;

				for (var i = 0; i < routes.length; i++)
				{

					const route = routes[i];

					if (this.mode == 'client')
					{
						const clientAccessKey = route.meta.clientAccessKeys[0];

						const index = accesskeys.findIndex((x) =>
						{
							return x.groupName == 'client-web' && x.keyName == clientAccessKey
						})

						if (index > -1)
						{
							routes[i].checked = true
						}

						if (route.children)
						{
							this.setTreeChecked(accesskeys, route.children)
						}
					}
					else
					{
						const serverAccessKey = route.meta.serverAccessKeys[0];

						const index = accesskeys.findIndex((x) =>
						{
							return x.groupName == 'server' && x.keyName == serverAccessKey
						})

						if (index > -1)
						{
							routes[i].checked = true
						}

						if (route.children)
						{
							this.setTreeChecked(accesskeys, route.children)
						}
					}
				}
			}
		}
	}
</script>
