<template>
	<popper class="popper-light" arrow hover>
		<span class="pointer truncate text-danger">
			{{data.displayValue}}
			<kaveh-icon-bootstrap name="bi-info-circle" color="red" class="ms-1" size="15px" />
		</span>
		<template #content>
			<div class="w-100" style="max-height:200px; overflow:auto;">
				<table>
					<tr v-for="(error,i) in data.errors" :key="i">
						<th>
							<small> {{ i + 1 }}. {{ getErrorText(error) }}</small>
						</th>
					</tr>
				</table>
			</div>
		</template>
	</popper>
</template>
<script>
	export default {
		props: {
			data: Object
		},

		methods: {
			getErrorText(error)
			{
				const label =
					this.$loc.fromResource(error.model.resourceKey);

				return this.$loc.fromResource(
					error.resourceKey, {
						label: label
				})
			},

			getFirstErrorText()
			{
				return this.getErrorText(this.data.errors[0])
			}
		}
	}
</script>
